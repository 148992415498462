// This is autogenerated based on the operationIds from the swagger. You probably shouldn't edit this directly!
export enum OperationIds {
  AddTuitionPayment = 'addTuitionPayment',
  AssignRole = 'assignRole',
  AuthorizeDotNetEventWebhook = 'authorizeDotNetEventWebhook',
  CancelEnrollment = 'cancelEnrollment',
  CancelInvitation = 'cancelInvitation',
  ChangeEmail = 'changeEmail',
  ChangePassword = 'changePassword',
  CheckCityUniqueness = 'checkCityUniqueness',
  CompleteAgreement = 'completeAgreement',
  CompleteAgreementWebhook = 'completeAgreementWebhook',
  CompleteEnrollmentInvite = 'completeEnrollmentInvite',
  ConfirmAdobeSignWebhook = 'confirmAdobeSignWebhook',
  ConfirmToken = 'confirmToken',
  ConfirmTokenForSignup = 'confirmTokenForSignup',
  CreateAgreement = 'createAgreement',
  CreateCommunity = 'createCommunity',
  CreateEvent = 'createEvent',
  CreateLicensingEnrollment = 'createLicensingEnrollment',
  CreateProgram = 'createProgram',
  CreateRegion = 'createRegion',
  CreateRegionContractingHierarchies = 'createRegionContractingHierarchies',
  CreateRegistrant = 'createRegistrant',
  CreateRole = 'createRole',
  CreateStudent = 'createStudent',
  CreateTranscript = 'createTranscript',
  CreateUser = 'createUser',
  DeleteAgreement = 'deleteAgreement',
  DeleteEvent = 'deleteEvent',
  DeletePayment = 'deletePayment',
  DeleteRegionContractingHierarchies = 'deleteRegionContractingHierarchies',
  DeleteRole = 'deleteRole',
  DeleteTranscript = 'deleteTranscript',
  EditRole = 'editRole',
  EditStudent = 'editStudent',
  EditUserProfile = 'editUserProfile',
  ExpireTeamMember = 'expireTeamMember',
  FavoritePath = 'favoritePath',
  FetchAcademicYears = 'fetchAcademicYears',
  FetchAgreementPreview = 'fetchAgreementPreview',
  FetchAgreementSigningUrl = 'fetchAgreementSigningUrl',
  FetchAgreementStatus = 'fetchAgreementStatus',
  FetchAgreementTemplateFormFields = 'fetchAgreementTemplateFormFields',
  FetchAllFamilies = 'fetchAllFamilies',
  FetchAllPrograms = 'fetchAllPrograms',
  FetchBilling = 'fetchBilling',
  FetchBusinessContent = 'fetchBusinessContent',
  FetchBusinessContentOptions = 'fetchBusinessContentOptions',
  FetchBusinessLearningPath = 'fetchBusinessLearningPath',
  FetchCommunitiesForUser = 'fetchCommunitiesForUser',
  FetchCommunity = 'fetchCommunity',
  FetchCommunityOptions = 'fetchCommunityOptions',
  FetchCommunitySpecificProgramOptions = 'fetchCommunitySpecificProgramOptions',
  FetchContentDetail = 'fetchContentDetail',
  FetchContentTagOptions = 'fetchContentTagOptions',
  FetchCountries = 'fetchCountries',
  FetchCoursesOfStudies = 'fetchCoursesOfStudies',
  FetchDashboard = 'fetchDashboard',
  FetchDiscourseConnectRedirectUrl = 'fetchDiscourseConnectRedirectUrl',
  FetchDownloadUrl = 'fetchDownloadUrl',
  FetchEnrollmentBillingPaymentOptions = 'fetchEnrollmentBillingPaymentOptions',
  FetchEnrollmentInvites = 'fetchEnrollmentInvites',
  FetchEnrollments = 'fetchEnrollments',
  FetchEnrollmentStatuses = 'fetchEnrollmentStatuses',
  FetchEvent = 'fetchEvent',
  FetchEventRegistrants = 'fetchEventRegistrants',
  FetchEvents = 'fetchEvents',
  FetchFamilyTuitionBilling = 'fetchFamilyTuitionBilling',
  FetchGradingScales = 'fetchGradingScales',
  FetchInternationalCountries = 'fetchInternationalCountries',
  FetchInternationalEvents = 'fetchInternationalEvents',
  FetchInviteFamilyCommunities = 'fetchInviteFamilyCommunities',
  FetchInviteFamilyPrograms = 'fetchInviteFamilyPrograms',
  FetchInviteTeamMemberOptions = 'fetchInviteTeamMemberOptions',
  FetchInviteValidity = 'fetchInviteValidity',
  FetchLearningCenterAvailableProgramTypes = 'fetchLearningCenterAvailableProgramTypes',
  FetchLearningCenterContent = 'fetchLearningCenterContent',
  FetchLearningCenterLearningPath = 'fetchLearningCenterLearningPath',
  FetchLearningCenterOptions = 'fetchLearningCenterOptions',
  FetchPaymentOptions = 'fetchPaymentOptions',
  FetchPermissions = 'fetchPermissions',
  FetchProgram = 'fetchProgram',
  FetchProgramOptions = 'fetchProgramOptions',
  FetchProgramsForUser = 'fetchProgramsForUser',
  FetchPublicEventDetails = 'fetchPublicEventDetails',
  FetchRegion = 'fetchRegion',
  FetchRegionContractingHierarchies = 'fetchRegionContractingHierarchies',
  FetchRegionFees = 'fetchRegionFees',
  FetchRegionOptions = 'fetchRegionOptions',
  FetchRegions = 'fetchRegions',
  FetchRoles = 'fetchRoles',
  FetchShopifyMultipassIdentifier = 'fetchShopifyMultipassIdentifier',
  FetchShopifyMultipassUrl = 'fetchShopifyMultipassUrl',
  FetchStates = 'fetchStates',
  FetchStudents = 'fetchStudents',
  FetchStudentsForFamily = 'fetchStudentsForFamily',
  FetchTeamAgreementsForUser = 'fetchTeamAgreementsForUser',
  FetchTeamForUser = 'fetchTeamForUser',
  FetchTimezones = 'fetchTimezones',
  FetchTranscript = 'fetchTranscript',
  FetchTranscripts = 'fetchTranscripts',
  FetchTuitionInvoice = 'fetchTuitionInvoice',
  FetcHubspotTerritory = 'fetcHubspotTerritory',
  FetchUser = 'fetchUser',
  FetchUserCommunities = 'fetchUserCommunities',
  FetchUserProfile = 'fetchUserProfile',
  FetchUserProfileImage = 'fetchUserProfileImage',
  FetchUsers = 'fetchUsers',
  Healthcheck = 'healthcheck',
  InviteFamily = 'inviteFamily',
  InviteTeamMember = 'inviteTeamMember',
  Login = 'login',
  LoginForInvite = 'loginForInvite',
  Logout = 'logout',
  LogoutAllSessions = 'logoutAllSessions',
  NdaAcknowledgementPath = 'ndaAcknowledgementPath',
  PayByAch = 'payByAch',
  RemoveLicensingEnrollments = 'removeLicensingEnrollments',
  RemoveTuitionPayment = 'removeTuitionPayment',
  ResendInvitation = 'resendInvitation',
  ResendTeamMember = 'resendTeamMember',
  ResetPassword = 'resetPassword',
  SearchPublicCommunities = 'searchPublicCommunities',
  SearchPublicCommunity = 'searchPublicCommunity',
  SearchPublicEvents = 'searchPublicEvents',
  SearchPublicInternationalCommunities = 'searchPublicInternationalCommunities',
  SearchPublicInternationalCommunity = 'searchPublicInternationalCommunity',
  SendTokenForSignup = 'sendTokenForSignup',
  SendTranscriptToParchment = 'sendTranscriptToParchment',
  SendTuitionInvoice = 'sendTuitionInvoice',
  SetRegionFees = 'setRegionFees',
  StarRating = 'starRating',
  StartPayment = 'startPayment',
  SubmitContactForm = 'submitContactForm',
  SyncShopifyDiscounts = 'syncShopifyDiscounts',
  TransferEnrollment = 'transferEnrollment',
  UnassignRole = 'unassignRole',
  UpdateCommunity = 'updateCommunity',
  UpdateEnrollmentStatus = 'updateEnrollmentStatus',
  UpdateEvent = 'updateEvent',
  UpdateInvoiceLine = 'updateInvoiceLine',
  UpdateLicensingEnrollments = 'updateLicensingEnrollments',
  UpdateLogin = 'updateLogin',
  UpdatePayment = 'updatePayment',
  UpdatePaymentAddress = 'updatePaymentAddress',
  UpdateProgram = 'updateProgram',
  UpdateRegion = 'updateRegion',
  UpdateRegionContentTags = 'updateRegionContentTags',
  UpdateRegistrant = 'updateRegistrant',
  UpdateRole = 'updateRole',
  UpdateStudentTutorAssignments = 'updateStudentTutorAssignments',
  UpdateTranscript = 'updateTranscript',
  UpdateTuitionPayment = 'updateTuitionPayment',
  UpdateUser = 'updateUser',
  ValidateAchRoutingNumber = 'validateAchRoutingNumber',
  ValidateAddress = 'validateAddress',
  ValidateInvitation = 'validateInvitation',
  ValidatePotentialEnrollments = 'validatePotentialEnrollments',
}
