/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface ServerStatusVersion
 */
export interface ServerStatusVersion {
  /**
   * Optional note. Generally absent except in local dev.
   * @type {string}
   * @memberof ServerStatusVersion
   */
  note?: string
  /**
   * The short hash for the deployed git object.
   * @type {string}
   * @memberof ServerStatusVersion
   */
  version: string
  /**
   * The full hash for the deployed git object, included in the off chance the short `version` happens not to be unique.
   * @type {string}
   * @memberof ServerStatusVersion
   */
  hash: string
}

export function ServerStatusVersionFromJSON(json: any): ServerStatusVersion {
  return ServerStatusVersionFromJSONTyped(json, false)
}

export function ServerStatusVersionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ServerStatusVersion {
  if (json === undefined || json === null) {
    return json
  }
  return {
    note: !exists(json, 'note') ? undefined : json['note'],
    version: json['version'],
    hash: json['hash'],
  }
}

export function ServerStatusVersionToJSON(
  value?: ServerStatusVersion | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    note: value.note,
    version: value.version,
    hash: value.hash,
  }
}
