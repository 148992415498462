/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  ContentAssetRoles,
  ContentAssetRolesFromJSON,
  ContentAssetRolesToJSON,
} from './'

/**
 *
 * @export
 * @interface ContentAsset
 */
export interface ContentAsset {
  /**
   * Content asset unique identifier
   * @type {number}
   * @memberof ContentAsset
   */
  assetKey: number
  /**
   * The external id for the asset, used in embed links.
   * @type {string}
   * @memberof ContentAsset
   */
  embedId?: string
  /**
   * Generic link to the content
   * @type {string}
   * @memberof ContentAsset
   */
  embedUrl?: string
  /**
   * Thumbnail image for the content.
   * @type {string}
   * @memberof ContentAsset
   */
  thumbnailUrl?: string
  /**
   * The filesize of the content in kilobytes.
   * @type {number}
   * @memberof ContentAsset
   */
  filesize?: number
  /**
   * Duration of the video in seconds, contains partial seconds.
   * @type {number}
   * @memberof ContentAsset
   */
  videoDuration?: number
  /**
   * Specifies the role allowed to view the content. For example, 'parent', anyone with parent permission will be allowed to view the content.
   * @type {string}
   * @memberof ContentAsset
   */
  role: string
  /**
   * Within the Learning Center, the week in which the content made available.
   * @type {number}
   * @memberof ContentAsset
   */
  week?: number
  /**
   * Within the Learning Center, content belongs to a cycle, and within each cycle you have weeks. The hierarchy follows: cycle > week > content. There are three cycles, where each cycle lasts a year. A cycle is tied to the region.
   * @type {number}
   * @memberof ContentAsset
   */
  cycle?: number
  /**
   * Challenges are split into two semesters and within each semester you have weeks. The hierarchy follows: semester > week > content.
   * @type {number}
   * @memberof ContentAsset
   */
  semester?: number
  /**
   * A classification of content within a cycle / week or semester / week.
   * @type {string}
   * @memberof ContentAsset
   */
  strand?: string
  /**
   * This is what language the content is written in.
   * @type {string}
   * @memberof ContentAsset
   */
  language?: string
  /**
   * The type of file within the content asset e.g. video, pdf, document, excel
   * @type {string}
   * @memberof ContentAsset
   */
  filetype?: string
  /**
   * A description of the content.
   * @type {string}
   * @memberof ContentAsset
   */
  description?: string
  /**
   * Title of the content.
   * @type {string}
   * @memberof ContentAsset
   */
  title: string
  /**
   * The learning path title. All assets within a learning path will have the exact same title to be grouped into one learning path.
   * @type {string}
   * @memberof ContentAsset
   */
  learningPathTitle?: string
  /**
   * Determines the sort ordering for content within a learning path or welcome section.
   * @type {number}
   * @memberof ContentAsset
   */
  listingPriority?: number
  /**
   * True means that they are not able to download, a false would mean they can view and download the content.
   * @type {boolean}
   * @memberof ContentAsset
   */
  viewOnly?: boolean
  /**
   * Auxiliary words that improve content search, separated by commas.
   * @type {string}
   * @memberof ContentAsset
   */
  keywords?: string
  /**
   * True means that the asset is visible when searching for content, a false means that the asset is only visible inside of a Learning Path.
   * @type {boolean}
   * @memberof ContentAsset
   */
  searchOutsideLearningPath?: boolean
  /**
   * Category for the business tab. If this is business tab content then use this category.
   * @type {string}
   * @memberof ContentAsset
   */
  businessCategory?: string
  /**
   * Category for the learning center tab. If this is learning center content then use this category.
   * @type {string}
   * @memberof ContentAsset
   */
  learningCenterCategory?: string
  /**
   * The user's star rating assigned to this content asset. The rating is 1 out of 5 is an integer.
   * @type {number}
   * @memberof ContentAsset
   */
  starRating?: number
  /**
   * The average star rating assigned to this content asset. The rating is 1 out of 5 and can be a decimal.
   * @type {number}
   * @memberof ContentAsset
   */
  avgStarRating?: number
  /**
   * Whether or not the content asset has been favorited by the user.
   * @type {boolean}
   * @memberof ContentAsset
   */
  isFavorited?: boolean
  /**
   *
   * @type {string}
   * @memberof ContentAsset
   */
  learningPathDescription?: string
  /**
   * When True, prior to viewing the asset the user needs to acknowledge that they agree to an NDA. While False means they can directly view the asset. By default no NDA is required.
   * @type {boolean}
   * @memberof ContentAsset
   */
  isNdaRequired?: boolean
  /**
   * True when the user acknowledged an NDA to view a NDA-required asset or when an asset does not require an NDA. False when the user has not acknowledged the NDA for an NDA-required asset.
   * @type {boolean}
   * @memberof ContentAsset
   */
  ndaAcknowledged?: boolean
  /**
   *
   * @type {Array<ContentAssetRoles>}
   * @memberof ContentAsset
   */
  roles?: Array<ContentAssetRoles>
}

export function ContentAssetFromJSON(json: any): ContentAsset {
  return ContentAssetFromJSONTyped(json, false)
}

export function ContentAssetFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ContentAsset {
  if (json === undefined || json === null) {
    return json
  }
  return {
    assetKey: json['assetKey'],
    embedId: !exists(json, 'embedId') ? undefined : json['embedId'],
    embedUrl: !exists(json, 'embedUrl') ? undefined : json['embedUrl'],
    thumbnailUrl: !exists(json, 'thumbnailUrl')
      ? undefined
      : json['thumbnailUrl'],
    filesize: !exists(json, 'filesize') ? undefined : json['filesize'],
    videoDuration: !exists(json, 'videoDuration')
      ? undefined
      : json['videoDuration'],
    role: json['role'],
    week: !exists(json, 'week') ? undefined : json['week'],
    cycle: !exists(json, 'cycle') ? undefined : json['cycle'],
    semester: !exists(json, 'semester') ? undefined : json['semester'],
    strand: !exists(json, 'strand') ? undefined : json['strand'],
    language: !exists(json, 'language') ? undefined : json['language'],
    filetype: !exists(json, 'filetype') ? undefined : json['filetype'],
    description: !exists(json, 'description') ? undefined : json['description'],
    title: json['title'],
    learningPathTitle: !exists(json, 'learningPathTitle')
      ? undefined
      : json['learningPathTitle'],
    listingPriority: !exists(json, 'listingPriority')
      ? undefined
      : json['listingPriority'],
    viewOnly: !exists(json, 'viewOnly') ? undefined : json['viewOnly'],
    keywords: !exists(json, 'keywords') ? undefined : json['keywords'],
    searchOutsideLearningPath: !exists(json, 'searchOutsideLearningPath')
      ? undefined
      : json['searchOutsideLearningPath'],
    businessCategory: !exists(json, 'businessCategory')
      ? undefined
      : json['businessCategory'],
    learningCenterCategory: !exists(json, 'learningCenterCategory')
      ? undefined
      : json['learningCenterCategory'],
    starRating: !exists(json, 'starRating') ? undefined : json['starRating'],
    avgStarRating: !exists(json, 'avgStarRating')
      ? undefined
      : json['avgStarRating'],
    isFavorited: !exists(json, 'isFavorited') ? undefined : json['isFavorited'],
    learningPathDescription: !exists(json, 'learningPathDescription')
      ? undefined
      : json['learningPathDescription'],
    isNdaRequired: !exists(json, 'isNdaRequired')
      ? undefined
      : json['isNdaRequired'],
    ndaAcknowledged: !exists(json, 'ndaAcknowledged')
      ? undefined
      : json['ndaAcknowledged'],
    roles: !exists(json, 'roles')
      ? undefined
      : (json['roles'] as Array<any>).map(ContentAssetRolesFromJSON),
  }
}

export function ContentAssetToJSON(value?: ContentAsset | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    assetKey: value.assetKey,
    embedId: value.embedId,
    embedUrl: value.embedUrl,
    thumbnailUrl: value.thumbnailUrl,
    filesize: value.filesize,
    videoDuration: value.videoDuration,
    role: value.role,
    week: value.week,
    cycle: value.cycle,
    semester: value.semester,
    strand: value.strand,
    language: value.language,
    filetype: value.filetype,
    description: value.description,
    title: value.title,
    learningPathTitle: value.learningPathTitle,
    listingPriority: value.listingPriority,
    viewOnly: value.viewOnly,
    keywords: value.keywords,
    searchOutsideLearningPath: value.searchOutsideLearningPath,
    businessCategory: value.businessCategory,
    learningCenterCategory: value.learningCenterCategory,
    starRating: value.starRating,
    avgStarRating: value.avgStarRating,
    isFavorited: value.isFavorited,
    learningPathDescription: value.learningPathDescription,
    isNdaRequired: value.isNdaRequired,
    ndaAcknowledged: value.ndaAcknowledged,
    roles:
      value.roles === undefined
        ? undefined
        : (value.roles as Array<any>).map(ContentAssetRolesToJSON),
  }
}
