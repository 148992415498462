export enum EventFormCardVariants {
  CreateEvent = 'Create Event',
  EditEvent = 'Edit Event',
}

export enum EventFormValidationMessageTypes {
  Default = 'default',
  EventName = 'eventName',
  EventType = 'eventType',
  Registration = 'registration',
  MaxCapacity = 'maxCapacity',
  EventDescription = 'eventDescription',
  EventDescriptionMaxCapacity = 'eventDescriptionMaxCapacity',
  EventAdditionalInformationMaxCapacity = 'eventAdditionalInformationMaxCapacity',
  EventAdditionalInformation = 'eventAdditionalInformation',
  EventLocationType = 'eventLocationType',
  City = 'city',
  State = 'state',
  PostalCode = 'postalCode',
  Country = 'country',
  OnlineEventUrl = 'onlineEventUrl',
  Location = 'location',
  StartDate = 'startDate',
  BeforeMax = 'beforeMax',
  AfterMin = 'afterMin',
  EndDate = 'endDate',
  StartTime = 'startTime',
  EndTime = 'endTime',
  TimeZone = 'timeZone',
  StartDateGreaterThanEndDate = 'startDateGreater',
  EndDateGreaterOrEqualThanRegistrationCloseDate = 'endDateGreaterOrEqualThanRegistrationCloseDate',
  StartTimeGreaterThanEndTime = 'startTimeGreater',
  ContactEmail = 'contactEmail',
  HostName = 'hostName',
  EarlyCloseDate = 'EarlyCloseDate',
}

export enum EventSortOptions {
  StartDate = 'Sort By: Start Date',
  EventName = 'Sort By: Event Name',
  PostalCode = 'Sort By: Postal Code',
  StartTime = 'Sort By: Start Time',
  CreatedBy = 'Sort By: Created By',
}

export enum EventSortBy {
  StartDate = 'startDate',
  EventName = 'name',
  PostalCode = 'zip',
  StartTime = 'startTime',
  CreatedBy = 'CreatedBy',
  None = 'none',
}
