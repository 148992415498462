/**
 * 
 * A NOTE ABOUT CYCLES: Cycles apply to Foundations and/or Essentials
 * 
 *  (Cycle Math: ((Current Academic Year - Year 2000) % 3) + 1) 
*     => 2024 - 2000 = 24 % 3 = 0 + 1 = 1
 *    => 2025 - 2000 = 25 % 3 = 1 + 1 = 2
 *    => 2026 - 2000 = 26 % 3 = 2 + 1 = 3
 * 
 * A NOTE ABOUT SEMESTERS: Semesters only apply to Challenge.
 * 
 * When the Learning Center loads:
 * 
 *  ==== ALL USERS ==== 
 *  - Content defaults to the enrollment Program(s) and AcademicYear(s).
 *      - Program is one of the following tabs: 
 *          Foundations, Scribblers, Essentials, Challenge in that order.
 *        - SCRIBBLERS SHOULD NEVER BE THE DEFAULT TAB
 *        - Default to the left-most tab THAT IS NOT SCRIBBLERS. Really, this 
 *            should be the Welcome Center when it arrives.
 *  - Content defaults to the Current Academic Year filter option. If the AY
 *    is selected to be something different than the default then it is tracked
 *    in the LearningCenterContext (or LocalStorage) until the user changes
 *    actingAs or logs out.
 * 
 *  - Each tab includes:
 *    - Search bar
 *    - Academic Year (options returned from the fetchLearningCenterContentOptions) Filter
 *    - Content Type: All Types (options returned from fetchLearningCenterContentOptions) Filter
 *    - Categories: All Categories (options returned from fetchLearningCenterContentOptions) Filter
 * 
 *  - Call to fetchLearningCenterAvailableProgramTypes to populate the Tabs. While
 *    awaiting the response from the endpoint, provide a LoadingProgress for the page.
 *  - When fetchLearningCenterAvailableProgramTypes returns with programs, display tabs.
 *    - Then call fetchLearningCenterContentOptions to populate the filters.
 *    - Then call fetchLearningCenterContent with the appropriate Program and AY 
 *       and default filter selections and search value.
 *      - When fetchLearningCenterContent is called, the tabs display but there
 *        is a LoadingProgress within the tab.
 * 
 *  ==== WHEN NOT A PARENT ====
 *      - AcademicYear (AY) is determined by the AY in which the role of the 
 *            current actingAs resides.
 * 
 *      Examples:
 *      e.g. This expects a role of Challenge A/B/I/II/III/IV director in 
 *       2022-2023 (2022 AY) to display:
 *        - Challenge Tab with filter defaults:
 *          - AY: 2022 (Current) -> (options returned from fetchLearningCenterContentOptions)
 *          - Challenge Level: Challenge A/B/I/II/III/IV -> (options returned from fetchLearningCenterContentOptions)
 *          
 *        - NO OTHER TABS (until we get Welcome Center)
 * 
 *      e.g. This expects a role of Foundations/Essentials director in 
 *       2022-2023 (2022 AY) to display:
 *        - Foundations Tab with correct AY cycle. For 2022 This is cycle 2.
            - Search bar
 *          - Filter default:
 *            - AY: 2022 (Current) -> (options returned from fetchLearningCenterContentOptions) 
 * 
 *        - Scribblers Tab (not yet loaded)
 *        - Essentials Tab (not yet loaded)
 *        - NO OTHER TABS (until we get Welcome Center)
 * 
 *     e.g. This expects a role with grant viewAnyLearning/Content to display: 
 *        - All tabs defaulting to Foundations
 *          - Filter default:
 *            - AY: 2022 (Current) -> (options returned from fetchLearningCenterContentOptions)
 *            - Challenge Level: All of Challenge A/B/I/II/III/IV
 *              !!! All Levels is included if there are more than one Challenge 
 *                Level to select.
 * 
 *    - Backend determines the content included. See fetchLearningCenterContent 
 *        calls, or the endpoint, if there is a problem with the content returned.
 *    - Content within a given tab:
 *       - Anything labelled Getting Started should be First Things: https://classicalconversations.my.workfront.com/issue/642da72b0005f07b7b27bb177de07f93/overview
 *       - There should be NO Cycle/Semester 0. The only Cycle shown for the given AY
 *       - Expand the First Things accordion within Cycle N for the given AY
 * 
 * ==== WHEN A PARENT ====
 *    - Backend determines the content included. This is based on their 
 *        childrens' enrollment status. See fetchLearningCenterContent endpoint
 *        - Defaults to the status where the MOST content is included per program.
 *        - Only includes content IFF a child is at least ENROLLED in a given program.
 *        - PENDING gets nothing. https://www.youtube.com/watch?v=fpK36FZmTFY&t=74s
 *        - MEMBER_HALF gets all content for 2nd Semester in Challenge Programs
 *        - MEMBER_FULL gets all content for Foundations/Essentials/Challenge Programs
 *        - SCRIBBLERS FOLLOWS THE MOST CONTENT RULE
 *    
 *          e.g. A parent with a MEMBER_FULL and ENROLLED student in Foundations
 *            receives all Foundations Learning Center content. They will also 
 *            receive all Scribblers.
 *          
 *          e.g A parent with ENROLLED Essentials, MEMBER_HALF Challenge A, and 
 *            MEMBER_FULL Challenge III receives 6 weeks of Essentials, 2nd Semester
 *            Challenge A, and all Challenge III content. They will also 
 *            receive all Scribblers.
 * 
 * Using Learning Center:
 *  - Selecting a tab:
 *    - call fetchLearningCenterContentOptions and populate filters.
 *    - call fetchLearningCenterContent with selected filters and search.
 *    - Provide a LoadingProgress when any endpoint is awaiting a response.
 *    - tracks the currently selected tab for navigation back to Learning Center.
 * 
 *  - Submitting in the search field will recall fetchLearningCenterContent
 *  - Selecting a new filter option will recall fetchLearningCenterContent
 *  - Searching and Filtering display content WITHOUT accordions.
 * 
 *  - Learning Center will track BY TAB:
 *    - Last selected Level, Category, Type and Academic Year
 *    - Last input AND submitted Search query.
 *    
 *  - Selecting LC Content and navigating back to the Learning Center tabs will
 *    maintain the tab selected with filters/search.
 * 
 *  - Changing ActingAs or Logging Out reset the filter, search, and selected tab.
 */

import { CanAccess } from '../Elements/Access'
import React, { useContext, useEffect, useMemo } from 'react'
import { useAcademicYears } from '../../hooks/useAcademicYears'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../Routes/AuthProvider'
import { useLearningCenterProgramTypes } from './hooks/useLearningCenterProgramTypes'
import { useLearningCenterContext } from '../Context/LearningCenterContext'
import { LoadingContext } from '../Context/LoadingContext'
import { useLearningCenterOptions } from './hooks/useLearningCenterOptions'
import {
  FetchLearningCenterContentProgramTypeEnum,
  FetchLearningCenterOptionsProgramTypeEnum,
} from '../../swagger'
import LearningCenterTabContent from './LearningCenterTabContent'
import { useLearningCenterContent } from './hooks/useLearningCenterContent'
import {
  FilterFileByChallengeLevel,
  LearningCenterTabs,
} from '../../utils/searchAndFilterEnums'
import LoadingProgress from '../Elements/LoadingProgress'
import { useMountEffect } from '../../hooks/useMountEffect'
import { Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Outlet, useLocation, useNavigate } from 'react-router'
import TitleContext from '../../TitleContext'

const LearningCenter: React.FC = () => {
  /** Hooks and static variables */
  const { t } = useTranslation()
  const errorMessageFetchAcademicYears = t(
    'LearningCenter.Error.FetchAcademicYears',
    'An error occurred while retrieving academic years.'
  )
  const { featureAbility } = useAuth()
  const hasLearningCenterFeatureDefault = featureAbility.can(
    'learningCenter',
    'Feature'
  )

  const title = t('LearningCenter.Title', 'Learning Center')
  const { useTitleEffect } = useContext(TitleContext)
  useTitleEffect(title)

  const { loadingIds, addLoadingIds } = React.useContext(LoadingContext)
  const navigate = useNavigate()
  const location = useLocation()
  const {
    academicYear,
    selectedTabKey,
    updateSelectedTabKey,
    programTypeForOptions,
    updateProgramTypeForOptions,
    programTypesLoadingId,
    optionsLoadingId,
    contentLoadingId,
    updateLevel,
    challengePrograms,
    updateSearchAndFiltersForTab,
    level,
    updateWelcomeCenterContent,
    welcomeCenterContent,
  } = useLearningCenterContext()

  const {
    selectedAcademicYear,
    loadingId: academicYearLoadingId,
    academicYears,
  } = useAcademicYears({
    // isMounted,
    errorMessage: errorMessageFetchAcademicYears,
    isLC: true,
    defaultAY: academicYear,
    preventFetch: !hasLearningCenterFeatureDefault,
  })

  /** Tab information */
  const { tabsUserCanAccess, availableProgramTypes } =
    useLearningCenterProgramTypes({
      selectedAcademicYear: academicYear ?? selectedAcademicYear,
    })
  /**
   * An easy way to get the tab label by the programType
   *
   * The programType used to index this map is from LearningCenterTabs
   * in searchAndFilterEnum
   */
  const tabLabelMap: { [key: string]: string } = {
    WelcomeCenter: t('LearningCenter.TabTitle.WelcomeCenter', 'Welcome'),
    Foundations: t('LearningCenter.TabTitle.Foundations', 'Foundations'),
    Scribblers: t('LearningCenter.TabTitle.Scribblers', 'Scribblers'),
    Essentials: t('LearningCenter.TabTitle.Essentials', 'Essentials'),
    Challenge: t('LearningCenter.TabTitle.Challenge', 'Challenge'),
  }

  /** Build the tabs based on those we can access */
  const tabs =
    tabsUserCanAccess &&
    tabsUserCanAccess.map((programType) => {
      return (
        <Tab
          key={programType}
          value={programType}
          label={tabLabelMap[programType]}
        />
      )
    })

  /**
   * We need to validate which program types we can load and if we have
   * any available academicYear (we always should). The selectedTabKey
   * may have been selected and NOT Welcome Center in which case we need
   * to load the available program types before rendering the tabs so we
   * don't attempt to provide a value to the TabContext that doesn't exist.
   */
  const isLearningCenterLoading =
    // And are not loading fetchAcademicYears
    loadingIds.has(academicYearLoadingId) ||
    // And are not loading fetchLearningCenterAvailableProgramTypes
    loadingIds.has(programTypesLoadingId) ||
    // And the selectedTabKey is not Welcome Center and we've not loaded extra tabs
    (selectedTabKey !== LearningCenterTabs.WelcomeCenter &&
      tabsUserCanAccess &&
      tabsUserCanAccess.length === 1)

  useEffect(() => {
    /**
     * Given we have availableProgramTypes but have yet to select and initial
     * programTypeForOptions, set it. Selecting a different program tab will
     * update the programTypeForOptions and we don't want to filter Scribblers
     * if we select the Scribblers tab.
     */
    if (
      availableProgramTypes.length > 0 &&
      !programTypeForOptions &&
      !welcomeCenterContent
    ) {
      // Call the available option for the tab, if not Scribblers.
      let tab = availableProgramTypes.filter(
        (it) => it !== FetchLearningCenterOptionsProgramTypeEnum.Scribblers
      )[0]
      /**
       * If the only Program a user has access to is Scribblers, well, give it
       * to them. This should never be the case, but we test it anyway.
       */
      if (!tab) {
        tab = FetchLearningCenterOptionsProgramTypeEnum.Scribblers
      }

      updateProgramTypeForOptions(tab)
    }
  }, [
    availableProgramTypes,
    programTypeForOptions,
    updateProgramTypeForOptions,
    welcomeCenterContent,
  ])

  /**
   * We know that programTypeForOptions must be defined for fetchLearningCenterOptions
   * to be called. Additionally we don't call if there are no available programTypes.
   *
   * We don't base the prevent on tabsUserCanAccess because we forward plan that
   * a user will always access the Welcome Center tab.
   */
  useLearningCenterOptions({
    programType:
      programTypeForOptions as FetchLearningCenterOptionsProgramTypeEnum,
  })

  /**
   * We know that programTypeForOptions must be defined for fetchLearningCenterContent
   * to be called. Additionally we don't call if the selectedAcademicYear is < 0.
   *
   */
  useLearningCenterContent({
    programType:
      programTypeForOptions as unknown as FetchLearningCenterContentProgramTypeEnum,
    selectedAcademicYear: academicYear ?? selectedAcademicYear,
  })

  /**
   * Build the tabPanels to display based on the tabsUserCanAccess. If they cannot
   * access any tab, there will be no panels, but instead provide an EmptyLearningCenter
   * if they cannot see content.
   */
  const tabPanels =
    tabsUserCanAccess &&
    tabsUserCanAccess.map((programType) => {
      return (
        <TabPanel key={programType} value={programType}>
          <LearningCenterTabContent
            loadingContent={loadingIds.has(contentLoadingId)}
            loadingOptions={loadingIds.has(optionsLoadingId)}
            academicYears={academicYears}
            selectedYear={academicYear ?? selectedAcademicYear}
          />
        </TabPanel>
      )
    })

  /**
   * Whenever we select a given tab, we should do a few things. We need nothing
   * from the event, but we can use the selectedTab value to track which tab is selected
   * and what value we ought to use for the endpoints.
   */
  //SyntheticEvent<Element, Event>, value: any) => void
  const onTabChange = useMemo(
    () =>
      (event?: React.SyntheticEvent<Element, Event>, selectedTab?: string) => {
        // As of now, we need nothing from it, but the Node16 upgrade will lint us if we don't use it.
        void event

        if (!selectedTab) return

        /**
         * Update the value to match the selectedTabKey.
         */
        updateSelectedTabKey(selectedTab as LearningCenterTabs)

        /**
         * Provide a programTypeForOptions value. This is different for challenge since
         * we need to select the first programType from filterOptions and use that.
         */
        const valueForProgramOptions =
          /** If we change to challenge program */
          selectedTab.includes(LearningCenterTabs.Challenge) &&
          !!challengePrograms
            ? /**
               * Provide which challenge comes first in availableProgramTypes.
               */
              (challengePrograms[0] as FetchLearningCenterOptionsProgramTypeEnum)
            : /** Otherwise use foundations and essentials.  */
            selectedTab.includes(LearningCenterTabs.WelcomeCenter)
            ? undefined
            : (selectedTab as FetchLearningCenterOptionsProgramTypeEnum)

        updateProgramTypeForOptions(valueForProgramOptions)
        updateWelcomeCenterContent(!valueForProgramOptions)

        /**
         * Update the filter options for the given tab.
         */
        updateSearchAndFiltersForTab(selectedTab as LearningCenterTabs)

        /** And update the level if not already chosen. */
        if (selectedTab.includes(LearningCenterTabs.Challenge)) {
          !!challengePrograms &&
            updateLevel(
              level ?? (challengePrograms[0] as FilterFileByChallengeLevel)
            )
        }

        /**
         * Add the loadingIds of the endpoints we need to recall because of the tab
         * change. Just options and content endpoints. Do not call the endpoint if selecting the Welcome Center tab
         */
        addLoadingIds([
          ...(!selectedTab.includes(LearningCenterTabs.WelcomeCenter)
            ? [optionsLoadingId]
            : []),
          contentLoadingId,
        ])
        /**
         * And navigate to the /programType we've selected, based on the tab.
         * This needs a .toLowerCase since the value is upper case and paths aren't.
         */
        navigate({ pathname: `/learning/${selectedTab.toLowerCase()}` })
      },
    [
      addLoadingIds,
      challengePrograms,
      contentLoadingId,
      level,
      navigate,
      optionsLoadingId,
      updateLevel,
      updateProgramTypeForOptions,
      updateSearchAndFiltersForTab,
      updateSelectedTabKey,
      updateWelcomeCenterContent,
    ]
  )
  /** Only select a tab if we're not viewing a piece of content, because we render the content as a child of this component. */
  useMountEffect(() => {
    if (!location.pathname.includes(`/learning/learning-content/`)) {
      navigate({ pathname: `/learning/${selectedTabKey.toLowerCase()}` })
    }
  })

  useEffect(() => {
    if (
      !location.pathname.includes('/learning-content') &&
      !location.pathname.includes(selectedTabKey.toLowerCase())
    ) {
      /**
       * ???: Are we ever going to have a disconnect scenario where the location
       * and selectedTabKey are off and are meant to be or we're awaiting an update?
       *
       * If the location pathname and the selectedTabKey don't match, we should
       * probably select the tab in the pathname (the user navigated 'back')
       *
       * As far as we can tell, we're NOT keeping the states of the same tab in
       * history. If the user goes to Challenge, filters, moves to Scribblers,
       * searches, then back to Challenge and redoes filters/search, clicking
       * back will give the tab from the pathname, but not the search & filter.
       */
      const tabPaths = location.pathname.split('/')
      const tabFromPath = tabPaths[tabPaths.length - 1]

      let selectedTabValue = ''
      switch (tabFromPath) {
        case LearningCenterTabs.Challenge.toLowerCase():
          selectedTabValue = LearningCenterTabs.Challenge
          break
        case LearningCenterTabs.Scribblers.toLowerCase():
          selectedTabValue = LearningCenterTabs.Scribblers
          break
        case LearningCenterTabs.Foundations.toLowerCase():
          selectedTabValue = LearningCenterTabs.Foundations
          break
        case LearningCenterTabs.Essentials.toLowerCase():
          selectedTabValue = LearningCenterTabs.Essentials
          break
        case LearningCenterTabs.WelcomeCenter.toLowerCase():
          selectedTabValue = LearningCenterTabs.WelcomeCenter
          break
      }
      /**
       * We might as well call the onTabChange method since that handles
       * all our cases for tabs.
       */
      onTabChange(undefined, selectedTabValue)
    }
  }, [location.pathname, onTabChange, selectedTabKey])

  return (
    <CanAccess I="learningCenter" on="Feature">
      {location.pathname.includes(`/learning/learning-content/`) ? (
        /**
         *  Include the Outlet because learning center content is a child
         *  of the "/learning" path which renders LearningCenter
         */
        <Outlet />
      ) : (
        <>
          {isLearningCenterLoading ? (
            <LoadingProgress />
          ) : (
            <TabContext value={selectedTabKey}>
              <TabList
                value={selectedTabKey}
                textColor="primary"
                variant="scrollable"
                scrollButtons={false}
                onChange={onTabChange}
              >
                {tabs}
              </TabList>
              {tabPanels}
            </TabContext>
          )}
        </>
      )}
    </CanAccess>
  )
}

export default LearningCenter
