import { Save, Edit, Cancel } from '@mui/icons-material'
import React, { useRef } from 'react'

export interface SpeedDialActions {
  /** Tooltip title and key for the action. Needs to use `t` */
  name: string
  /** Icon to represent the action. */
  icon: React.ReactNode
  /** On click handler for the SpeedDialAction */
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  /** onClick tour handler */
  onTourClick?: () => void
  /**
   * CSS Selector to add as a className to the action for ease of selection during steps
   *
   * This should be the target without the '.' prefix
   */
  selector?: string
  /** Determines whether to hide the action */
  hidden?: boolean
  /** Pause for load */
  pauseTourBriefly?: boolean
}

/**
 * Provides common actions with the ability to override props for the most common.
 *
 * Edit, Save and Cancel are widely used.
 *
 * @param opts - an options object to override action properties with SpeedDialAction props
 * @returns references to the common actions.
 */
export const useSpeedDialCommonActions = ({
  saveProps,
  cancelProps,
  editProps,
}: {
  /** Override props for save action */
  saveProps?: Partial<SpeedDialActions>
  /** Override props for cancel action */
  cancelProps?: Partial<SpeedDialActions>
  /** Override props for edit action */
  editProps?: Partial<SpeedDialActions>
} = {}): {
  saveAction: React.MutableRefObject<SpeedDialActions>
  cancelAction: React.MutableRefObject<SpeedDialActions>
  editAction: React.MutableRefObject<SpeedDialActions>
} => {
  const saveAction = useRef<SpeedDialActions>({
    name: 'Save',
    icon: <Save aria-label="save-action-icon" />,
    ...saveProps,
  })
  const editAction = useRef({
    name: 'Edit',
    icon: <Edit aria-label="edit-action-icon" />,
    ...editProps,
  })
  const cancelAction = useRef<SpeedDialActions>({
    name: 'Cancel',
    icon: <Cancel aria-label="cancel-action-icon" />,
    ...cancelProps,
  })
  return { saveAction, editAction, cancelAction }
}
