import React, { useState, useEffect } from 'react'
import { Can } from '@casl/react'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import { Page } from '../../Elements/PageMargins'
import TitleContext from '../../../TitleContext'
import ResponsiveFab from '../../Buttons/ResponsiveFab'
import { addIconFudgeFactor } from '../../../utils/addIconFudgeFactor'
import SearchBar from '../../Search/SearchBar'
import RegionsSummaryTable from './RegionsSummaryTable'
import EmptyRegion from './EmptyRegion'
import { escapeString } from '../../../utils/stringUtility'
import { CanAccess } from '../../Elements/Access'
import { useAuth } from '../../Routes/AuthProvider'
import { Region } from '../../../swagger'
import { regionsApi, extractedErrorObject } from '../../../api/swagger'
import { SnackbarSeverity } from '../../Alerts/SnackbarAlert'
import { useSnackbarContext } from '../../Context/SnackbarContext'
import { Outlet, useLocation } from 'react-router'
import useLoadingContext from '../../../hooks/useLoadingContext'
import { LoadingContext } from '../../Context/LoadingContext'
import { useLoadingIds } from '../../../hooks/useLoadingIds'
import { useMountEffect } from '../../../hooks/useMountEffect'

const searchRegions = (args: {
  regionsToSearch: Region[]
  searchInput: string
}): Region[] => {
  const filterRegex = new RegExp(escapeString(args.searchInput).trim(), 'i')
  const filteredRegions = args.regionsToSearch.filter((region) => {
    return (
      filterRegex.test(region.name) ||
      filterRegex.test(region.parentRegionName ?? '') ||
      filterRegex.test(region.regionManagerName)
    )
  })
  return filteredRegions
}

export const Regions: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const { permissionAbility } = useAuth()
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()
  const location = useLocation()

  const title = t('Regions.Title', 'Regions')
  const { useTitleEffect } = React.useContext(TitleContext)
  useTitleEffect(title)

  const [regions, setRegions] = useState<Region[]>([])
  const [searchQuery, setSearchQuery] = useState('')

  const [errorMessage, setErrorMessage] = useState('')
  const availableLoadingIds = useLoadingIds()
  const { addLoadingIds, loadingIds, removeLoadingIds } =
    React.useContext(LoadingContext)
  const isLoading = loadingIds.has(availableLoadingIds.Regions.fetchRegions)

  /**
   * Fetch all regions
   */

  useMountEffect(() => {
    // FIXME: Do not call if not granted
    addLoadingIds([availableLoadingIds.Regions.fetchRegions])
  })

  const fetchRegions = async () => {
    try {
      const fetchedRegions = await regionsApi.fetchRegions({})

      setRegions(fetchedRegions.regions)
    } catch (err) {
      const errorObject = (await extractedErrorObject(err)) ?? {
        code: 'UnknownError',
        message:
          (err as unknown as Error).message ?? 'Failed to fetch regions.',
      }
      setErrorMessage(errorObject.message)
    } finally {
      removeLoadingIds([availableLoadingIds.Regions.fetchRegions])
    }
  }

  useLoadingContext({
    asyncFunction: fetchRegions,
    loadingId: availableLoadingIds.Regions.fetchRegions,
  })

  /**
   * If error fetching regions, display snack bar alert
   */
  useEffect(() => {
    if (!!errorMessage) {
      setSnackbarState(true)
      setSnackbarMessage(errorMessage)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  })

  const sortedAndSearchedRegions = searchRegions({
    regionsToSearch: regions.sort((a, b) => {
      const nameA = a.name.replace(/[^a-zA-Z]/g, '')
      const nameB = b.name.replace(/[^a-zA-Z]/g, '')

      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    }),
    searchInput: searchQuery,
  })

  const AddRegionFAB = () => (
    <Can I="create" on="Region" ability={permissionAbility}>
      <ResponsiveFab
        to="/admin/regions/add-region"
        icon={AddIcon}
        spacingFudgeFactor={addIconFudgeFactor}
        iconLabel={t('Regions.AddRegionButton.IconLabel', 'Add')}
        textLabel={t('Regions.AddRegionButton.TextLabel', 'Region')}
        fullLabel={t('Regions.AddRegionButton.FullLabel', 'Add Region')}
      />
    </Can>
  )

  if (regions.length === 0) {
    return (
      <CanAccess I="view" on="Region">
        {AddRegionFAB()}
        <EmptyRegion isLoading={isLoading} />
      </CanAccess>
    )
  }

  return (
    <>
      <Page>
        {location.pathname === '/admin/regions' && (
          <CanAccess I="accessRegionsSection" on="Admin">
            <CanAccess I="view" on="Region">
              {AddRegionFAB()}
              <SearchBar
                handleSearch={(str) => setSearchQuery(str)}
                placeholder={t(
                  'Regions.Search.PlaceholderText',
                  'Search by Region Name or Manager'
                )}
              />

              <Box component="section" my={3}>
                <RegionsSummaryTable regions={sortedAndSearchedRegions} />
              </Box>
            </CanAccess>
          </CanAccess>
        )}
        {/* Render the children of regions, anything with a route prefix of /admin/regions and also in the /regions children. */}
        <Outlet />
      </Page>
    </>
  )
}

export default Regions
