/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  CashAmount,
  CashAmountFromJSON,
  CashAmountToJSON,
  LicensingBillFamilies,
  LicensingBillFamiliesFromJSON,
  LicensingBillFamiliesToJSON,
  LicensingBillingAccount,
  LicensingBillingAccountFromJSON,
  LicensingBillingAccountToJSON,
  LicensingFee,
  LicensingFeeFromJSON,
  LicensingFeeToJSON,
} from './'

/**
 *
 * @export
 * @interface LicensingBill
 */
export interface LicensingBill {
  /**
   *
   * @type {LicensingBillingAccount}
   * @memberof LicensingBill
   */
  _for: LicensingBillingAccount
  /**
   *
   * @type {CashAmount}
   * @memberof LicensingBill
   */
  total: CashAmount
  /**
   *
   * @type {Array<LicensingFee>}
   * @memberof LicensingBill
   */
  fees: Array<LicensingFee>
  /**
   *
   * @type {Array<LicensingBillFamilies>}
   * @memberof LicensingBill
   */
  families: Array<LicensingBillFamilies>
}

export function LicensingBillFromJSON(json: any): LicensingBill {
  return LicensingBillFromJSONTyped(json, false)
}

export function LicensingBillFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LicensingBill {
  if (json === undefined || json === null) {
    return json
  }
  return {
    _for: LicensingBillingAccountFromJSON(json['for']),
    total: CashAmountFromJSON(json['total']),
    fees: (json['fees'] as Array<any>).map(LicensingFeeFromJSON),
    families: (json['families'] as Array<any>).map(
      LicensingBillFamiliesFromJSON
    ),
  }
}

export function LicensingBillToJSON(value?: LicensingBill | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    for: LicensingBillingAccountToJSON(value._for),
    total: CashAmountToJSON(value.total),
    fees: (value.fees as Array<any>).map(LicensingFeeToJSON),
    families: (value.families as Array<any>).map(LicensingBillFamiliesToJSON),
  }
}
