import React, { useCallback } from 'react'
import Snackbar from '@mui/material/Snackbar'
import CheckCircle from '@mui/icons-material/CheckCircle'
import { Alert } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { ErrorIcon } from './ErrorAlert'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material'

export enum SnackbarSeverity {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
}

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  marginTop: theme.spacing(6),
}))

const SuccessIcon = () => {
  const theme = useTheme()

  return (
    <CheckCircle
      style={{
        fill: theme.palette.secondary.main,
      }}
    />
  )
}

const SnackbarAlert: React.FC = () => {
  const { open, message, severity, handleClose } = useSnackbarContext()
  const theme = useTheme()

  const severityFromContextOrProps = severity

  const onClose = useCallback(() => handleClose(), [handleClose])

  return (
    <StyledSnackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
    >
      <Alert
        sx={{
          color:
            severity === SnackbarSeverity.Success
              ? theme.palette.secondary.main
              : severity === SnackbarSeverity.Warning
              ? theme.palette.textOrIcon.warning
              : theme.palette.error.main,
        }}
        elevation={6}
        severity={severity}
        icon={
          severityFromContextOrProps === SnackbarSeverity.Success ? (
            <SuccessIcon />
          ) : (
            <ErrorIcon />
          )
        }
        onClose={onClose}
      >
        <Typography variant="subtitle2" component="p">
          {message}
        </Typography>
      </Alert>
    </StyledSnackbar>
  )
}

export default SnackbarAlert
