/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { CashAmount, CashAmountFromJSON, CashAmountToJSON } from './'

/**
 *
 * @export
 * @interface TuitionPayment
 */
export interface TuitionPayment {
  /**
   * The unique identifier of the payment made towards an enrollment.
   * @type {number}
   * @memberof TuitionPayment
   */
  paymentKey: number
  /**
   * An explanation for why a payment was applied.
   * @type {string}
   * @memberof TuitionPayment
   */
  desc: string
  /**
   *
   * @type {CashAmount}
   * @memberof TuitionPayment
   */
  amount: CashAmount
  /**
   * The payment execution date.
   * @type {Date}
   * @memberof TuitionPayment
   */
  paymentDate?: Date
}

export function TuitionPaymentFromJSON(json: any): TuitionPayment {
  return TuitionPaymentFromJSONTyped(json, false)
}

export function TuitionPaymentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TuitionPayment {
  if (json === undefined || json === null) {
    return json
  }
  return {
    paymentKey: json['paymentKey'],
    desc: json['desc'],
    amount: CashAmountFromJSON(json['amount']),
    paymentDate: !exists(json, 'paymentDate')
      ? undefined
      : new Date(json['paymentDate']),
  }
}

export function TuitionPaymentToJSON(value?: TuitionPayment | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    paymentKey: value.paymentKey,
    desc: value.desc,
    amount: CashAmountToJSON(value.amount),
    paymentDate:
      value.paymentDate === undefined
        ? undefined
        : value.paymentDate.toISOString().substr(0, 10),
  }
}
