import React, { SyntheticEvent } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Page } from '../Elements/PageMargins'
import TitleContext from '../../TitleContext'
import ContentDetailsCard from '../Card/ContentDetailsCard'
import { FileType, FileTypeIcon } from '../Elements/FileTypeResource'
import { contentApi, extractedErrorObject } from '../../api/swagger'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import { CanAccess } from '../Elements/Access'
import EmptyLearningCenter from './EmptyLearningCenter'
import { useMediaQuery, useTheme } from '@mui/material'
import ShowFullScreenButton from '../Buttons/ShowFullScreenButton'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { useMountEffect } from '../../hooks/useMountEffect'
import { useFetchWidenAsset } from '../../hooks/useFetchWidenAsset'

const formatFilesize = (value: number): string => {
  const DIVISOR = 1024
  return `${(value / DIVISOR).toFixed(2)} MB`
}

const LearningCenterContent: React.FunctionComponent = (props) => {
  const { t } = useTranslation()
  const title = t('Learning.LearningContent.Title', 'Learning Center')
  const navigate = useNavigate()
  const { useTitleEffect } = React.useContext(TitleContext)
  const theme = useTheme()
  const isMobileOrSmaller = useMediaQuery(theme.breakpoints.down('xs'))
  useTitleEffect(title)
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()

  const { assetKey } = useParams<{
    assetKey: string
  }>()
  const parsedAssetKey = parseInt(`${assetKey}`)

  const { LearningCenter } = useLoadingIds()

  const {
    contentAsset: learningContent,
    isLoading,
    fetchAsset,
    refetch,
  } = useFetchWidenAsset({
    assetKey: parsedAssetKey,
    loadingId: LearningCenter.fetchContentDetail,
  })

  useMountEffect(() => {
    fetchAsset()
  })

  const averageStarRating = learningContent?.avgStarRating ?? 0

  const handleStarRatingUpdate = async (rating: number) => {
    try {
      await contentApi.starRating({
        body: { widenAssetKey: parsedAssetKey, starRating: rating },
      })
    } catch (error) {
      const errorObject = (await extractedErrorObject(error)) ?? {
        code: 'UnknownError',
        message:
          (error as unknown as Error).message ??
          t(
            'LearningCenterContent.StarRating.Error',
            'Failed to update Star Rating'
          ),
      }
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarMessage(errorObject.message)
      setSnackbarState(true)
    }

    // This is to re-fetch the new value so that the star rating component updates when a user clicks a new rating
    refetch()
  }

  const navigateToLearning = () => {
    navigate(
      {
        pathname: '/learning',
      },
      {
        /** Navigation Options */
      }
    )
  }

  const { filetype, viewOnly, embedUrl, isFavorited } = learningContent ?? {}

  const emptyTitle = t('LearningCenter.EmptyTitle', 'No title available')
  const contentTitle = learningContent?.title || emptyTitle
  const showFileType = filetype

  return (
    <Page>
      <CanAccess I="learningCenter" on="Feature">
        {!learningContent ? (
          <EmptyLearningCenter isLoading={isLoading} />
        ) : (
          <>
            <DynamicBreadcrumbs
              breadcrumbs={[
                {
                  label: t(
                    'Learning.LearningContent.BreadCrumb',
                    'Learning Center'
                  ),
                  onClick: navigateToLearning,
                },
                {
                  label: contentTitle,
                },
              ]}
            />
            {isMobileOrSmaller && (
              <ShowFullScreenButton assetKey={parsedAssetKey} />
            )}
            <ContentDetailsCard
              assetKey={parsedAssetKey}
              filetype={filetype as FileType}
              showFileByType={showFileType}
              viewOnly={viewOnly ?? false}
              embedUrl={embedUrl}
              icon={<FileTypeIcon fileType={filetype as FileType} large />}
              iconBackground="dark"
              title={contentTitle}
              description={
                learningContent?.description ||
                t('LearningCenter.EmptyDescription', 'No description available')
              }
              subText={
                !!learningContent?.filesize
                  ? `Size: ${formatFilesize(learningContent.filesize)}`
                  : undefined
              }
              starRating={averageStarRating}
              starRatingOnChange={(
                event: SyntheticEvent<Element, Event>,
                rating: number | null
              ) => {
                handleStarRatingUpdate(rating ?? 0)
              }}
              isFavorite={isFavorited ?? false}
              {...props}
            />
          </>
        )}
      </CanAccess>
    </Page>
  )
}

export default LearningCenterContent
