import { useParams } from 'react-router'
export const useCommunityParams = (): { communityId: number } => {
  const { communityKey } = useParams<{
    communityKey: string
  }>()
  const getcommunityKeyFromUrl = (): number => {
    return parseInt(`${communityKey}`)
  }

  return {
    communityId: getcommunityKeyFromUrl(),
  }
}
