import {
  Box,
  Card,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header, { HeaderVariant } from '../Elements/Header'
import CardFormHeader from './CardFormHeader'
import { useAuth } from '../Routes/AuthProvider'

const UserTwoFactorAuthenticationCard: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const { featureAbility } = useAuth()
  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(false)

  return (
    <>
      {featureAbility.can(
        'userTwoFactorAuthenticationVisibility',
        'Feature'
      ) && (
        <Card>
          <Box p={3}>
            <CardFormHeader
              header={
                <Header
                  variant={HeaderVariant.SettingsHeader}
                  id="twoFactorAuthentication"
                  headerName={t(
                    'AccountSettingsTab.TwoFactorAuthentication',
                    'Two-Factor Authentication (2FA)'
                  )}
                  component="h3"
                />
              }
            />
            <Box display="flex">
              <Box width="100%">
                <Typography>
                  {t(
                    'AccountSettingsTab.TwoFactorDescription',
                    "Protect your account by enabling Two-Factor Authentication (2FA). Each time you login you'll need to enter your password and a verification code."
                  )}
                </Typography>
              </Box>
              <FormGroup>
                <FormControlLabel
                  label={
                    isTwoFactorEnabled
                      ? t('AccountSettingsTab.TwoFactorStatus', 'Enabled')
                      : t('AccountSettingsTab.TwoFactorStatus', 'Disabled')
                  }
                  control={
                    <Switch
                      onChange={() =>
                        setIsTwoFactorEnabled(!isTwoFactorEnabled)
                      }
                    />
                  }
                />
              </FormGroup>
            </Box>
          </Box>
        </Card>
      )}
    </>
  )
}

export default UserTwoFactorAuthenticationCard
