import { Box, Typography } from '@mui/material'
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid'
import React, { useMemo } from 'react'
import CC_Seal from '../../Images/Branding/cc-seal.png'
import { useTranslation } from 'react-i18next'
import { OrderByDirection, PaginationResponse } from '../../swagger'
import Header from '../Elements/Header'
import { DEFAULT_PAGE_SIZE } from '../../utils/constants'
import { toPascalCase } from '../../utils/stringUtility'
import RowActions, { Action } from './RowActions'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'

const DEFAULT_COLUMN_WIDTH = 150

export type ActionableTableColumn = Pick<
  GridColDef,
  | 'align'
  | 'flex'
  | 'headerAlign'
  | 'minWidth'
  | 'sortable'
  | 'valueFormatter'
  | 'width'
  | 'sortingOrder'
  | 'cellClassName'
  | 'description'
> & {
  fieldName: string
  columnHeaderName?: string
}

export interface ActionableTableProps<T> {
  columns: ActionableTableColumn[]
  noResultsMessage: string
  rows: Array<T>
  rowActions?: Action<T>[]
  pagination?: PaginationResponse
  title?: string
  handlePaginationChange?: (pagination: PaginationResponse) => void
  onCellClick?: (params: GridCellParams) => void
  onCompleteTour?: () => void
  isTourCompleted?: boolean
}

export const ActionableTable = <T,>({
  columns,
  rows,
  noResultsMessage,
  title,
  rowActions,
  pagination = {
    pageSize: DEFAULT_PAGE_SIZE,
    page: 0,
    totalCount: 0,
    orderBy: [],
  },
  handlePaginationChange,
  onCellClick,
  onCompleteTour,
  isTourCompleted,
}: ActionableTableProps<T>): JSX.Element => {
  const { t } = useTranslation()
  const isDesktop = useShowOnDesktop()
  const actionsTitle = t(
    'Table.ActionableTable.ColumnHeader.Actions',
    'Actions'
  )
  const hidePagination = pagination.totalCount < pagination.pageSize

  const handleSortModelChange = (model: GridSortModel) => {
    const orderBy = model.length
      ? [{ [model[0].field]: model[0].sort as OrderByDirection }]
      : []

    handlePaginationChange?.({
      ...pagination,
      orderBy,
    })
  }

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    handlePaginationChange?.({
      ...pagination,
      page: model.page,
      pageSize: model.pageSize,
    })
  }

  const cols: GridColDef[] = useMemo(() => {
    return columns.map((column) => ({
      ...column,
      field: column.fieldName,
      headerName: column.columnHeaderName ?? toPascalCase(column.fieldName),
      sortable: column.sortable || false,
      align: column.align || 'left',
      minWidth:
        column.minWidth || (isDesktop ? undefined : DEFAULT_COLUMN_WIDTH),
      width: column.width || undefined,
      flex: column.flex || 1,
      valueFormatter: column.valueFormatter || undefined,
      headerAlign: column.headerAlign || undefined,
      description: column.description,
    }))
  }, [columns, isDesktop])

  /**
   * Add the table unique identifier id for each row
   * on the array index, when the don't have an id property
   */
  const tableRows = rows.map((row, index) => {
    const { id = index, ...rest } = row as T & { id?: number }
    return {
      id,
      ...rest,
    }
  })

  const rowActionsTourSteps = [
    {
      target: '.featureTour',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      title: t('SpeedDialMenu.Tour.StepZero.Title', 'Actions have moved!'),
      content: t(
        'SpeedDialMenu.Tour.StepZero.Content',
        'Click here to Edit or Resend an Invite.'
      ),
      disableBeacon: true,
      disableScrolling: true,
      placement: 'left',
    },
  ]

  /**
   * Add actions column to the end of the table
   */

  if (!!rowActions && rowActions.length) {
    cols.push({
      field: 'actions',
      headerName: actionsTitle,
      sortable: false,
      align: 'center',
      minWidth: isDesktop ? undefined : DEFAULT_COLUMN_WIDTH,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <RowActions
            actions={rowActions}
            row={params.row}
            rowId={params.row.id}
            onCompleteTour={() => {
              onCompleteTour && onCompleteTour()
            }}
            elementsCount={rows.length}
            isTourCompleted={isTourCompleted}
            joyRideTourId="joyride-programDetails-enrollmentInvitesTable-complete"
            rowActionsTourSteps={rowActionsTourSteps}
          />
        )
      },
    })
  }

  return (
    <>
      {title && <Header id={title} headerName={title} component="h3" />}
      <Box sx={{ width: '100%', background: 'white' }}>
        <DataGrid
          autoHeight
          rows={tableRows}
          columns={cols}
          getRowHeight={() => 'auto'}
          rowCount={pagination.totalCount}
          paginationMode="server"
          paginationModel={pagination}
          hideFooter={hidePagination}
          hideFooterPagination={hidePagination}
          disableColumnMenu={true}
          disableRowSelectionOnClick={true}
          sortingMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          onSortModelChange={handleSortModelChange}
          onCellClick={onCellClick}
          slots={{
            noRowsOverlay: () => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <img
                  src={CC_Seal}
                  alt={t(
                    'ActionableTable.NoRowsOverlay',
                    'Classical Conversations Seal'
                  )}
                />
                <Box mt={1}>
                  <Typography variant="body1" component="p">
                    {noResultsMessage}
                  </Typography>
                </Box>
              </Box>
            ),
          }}
          //used to display all columns in test mode
          disableVirtualization={process.env.NODE_ENV === 'test'}
        />
      </Box>
    </>
  )
}
