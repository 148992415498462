import React, { useEffect, useState } from 'react'
import { UserAccountDetails } from '../../swagger'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import Header, { HeaderVariant } from '../Elements/Header'
import fullName from '../../utils/fullName'
import CardFormHeader from './CardFormHeader'
import { Box, Card, CardContent, TextField, useTheme } from '@mui/material'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import UserAvatar from '../Elements/UserAvatar'
import AdjacentLabels from '../Labels/AdjacentLabels'
import { useTranslation } from 'react-i18next'
import { dateToSlashStringReinterpretedAsLocal } from '../../utils/dateUtility'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import EditUserInformationModal from '../Modals/UserAccountModal'
import { updateUser } from '../../api/userAccounts'
import { extractedErrorObject } from '../../api/swagger'
import { Can } from '@casl/react'
import { useAuth } from '../Routes/AuthProvider'
import { useNavigate } from 'react-router'
import { useSnackbarContext } from '../Context/SnackbarContext'

interface UserAccountCardProps {
  initialUserAccountDetails?: UserAccountDetails
  updateNameForParent: (fullName: string) => void
}

const UserAccountCard: React.FC<UserAccountCardProps> = ({
  initialUserAccountDetails,
  updateNameForParent,
}) => {
  const { permissionAbility } = useAuth()
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleDeactivateAccount = () => {
    alert('Coming soon. See https://projekt202.atlassian.net/browse/CCP1-421')
  }
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()

  const [userAccountDetails, setUserAccountDetails] = useState(
    initialUserAccountDetails
  )

  const userInformation = {
    personalEmail: userAccountDetails?.username ?? '',
    twoFA: !!userAccountDetails?.isTwoFactorAuthenticationActive
      ? t('UserAccountCard.2FA.Enabled', 'Enabled')
      : t('UserAccountCard.2FA.Disabled', 'Disabled'),
    status: !!userAccountDetails?.isDeactivated
      ? t('UserAccountCard.Status.Deactivated', 'Deactivated')
      : t('UserAccountCard.Status.Active', 'Active'),
    accountCreated: !!userAccountDetails
      ? dateToSlashStringReinterpretedAsLocal(userAccountDetails.accountCreated)
      : '',
    ccEmail: userAccountDetails?.email ?? '',
  }

  useEffect(() => {
    if (!!initialUserAccountDetails) {
      setUserAccountDetails(initialUserAccountDetails)
    }
  }, [initialUserAccountDetails])

  const isShowOnDesktop = useShowOnDesktop()

  const [isUserAccountModalOpen, setIsUserAccountModalOpen] = useState(false)

  const handleEditUser = () => {
    setIsUserAccountModalOpen((isOpen) => !isOpen)
  }

  const onCloseUserAccountModal = () => {
    setIsUserAccountModalOpen((isOpen) => !isOpen)
  }

  const updateUserAccount = async (userAccount: UserAccountDetails) => {
    try {
      await updateUser({
        userId: `${userAccountDetails?.userKey}`,
        body: {
          firstName: userAccount.firstName,
          lastName: userAccount.lastName,
          username: userAccount.username,
          email: userAccount.email,
        },
      })

      setSnackbarMessage?.(
        t(
          'UserAccountCard.UpdateUser.Success',
          'Successfully updated user information.'
        )
      )
      setSnackbarState?.(true)
      setSnackbarSeverity?.(SnackbarSeverity.Success)

      // On success, update the state objects
      setUserAccountDetails(userAccount)
      updateNameForParent(
        fullName({
          firstName: userAccount.firstName,
          lastName: userAccount.lastName,
        })
      )
      setIsUserAccountModalOpen((isOpen) => !isOpen)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error).message ??
          t(
            'UserAccountCard.UpdateUser.Error',
            'Error occurred while updated user information.'
          ),
      }
      setSnackbarMessage?.(errorObject.message)
      setSnackbarState?.(true)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
    }
  }

  const handleNavigateToFamilies = () => {
    userAccountDetails?.userKey &&
      navigate(
        {
          pathname: `/family-profile/${userAccountDetails.userKey}`,
        },
        {
          /** Navigation Options */
        }
      )
  }

  return (
    <>
      {isUserAccountModalOpen && userAccountDetails && (
        <EditUserInformationModal
          isOpen={isUserAccountModalOpen}
          onClose={onCloseUserAccountModal}
          initialUserAccount={userAccountDetails}
          updateUserAccountForParent={updateUserAccount}
        />
      )}
      <Card
        sx={{
          marginBottom: theme.spacing(3),
        }}
      >
        <CardFormHeader
          header={
            <Box display="block">
              <Box ml={2} mt={3} display="flex" width="100%">
                <Box pr={2}>
                  <UserAvatar
                    familyName={fullName({
                      firstName: userAccountDetails?.firstName,
                      lastName: userAccountDetails?.lastName,
                    })}
                  />
                </Box>
                <Box>
                  <Header
                    id="fullNameHeader"
                    headerName={fullName({
                      firstName: userAccountDetails?.firstName,
                      lastName: userAccountDetails?.lastName,
                    })}
                    component="h2"
                    variant={HeaderVariant.UserAccountCard}
                  />
                </Box>
              </Box>
            </Box>
          }
          buttons={
            <Can I={'accessUsersTab'} on="Admin" ability={permissionAbility}>
              <Box mr={2} mt={3} ml={isShowOnDesktop ? 0 : 2}>
                <ContainedButton
                  id="deactivateUser"
                  variant={ContainedButtonVariant.Deactivate}
                  onClick={handleDeactivateAccount}
                  fullWidth={!isShowOnDesktop}
                />
                <ContainedButton
                  id="editUser"
                  variant={ContainedButtonVariant.Edit}
                  onClick={handleEditUser}
                  fullWidth={!isShowOnDesktop}
                />
                <ContainedButton
                  id="viewFamilyButton"
                  variant={ContainedButtonVariant.ViewFamily}
                  onClick={handleNavigateToFamilies}
                  fullWidth={!isShowOnDesktop}
                />
              </Box>
            </Can>
          }
          useDivider
        />
        <CardContent>
          <Box pb={5} pt={3}>
            <AdjacentLabels
              leftLabel={t('UserAccountCard.Label.LoginEmail', 'Login Email')}
              rightLabel={userInformation.personalEmail}
            />
            <Box
              display="flex"
              flexDirection={isShowOnDesktop ? 'row' : 'column'}
            >
              <Box pr={2}>
                <AdjacentLabels
                  leftLabel={t(
                    'UserAccountCard.Label.AccountCreated',
                    'Account Created'
                  )}
                  rightLabel={userInformation.accountCreated}
                />
              </Box>
              <AdjacentLabels
                leftLabel={t('UserAccountCard.Label.Status', 'Status')}
                rightLabel={userInformation.status}
              />
            </Box>
            <AdjacentLabels
              leftLabel={t(
                'UserAccountCard.Label.TwoFactorAuthentication',
                'Two Factor Authentication'
              )}
              rightLabel={userInformation.twoFA}
            />
            {!!userAccountDetails?.email && (
              <Box
                sx={{
                  display: 'flex',
                  [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                  },
                }}
              >
                <Box pr={2}>
                  <TextField
                    id="classicalConversationsEmailField"
                    label={t(
                      'UserAccountCard.Field.ClassicalConversationsEmail',
                      'Classical Conversations Email'
                    )}
                    variant="filled"
                    disabled
                    value={userInformation.ccEmail}
                    sx={{
                      minWidth: '400px',
                      [theme.breakpoints.down('sm')]: {
                        minWidth: `calc(100vw - ${theme.spacing(10)})`,
                      },
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  )
}

export default UserAccountCard
