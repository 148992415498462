import React from 'react'
import { Box, IconButton, Paper, Typography, useTheme } from '@mui/material'
import { TooltipRenderProps } from 'react-joyride'
import Header, { HeaderVariant } from './Header'
import Close from '@mui/icons-material/Close'
import CardFormHeader from '../Card/CardFormHeader'

export const JoyrideTooltip: React.FC<TooltipRenderProps> = ({
  primaryProps,
  step,
  tooltipProps,
}) => {
  const theme = useTheme()

  return (
    <Box
      {...tooltipProps}
      border={'none'}
      py={2}
      sx={{
        paddingLeft: 2,
        paddingRight: 1,
      }}
      maxWidth={420}
      minWidth={290}
      overflow="hidden"
      component={Paper}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          {step.title && (
            <CardFormHeader
              headerContainerProps={{
                marginTop: 0,
                marginLeft: 0,
                [theme.breakpoints.down('sm')]: {
                  margin: 0,
                },
              }}
              header={
                <Header
                  css={{
                    padding: 0,
                  }}
                  variant={HeaderVariant.Card}
                  headerName={`${step.title}`}
                />
              }
            />
          )}
          <Box pb={1}>
            {step.content && (
              <Typography variant="body1">{step.content}</Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ display: 'flex ' }}>
          <IconButton
            aria-label="close"
            onClick={primaryProps.onClick}
            sx={{ marginBlock: theme.spacing(1) }}
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

export default JoyrideTooltip
