import React from 'react'
import Divider from '@mui/material/Divider'
import { useTheme } from '@mui/material'

export const FormDivider: React.FC = () => {
  const theme = useTheme()

  return (
    <Divider
      variant="middle"
      sx={{
        margin: theme.spacing(3, 0),
        backgroundColor: theme.palette.customBackground.divider,
        gridColumn: '1 / span 2',
      }}
    />
  )
}
