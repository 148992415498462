import React, { PropsWithChildren, useCallback, useState } from 'react'
import { fetchCommunity } from '../../api/communities'
import { CommunityDetail } from '../../swagger/models/CommunityDetail'
import { useCommunityParams } from '../../hooks/useCommunityParams'
import {
  ActorSelectOption,
  CommunitySpecificProgramOptionsFees,
} from '../../swagger'
import { fetchCommunitySpecificProgramOptions } from '../../api/programs'

export const defaultCommunitiesContextValue = {
  /** The search value for the filter in communities  */
  search: undefined as string | undefined,

  /** The academicYear for the filter in communities  */
  academicYear: undefined as number | undefined,

  /** The community name to use in breadcrumbs  */
  communityName: undefined as string | undefined,

  communityDetails: undefined as CommunityDetail | undefined,

  /** The director options to use in dropdowns  */
  directorOptions: undefined as ActorSelectOption[] | undefined,

  /** The tutor options to use in dropdowns  */
  tutorOptions: [],

  /** The Community specific program options fees */
  programTypesFeeOptions: undefined as
    | CommunitySpecificProgramOptionsFees
    | undefined,

  /** Method to allow updates to the academicYear in Communities */
  updateAcademicYear: (academicYear: number): void => {
    console.warn(
      `The CommunitiesContext.updateAcademicYear was called with value ${academicYear}. Did you forget to use a CommunitiesProvider?`
    )
  },

  /** Method to allow updates to the communityName in Communities */
  updateCommunityName: (communityName: string): void => {
    console.warn(
      `The CommunitiesContext.updateCommunityName was called with value ${communityName}. Did you forget to use a CommunitiesProvider?`
    )
  },
  fetchCommunityDetails: async (): Promise<void> => {
    console.warn(
      `The CommunitiesContext.fetchCommunityDetails was called. Did you forget to use a CommunitiesProvider?`
    )
  },

  /** Method to set the directors and tutors for a specific community  */
  setDirectorsAndTutorsForCommunitySelection: async (
    communityKey: number
  ): Promise<void> => {
    console.warn(
      `The CommunitiesContext.setDirectorsAndTutorsForCommunitySelection was called with value ${communityKey}. Did you forget to use a CommunitiesProvider?`
    )
  },

  setTutorOptions: (tutorOptions: ActorSelectOption[]): void => {
    console.warn(
      `The CommunitiesContext.setTutorOptions was called with value ${tutorOptions}. Did you forget to use a CommunitiesProvider?`
    )
  },

  setDirectorOptions: (directorOptions: ActorSelectOption[]): void => {
    console.warn(
      `The CommunitiesContext.setTutorOptions was called with value ${directorOptions}. Did you forget to use a CommunitiesProvider?`
    )
  },

  /** Method to allow updates to the search filter in communities */
  updateSearch: (search: string): void => {
    console.warn(
      `The CommunitiesContext.updateSearch was called with value ${search}. Did you forget to use a CommunitiesProvider?`
    )
  },

  resetContextToDefaults: (): void => {
    console.warn(
      `The CommunitiesContext.resetContextToDefaults was called by default. Did you forget to use a CommunitiesProvider?`
    )
  },
}

export const CommunitiesContext = React.createContext(
  defaultCommunitiesContextValue
)

export const useCommunitiesContext =
  (): typeof defaultCommunitiesContextValue =>
    React.useContext(CommunitiesContext)

export type TestCommunitiesContextConfig = typeof defaultCommunitiesContextValue

export interface TestCommunitiesContextAuth extends PropsWithChildren {
  testConfig?: Partial<TestCommunitiesContextConfig>
}

export const CommunitiesProvider: React.FC<TestCommunitiesContextAuth> = ({
  testConfig,
  children,
}) => {
  const [search, setSearch] = useState<string | undefined>()
  const [communityName, setCommunityName] = useState<string | undefined>()
  const [academicYear, setAcademicYear] = useState<number | undefined>()
  const [directorOptions, setDirectorOptions] = useState<ActorSelectOption[]>()
  const [tutorOptions, setTutorOptions] = useState<ActorSelectOption[]>()
  const [programTypesFeeOptions, setProgramTypesFeeOptions] =
    useState<CommunitySpecificProgramOptionsFees>()
  const updateAcademicYear = (academicYear: number) => {
    setAcademicYear(academicYear)
  }
  const [communityDetails, setCommunityDetails] = useState<
    CommunityDetail | undefined
  >(undefined)

  const { communityId } = useCommunityParams()

  const fetchCommunityDetails = async (): Promise<void> => {
    const fetchedCommunityDetails = await fetchCommunity(
      parseInt(`${communityId}`)
    )
    setCommunityDetails(fetchedCommunityDetails)
    setCommunityName(fetchedCommunityDetails?.name ?? '')
  }

  const setDirectorsAndTutorsForCommunitySelection = useCallback(
    async (communityKey: number) => {
      const fetchedCommunityProgramOptions =
        await fetchCommunitySpecificProgramOptions(communityKey)
      setDirectorOptions([
        ...fetchedCommunityProgramOptions.directors,
        ...(fetchedCommunityProgramOptions.expiredDirectors ?? []),
      ])
      setTutorOptions([
        ...fetchedCommunityProgramOptions.tutors,
        ...(fetchedCommunityProgramOptions.expiredTutors ?? []),
      ])
      setProgramTypesFeeOptions(fetchedCommunityProgramOptions.fees)
    },
    []
  )
  const resetContextToDefaults = () => {
    setSearch(defaultCommunitiesContextValue.search)
    setCommunityName(defaultCommunitiesContextValue.communityName)
    setAcademicYear(defaultCommunitiesContextValue.academicYear)
    setDirectorOptions(defaultCommunitiesContextValue.directorOptions)
    setTutorOptions(defaultCommunitiesContextValue.tutorOptions)
    setProgramTypesFeeOptions(
      defaultCommunitiesContextValue.programTypesFeeOptions
    )
    setCommunityDetails(defaultCommunitiesContextValue.communityDetails)
  }
  const value = {
    academicYear,
    communityName,
    communityDetails,
    directorOptions,
    tutorOptions: tutorOptions === undefined ? [] : tutorOptions,
    programTypesFeeOptions,
    search,

    updateAcademicYear,
    updateCommunityName: setCommunityName,
    fetchCommunityDetails,
    setDirectorsAndTutorsForCommunitySelection,
    setTutorOptions,
    setDirectorOptions,
    updateSearch: setSearch,
    resetContextToDefaults,
    ...testConfig,
  } as typeof defaultCommunitiesContextValue

  return (
    <CommunitiesContext.Provider value={value}>
      {children}
    </CommunitiesContext.Provider>
  )
}

export default CommunitiesProvider
