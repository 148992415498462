import React from 'react'
import { ContentAsset } from '../../swagger'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FileType } from '../Elements/FileTypeResource'
import LearningCenterContentCard from '../Card/LearningCenterContentCard'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import EmptyLearningCenter from './EmptyLearningCenter'

interface WelcomeCenterTabProps {
  content: ContentAsset[]
}

export const WelcomeCenterTab: React.FC<WelcomeCenterTabProps> = ({
  content,
}) => {
  const { t } = useTranslation()
  const accordionSectionTitle = t(
    'LearningCenter.WelcomeTab.Heading',
    'Welcome To CC Connected'
  )
  const theme = useTheme()
  const showOnDesktop = useShowOnDesktop()

  return (
    <div
      style={{
        margin: theme.spacing(3),
        ...(showOnDesktop && {
          margin: theme.spacing(3, 2),
        }),
      }}
    >
      {content.length > 0 ? (
        <Accordion defaultExpanded TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="welcomeCenter-content"
            id="welcomeCenter-header"
          >
            <Typography variant="button" component="p">
              {accordionSectionTitle}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              ...(showOnDesktop && {
                justifyContent: 'left',
              }),
            }}
            aria-labelledby="welcomeCenter-header"
          >
            {content.map((details) => (
              <LearningCenterContentCard
                key={details.assetKey}
                id={details.assetKey}
                fileType={details.filetype as FileType}
                title={
                  details.title ||
                  t('LearningCenter.EmptyTitle', 'No title available')
                }
                description={
                  details.description ||
                  t(
                    'LearningCenter.EmptyDescription',
                    'No description available'
                  )
                }
                subTitle={accordionSectionTitle}
                strand={details.strand ?? ''}
                isFavorite={details.isFavorited ?? false}
                viewOnly={details.viewOnly ?? false}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      ) : (
        <EmptyLearningCenter />
      )}
    </div>
  )
}

export default WelcomeCenterTab
