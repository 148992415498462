import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Typography from '@mui/material/Typography'
import { CheckboxesTypes } from './EnrollmentsTable'
import EnrollmentRowInfo from '../Interfaces/EnrollmentRowInfo'
import { useTranslation } from 'react-i18next'

interface TuitionPaidCheckboxesProps {
  studentKey: number
  programKey: number
  semesterOneTuitionPaid?: boolean
  semesterTwoTuitionPaid?: boolean
  isChallenge: boolean
  initialEnrollments: EnrollmentRowInfo[]
  canRemoveTuitionPayment: boolean
  isEnrollmentsTableEdit: boolean
  handleOnChange: (
    studentKey: number,
    programKey: number,
    checkboxType: string
  ) => void
}

const TuitionPaidCheckboxes: React.FC<TuitionPaidCheckboxesProps> = ({
  semesterOneTuitionPaid,
  semesterTwoTuitionPaid,
  studentKey,
  programKey,
  isChallenge,
  initialEnrollments,
  canRemoveTuitionPayment,
  isEnrollmentsTableEdit,
  handleOnChange,
}) => {
  const { t } = useTranslation()
  const isEnrollmentsTableEditInverted = !isEnrollmentsTableEdit
  /**
   * We need to filter the initialEnrollments by studentKey and programKey to allow
   * the non admin to click and unClick the tuition paid checkboxes before save
   */
  const initialEnrollmentFiltered = initialEnrollments.filter(
    (initialEnrollment) =>
      initialEnrollment.studentKey === studentKey &&
      initialEnrollment.programKey === programKey
  )

  /**
   * If the user has the permission (removeTuitionPayment) and the conditions (edit mode) for his role are met,
   * it will be able to `uncheck` the semester one tuition checkbox,
   * otherwise it will only be able to check
   */
  let isUserAllowedToUncheckTuitionCheckboxSemesterOne =
    isEnrollmentsTableEditInverted

  let isUserAllowedToUncheckTuitionCheckboxSemesterTwo =
    isEnrollmentsTableEditInverted

  if (
    !canRemoveTuitionPayment &&
    !!initialEnrollmentFiltered[0].semesterOneTuitionPaid
  ) {
    isUserAllowedToUncheckTuitionCheckboxSemesterOne = true
  }

  if (
    !canRemoveTuitionPayment &&
    !!initialEnrollmentFiltered[0].semesterTwoTuitionPaid
  ) {
    isUserAllowedToUncheckTuitionCheckboxSemesterTwo = true
  }

  return (
    <FormGroup sx={{ flexDirection: 'row' }}>
      <FormControlLabel
        control={
          <Checkbox
            name="tuitionPaidCheckBox1stSemester"
            color="secondary"
            sx={{ color: 'rgba(0, 0, 0, 0.26)' }}
          />
        }
        label={
          isChallenge && (
            <Typography variant="caption" component="p">
              {t(
                'TuitionPaidCheckboxes.Checkbox.FirstSemester',
                '1st Semester'
              )}
            </Typography>
          )
        }
        disabled={isUserAllowedToUncheckTuitionCheckboxSemesterOne}
        checked={semesterOneTuitionPaid}
        onChange={() =>
          handleOnChange(
            studentKey,
            programKey,
            CheckboxesTypes.semesterOneTuitionPaid
          )
        }
      />
      {isChallenge && (
        <FormControlLabel
          control={
            <Checkbox
              name="tuitionPaidCheckBox2ndSemester"
              color="secondary"
              sx={{ color: 'rgba(0, 0, 0, 0.26)' }}
            />
          }
          aria-label="2nd Semester"
          label={
            <Typography variant="caption" component="p">
              {t(
                'TuitionPaidCheckboxes.Checkbox.SecondSemester',
                '2nd Semester'
              )}
            </Typography>
          }
          disabled={isUserAllowedToUncheckTuitionCheckboxSemesterTwo}
          checked={semesterTwoTuitionPaid}
          onChange={() =>
            handleOnChange(
              studentKey,
              programKey,
              CheckboxesTypes.semesterTwoTuitionPaid
            )
          }
        />
      )}
    </FormGroup>
  )
}

export default TuitionPaidCheckboxes
