import { useTheme } from '@mui/material/styles'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams, Outlet } from 'react-router'
import {
  extractedErrorObject,
  families,
  programs as programApi,
  invoicesApi,
  tuitionPaymentsApi,
  users,
} from '../../api/swagger'
import { buildAvatarUrl } from '../../api/user'
import {
  AcademicYearPrograms,
  AddTuitionPaymentRequestBodyInvoiceOriginatorKeyEnum,
  BillingInfo,
  CashAmount,
  Program,
  Student,
  UserCommunity,
  UserProfile,
} from '../../swagger'
import { dateWithTimeToDashString } from '../../utils/dateUtility'
import { getCommaSeparatedList } from '../../utils/getCommaSeparatedList'
import { standardSort } from '../../utils/standardSort'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import { OutlinedButtonVariant } from '../Buttons/OutlinedButton'
import { sumAmounts } from '../Card/InvoiceCard'
import ProfileCard, { ProfileCardVariant } from '../Card/ProfileCard'
import { CanAccess } from '../Elements/Access'
import Header, { HeaderVariant } from '../Elements/Header'
import PartialPageLoadingSpinner from '../Elements/PartialPageLoadingSpinner'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import InvoicePaymentModal, {
  buildEnrollmentKey,
  buildEnrollmentLabel,
  InvoicePaymentModalVariant,
  PaymentModalState,
  TuitionEnrollmentOption,
  TuitionPaymentActionType,
} from '../Modals/InvoicePaymentModal'
import ProgramRenderer from '../Programs/ProgramRenderer'
import { useAuth } from '../Routes/AuthProvider'
import ChildrenSummaryCardForFamilies from './ChildrenSummaryCardForFamilies'
import EmptyFamilyProfile from './EmptyFamilyProfile'
import TuitionBillingTable, { TuitionBillingRow } from './TuitionBillingTable'
import { Page } from '../Elements/PageMargins'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { useSnackbarOnNavigation } from '../../hooks/useSnackbarOnNavigation'
import { styled } from '@mui/system'
import { Box } from '@mui/material'
import TitleContext from '../../TitleContext'
import { ElevatedCardVariants } from '../Card/ElevatedCard'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { LoadingContext } from '../Context/LoadingContext'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useMountEffect } from '../../hooks/useMountEffect'
import { useAccountContext } from '../Context/AccountContext'

const SectionPadding = styled('section')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(4, 0),
  },
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(4, 0),
  },
}))

interface TuitionInvoiceQueryParams {
  billingUserKey: number
  isTutorInvoice: boolean
}

const defaultCurrencyCode = 'USD'
const thisYear = new Date().getFullYear()
const recentAcademicYears = [thisYear - 1, thisYear, thisYear + 1]

const FamilyProfile: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location: {
    state: { triggerRefetch: boolean | undefined }
    pathname: string
  } = useLocation()
  const { permissionAbility, featureAbility } = useAuth()
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()
  const avatarUrl = buildAvatarUrl()
  useSnackbarOnNavigation()

  const availableLoadingIds = useLoadingIds()
  const { addLoadingIds } = React.useContext(LoadingContext)

  const title = t('FamilyProfile.Title', 'Profile')
  const { useTitleEffect } = useContext(TitleContext)
  useTitleEffect(title)

  const { userId } = useParams<{ userId: string }>()
  const parsedUserId = parseInt(`${userId}`)

  const [isLoadingProfileCard, setIsLoadingProfileCard] = useState(true)
  const [isLoadingStudents, setIsLoadingStudents] = useState(true)
  const [isLoadingPrograms, setIsLoadingPrograms] = useState(true)
  const [isLoadingTuitionBilling, setIsLoadingTuitionBilling] = useState(true)
  const [isLoadingTuitionEnrollments, setIsLoadingTuitionEnrollments] =
    useState(false)

  const [userInfo, setUserInfo] = useState<UserProfile>()
  const [userRolesForCommunities, setUserRolesForCommunities] = useState<
    UserCommunity[]
  >([])
  const [programs, setPrograms] = useState<Program[]>([])
  const [children, setChildren] = useState<Student[]>([])
  const [tuitionCurrencyCode, setTuitionCurrencyCode] =
    useState(defaultCurrencyCode)
  const [tuitionBilling, setTuitionBilling] = useState<BillingInfo[]>([])
  const [tuitionEnrollmentOptions, setTuitionEnrollmentOptions] = useState<
    TuitionEnrollmentOption[]
  >([])

  const billingUserActingOnIsTutor = useRef<boolean>()
  const paymentDataActingOn = useRef<PaymentModalState>()
  const paymentKeyActingOn = useRef<number>()
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
  const [paymentModalVariantToShow, setPaymentModalVariantToShow] = useState(
    InvoicePaymentModalVariant.AddPayment
  )

  const [studentPrograms, setStudentPrograms] = useState<
    AcademicYearPrograms[]
  >([])
  /**
   * We use this to trigger the useEffect for fetching data programmatically.
   * The value itself is not significant, however when the value changes it will trigger the useEffect
   */
  const [triggerRefetchStudents, setTriggerRefetchStudents] = useState(false)
  const [triggerRefetchTuitionInfo, setTriggerRefetchTuitionInfo] =
    useState(false)

  const { triggerRefetch: triggerRefetchAfterSendingInvoice } =
    location.state ?? {}

  const hasInvoiceFeatureFlag = featureAbility.can('invoices', 'Feature')

  const canViewPayment = permissionAbility.can('view', 'Payment')
  const canSendTuitionInvoice = permissionAbility.can(
    'sendTuitionInvoice',
    'Payment'
  )

  const canAddTuitionPayment = permissionAbility.can(
    'addTuitionPayment',
    'Payment'
  )

  const canAddTuitionCredit = permissionAbility.can(
    'addTuitionCredit',
    'Payment'
  )

  const hasProperPermissionForBillingSection = [
    canSendTuitionInvoice,
    canAddTuitionPayment,
    canViewPayment,
  ].some(Boolean)

  const { students } = useAccountContext()

  const commonReqParam = useMemo(() => ({ userId: userId ?? 'me' }), [userId])

  /**
   * Fetch user profile and user community
   */
  useMountEffect(() => {
    addLoadingIds([availableLoadingIds.UserAccounts.fetchUserProfile])
  })

  const getDataForProfileCard = async () => {
    try {
      const fetchedUserProfile = await users.fetchUserProfile(commonReqParam)
      setUserInfo(fetchedUserProfile)

      const fetchedUserCommunities = await users.fetchUserCommunities(
        commonReqParam
      )
      setUserRolesForCommunities(fetchedUserCommunities)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message,
      }
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarState?.(true)
    } finally {
      setIsLoadingProfileCard(false)
    }
  }

  useLoadingContext({
    asyncFunction: getDataForProfileCard,
    loadingId: availableLoadingIds.UserAccounts.fetchUserProfile,
  })

  /**
   * Fetch students for user
   */
  useEffect(() => {
    addLoadingIds([availableLoadingIds.Families.fetchStudentsForFamily])
  }, [
    addLoadingIds,
    availableLoadingIds.Families.fetchStudentsForFamily,
    triggerRefetchStudents,
  ])

  const getStudentsForFamily = async () => {
    try {
      /**
       * A temporary fix to prevent multiple calls to this endpoint when
       * already containing the students for the AccountContext.
       */
      const fetchedStudents = !userId
        ? { students }
        : await families.fetchStudentsForFamily(commonReqParam)
      const studentPrograms: AcademicYearPrograms[] = []
      if (fetchedStudents) {
        const programsAfterFetch: Program[] = []
        const arrayOfDistinctPrograms = new Set<number>()

        fetchedStudents.students.forEach((student) => {
          student.programs.forEach((program) => {
            arrayOfDistinctPrograms.add(program.programKey)
          })
        })

        const responses = await Promise.all(
          Array.from(arrayOfDistinctPrograms).map((programkey: number) => {
            if (programkey) {
              return programApi.fetchProgram({
                programId: programkey,
              })
            } else {
              return [] as unknown as Program
            }
          })
        )
        responses.forEach((response) => {
          programsAfterFetch.push(response)
        })

        //get an array with the programs
        programsAfterFetch.map(async (program: Program) => {
          const utcFullYear = new Date(
            program.semesterOneStartDate
          ).getUTCFullYear()

          const existedStudents = studentPrograms.find(
            (program: AcademicYearPrograms) =>
              program.academicYearBeginning === utcFullYear
          )

          if (existedStudents) {
            existedStudents.programs.push(program)
          } else {
            studentPrograms.push({
              academicYearBeginning: utcFullYear,
              programs: [program],
            })
          }
        })
      }

      studentPrograms && setStudentPrograms(studentPrograms)
      setChildren(fetchedStudents.students)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message,
      }
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarState?.(true)
    } finally {
      setIsLoadingStudents(false)
    }
  }

  useLoadingContext({
    asyncFunction: getStudentsForFamily,
    loadingId: availableLoadingIds.Families.fetchStudentsForFamily,
  })

  /**
   * Fetch programs for user
   */
  useEffect(() => {
    const getProgramsForUser = async () => {
      try {
        const filteredAcademicYearPrograms: AcademicYearPrograms[] =
          studentPrograms.filter((academicYearProgram) =>
            recentAcademicYears.includes(
              academicYearProgram.academicYearBeginning
            )
          )

        const programs = filteredAcademicYearPrograms.reduce(
          (acc, curr): Program[] => [...acc, ...curr.programs],
          [] as Program[]
        )

        setPrograms(programs)
      } catch (e) {
        const errorObject = (await extractedErrorObject(e)) ?? {
          code: 'Unknown',
          message: (e as unknown as Error).message,
        }
        setSnackbarSeverity?.(SnackbarSeverity.Error)
        setSnackbarMessage?.(errorObject.message)
        setSnackbarState?.(true)
      } finally {
        setIsLoadingPrograms(false)
      }
    }

    getProgramsForUser()
  }, [
    commonReqParam,
    setSnackbarSeverity,
    setSnackbarMessage,
    setSnackbarState,
    studentPrograms,
  ])

  /**
   * Fetch tuition billing
   */
  useEffect(() => {
    if (hasProperPermissionForBillingSection) {
      addLoadingIds([availableLoadingIds.Families.fetchFamilyTuitionBilling])
    }
  }, [
    hasProperPermissionForBillingSection,
    triggerRefetchTuitionInfo,
    triggerRefetchAfterSendingInvoice,
    addLoadingIds,
    availableLoadingIds.Families.fetchFamilyTuitionBilling,
  ])

  const getTuitionBilling = async () => {
    try {
      const fetchedTuitionBilling = await families.fetchFamilyTuitionBilling({
        parentUserKey: parsedUserId,
      })

      setTuitionBilling(fetchedTuitionBilling.billingList)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message,
      }
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarState?.(true)
    } finally {
      setIsLoadingTuitionBilling(false)
    }
  }

  useLoadingContext({
    asyncFunction: getTuitionBilling,
    loadingId: availableLoadingIds.Families.fetchFamilyTuitionBilling,
  })

  const refetchStudents = () => {
    // Programmatically trigger a refetch
    setTriggerRefetchStudents((prevState) => !prevState)
  }

  const refetchTuitionInfo = () => {
    // Programmatically trigger a refetch
    setTriggerRefetchTuitionInfo((prevState) => !prevState)
  }

  const navigateToInvoicePreview = (
    billingUserKey: number,
    isTutorInvoice: boolean,
    invoiceKey?: number
  ) => {
    const basePath = `${location.pathname}/invoice`
    const pathname = !invoiceKey
      ? basePath
      : `${basePath}/${invoiceKey.toString()}`

    navigate(
      {
        pathname,
      },
      {
        state: { billingUserKey, isTutorInvoice },
      }
    )
  }

  const getTuitionEnrollmentsFromInvoice = async ({
    billingUserKey,
    isTutorInvoice,
  }: TuitionInvoiceQueryParams) => {
    setIsLoadingTuitionEnrollments(true)
    try {
      const { currencyCode, enrollments } =
        await invoicesApi.fetchTuitionInvoice({
          parentUserKey: parsedUserId,
          billingUserKey,
          isTutorInvoice,
        })

      // Build tuition enrollment options
      const tuitionEnrollmentOptions = enrollments.map(
        ({
          programKey,
          programType,
          studentKey,
          studentName,
          fees,
          discounts = [],
          payments = [],
        }): TuitionEnrollmentOption => {
          // Calculate how much each enrollment owes
          const totalFees = sumAmounts(fees)
          const totalDiscounts = sumAmounts(discounts)
          const totalPayments = sumAmounts(payments)
          const total = totalFees - totalDiscounts - totalPayments

          return {
            studentKey,
            studentName,
            programKey,
            programName: programType,
            amountOwed: { amount: total, currencyCode },
          }
        }
      )

      setTuitionCurrencyCode(currencyCode)
      setTuitionEnrollmentOptions(tuitionEnrollmentOptions)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message,
      }
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarState?.(true)
    } finally {
      setIsLoadingTuitionEnrollments(false)
    }
  }

  const onAddOrEditPaymentClick = (
    variantToShow: InvoicePaymentModalVariant,
    { billingUserKey, isTutorInvoice }: TuitionInvoiceQueryParams,
    initialData?: PaymentModalState,
    tuitionPaymentKey?: number
  ) => {
    // Store data for modal
    paymentDataActingOn.current = initialData
    paymentKeyActingOn.current = tuitionPaymentKey

    // Open modal
    setPaymentModalVariantToShow(variantToShow)
    setIsPaymentModalOpen(true)

    // Make api call to fetch enrollment options
    getTuitionEnrollmentsFromInvoice({ billingUserKey, isTutorInvoice })
  }

  const handleEnterPayment = (
    billingUserKey: number,
    isTutorBilling: boolean,
    variantToShow: InvoicePaymentModalVariant
  ) => {
    billingUserActingOnIsTutor.current = isTutorBilling
    onAddOrEditPaymentClick(variantToShow, {
      billingUserKey,
      isTutorInvoice: isTutorBilling,
    })
  }

  const handleViewTuitionPaymentClick = (
    {
      tuitionPaymentKey,
      enrollmentKey,
      amount,
      date,
      description,
      isDiscount,
    }: {
      tuitionPaymentKey?: number
      enrollmentKey: string
      amount: CashAmount
      date: Date
      description?: string
      isDiscount: boolean
    },
    { billingUserKey, isTutorInvoice }: TuitionInvoiceQueryParams
  ) => {
    // Initial data with which to initialize payment modal
    const initialData: PaymentModalState = {
      enrollmentKey,
      amount: amount.amount,
      dateReceived: dateWithTimeToDashString(date),
      description: description ?? '',
    }

    onAddOrEditPaymentClick(
      isDiscount
        ? InvoicePaymentModalVariant.EditDiscount
        : InvoicePaymentModalVariant.EditPayment,
      { billingUserKey, isTutorInvoice },
      initialData,
      tuitionPaymentKey
    )
  }

  const performTuitionPaymentAction = async ({
    action,
    paymentData,
    callback,
    isDiscount,
  }: {
    action: TuitionPaymentActionType
    paymentData?: PaymentModalState
    callback?: () => void
    isDiscount: boolean
  }) => {
    switch (action) {
      case 'add':
        {
          // Do we have the needed data?
          if (
            !paymentData ||
            typeof billingUserActingOnIsTutor.current === 'undefined'
          )
            return

          const {
            enrollmentKey,
            amount = 0,
            dateReceived,
            description,
          } = paymentData

          const [programKey, studentKey] = enrollmentKey.split('-')

          const invoiceOriginatorKey = billingUserActingOnIsTutor.current
            ? AddTuitionPaymentRequestBodyInvoiceOriginatorKeyEnum.Tutor
            : AddTuitionPaymentRequestBodyInvoiceOriginatorKeyEnum.Director

          try {
            await tuitionPaymentsApi.addTuitionPayment({
              body: {
                studentKey: +studentKey,
                programKey: +programKey,
                amount: { amount, currencyCode: tuitionCurrencyCode },
                dateReceived: new Date(dateReceived),
                description,
                invoiceOriginatorKey,
                isDiscount,
              },
            })

            setSnackbarSeverity?.(SnackbarSeverity.Success)
            setSnackbarMessage?.(
              t(
                'TuitionBillingTable.EnterPayment.Success',
                'Payment successfully added.'
              )
            )
            setSnackbarState?.(true)

            // Refresh the tuition table upon success
            refetchTuitionInfo()
          } catch (e) {
            const errorObject = (await extractedErrorObject(e)) ?? {
              code: 'Unknown',
              message: (e as unknown as Error).message,
            }
            setSnackbarSeverity?.(SnackbarSeverity.Error)
            setSnackbarMessage?.(errorObject.message)
            setSnackbarState?.(true)
          }
        }
        break
      case 'edit':
        {
          // Do we have the needed data?
          if (!paymentData || !paymentKeyActingOn.current) return

          const {
            enrollmentKey,
            amount = 0,
            dateReceived,
            description,
          } = paymentData

          const [programKey, studentKey] = enrollmentKey.split('-')

          try {
            await tuitionPaymentsApi.updateTuitionPayment({
              tuitionPaymentKey: paymentKeyActingOn.current,
              body: {
                studentKey: +studentKey,
                programKey: +programKey,
                amount: { amount, currencyCode: tuitionCurrencyCode },
                dateReceived: new Date(dateReceived),
                description,
              },
            })

            setSnackbarSeverity?.(SnackbarSeverity.Success)
            setSnackbarMessage?.(
              t(
                'TuitionBillingTable.EditPayment.Success',
                'Payment successfully edited.'
              )
            )
            setSnackbarState?.(true)

            // Refresh the tuition table upon success
            refetchTuitionInfo()
          } catch (e) {
            const errorObject = (await extractedErrorObject(e)) ?? {
              code: 'Unknown',
              message: (e as unknown as Error).message,
            }
            setSnackbarSeverity?.(SnackbarSeverity.Error)
            setSnackbarMessage?.(errorObject.message)
            setSnackbarState?.(true)
          }
        }
        break
      case 'delete':
        // Do we have the needed data?
        if (!paymentKeyActingOn.current) return

        try {
          await tuitionPaymentsApi.removeTuitionPayment({
            tuitionPaymentKey: paymentKeyActingOn.current,
          })

          setSnackbarSeverity?.(SnackbarSeverity.Success)
          setSnackbarMessage?.(
            t(
              'TuitionBillingTable.RemovePayment.Success',
              'Payment successfully removed.'
            )
          )
          setSnackbarState?.(true)

          // Refresh the tuition table upon success
          refetchTuitionInfo()
        } catch (e) {
          const errorObject = (await extractedErrorObject(e)) ?? {
            code: 'Unknown',
            message: (e as unknown as Error).message,
          }
          setSnackbarSeverity?.(SnackbarSeverity.Error)
          setSnackbarMessage?.(errorObject.message)
          setSnackbarState?.(true)
        }

        // This is needed to close the "Are you sure..." confirmation modal
        callback?.()
        break
    }
    setIsPaymentModalOpen(false)
  }

  if (location.pathname.includes(`/family-profile/${userId}/invoice`)) {
    return (
      <Page>
        <CanAccess I="families" on="Feature">
          <Outlet />
        </CanAccess>
      </Page>
    )
  }

  return (
    <Page>
      <CanAccess I="families" on="Feature">
        {!userInfo ? (
          <EmptyFamilyProfile isLoading={isLoadingProfileCard} />
        ) : (
          <>
            <ProfileCard
              userInfo={userInfo}
              userRolesForCommunities={userRolesForCommunities}
              avatarUrl={avatarUrl}
              variant={ProfileCardVariant.Family}
            />
            <PartialPageLoadingSpinner isLoading={isLoadingStudents}>
              <SectionPadding aria-labelledby="children">
                <ChildrenSummaryCardForFamilies
                  kids={children}
                  refetchStudents={refetchStudents}
                />
              </SectionPadding>
            </PartialPageLoadingSpinner>

            <PartialPageLoadingSpinner isLoading={isLoadingPrograms}>
              <SectionPadding aria-labelledby="programs">
                <Header
                  id="programs"
                  headerName={t('Families.Programs.Header', 'Programs')}
                  component="h2"
                  variant={HeaderVariant.Card}
                />
                <ProgramRenderer
                  type={ElevatedCardVariants.Programs}
                  programs={programs}
                />
              </SectionPadding>
            </PartialPageLoadingSpinner>
            {hasInvoiceFeatureFlag && hasProperPermissionForBillingSection && (
              <PartialPageLoadingSpinner isLoading={isLoadingTuitionBilling}>
                <SectionPadding aria-label="tuition billing">
                  {tuitionBilling.map((billingInfo, index) => {
                    const {
                      billingUserFullName,
                      billingUserKey,
                      isTutorBilling,
                      totalAmountOwed,
                      tuitionPayments,
                      invoices,
                    } = billingInfo

                    const headerName = isTutorBilling
                      ? t(
                          'FamilyProfile.Header.TutorBilling',
                          'Tutor Billing - {{tutorName}}',
                          {
                            tutorName: billingUserFullName,
                            interpolation: { escapeValue: false },
                          }
                        )
                      : t(
                          'FamilyProfile.Header.Billing',
                          'Billing - {{programDirectorName}}',
                          {
                            programDirectorName: billingUserFullName,
                            interpolation: { escapeValue: false },
                          }
                        )

                    const paymentRows = (tuitionPayments ?? []).map(
                      ({
                        tuitionPaymentKey,
                        amountPaid: amount,
                        description,
                        paymentDate: date,
                        programKey,
                        programType,
                        studentKey,
                        studentName,
                        isDirectorDiscount,
                      }): TuitionBillingRow => {
                        return {
                          date,
                          transaction: isDirectorDiscount
                            ? t(
                                'TuitionBillingTable.Transaction.Discount',
                                'Discount Applied'
                              )
                            : t(
                                'TuitionBillingTable.Transaction.Payment',
                                'Payment Received'
                              ),
                          program: `${programType} (${studentName})`,
                          description,
                          amount,
                          handleClick: () => {
                            handleViewTuitionPaymentClick(
                              {
                                tuitionPaymentKey,
                                enrollmentKey: buildEnrollmentKey(
                                  programKey,
                                  studentKey
                                ),
                                amount,
                                date,
                                description,
                                isDiscount: isDirectorDiscount,
                              },
                              {
                                billingUserKey,
                                isTutorInvoice: isTutorBilling,
                              }
                            )
                          },
                        }
                      }
                    )

                    const invoiceRows = invoices.map(
                      ({
                        invoiceKey,
                        // has keys now
                        studentPrograms,
                        dateSent: date = new Date(), // prevents dataSent from being undefined
                        amountOwed: amount,
                      }): TuitionBillingRow => {
                        const studentProgramsList = (studentPrograms ?? []).map(
                          ({ programType, studentName }) =>
                            buildEnrollmentLabel(programType, studentName)
                        )
                        return {
                          date,
                          transaction: t(
                            'TuitionBillingTable.Transaction.Invoice',
                            'Invoice #{{invoiceKey}}',
                            { invoiceKey }
                          ),
                          program: getCommaSeparatedList(studentProgramsList),
                          description: '-',
                          amount,
                          handleClick: () => {
                            navigateToInvoicePreview(
                              billingUserKey,
                              isTutorBilling,
                              invoiceKey
                            )
                          },
                        }
                      }
                    )

                    // Sorted in descending order to show most recent data first
                    const tuitionBillingData = [
                      ...paymentRows,
                      ...invoiceRows,
                    ].sort((a, b) => standardSort(b.date, a.date))

                    const uniqueHeaderId = `billingHeader${index}`

                    const SendInvoiceButton = canSendTuitionInvoice && (
                      <ContainedButton
                        id="sendInvoice"
                        variant={ContainedButtonVariant.Invoice}
                        onClick={() =>
                          navigateToInvoicePreview(
                            billingUserKey,
                            isTutorBilling
                          )
                        }
                      />
                    )

                    const secondaryButtonProps: {
                      id: string
                      variant: OutlinedButtonVariant
                      onClick: () => void
                    }[] = [
                      ...(canAddTuitionCredit
                        ? [
                            {
                              id: 'enterDiscount',
                              variant: OutlinedButtonVariant.EnterDiscount,
                              onClick: () =>
                                handleEnterPayment(
                                  billingUserKey,
                                  isTutorBilling,
                                  InvoicePaymentModalVariant.AddDiscount
                                ),
                            },
                          ]
                        : []),
                      ...(canAddTuitionPayment
                        ? [
                            {
                              id: 'enterPayment',
                              variant: OutlinedButtonVariant.EnterPayment,
                              onClick: () =>
                                handleEnterPayment(
                                  billingUserKey,
                                  isTutorBilling,
                                  InvoicePaymentModalVariant.AddPayment
                                ),
                            },
                          ]
                        : []),
                    ]

                    return (
                      <React.Fragment key={index}>
                        <Box
                          sx={{
                            margin: theme.spacing(3),
                          }}
                        >
                          <Header
                            id={uniqueHeaderId}
                            headerName={headerName}
                            component="h6"
                            variant={
                              index === 0 ? HeaderVariant.Card : undefined
                            }
                          />
                        </Box>
                        <TuitionBillingTable
                          tuitionBillingData={tuitionBillingData}
                          amountDue={totalAmountOwed}
                          primaryButton={SendInvoiceButton}
                          secondaryButtonProps={secondaryButtonProps}
                          ariaLabelledBy={uniqueHeaderId}
                        />
                      </React.Fragment>
                    )
                  })}
                </SectionPadding>
              </PartialPageLoadingSpinner>
            )}

            <InvoicePaymentModal
              variant={paymentModalVariantToShow}
              isOpen={isPaymentModalOpen}
              setIsOpen={setIsPaymentModalOpen}
              isLoading={isLoadingTuitionEnrollments}
              clearTuitionEnrollmentOptions={() =>
                setTuitionEnrollmentOptions([])
              }
              tuitionEnrollmentOptions={tuitionEnrollmentOptions}
              performTuitionPaymentAction={performTuitionPaymentAction}
              initialPaymentData={paymentDataActingOn.current}
              name={InvoicePaymentModal.name}
            />
          </>
        )}
      </CanAccess>
    </Page>
  )
}

export default FamilyProfile
