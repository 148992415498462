/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  Payment,
  PaymentFromJSON,
  PaymentToJSON,
  TuitionPayments1,
  TuitionPayments1FromJSON,
  TuitionPayments1ToJSON,
} from './'

/**
 *
 * @export
 * @interface TuitionPaymentResponse
 */
export interface TuitionPaymentResponse {
  /**
   * The unique identifier for the student the tuition payment if for
   * @type {number}
   * @memberof TuitionPaymentResponse
   */
  studentKey?: number
  /**
   * The unique identifier for the program the tuition payment if for
   * @type {number}
   * @memberof TuitionPaymentResponse
   */
  programKey?: number
  /**
   *
   * @type {Payment}
   * @memberof TuitionPaymentResponse
   */
  payment: Payment
  /**
   * The unique identifier for the tuition payment
   * @type {number}
   * @memberof TuitionPaymentResponse
   */
  tuitionPaymentKey?: number
  /**
   * The tuition payments for the family
   * @type {Array<TuitionPayments1>}
   * @memberof TuitionPaymentResponse
   */
  tuitionPayments?: Array<TuitionPayments1>
}

export function TuitionPaymentResponseFromJSON(
  json: any
): TuitionPaymentResponse {
  return TuitionPaymentResponseFromJSONTyped(json, false)
}

export function TuitionPaymentResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TuitionPaymentResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentKey: !exists(json, 'studentKey') ? undefined : json['studentKey'],
    programKey: !exists(json, 'programKey') ? undefined : json['programKey'],
    payment: PaymentFromJSON(json['payment']),
    tuitionPaymentKey: !exists(json, 'tuitionPaymentKey')
      ? undefined
      : json['tuitionPaymentKey'],
    tuitionPayments: !exists(json, 'tuitionPayments')
      ? undefined
      : (json['tuitionPayments'] as Array<any>).map(TuitionPayments1FromJSON),
  }
}

export function TuitionPaymentResponseToJSON(
  value?: TuitionPaymentResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    studentKey: value.studentKey,
    programKey: value.programKey,
    payment: PaymentToJSON(value.payment),
    tuitionPaymentKey: value.tuitionPaymentKey,
    tuitionPayments:
      value.tuitionPayments === undefined
        ? undefined
        : (value.tuitionPayments as Array<any>).map(TuitionPayments1ToJSON),
  }
}
