import React, { useMemo, useState, useContext } from 'react'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import Header, { HeaderVariant } from '../Elements/Header'
import CardFormHeader from '../Card/CardFormHeader'
import {
  AgreementTemplateFormFieldInputType,
  AgreementTemplateFormFields,
  AgreementDocumentSelectOption,
  extractedErrorObject,
  RoleAgreementDocumentSelectOption,
  AgreementTemplateFormField,
} from '../../api/swagger'
import { FormDivider } from '../Elements/FormDivider'
import { MenuItem, useTheme } from '@mui/material'
import { useEffect } from 'react'
import { fetchTeamInviteOptions, inviteTeamMember } from '../../api/teams'
import useValidationMessages from '../../hooks/useValidationMessages'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import {
  fetchAgreementTemplateFormFields,
  createAgreement,
  fetchAgreementPreview,
  deleteAgreement,
} from '../../api/agreements'
import FormRecipients from '../Team/FormRecipients'
import FormData from '../Team/FormData'
import useDynamicFieldStates from '../../hooks/useDynamicFieldStates'
import StandardizedFields, {
  StandardizedFieldNames,
} from '../Team/StandardizedFields'
import ConfirmationModal from '../Modals/ConfirmationModal'
import TeamMemberButtons from '../Buttons/TeamMemberButtons'
import { jsDateFromYearMonthDayString } from '../../utils/jsDateFromYearMonthDayString'
import { useUser } from '../../UserContext'
import {
  dateToDashString,
  isFirstDateStringBeforeSecond,
} from '../../utils/dateUtility'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { useNavigate } from 'react-router'
import { TextButtonVariant } from '../Buttons/TextButton'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import Typography from '@mui/material/Typography'
import { validateEmailText } from '../../helpers/validateEmail'
import ActionButtons from '../Buttons/ActionButtons'
import { useEmailMessage } from '../../hooks/useEmailMessage'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { styled } from '@mui/system'
import LoadingProgress from '../Elements/LoadingProgress'
import { reinterpretYearMonthDayAsLocalTime } from '../../utils/reinterpretYearMonthDayAsLocalTime'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import useLoadingContext from '../../hooks/useLoadingContext'
import { LoadingContext } from '../Context/LoadingContext'

const Form = styled('form')(({ theme }) => ({
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(1)})`,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0),
    },
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const PublicUrlLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  marginLeft: theme.spacing(2),
}))

const SectionOne = styled('section')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  gridTemplateRows: 'auto ',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

interface TeamMemberCardProps {
  title: string
}

enum TeamMemberValidationMessageTypes {
  Default = 'default',
  NoResultsForRole = 'noResultsForRole',
  Role = 'role',
  AgreementTemplate = 'agreementTemplate',
  SignerEmail = 'signerEmail',
  NoRoles = 'noRoles',
  SignerEmailFormat = 'signerEmailFormat',
}

const TeamMemberCard: React.FC<TeamMemberCardProps> = (props) => {
  const { validationMessages } = useEmailMessage()
  const theme = useTheme()
  const { t } = useTranslation()
  const { title } = props
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()

  const { TeamMemberCard } = useLoadingIds()
  const { addLoadingIds, loadingIds } = useContext(LoadingContext)

  const navigate = useNavigate()

  const showOnDesktop = useShowOnDesktop()

  const validationMessageMap = useValidationMessages([
    {
      field: TeamMemberValidationMessageTypes.Default,
      message: t(
        'Team.TeamMemberForm.ErrorMessage',
        'Something went wrong. Please make sure you have filled out the required fields.'
      ),
    },
    {
      field: TeamMemberValidationMessageTypes.NoResultsForRole,
      message: t(
        'Team.TeamMemberForm.NoResultsForRole',
        'No Results for selected Role.'
      ),
    },
    {
      field: TeamMemberValidationMessageTypes.Role,
      message: t(
        'Team.TeamMemberForm.RoleValidationMessage',
        'Role cannot be empty.'
      ),
    },
    {
      field: TeamMemberValidationMessageTypes.NoRoles,
      message: t(
        'Team.TeamMemberForm.NoRolesErrorMessage',
        'Something went wrong. No roles were found to start an agreement.'
      ),
    },
    {
      field: TeamMemberValidationMessageTypes.AgreementTemplate,
      message: t(
        'Team.TeamMemberForm.AgreementTemplateValidationMessage',
        'Agreement Template cannot be empty.'
      ),
    },
    {
      field: TeamMemberValidationMessageTypes.SignerEmail,
      message: t(
        'Team.TeamMemberForm.SignerEmailValidationMessage',
        'Email address cannot be empty.'
      ),
    },
    {
      field: TeamMemberValidationMessageTypes.SignerEmailFormat,
      message: t(
        'Team.TeamMemberForm.SignerEmailValidationMessage',
        'Email address is formatted incorrectly.'
      ),
    },
  ])

  const errorMessage = validationMessageMap.get(
    TeamMemberValidationMessageTypes.Default
  ) as string

  const [isLoading, setIsLoading] = useState(false)

  const [role, setRole] = useState<RoleAgreementDocumentSelectOption>()
  const [roleId, setRoleId] = useState(-1)
  const [bypassAgreement, setBypassAgreement] = useState(false)
  const [requiredEndDate, setRequiredEndDate] = useState(false)
  const [roleChanged, setRoleChanged] = useState(false)
  const [agreementTemplateChanged, setAgreementTemplateChanged] =
    useState(false)
  const [agreementTemplate, setAgreementTemplate] = useState('')
  const [signerEmail, setSignerEmail] = useState('')

  const [isRoleValid, setIsRoleValid] = useState(true)
  const [isAgreementDocumentValid, setIsAgreementDocumentValid] = useState(true)
  const [isSignerEmailValid, setIsSignerEmailValid] = useState(false)
  const [textErrorEmail, setTextErrorEmail] = useState<string>('')

  const clearFields = () => {
    setIsAgreementDocumentValid(true)
    setRecipientStates({})
    setIsInitialRecipientLoad(true)
    setFormDataStates({})
    setIsInitialFormDataLoad(true)
    setSignerEmail('')
  }

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const roleAgreementDocument = rolesMap.get(event.target.value)
    const selectedRoleBypassesAgreements =
      roleAgreementDocument?.bypassAutomatedAgreements ?? false
    setRole(roleAgreementDocument)
    setRoleId(roleAgreementDocument?.roleKey ?? roleId)
    setBypassAgreement(selectedRoleBypassesAgreements)
    setRequiredEndDate(roleAgreementDocument?.requireEndDate ?? false)
    setRoleChanged(true)
    setIsRoleValid(true)
    const firstAgreementTemplate = roleAgreementMap.get(event.target.value)
    setPreviousAgreementDocumentId(agreementDocumentId)
    setPreviousAgreementTemplateId(agreementTemplateId)
    const newAgreementTemplateId =
      firstAgreementTemplate?.templateId ?? agreementTemplateId
    const newAgreementDocumentId =
      firstAgreementTemplate?.agreementDocuments[0]?.agreementDocumentId ??
      agreementDocumentId
    setAgreementTemplate(
      firstAgreementTemplate?.agreementDocuments[0]?.agreementDocumentName ??
        agreementTemplate
    )
    setAgreementDocumentId(newAgreementDocumentId)
    setAgreementTemplateId(newAgreementTemplateId)
    if (
      selectedRoleBypassesAgreements ||
      newAgreementDocumentId !== agreementDocumentId ||
      newAgreementTemplateId !== agreementTemplateId
    )
      setAgreementTemplateFormFields(undefined)
    clearFields()
    setUpdateStandardizedFieldStates(true)
  }

  const handleAgreementTemplateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const [agreementTemplate, newAgreementDocumentId] =
      event.target.value.split('|-|')

    setPreviousAgreementDocumentId(agreementDocumentId)
    setPreviousAgreementTemplateId(agreementTemplateId)
    setAgreementTemplate(agreementTemplate)
    setAgreementDocumentId(newAgreementDocumentId)
    setIsAgreementDocumentValid(true)
    clearFields()
    setAgreementTemplateFormFields(undefined)
    setFormDataFieldNames([])
    setAgreementTemplateChanged(true)
    setUpdateStandardizedFieldStates(true)
  }

  const handleSignerEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSignerEmail(event.target.value.trim())
    setIsSignerEmailValid(false)
  }

  const doRecipientsValidate = () => {
    let doRecipientsValidate = true
    let recipientStateObject = {}
    for (const [key, { state, maxLength, minLength }] of Object.entries(
      recipientStates
    )) {
      const isOverLength =
          !maxLength || (!!maxLength && state.length <= maxLength),
        isUnderLength =
          !minLength || (!!minLength && state.length >= minLength),
        isProperLength = isOverLength && isUnderLength

      if (!state || (!!state && !isProperLength)) {
        doRecipientsValidate = false
      }

      recipientStateObject = {
        ...recipientStateObject,
        [key]: {
          state,
          maxLength,
          minLength,
          isValid: {
            input: !!state,
            maxLength: isOverLength,
            minLength: isUnderLength,
            email: !validateEmailText(state),
          },
          validationMessage: validationEmailMessage(validateEmailText(state)),
        },
      }
    }
    setRecipientStates({ ...recipientStates, ...recipientStateObject })
    const signerEmailValid = !!validateEmailText(signerEmail)
    setTextErrorEmail(validateEmailText(signerEmail))
    setIsSignerEmailValid(signerEmailValid)
    return (bypassAgreement || doRecipientsValidate) && !signerEmailValid
  }

  const doesFormDataValidate = () => {
    let doesFormDataValidate = true
    let formDataStateObject = {}
    for (const [
      key,
      { state, required, maxLength, minLength, inputType },
    ] of Object.entries(formDataStates)) {
      const isDateField = inputType === AgreementTemplateFormFieldInputType.Date
      const isOverLength = !!maxLength && state.length > maxLength,
        isUnderLength = !!minLength && state.length < minLength,
        isProperLength = !isOverLength && !isUnderLength

      if (required && (!state || (!!state && !isProperLength))) {
        doesFormDataValidate = false
      }
      formDataStateObject = {
        ...formDataStateObject,
        [key]: {
          state,
          required,
          maxLength,
          minLength,
          inputType,
          isValid: required
            ? {
                input: !!state,
                maxLength: isDateField || !maxLength ? true : !isOverLength,
                minLength: isDateField || !minLength ? true : !isUnderLength,
                email:
                  key.trim() === 'Personal Email of Contractor'
                    ? !validateEmailText(state)
                    : true,
              }
            : { input: true, maxLength: true, minLength: true },
          validationMessage:
            key.trim() === 'Personal Email of Contractor' &&
            validationEmailMessage(validateEmailText(state)),
        },
      }
    }
    setFormDataStates({ ...formDataStates, ...formDataStateObject })
    return doesFormDataValidate
  }

  const doStandardizedFieldsValidate = () => {
    const maximumDate = new Date(Date.UTC(9999, 11, 31, 23, 59, 59))
    const minimumDate = new Date(Date.UTC(new Date().getFullYear() - 2, 0, 1))
    let doStandardizedFieldsValidate = true
    let stateObject = {}
    for (const [key, { state, required }] of Object.entries(
      standardizedFieldStates
    )) {
      let isRequired = required
      let isWithinDateRange = true
      let isRequiredInSomeRolesIfValueIsEmpty = true

      if (required && !state) {
        doStandardizedFieldsValidate = false
      } else if (key === StandardizedFieldNames.EndDate) {
        if (!!state) {
          // Validate the End Date is before Effective Date IF it exists
          const effectiveDate =
            standardizedFieldStates[StandardizedFieldNames.EffectiveDate]

          if (!!effectiveDate) {
            isWithinDateRange = isFirstDateStringBeforeSecond(
              new Date(effectiveDate.state),
              new Date(state)
            )

            if (!isWithinDateRange) {
              doStandardizedFieldsValidate = false
              isRequiredInSomeRolesIfValueIsEmpty = true
            }
          }
        } else if (bypassAgreement && requiredEndDate) {
          doStandardizedFieldsValidate = false
          isRequiredInSomeRolesIfValueIsEmpty = false
          isWithinDateRange = true
          isRequired = true
        }
      }

      stateObject = {
        ...stateObject,
        [key]: {
          ...standardizedFieldStates[key],
          state,
          required: isRequired,
          isValid: {
            input: !required || (required && !!state),
            dateRange: isWithinDateRange,
            isRequiredInSomeRolesIfValueIsEmpty,
            beforeMax: new Date(state) <= maximumDate,
            afterMin: new Date(state) >= minimumDate,
          },
        },
      }
    }
    setStandardizedFieldStates(stateObject)
    return doStandardizedFieldsValidate
  }

  const fieldsDoValidate = () => {
    const recipientsValidate = doRecipientsValidate()
    const formDataValidates = doesFormDataValidate()
    const standardizedFieldsValidate = doStandardizedFieldsValidate()

    setIsRoleValid(!!role)
    setIsAgreementDocumentValid(!!agreementTemplate)
    return (
      !!role &&
      (bypassAgreement || !!agreementTemplate) &&
      recipientsValidate &&
      (bypassAgreement || formDataValidates) &&
      standardizedFieldsValidate
    )
  }

  const removeSpacesStartAndEndFromEmailField = (
    inputName: string,
    value: string
  ): string => {
    // Add here all fields containing email to avoid entering spaces at the beginning and end of the field
    const emailTypeFields = [
      'Area Representative',
      'Sales Manager',
      'RecipientArea Representative',
      'Personal Email of Contractor',
      'Personal Email of Employee',
      'Support Rep',
    ]

    const exists = emailTypeFields.includes(inputName.trim())

    if (exists) {
      return value.trim()
    }
    return value
  }

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  // FIXME: Need to determine supervisorId, not just default to current user
  const { user } = useUser()

  //Enter the handle next before to validate the fields and call the endpoint to create an Agreement.
  const handleNext = async () => {
    setIsLoading(true)
    if (fieldsDoValidate()) {
      try {
        const result = await createAgreement({
          roleId,
          agreementTemplateId, // This to be deprecated, the name agreementTemplateId is actually the agreementDocumentId (names got changed)
          agreementDocumentId,
          // FIXME: Some users, like admin, who can select roles cannot create agreements
          supervisorUserId: user?.id,
          signerEmail,
          validFrom: jsDateFromYearMonthDayString(
            standardizedFieldStates[StandardizedFieldNames.EffectiveDate].state
          ),
          validTo: !!standardizedFieldStates[StandardizedFieldNames.EndDate]
            .state
            ? jsDateFromYearMonthDayString(
                standardizedFieldStates[StandardizedFieldNames.EndDate].state
              )
            : undefined,
          recipients: recipientFieldNames.map((fieldId) => {
            if (
              !!role &&
              !!role.roleName &&
              (new RegExp(role.roleName, 'i').test(fieldId) ||
                (new RegExp('Director', 'i').test(role.roleName) &&
                  new RegExp('Director', 'i').test(fieldId)))
            ) {
              return { label: fieldId, email: signerEmail }
            }
            return { label: fieldId, email: recipientStates[fieldId].state }
          }),
          prefilledFormFields: formDataFieldNames.map((fieldId) => {
            if (
              fieldId.match(StandardizedFieldNames.EffectiveDate) ||
              fieldId.match(
                StandardizedFieldNames.EffectiveDate.split(' ').join('')
              )
            ) {
              return {
                fieldName: fieldId,
                fieldValue:
                  standardizedFieldStates[StandardizedFieldNames.EffectiveDate]
                    ?.state,
              }
            }
            if (
              fieldId.match(StandardizedFieldNames.EndDate) ||
              fieldId.match(StandardizedFieldNames.EndDate.split(' ').join(''))
            ) {
              return {
                fieldName: fieldId,
                fieldValue:
                  standardizedFieldStates[StandardizedFieldNames.EndDate]
                    ?.state,
              }
            }
            return {
              fieldName: fieldId,
              fieldValue: formDataStates[fieldId].state,
            }
          }),
        })
        if (!!result) {
          setAgreementId(result.agreementId)
          setShowConfirmationModal(true)
        } else {
          setSnackbarState(true)
          setSnackbarMessage(errorMessage)
          setSnackbarSeverity(SnackbarSeverity.Error)
          setIsLoading(false)
        }
      } catch (e) {
        const errorObject = (await extractedErrorObject(e)) ?? {
          code: 'UnknownError',
          message: (e as unknown as Error).message ?? errorMessage,
        }
        setSnackbarState(true)
        setSnackbarMessage(errorObject.message ?? errorMessage)
        setSnackbarSeverity(SnackbarSeverity.Error)
        setIsLoading(false)
      }
    } else {
      setSnackbarState(true)
      setSnackbarMessage(errorMessage)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setIsLoading(false)
    }
  }

  const [loadRoleAgreementDocuments, setLoadRoleAgreementDocuments] =
    useState(true)
  const [roleAgreementDocuments, setRoleAgreementDocuments] =
    useState<RoleAgreementDocumentSelectOption[]>()

  const roleAgreementMap = useMemo(() => {
    const map = new Map<
      string,
      {
        templateId: string
        agreementDocuments: AgreementDocumentSelectOption[]
      }
    >()
    roleAgreementDocuments?.forEach((roleAgreementDocument) => {
      map.set(roleAgreementDocument.roleName, {
        templateId: roleAgreementDocument.agreementTemplateId ?? '',
        agreementDocuments: roleAgreementDocument.agreementDocuments,
      })
    })
    return map
  }, [roleAgreementDocuments])

  const rolesMap = useMemo<
    Map<string /** role.roleName */, RoleAgreementDocumentSelectOption>
  >(() => {
    const map = new Map<string, RoleAgreementDocumentSelectOption>()
    roleAgreementDocuments?.forEach((roleAgreementDocument) => {
      map.set(roleAgreementDocument.roleName, roleAgreementDocument)
    })
    return map
  }, [roleAgreementDocuments])

  const agreementDocuments = useMemo(() => {
    return roleAgreementMap.get(role?.roleName ?? '')?.agreementDocuments ?? []
  }, [roleAgreementMap, role])

  const [previousAgreementTemplateId, setPreviousAgreementTemplateId] =
    useState('')
  const [agreementTemplateId, setAgreementTemplateId] = useState('')
  const [previousAgreementDocumentId, setPreviousAgreementDocumentId] =
    useState('')
  const [agreementDocumentId, setAgreementDocumentId] = useState('')
  useEffect(() => {
    if (!!agreementTemplateId) {
      setAgreementTemplateId(
        roleAgreementMap.get(role?.roleName ?? '')?.templateId ?? ''
      )
      if (
        previousAgreementTemplateId !== agreementTemplateId ||
        previousAgreementDocumentId !== agreementDocumentId
      ) {
        setAgreementTemplateFormFields(undefined)
        setLoading(true)
      }
    }
  }, [
    agreementTemplateId,
    roleAgreementMap,
    role,
    agreementDocumentId,
    previousAgreementTemplateId,
    previousAgreementDocumentId,
  ])

  const agreementDocumentsIsEmpty = useMemo(() => {
    return !!agreementDocuments && agreementDocuments.length === 0
  }, [agreementDocuments])

  const rolesIsEmpty = useMemo(() => {
    return !!roleAgreementDocuments && roleAgreementDocuments.length === 0
  }, [roleAgreementDocuments])

  const [roleEndDate, setRoleEndDate] = useState(new Date())

  const [updateStandardizedFieldStates, setUpdateStandardizedFieldStates] =
    useState(false)

  const fetchRoleAndAgreementTemplatesErrorMessage = t(
    'Team.TeamMemberForm. fetchRoleAndAgreementTemplatesErrorMessage',
    `Unknown error occurred retrieving agreement options.`
  )

  useEffect(() => {
    const fetchRoleAndAgreementTemplates = async () => {
      try {
        const result = await fetchTeamInviteOptions()
        setRoleAgreementDocuments(result.roleAgreementDocuments)
        setRoleEndDate(result.defaultRoleValidToDate)
        setUpdateStandardizedFieldStates(true)
        setLoadRoleAgreementDocuments(false)
      } catch (err) {
        const errorObject = (await extractedErrorObject(err)) ?? {
          code: 'UnknownError',
          message:
            (err as unknown as Error).message ??
            fetchRoleAndAgreementTemplatesErrorMessage,
        }
        setSnackbarState(true)
        setSnackbarMessage(
          errorObject.message ?? fetchRoleAndAgreementTemplatesErrorMessage
        )
        setSnackbarSeverity(SnackbarSeverity.Error)
      }
    }
    fetchRoleAndAgreementTemplates()
  }, [
    errorMessage,
    fetchRoleAndAgreementTemplatesErrorMessage,
    setSnackbarState,
    setSnackbarMessage,
    setSnackbarSeverity,
  ])

  const [agreementTemplateFormFields, setAgreementTemplateFormFields] =
    useState<AgreementTemplateFormFields | undefined>()

  const fetchAgreementTemplateFieldsErrorMessage = t(
    'Team.AddTeamMemberForm.AgreementTemplateFieldsErrorMessage',
    `Error while loading agreement template form fields.`
  )

  useEffect(() => {
    if (!!agreementTemplateId && !!agreementDocumentId) {
      const fetchAgreementTemplateFields = async () => {
        try {
          const result = await fetchAgreementTemplateFormFields(
            agreementTemplateId,
            agreementDocumentId
          )

          const formRecipients = result.formRecipients
          const formData = result.formData

          const formDataWithoutCommunity =
            formData?.filter((data) => {
              const dataFieldName = !!data.displayName
                ? data.displayName
                : data.inputName
              return dataFieldName !== 'Community'
            }) ?? []

          setAgreementTemplateFormFields({
            formRecipients: formRecipients,
            formData: formDataWithoutCommunity,
          })
          setLoading(false)
        } catch (err) {
          const errorObject = (await extractedErrorObject(err)) ?? {
            code: 'UnknownError',
            message:
              (err as unknown as Error).message ?? 'Failed to fetch regions.',
          }
          setSnackbarState(true)
          setSnackbarMessage(
            errorObject.message ?? fetchAgreementTemplateFieldsErrorMessage
          )
          setSnackbarSeverity(SnackbarSeverity.Error)
        }
      }
      fetchAgreementTemplateFields()
    }
  }, [
    agreementTemplateId,
    agreementDocumentId,
    setSnackbarState,
    setSnackbarMessage,
    setSnackbarSeverity,
    fetchAgreementTemplateFieldsErrorMessage,
  ])

  const [loading, setLoading] = useState(false)

  const formRecipients = useMemo(() => {
    return (
      agreementTemplateFormFields &&
      agreementTemplateFormFields.formRecipients?.filter((it) => {
        let filterBy = role?.roleName ?? ''
        if (!!role && new RegExp('Director', 'i').test(role.roleName)) {
          filterBy = 'Director'
        }
        return !new RegExp(filterBy, 'i').test(it.label)
      })
    )
  }, [agreementTemplateFormFields, role])

  const formRecipientStates = useMemo(() => {
    return formRecipients && formRecipients
      ? formRecipients.map((recipient) => {
          return {
            label: `${recipient.label}`,
            state: {
              state: recipient.email,
              isValid: {
                input: true,
                maxLength: true,
                minLength: true,
                email: true,
              },
              required: true,
            },
          }
        })
      : undefined
  }, [formRecipients])

  /**
   * Checks each agreement template form field to verify its displayName and
   * inputName do not match the standardized fields names to prevent duplicate
   * fields from displaying
   */
  const isStandardizedField = (field: AgreementTemplateFormField) => {
    const comparisonFields = Object.keys(StandardizedFieldNames)
    const spaceRemovedDisplayName = field.displayName.split(' ').join('')
    const shouldNotInclude = [
      field.displayName,
      spaceRemovedDisplayName,
      field.inputName,
    ]

    // Verify the field matches all or part of the condition.
    const isPartialOrFullMatch = comparisonFields.some((field) =>
      shouldNotInclude.some((condition) =>
        new RegExp(field, 'i').test(condition)
      )
    )

    return isPartialOrFullMatch
  }

  const formData = useMemo(() => {
    return (
      agreementTemplateFormFields &&
      agreementTemplateFormFields.formData?.filter(
        (it) => !isStandardizedField(it)
      )
    )
  }, [agreementTemplateFormFields])

  const standardizedFieldsOfFormFields = useMemo(() => {
    return agreementTemplateFormFields?.formData?.filter((it) =>
      isStandardizedField(it)
    )
  }, [agreementTemplateFormFields])

  const dataStates = useMemo(() => {
    return formData && formData
      ? formData.map((field) => {
          // Determine starting value of field by type
          let state = ''
          switch (field.inputType) {
            case AgreementTemplateFormFieldInputType.Date:
              state = dateToDashString(new Date())
              break
            case AgreementTemplateFormFieldInputType.Dropdown:
              state = !!field.dropdownSelectOptions?.[0]
                ? field.dropdownSelectOptions[0]
                : ''
              break
            case AgreementTemplateFormFieldInputType.Text:
              state = field.defaultValue
          }

          return {
            label: !!field.displayName ? field.displayName : field.inputName,
            state: {
              state,
              isValid: {
                input: true,
                maxLength: true,
                minLength: true,
                email: true,
                beforeMax: true,
                afterMin: true,
              },
              required: field.required,
              inputType: field.inputType,
              maxLength:
                field.maxTextLength !== -1 ? field.maxTextLength : undefined,
              minLength:
                field.minTextLength !== -1 ? field.minTextLength : undefined,
            },
          }
        })
      : undefined
  }, [formData])

  const [isInitialRecipientLoad, setIsInitialRecipientLoad] = useState(true)
  const { fieldStates: recipientStates, setFieldStates: setRecipientStates } =
    useDynamicFieldStates({
      fields: formRecipientStates,
      isInitialLoad: isInitialRecipientLoad,
      setIsInitialLoad: setIsInitialRecipientLoad,
    })

  const [isInitialFormDataLoad, setIsInitialFormDataLoad] = useState(true)
  const { fieldStates: formDataStates, setFieldStates: setFormDataStates } =
    useDynamicFieldStates({
      fields: dataStates,
      isInitialLoad: isInitialFormDataLoad,
      setIsInitialLoad: setIsInitialFormDataLoad,
    })

  const matchRequiredToStandardField = (
    field: AgreementTemplateFormField,
    compareTo: string
  ): boolean => {
    // We want to compare our scenarios against the StandardizedFieldNames name, say EffectiveDate = 'Effective Date'
    // But just in case we want the StandardizedFieldNames to be without spaces
    const comparisonFields = [compareTo.split(' ').join('')]

    const spaceRemovedDisplayName = field.displayName.split(' ').join('')
    const shouldInclude = [
      // We also want to check if the display name matches the field already
      // For the given example this could be EffectiveDate
      field.displayName,
      // Or if the display name with the spaces removed matches
      // For the given example this could be Effective Date => EffectiveDate
      spaceRemovedDisplayName,
      // Or if the input name matches
      // For the given example this could be EffectiveDate
      field.inputName,
    ]

    const isPartialOrFullMatch = comparisonFields.some((field) =>
      shouldInclude.some((condition) => new RegExp(field, 'i').test(condition))
    )
    // Only update if we match the field
    if (isPartialOrFullMatch) {
      return field.required
    }
    return false
  }

  const [isInitialStandardizedFieldLoad, setIsInitialStandardizedFieldLoad] =
    useState(true)
  const {
    fieldStates: standardizedFieldStates,
    setFieldStates: setStandardizedFieldStates,
  } = useDynamicFieldStates({
    fields: Object.values(StandardizedFieldNames).map((it) => {
      let isRequired = false
      standardizedFieldsOfFormFields?.forEach((field) => {
        const fieldRequired = matchRequiredToStandardField(field, it)
        if (fieldRequired) {
          isRequired = fieldRequired
        }
      })
      return {
        label: it,
        state: {
          state: '',
          isValid: {
            input: true,
            dateRange: true,
            email: true,
            isRequiredInSomeRolesIfValueIsEmpty: true,
            beforeMax: true,
            afterMin: true,
          },
          required:
            it === StandardizedFieldNames.EffectiveDate
              ? true
              : it === StandardizedFieldNames.EndDate
              ? false
              : isRequired, // End date is not a required field for validation, nor is c
          inputType: AgreementTemplateFormFieldInputType.Date,
        },
      }
    }),
    isInitialLoad: isInitialStandardizedFieldLoad,
    setIsInitialLoad: setIsInitialStandardizedFieldLoad,
  })

  const updateRecipientValueForParent = (
    name: string,
    state: string,
    required: boolean,
    isOverMaxLength?: boolean,
    isUnderMinLength?: boolean
  ) => {
    state = removeSpacesStartAndEndFromEmailField(name, state)

    setRecipientStates({
      ...recipientStates,
      [name]: {
        ...recipientStates[name],
        state,
        isValid: {
          ...recipientStates[name]?.isValid,
          input: true,
          maxLength: !isOverMaxLength,
          minLength: !isUnderMinLength,
          email: true,
        },
        validationMessage: '',
        required: required,
      },
    })
  }

  const updateFormDataValueForParent = (
    name: string,
    state: string,
    required: boolean,
    isOverMaxLength?: boolean,
    isUnderMinLength?: boolean
  ) => {
    state = removeSpacesStartAndEndFromEmailField(name, state)

    setFormDataStates({
      ...formDataStates,
      [name]: {
        ...formDataStates[name],
        state,
        isValid: {
          ...formDataStates[name]?.isValid,
          input: true,
          maxLength: !isOverMaxLength,
          minLength: !isUnderMinLength,
          email: true,
        },
        required: required,
        validationMessage: '',
      },
    })
  }

  const updateStandardizedFieldStatesForParent = (
    fieldId: string,
    state: string,
    required: boolean
  ) => {
    setStandardizedFieldStates({
      ...standardizedFieldStates,
      [fieldId]: {
        ...standardizedFieldStates[fieldId],
        state,
        isValid: {
          ...standardizedFieldStates[fieldId].isValid,
          input: true,
        },
        required: required,
      },
    })
  }

  const handleConfirmationCancel = async () => {
    // Cancel the agreement
    await deleteAgreement(agreementId)
    // Then close the confirmation dialog
    setIsLoading(false)
    setShowConfirmationModal(false)
  }

  const validationEmailMessage = (error: string) => {
    return validationMessages(error)
  }

  const [agreementId, setAgreementId] = useState('')
  const [agreementPreview, setAgreementPreview] = useState('')

  useEffect(() => {
    if (!!agreementId && !bypassAgreement) {
      const getAgreementPreview = async () => {
        const fetchedAgreementPreview = await fetchAgreementPreview(agreementId)
        setAgreementPreview(fetchedAgreementPreview.pdfPreview ?? '#')
      }
      getAgreementPreview()
    }
  }, [agreementId, bypassAgreement])

  const saveTeamMemberCard = async () => {
    try {
      await inviteTeamMember(agreementId)
      navigate(
        {
          pathname: '/team',
        },
        {
          state: {
            snackbarState: true,
            snackbarMessage: bypassAgreement
              ? t(
                  'Team.TeamView.SuccessMessage.InvitedTeamMember.Bypass',
                  'Team member added. An invitation will be emailed to that team member.'
                )
              : t(
                  'Team.TeamView.SuccessMessage.InvitedTeamMember.Default',
                  'Team member added. An Adobe Sign contract will be emailed to that team member.'
                ),
            snackbarSeverity: SnackbarSeverity.Success,
          },
        }
      )
    } catch (e) {
      setSnackbarState(true)
      setSnackbarMessage((e as unknown as Error).message ?? errorMessage)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  }

  useLoadingContext({
    asyncFunction: saveTeamMemberCard,
    loadingId: TeamMemberCard.inviteTeamMember,
  })

  const agreementButtons = (
    <ActionButtons
      primaryButtonLabel={ContainedButtonVariant.YesSend}
      disablePrimaryButton={loadingIds.has(TeamMemberCard.inviteTeamMember)}
      useBaseButton
      secondaryButtonLabel={TextButtonVariant.NoCancel}
      secondaryClick={handleConfirmationCancel}
    />
  )

  const headerMessage = t(
    'TeamMemberCard.ConfirmationModal.Header.AboutToSendTo.Default',
    'You are about to send a new agreement to {{signerEmail}} and invite them to your team.',
    { signerEmail }
  )

  const agreementBypassHeaderMessage = t(
    'TeamMemberCard.ConfirmationModal.Header.AboutToSendTo.Bypass',
    'You are about to invite {{signerEmail}} to your team.',
    { signerEmail }
  )

  const confirmationBodyMessage = t(
    'TeamMemberCard.ConfirmationModal.Body.Default',
    'Please confirm that this is the action you intend to complete. It is not reversible once the agreement is sent.'
  )

  const agreementBypassConfirmationBodyMessage = t(
    'TeamMemberCard.ConfirmationModal.Body.Bypass',
    'Please confirm that this is the action you intend to complete. It is not reversible once the invitation is sent.'
  )

  const confirmationBody = (
    <Typography variant="body1" component="p" align="center">
      {bypassAgreement
        ? agreementBypassConfirmationBodyMessage
        : confirmationBodyMessage}
      &nbsp;
      {!!agreementPreview && !bypassAgreement && (
        <PublicUrlLink
          target="_blank"
          rel="noopener noreferrer"
          href={agreementPreview}
        >
          {t(
            'TeamMemberCard.ConfirmationModal.PreviewAgreement',
            'Preview the Agreement.'
          )}
        </PublicUrlLink>
      )}
    </Typography>
  )

  const handleAgreementConfirmationSubmit = async (
    event: React.FormEvent<HTMLDivElement>
  ) => {
    event.preventDefault()
    addLoadingIds([TeamMemberCard.inviteTeamMember])
  }

  const agreementConfirmationProps = {
    isOpen: showConfirmationModal,
    dialogTitle: bypassAgreement ? agreementBypassHeaderMessage : headerMessage,
    dialogContent: confirmationBody,
    dialogActions: agreementButtons,
    handleFormSubmit: handleAgreementConfirmationSubmit,
    ...props,
  }

  const [formDataFieldNames, setFormDataFieldNames] = useState<string[]>([])
  const [recipientFieldNames, setRecipientFieldNames] = useState<string[]>([])

  useEffect(() => {
    if (
      (roleChanged || agreementTemplateChanged) &&
      !!agreementTemplateFormFields
    ) {
      const fieldsName =
        agreementTemplateFormFields.formData?.filter((data) => {
          const dataFieldName = !!data.displayName
            ? data.displayName
            : data.inputName
          return dataFieldName !== 'Community'
        }) ?? []

      const fieldsNameWithoutCommunity = fieldsName.map((fieldName) =>
        !!fieldName.displayName ? fieldName.displayName : fieldName.inputName
      )

      setFormDataFieldNames(fieldsNameWithoutCommunity)
      setRecipientFieldNames(
        agreementTemplateFormFields.formRecipients?.map(
          (recipient) => `${recipient.label}`
        ) ?? []
      )
      setUpdateStandardizedFieldStates(true)
      setRoleChanged(false)
      setAgreementTemplateChanged(false)
    }
  }, [agreementTemplateFormFields, roleChanged, agreementTemplateChanged])

  useEffect(() => {
    if (updateStandardizedFieldStates) {
      const today = new Date()
      let stateObject = standardizedFieldStates
      // Set the required values first
      Object.values(StandardizedFieldNames).forEach((it) => {
        let isRequired = false
        standardizedFieldsOfFormFields?.forEach((field) => {
          const fieldRequired = matchRequiredToStandardField(field, it)
          if (fieldRequired) {
            isRequired = fieldRequired
          }
        })
        stateObject = {
          ...stateObject,
          [it]: {
            ...stateObject[it],
            required: isRequired || it === StandardizedFieldNames.EffectiveDate, // End date is not a required field for validation, nor is community
          },
        }
      })

      const {
        [StandardizedFieldNames.EffectiveDate]: effectiveDate,
        [StandardizedFieldNames.EndDate]: endDate,
      } = standardizedFieldStates

      const {
        [StandardizedFieldNames.EffectiveDate]: stateObjectEffectiveDate,
        [StandardizedFieldNames.EndDate]: stateObjectEndDate,
      } = stateObject

      const newStandardizedFieldStates = {
        ...standardizedFieldStates,

        [StandardizedFieldNames.EffectiveDate]: {
          ...effectiveDate,
          required: stateObjectEffectiveDate.required,
          state: dateToDashString(today),
        },
        [StandardizedFieldNames.EndDate]: {
          ...endDate,
          required: stateObjectEndDate.required,
          state:
            requiredEndDate || stateObjectEndDate.required
              ? dateToDashString(
                  reinterpretYearMonthDayAsLocalTime(roleEndDate)
                )
              : '',
        },
      }

      // Then set all the states to default values where applicable
      setStandardizedFieldStates(newStandardizedFieldStates)
      setUpdateStandardizedFieldStates(false)
    }
  }, [
    updateStandardizedFieldStates,
    setStandardizedFieldStates,
    standardizedFieldStates,
    requiredEndDate,
    roleEndDate,
    standardizedFieldsOfFormFields,
  ])

  if (loadRoleAgreementDocuments) {
    return <LoadingProgress />
  }

  return (
    <>
      {((!showOnDesktop && !showConfirmationModal) || showOnDesktop) && (
        <Card
          sx={{
            maxWidth: 1200,
            padding: theme.spacing(3, 4),
            color: theme.palette.primary.main,
            [theme.breakpoints.down('sm')]: {
              padding: theme.spacing(3, 4),
            },
          }}
        >
          <CardFormHeader
            header={
              <Header
                id="teamMemberFormHeader"
                headerName={title}
                component="h2"
                variant={HeaderVariant.Card}
              />
            }
            buttons={
              showOnDesktop ? (
                <TeamMemberButtons
                  handleNext={handleNext}
                  isNextDisabled={!role || isLoading}
                />
              ) : null
            }
          />
          <Form
            noValidate
            autoComplete="off"
            aria-labelledby="teamMemberFormHeader"
          >
            <SectionOne aria-labelledby="teamMemberFormHeader">
              <TextField
                id="roleSelect"
                label={t('Team.TeamMemberForm.FormField.Role', 'Role')}
                variant="filled"
                onChange={handleRoleChange}
                error={!isRoleValid}
                sx={{
                  gridColumn: '1',
                  gridRow: '1',
                }}
                value={role?.roleName ?? ''}
                select={!rolesIsEmpty}
                disabled={rolesIsEmpty}
                helperText={
                  !isAgreementDocumentValid
                    ? validationMessageMap.get(
                        TeamMemberValidationMessageTypes.Role
                      )
                    : rolesIsEmpty
                    ? validationMessageMap.get(
                        TeamMemberValidationMessageTypes.NoRoles
                      )
                    : null
                }
              >
                {!rolesIsEmpty &&
                  roleAgreementDocuments?.map((role) => (
                    <MenuItem key={role.roleKey} value={role.roleName}>
                      {role.roleName}
                    </MenuItem>
                  ))}
              </TextField>
              {!bypassAgreement && (
                <TextField
                  id="agreementTemplateSelect"
                  label={t(
                    'Team.TeamMemberForm.FormField.AgreementTemplate',
                    'Agreement Template'
                  )}
                  variant="filled"
                  onChange={handleAgreementTemplateChange}
                  error={!isAgreementDocumentValid}
                  sx={{
                    gridColumn: '2',
                    gridRow: '1',
                  }}
                  value={
                    !!agreementTemplate && !!agreementDocumentId
                      ? `${agreementTemplate}|-|${agreementDocumentId}`
                      : ''
                  }
                  select={!agreementDocumentsIsEmpty}
                  disabled={!role}
                  helperText={
                    !isAgreementDocumentValid
                      ? validationMessageMap.get(
                          TeamMemberValidationMessageTypes.AgreementTemplate
                        )
                      : null
                  }
                >
                  {!agreementDocumentsIsEmpty ? (
                    agreementDocuments.map((document) => (
                      <MenuItem
                        key={document.agreementDocumentId}
                        value={`${document.agreementDocumentName}|-|${document.agreementDocumentId}`}
                      >
                        {document.agreementDocumentName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>
                      {validationMessageMap.get(
                        TeamMemberValidationMessageTypes.NoResultsForRole
                      )}
                    </MenuItem>
                  )}
                </TextField>
              )}
            </SectionOne>
            <FormDivider />
            <CardFormHeader
              header={
                <Header
                  id="recipientEmails"
                  headerName={t(
                    'Team.TeamMemberForm.Headers.RecipientEmails',
                    'Recipient Emails'
                  )}
                  component="h3"
                  variant={HeaderVariant.Subheader}
                />
              }
            />
            <SectionOne aria-labelledby="recipientEmails">
              {Object.values(recipientStates).length > 0 || bypassAgreement ? (
                <>
                  <TextField
                    id="signerEmailField"
                    label={
                      !bypassAgreement
                        ? t(
                            'Team.TeamMemberCard.FormField.SignerEmail',
                            'Signer Email'
                          )
                        : t(
                            'Team.TeamMemberCard.FormField.SelectedRole',
                            'Selected Role'
                          )
                    }
                    variant="filled"
                    value={signerEmail}
                    onChange={handleSignerEmailChange}
                    error={isSignerEmailValid}
                    helperText={
                      isSignerEmailValid &&
                      validationEmailMessage(textErrorEmail)
                    }
                  />
                  {!bypassAgreement && (
                    <FormRecipients
                      formRecipients={formRecipients}
                      recipientStates={recipientStates}
                      updateRecipientValueForParent={
                        updateRecipientValueForParent
                      }
                    />
                  )}
                </>
              ) : null}
            </SectionOne>
            <FormDivider />
            <CardFormHeader
              header={
                <Header
                  id="agreementFields"
                  headerName={t(
                    'Team.TeamMemberForm.Headers.AgreementFields',
                    'Agreement Fields'
                  )}
                  component="h4"
                  variant={HeaderVariant.Subheader}
                />
              }
            />
            <SectionOne aria-labelledby="agreementFields">
              {(Object.values(standardizedFieldStates).length > 0 &&
                Object.values(formDataStates).length > 0) ||
              bypassAgreement ? (
                <>
                  <StandardizedFields
                    updateStandardizedFieldStatesForParent={
                      updateStandardizedFieldStatesForParent
                    }
                    standardizedFieldStates={standardizedFieldStates}
                  />
                  {!bypassAgreement && (
                    <FormData
                      formData={formData}
                      formDataStates={formDataStates}
                      updateFormDataValueForParent={
                        updateFormDataValueForParent
                      }
                    />
                  )}
                </>
              ) : null}
              {loading && <LoadingProgress />}
            </SectionOne>
          </Form>
        </Card>
      )}
      {!showOnDesktop && !showConfirmationModal ? (
        <TeamMemberButtons
          handleNext={handleNext}
          isNextDisabled={!role || isLoading}
        />
      ) : null}
      <ConfirmationModal {...agreementConfirmationProps} />
    </>
  )
}

export default TeamMemberCard
