/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  CashAmount,
  CashAmountFromJSON,
  CashAmountToJSON,
  PaymentInfo,
  PaymentInfoFromJSON,
  PaymentInfoToJSON,
  StudentToProgramType,
  StudentToProgramTypeFromJSON,
  StudentToProgramTypeToJSON,
} from './'

/**
 * Object that encapsulates the information needed for each invoice.
 * @export
 * @interface InvoiceInfo
 */
export interface InvoiceInfo {
  /**
   * The unique identifier of the invoice sent by the director or tutor.
   * @type {number}
   * @memberof InvoiceInfo
   */
  invoiceKey: number
  /**
   * The date the invoice was sent.
   * @type {Date}
   * @memberof InvoiceInfo
   */
  dateSent?: Date
  /**
   *
   * @type {CashAmount}
   * @memberof InvoiceInfo
   */
  amountOwed: CashAmount
  /**
   * List of payments made towards the invoice.
   * @type {Array<PaymentInfo>}
   * @memberof InvoiceInfo
   */
  payments?: Array<PaymentInfo>
  /**
   * DEPRECATED: This will be removed as it is not in use. List of studentName-programType pair objects associated to the sent invoice.
   * @type {Array<StudentToProgramType>}
   * @memberof InvoiceInfo
   */
  studentPrograms?: Array<StudentToProgramType>
  /**
   * The lastSentEmailDate represents the date when the most recent invoice email was sent.
   * @type {Date}
   * @memberof InvoiceInfo
   */
  lastSentEmailDate?: Date
  /**
   * The last modification date of the invoice line.
   * @type {Date}
   * @memberof InvoiceInfo
   */
  lastInvoiceLineModifiedDate?: Date
}

export function InvoiceInfoFromJSON(json: any): InvoiceInfo {
  return InvoiceInfoFromJSONTyped(json, false)
}

export function InvoiceInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InvoiceInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    invoiceKey: json['invoiceKey'],
    dateSent: !exists(json, 'dateSent')
      ? undefined
      : new Date(json['dateSent']),
    amountOwed: CashAmountFromJSON(json['amountOwed']),
    payments: !exists(json, 'payments')
      ? undefined
      : (json['payments'] as Array<any>).map(PaymentInfoFromJSON),
    studentPrograms: !exists(json, 'studentPrograms')
      ? undefined
      : (json['studentPrograms'] as Array<any>).map(
          StudentToProgramTypeFromJSON
        ),
    lastSentEmailDate: !exists(json, 'lastSentEmailDate')
      ? undefined
      : new Date(json['lastSentEmailDate']),
    lastInvoiceLineModifiedDate: !exists(json, 'lastInvoiceLineModifiedDate')
      ? undefined
      : new Date(json['lastInvoiceLineModifiedDate']),
  }
}

export function InvoiceInfoToJSON(value?: InvoiceInfo | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    invoiceKey: value.invoiceKey,
    dateSent:
      value.dateSent === undefined ? undefined : value.dateSent.toISOString(),
    amountOwed: CashAmountToJSON(value.amountOwed),
    payments:
      value.payments === undefined
        ? undefined
        : (value.payments as Array<any>).map(PaymentInfoToJSON),
    studentPrograms:
      value.studentPrograms === undefined
        ? undefined
        : (value.studentPrograms as Array<any>).map(StudentToProgramTypeToJSON),
    lastSentEmailDate:
      value.lastSentEmailDate === undefined
        ? undefined
        : value.lastSentEmailDate.toISOString(),
    lastInvoiceLineModifiedDate:
      value.lastInvoiceLineModifiedDate === undefined
        ? undefined
        : value.lastInvoiceLineModifiedDate.toISOString(),
  }
}
