/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  RegionCommunityOptions,
  RegionCommunityOptionsFromJSON,
  RegionCommunityOptionsToJSON,
} from './'

/**
 *
 * @export
 * @interface CommunityOptions
 */
export interface CommunityOptions {
  /**
   * A list of regions that contains the id and name for the region. In addition, the community managers and their supervisors along with each one of their user info.
   * @type {Array<RegionCommunityOptions>}
   * @memberof CommunityOptions
   */
  regions: Array<RegionCommunityOptions>
  /**
   * A list of available contractual models for a tutor.
   * @type {Array<string>}
   * @memberof CommunityOptions
   */
  tutorModels: Array<string>
}

export function CommunityOptionsFromJSON(json: any): CommunityOptions {
  return CommunityOptionsFromJSONTyped(json, false)
}

export function CommunityOptionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommunityOptions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    regions: (json['regions'] as Array<any>).map(
      RegionCommunityOptionsFromJSON
    ),
    tutorModels: json['tutorModels'],
  }
}

export function CommunityOptionsToJSON(value?: CommunityOptions | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    regions: (value.regions as Array<any>).map(RegionCommunityOptionsToJSON),
    tutorModels: value.tutorModels,
  }
}
