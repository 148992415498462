import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import WarningIcon from '@mui/icons-material/Warning'
import { Address } from '../../swagger'
import CardFormHeader from '../Card/CardFormHeader'
import Header, { HeaderVariant } from '../Elements/Header'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../Buttons/OutlinedButton'
import AddressModal, { AddressModalProps } from '../Address/AddressModal'
import { styled } from '@mui/system'

interface AddressCardProps extends Pick<AddressModalProps, 'onAddressConfirm'> {
  isEditMode: boolean
  address: Address
}

const AddressCardPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(4, 4, 5.5),
}))

const AddressCard: React.FC<AddressCardProps> = ({
  address,
  isEditMode,
  onAddressConfirm,
}) => {
  const { t } = useTranslation()

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false)

  /** Check if all fields (except locationName) are empty  */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { locationName, ...remainingAddressFields } = address
  const indexableAddress = remainingAddressFields as unknown as {
    [key: string]: string
  }
  const noLocationInfo = Object.keys(remainingAddressFields).every(
    (it) => !indexableAddress[it]
  )

  const { streetAddress1, streetAddress2, city, state, zip, countryCode } =
    address

  return (
    <>
      <AddressModal
        disableGeocoding
        isOpen={isAddressModalOpen}
        onClose={() => setIsAddressModalOpen(false)}
        initialAddress={address}
        onAddressConfirm={onAddressConfirm}
      />
      <AddressCardPaper>
        <CardFormHeader
          header={
            <Header
              id="communityLocationHeader"
              headerName={t('Settings.Profile.Location.Header', 'Location')}
              component="h2"
              variant={HeaderVariant.Card}
            />
          }
          buttons={
            isEditMode && (
              <OutlinedButton
                id="editLocation"
                variant={OutlinedButtonVariant.EditLocation}
                onClick={() => setIsAddressModalOpen(true)}
              />
            )
          }
        />
        <Box
          mb={1.5}
          display="flex"
          alignItems="center"
          color="textOrIcon.tableHeader"
        >
          <Box mr={0.75}>
            <WarningIcon />
          </Box>
          <Typography variant="body2">
            {t(
              'Settings.Profile.Location.Subtitle',
              'Your location information is NEVER shared and visible only to you and your CC leadership.'
            )}
          </Typography>
        </Box>
        {noLocationInfo ? (
          <Box pt={2} color="textOrIcon.tableHeader">
            <Typography variant="subtitle1">
              {t(
                'Settings.Profile.Location.Empty',
                'No location has been entered'
              )}
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="column">
                <TextField
                  id="streetAddress1"
                  name="streetAddress1"
                  variant="filled"
                  label={t(
                    'Address.FormFields.StreetAddress1',
                    'Street Address'
                  )}
                  disabled
                  value={streetAddress1}
                />
                <TextField
                  id="streetAddress2"
                  name="streetAddress2"
                  variant="filled"
                  label={t(
                    'Address.FormFields.StreetAddress2',
                    'Building, Suite, etc.'
                  )}
                  disabled
                  value={streetAddress2}
                />
                <TextField
                  id="city"
                  name="city"
                  variant="filled"
                  label={t('Address.FormFields.City', 'City')}
                  disabled
                  value={city}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="column">
                <TextField
                  id="countryCode"
                  name="countryCode"
                  variant="filled"
                  label={t('Address.FormFields.Country', 'Country')}
                  disabled
                  value={countryCode}
                />
                <TextField
                  id="state"
                  name="state"
                  variant="filled"
                  label={t('Address.FormFields.State', 'State/Province')}
                  disabled
                  value={state}
                />
                <TextField
                  id="zip"
                  name="zip"
                  variant="filled"
                  label={t('Address.FormFields.ZipCode', 'Postal Code')}
                  disabled
                  value={zip}
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </AddressCardPaper>
    </>
  )
}

export default AddressCard
