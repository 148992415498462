import React, { useEffect, useState } from 'react'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { ElevatedCardVariants } from './ElevatedCard'
import { useAccountContext } from '../Context/AccountContext'
import {
  EnrollmentDashboard,
  EnrollmentInviteDashboard,
  ProgramDashboard,
} from '../../swagger'
import CardFormHeader from './CardFormHeader'
import Header, { HeaderVariant } from '../Elements/Header'
import { useMountEffect } from '../../hooks/useMountEffect'
import ProgramRenderer from '../Programs/ProgramRenderer'
import useSortedByTypeDashboardPrograms from '../../hooks/useSortedDashboardPrograms'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { LoadingContext } from '../Context/LoadingContext'

export enum DirectorDashboardDetailsVariants {
  outstandingEnrollments = 'outstandingEnrollments',
  outstandingInvitations = 'outstandingInvitations',
  programs = 'programs',
  billing = 'Billing',
}

export type ProgramData = ProgramDashboard &
  Omit<EnrollmentDashboard, 'textColor'> &
  EnrollmentInviteDashboard

const DirectorDashboardDetails: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { directorDashboard } = useAccountContext()

  const { DashboardTab } = useLoadingIds()

  const { addLoadingIds } = React.useContext(LoadingContext)

  useLoadingContext({
    asyncFunction: directorDashboard.fetchDirectorDashboardInformation,
    loadingId: DashboardTab.fetchDashboard,
  })

  useMountEffect(() => {
    addLoadingIds([DashboardTab.fetchDashboard])
  })

  const { myPrograms, outstandingEnrollments, outstandingInvitations } =
    directorDashboard
  const location: { state: { variant: DirectorDashboardDetailsVariants } } =
    useLocation()

  const { variant } = location.state

  const isProgram = variant === DirectorDashboardDetailsVariants.programs
  const isEnrollments =
    variant === DirectorDashboardDetailsVariants.outstandingEnrollments
  const isInvitations =
    variant === DirectorDashboardDetailsVariants.outstandingInvitations

  const [programData, setProgramData] = useState<ProgramData[] | undefined>([])
  const sortedPrograms = useSortedByTypeDashboardPrograms(
    myPrograms as ProgramData[]
  )
  const sortedEnrollments = useSortedByTypeDashboardPrograms(
    outstandingEnrollments ?? []
  )
  const sortedInvitations = useSortedByTypeDashboardPrograms(
    outstandingInvitations ?? []
  )

  useEffect(() => {
    if (isProgram) setProgramData(sortedPrograms)
    if (isEnrollments)
      setProgramData(sortedEnrollments as EnrollmentDashboard[])
    if (isInvitations)
      setProgramData(sortedInvitations as EnrollmentInviteDashboard[])
  }, [
    isProgram,
    isEnrollments,
    isInvitations,
    sortedPrograms,
    sortedInvitations,
    sortedEnrollments,
  ])

  const header = () => {
    switch (variant) {
      case DirectorDashboardDetailsVariants.outstandingEnrollments:
        return t(
          'Account.Dashboard.OutstandingEnrollments.Header',
          'Outstanding Enrollments'
        )
      case DirectorDashboardDetailsVariants.outstandingInvitations:
        return t(
          'Account.Dashboard.OutstandingInvitations.Header',
          'Outstanding Invitations'
        )
      case DirectorDashboardDetailsVariants.programs:
        return t('Account.Dashboard.Programs.Header', 'Programs')
    }
  }

  return (
    <>
      <DynamicBreadcrumbs
        breadcrumbs={[
          {
            label: t(
              'Account.Dashboard.Breadcrumb.MyDashboard',
              'My Dashboard'
            ),
            onClick: () => navigate(-1),
          },
          {
            ...(isProgram && {
              label: t('Account.Dashboard.Breadcrumb.Programs', 'Programs'),
            }),
            ...(isEnrollments && {
              label: t(
                'Account.Dashboard.Breadcrumb.OutstandingEnrollments',
                'Outstanding Enrollments'
              ),
            }),
            ...(isInvitations && {
              label: t(
                'Account.Dashboard.Breadcrumb.OutstandingInvitations',
                'Outstanding Invitations'
              ),
            }),
          },
        ]}
      />
      <CardFormHeader
        header={
          <Header
            headerName={header()}
            component="h1"
            variant={HeaderVariant.Card}
          />
        }
      />

      <ProgramRenderer
        type={ElevatedCardVariants.DirectorDashboard}
        programs={programData ?? []}
        isEnrollment={isEnrollments}
        isInvitation={isInvitations}
      />
    </>
  )
}

export default DirectorDashboardDetails
