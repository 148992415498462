/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The various program types findable in the public API, which as of 3 Jun 2021, is every program type except Scribblers.
 * @export
 * @enum {string}
 */
export enum PublicProgramType {
  Foundations = 'Foundations',
  Essentials = 'Essentials',
  ChallengeA = 'Challenge A',
  ChallengeB = 'Challenge B',
  ChallengeI = 'Challenge I',
  ChallengeIi = 'Challenge II',
  ChallengeIii = 'Challenge III',
  ChallengeIv = 'Challenge IV',
}

export function PublicProgramTypeFromJSON(json: any): PublicProgramType {
  return PublicProgramTypeFromJSONTyped(json, false)
}

export function PublicProgramTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PublicProgramType {
  return json as PublicProgramType
}

export function PublicProgramTypeToJSON(value?: PublicProgramType | null): any {
  return value as any
}
