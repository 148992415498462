import { useEffect } from 'react'
import { LoadingContext } from '../components/Context/LoadingContext'
import React from 'react'

const inProgressSuffix = 'InProgress'

const useLoadingContext = (opts: {
  /** Async method needing to await processing. */
  asyncFunction: () => Promise<void>
  /** Unique Id of the operationId + component name being awaited. */
  loadingId: string
  /** Optional callback method to perform any actions after the async function is complete */
  callback?: () => void
}): void => {
  const { asyncFunction, callback, loadingId } = opts
  const { loadingIds, addLoadingIds, removeLoadingIds } =
    React.useContext(LoadingContext)

  /** Id to verify we are not currently processing the requested loadingId. If we are, don't recall the asyncFunction */
  const inProgressId = `${loadingId}${inProgressSuffix}`
  useEffect(() => {
    // Verify the loading Ids have the current loading Id and we aren't already processing the request
    if (loadingIds.has(loadingId) && !loadingIds.has(inProgressId)) {
      const clickTheButton = async () => {
        addLoadingIds([inProgressId])
        try {
          await asyncFunction()
        } catch (err) {
          // Error handling for the requests should not be handled here.
          // Remove anything awaiting our load since we failed to load
          removeLoadingIds([loadingId, inProgressId])
        }
        // Order of removal should be important. We want to show we're done loading and then we're finished processing.
        removeLoadingIds([loadingId, inProgressId])
        callback?.()
      }
      clickTheButton()
    }
  }, [
    addLoadingIds,
    asyncFunction,
    callback,
    loadingIds,
    removeLoadingIds,
    inProgressId,
    loadingId,
  ])
}

export default useLoadingContext
