/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  ContentAsset,
  ContentAssetFromJSON,
  ContentAssetToJSON,
  PaginationResponse,
  PaginationResponseFromJSON,
  PaginationResponseToJSON,
} from './'

/**
 *
 * @export
 * @interface FetchLearningCenterContentResponse
 */
export interface FetchLearningCenterContentResponse {
  /**
   *
   * @type {Array<ContentAsset>}
   * @memberof FetchLearningCenterContentResponse
   */
  assets: Array<ContentAsset>
  /**
   *
   * @type {PaginationResponse}
   * @memberof FetchLearningCenterContentResponse
   */
  pagination?: PaginationResponse
}

export function FetchLearningCenterContentResponseFromJSON(
  json: any
): FetchLearningCenterContentResponse {
  return FetchLearningCenterContentResponseFromJSONTyped(json, false)
}

export function FetchLearningCenterContentResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FetchLearningCenterContentResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    assets: (json['assets'] as Array<any>).map(ContentAssetFromJSON),
    pagination: !exists(json, 'pagination')
      ? undefined
      : PaginationResponseFromJSON(json['pagination']),
  }
}

export function FetchLearningCenterContentResponseToJSON(
  value?: FetchLearningCenterContentResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    assets: (value.assets as Array<any>).map(ContentAssetToJSON),
    pagination: PaginationResponseToJSON(value.pagination),
  }
}
