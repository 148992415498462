import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { useTranslation } from 'react-i18next'
import { TextButtonVariant } from '../Buttons/TextButton'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import BasicModal from './BasicModal'
import ConfirmationModal from './ConfirmationModal'
import ActionButtons from '../Buttons/ActionButtons'
import AdjacentLabels from '../Labels/AdjacentLabels'
import { dateWithTimeToDashString } from '../../utils/dateUtility'
import getLocaleCurrencyForAmount from '../../utils/getLocaleCurrencyForAmount'
import { CashAmount } from '../../swagger'
import PartialPageLoadingSpinner from '../Elements/PartialPageLoadingSpinner'
import { useAuth } from '../Routes/AuthProvider'
import useLoadingContext from '../../hooks/useLoadingContext'
import { LoadingApiCall } from '../../api/LoadingApiCall'

export enum InvoicePaymentModalVariant {
  AddPayment,
  EditPayment,
  AddDiscount,
  EditDiscount,
}

const MAX_CHAR_LIMIT_FOR_DESC = 75

export interface TuitionEnrollmentOption {
  studentKey: number
  studentName: string
  programKey: number
  programName: string
  amountOwed: CashAmount
}
export interface PaymentModalState {
  // Enrollment key must be in the format {programKey}-{studentKey}
  enrollmentKey: string
  amount: number
  dateReceived: string
  description: string
}

export type TuitionPaymentActionType = 'add' | 'edit' | 'delete'

const today = new Date()

/**
 * Utility function that builds a formatted string from the given program/student names to display in the UI.
 * @param {string} programName
 * @param {string} studentName
 * @returns {string} Displays an enrollment in the format "Program Name (Student Name)""
 */
export const buildEnrollmentLabel = (
  programName: string,
  studentName: string
): string => `${programName} (${studentName})`

/**
 * Utility function that builds a unique key from the given program/student keys for internal use.
 * @param {number} programKey
 * @param {number} studentKey
 * @returns {string} A unique key in the format {programKey}-{studentKey}
 */
export const buildEnrollmentKey = (
  programKey: number,
  studentKey: number
): string => `${programKey}-${studentKey}`

/**
 * A utility function that validates a currency input by optionally allowing a single decimal point, followed by up to 2 digits.
 * @param {str} input
 * @returns {boolean}
 */
const isValidDecimalInput = (input: string): boolean => {
  // We consider an empty input as valid (allows clearing the input)
  if (!input) return true

  const regexp = /^\d+(\.\d{0,2})?$/
  return regexp.test(input)
}

const defaultPaymentData: PaymentModalState = {
  enrollmentKey: '',
  amount: 0,
  dateReceived: dateWithTimeToDashString(today),
  description: '',
}

export interface InvoicePaymentModalProps {
  variant: InvoicePaymentModalVariant
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  isLoading?: boolean
  tuitionEnrollmentOptions: TuitionEnrollmentOption[]
  clearTuitionEnrollmentOptions: () => void
  performTuitionPaymentAction: ({
    action,
    paymentData,
    callback,
    isDiscount,
  }: {
    action: TuitionPaymentActionType
    paymentData?: PaymentModalState
    callback?: () => void
    isDiscount: boolean
  }) => Promise<void>
  initialPaymentData?: PaymentModalState
  name: string
}

const InvoicePaymentModal: React.FC<InvoicePaymentModalProps> = ({
  variant,
  isOpen,
  setIsOpen,
  initialPaymentData,
  isLoading,
  tuitionEnrollmentOptions,
  clearTuitionEnrollmentOptions,
  performTuitionPaymentAction,
  name,
}) => {
  const { t } = useTranslation()

  const { permissionAbility } = useAuth()

  const [paymentData, setPaymentData] = useState(defaultPaymentData)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  // We maintain the amount state as a string, separate from paymentData, to allow decimal formatting.
  // onBlur, we update paymentData.amount by casting the string to a number.
  // We do this to ensure that paymentData.amount coming in to/going out of the modal remains of type number.
  const [stringAmount, setStringAmount] = useState('')

  // Update current state if initial values are passed in
  useEffect(() => {
    setPaymentData(initialPaymentData ?? defaultPaymentData)
    setStringAmount(initialPaymentData?.amount.toString() ?? '')
  }, [initialPaymentData])

  const isAddVariant =
    variant === InvoicePaymentModalVariant.AddPayment ||
    variant === InvoicePaymentModalVariant.AddDiscount
  const isDiscount =
    variant === InvoicePaymentModalVariant.AddDiscount ||
    variant === InvoicePaymentModalVariant.EditDiscount

  const closeModal = () => {
    setIsOpen(false)
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target

    // If entering an amount, update our stringAmount state
    if (name === 'amount' && isValidDecimalInput(value)) {
      setStringAmount(value)
      return
    }

    // Otherwise, update paymentData state as normal
    setPaymentData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleAmountInputBlur = () => {
    // When the amount field loses focus, update paymentData state with a numerical version of stringAmount
    setPaymentData((prevState) => ({
      ...prevState,
      amount: +stringAmount,
    }))
  }

  const cleanUp = () => {
    setPaymentData(initialPaymentData ?? defaultPaymentData)
    setStringAmount('')
    clearTuitionEnrollmentOptions()
  }

  const deletePaymentClick = () => {
    closeModal()
    setIsConfirmationOpen(true)
  }

  const confirmationCancel = () => {
    setIsConfirmationOpen(false)
    setIsOpen(true)
  }

  const canAdd = permissionAbility.can(
    isDiscount ? 'addTuitionCredit' : 'addTuitionPayment',
    'Payment'
  )
  const canDelete = permissionAbility.can(
    isDiscount ? 'deleteTuitionCredit' : 'deleteTuitionPayment',
    'Payment'
  )
  const canUpdate = permissionAbility.can(
    isDiscount ? 'updateTuitionCredit' : 'updateTuitionPayment',
    'Payment'
  )

  const disablePrimaryButton =
    (isAddVariant && !canAdd) ||
    (!isAddVariant && !canUpdate) ||
    [
      !paymentData.amount,
      !paymentData.enrollmentKey,
      !paymentData.description,
    ].some(Boolean)

  const cancelAction = {
    label: TextButtonVariant.Cancel,
    onClick: closeModal,
  }

  const deleteAction = {
    label: isDiscount
      ? TextButtonVariant.DeleteDiscount
      : TextButtonVariant.DeletePayment,
    onClick: deletePaymentClick,
  }

  const addVariantActionProps = {
    primaryButtonLabel: isDiscount
      ? ContainedButtonVariant.AddDiscount
      : ContainedButtonVariant.AddPayment,
    secondaryButtonLabel: cancelAction.label,
    secondaryClick: cancelAction.onClick,
    disablePrimaryButton,
  }

  const editVariantActionProps = {
    primaryButtonLabel: isDiscount
      ? ContainedButtonVariant.UpdateDiscount
      : ContainedButtonVariant.UpdatePayment,
    secondaryButtonLabel: canDelete ? deleteAction.label : undefined,
    secondaryClick: canDelete
      ? deleteAction.onClick
      : () => {
          return
        },
    tertiaryButtonLabel: cancelAction.label,
    tertiaryClick: cancelAction.onClick,
    disablePrimaryButton,
  }

  const actionButtonProps = isAddVariant
    ? addVariantActionProps
    : editVariantActionProps

  const action = isAddVariant ? 'add' : 'edit'

  const modalTitle = !isDiscount
    ? isAddVariant
      ? t('InvoicePaymentModal.Title.AddPayment', 'Enter a Payment Received')
      : t('InvoicePaymentModal.Title.EditPayment', 'Payment Received')
    : isAddVariant
    ? t('InvoicePaymentModal.Title.AddDiscount', 'Enter a Discount to Apply')
    : t('InvoicePaymentModal.Title.EditDiscount', 'Discount Applied')

  const confirmationModalTitle = !isDiscount
    ? t(
        'Invoice.ConfirmationModal.DeletePayment',
        'Are you sure you want to delete this payment?'
      )
    : t(
        'Invoice.ConfirmationModal.DeleteDiscount',
        'Are you sure you want to delete this discount?'
      )

  const isEnrollmentOptionsEmpty = !tuitionEnrollmentOptions.length

  const getIdByAction = () => {
    switch (action) {
      case 'add':
        return `${LoadingApiCall.AddTuitionPayment}${name}`
      case 'edit':
        return `${LoadingApiCall.UpdateTuitionPayment}${name}`
      default:
        return name
    }
  }

  /**
   * Asynchronously handle the formSubmission from this BasicModal.
   */
  useLoadingContext({
    asyncFunction: async () =>
      await performTuitionPaymentAction({ action, paymentData, isDiscount }),
    loadingId: getIdByAction(),
  })

  return (
    <>
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        ariaLabel={confirmationModalTitle}
        dialogTitle={confirmationModalTitle}
        dialogContent={null}
        dialogActions={
          <ActionButtons
            primaryButtonLabel={ContainedButtonVariant.YesDelete}
            secondaryButtonLabel={TextButtonVariant.NoCancel}
            secondaryClick={confirmationCancel}
          />
        }
        handleFormSubmit={async (e) => {
          e.preventDefault()
          await performTuitionPaymentAction({
            action: 'delete',
            callback: () => {
              setIsConfirmationOpen(false)
            },
            isDiscount,
          })
        }}
      />
      <BasicModal
        isOpen={isOpen}
        maxWidth="xs"
        handleFormSubmit={(e) => {
          e.preventDefault()
        }}
        ariaLabel={modalTitle}
        dialogTitle={modalTitle}
        dialogContent={
          <>
            {isAddVariant && (
              <>
                {!!isLoading && (
                  <PartialPageLoadingSpinner
                    isLoading={isLoading}
                    height={50}
                  />
                )}
                {tuitionEnrollmentOptions.map(
                  (
                    {
                      studentName,
                      programName,
                      amountOwed: { amount, currencyCode },
                    },
                    index
                  ) => (
                    <AdjacentLabels
                      key={index}
                      leftLabel={`${buildEnrollmentLabel(
                        programName,
                        studentName
                      )} Due`}
                      rightLabel={getLocaleCurrencyForAmount(
                        amount,
                        currencyCode
                      )}
                    />
                  )
                )}
              </>
            )}
            <TextField
              id="paymentProgram"
              name="enrollmentKey"
              label={t('Invoice.PaymentModal.Program', 'Program')}
              select
              fullWidth
              margin="normal"
              variant="filled"
              defaultValue=""
              value={isEnrollmentOptionsEmpty ? '' : paymentData.enrollmentKey}
              onChange={handleInputChange}
              disabled={!canUpdate}
            >
              {isEnrollmentOptionsEmpty ? (
                <MenuItem disabled>
                  {t('InvoicePaymentModal.MenuItem.NoOptions', 'No Options')}
                </MenuItem>
              ) : (
                tuitionEnrollmentOptions.map(
                  ({ studentName, programName, programKey, studentKey }) => (
                    <MenuItem
                      key={buildEnrollmentKey(programKey, studentKey)}
                      value={buildEnrollmentKey(programKey, studentKey)}
                    >
                      {buildEnrollmentLabel(programName, studentName)}
                    </MenuItem>
                  )
                )
              )}
            </TextField>
            <TextField
              id="paymentAmount"
              name="amount"
              label={t('Invoice.PaymentModal.AmountApplied', 'Amount Applied')}
              fullWidth
              margin="normal"
              variant="filled"
              value={stringAmount}
              onChange={handleInputChange}
              onBlur={handleAmountInputBlur}
              disabled={!canUpdate}
            />
            {!isDiscount && (
              <TextField
                id="paymentDate"
                name="dateReceived"
                label={t(
                  'Invoice.PaymentModal.DatePaymentReceived',
                  'Date Payment Received'
                )}
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="datetime-local"
                margin="normal"
                variant="filled"
                value={paymentData.dateReceived}
                onChange={handleInputChange}
                disabled={!canUpdate}
              />
            )}

            <TextField
              id="paymentDesc"
              name="description"
              label={t('Invoice.PaymentModal.Description', 'Description')}
              fullWidth
              multiline
              rows={3}
              margin="normal"
              variant="filled"
              inputProps={{ maxLength: MAX_CHAR_LIMIT_FOR_DESC }}
              helperText={t(
                'Invoice.PaymentModal.Description.HelperText',
                '75 character max'
              )}
              value={paymentData.description}
              onChange={handleInputChange}
              disabled={!canUpdate}
            />
          </>
        }
        dialogActions={
          <ActionButtons
            alwaysStack
            {...actionButtonProps}
            useBaseButton={true}
            primaryButtonLoadingId={getIdByAction()}
          />
        }
        afterClose={cleanUp}
      />
    </>
  )
}

export default InvoicePaymentModal
