import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useDynamicFieldStates, {
  Output,
} from '../../hooks/useDynamicFieldStates'
import { DynamicFieldStates } from '../Interfaces/DynamicFieldStates'
import {
  Student,
  Program2,
  EnrollmentStatusUpdate,
  Enrollment,
} from '../../swagger'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import {
  EnrollmentStatus,
  extractedErrorObject,
  studentApi,
} from '../../api/swagger'
import { updateEnrollmentStatus } from '../../api/enrollments'
import DropStudentModal from '../Modals/DropStudentModal'
import ChildrenSummaryCard, {
  ChildrenSummaryCardVariant,
} from '../Card/ChildrenSummaryCard'
import { getCommaSeparatedList } from '../../utils/getCommaSeparatedList'
import { EditStudentRequestBody } from '../../swagger/models/EditStudentRequestBody'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { useNotistackSnackbarKeyContext } from '../Context/NotistackSnackbarKeyProvider'

export type kidPrograms = Pick<
  Enrollment,
  'programKey' | 'studentKey' | 'status'
>
interface ChildrenSummaryCardForFamiliesProps {
  kids: Student[]
  refetchStudents?: () => void
}

/**
 * This is used to set the birth year as a
 * required field and validate that it field is not empty.
 */
export const setBirthYearAsRequired = (
  fieldId: string,
  childBirthYearStates: DynamicFieldStates,
  setChildBirthYearStates: Output['setFieldStates']
): void => {
  setChildBirthYearStates({
    ...childBirthYearStates,
    [fieldId]: {
      ...childBirthYearStates[fieldId],
      required: true,
      isValid: {
        ...childBirthYearStates[fieldId].isValid,
        input:
          !isNaN(Number(childBirthYearStates[fieldId].state)) &&
          childBirthYearStates[fieldId].state !== '',
      },
    },
  })
}

/**
 * This is used to set the birth month as a
 * required field and validate that it field is not empty.
 */
export const setBirthMonthAsRequired = (
  fieldId: string,
  childBirthMonthStates: DynamicFieldStates,
  setChildBirthMonthStates: Output['setFieldStates']
): void => {
  setChildBirthMonthStates({
    ...childBirthMonthStates,
    [fieldId]: {
      ...childBirthMonthStates[fieldId],
      required: true,
      isValid: {
        ...childBirthMonthStates[fieldId].isValid,
        input:
          !isNaN(Number(childBirthMonthStates[fieldId].state)) &&
          childBirthMonthStates[fieldId].state !== '',
      },
    },
  })
}
const ChildrenSummaryCardForFamilies: React.FC<
  ChildrenSummaryCardForFamiliesProps
> = ({ kids, refetchStudents }) => {
  const { t } = useTranslation()
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()

  const initialChildNameStates = useRef<DynamicFieldStates>()
  const initialChildBirthMonthStates = useRef<DynamicFieldStates>()
  const initialChildBirthYearStates = useRef<DynamicFieldStates>()
  const initialEnrollmentStates = useRef<DynamicFieldStates>()
  const initialChildHideStates = useRef<DynamicFieldStates>()
  const [isEditMode, setIsEditMode] = useState(false)

  const { addSnackbarKey, enqueueSnackbar } = useNotistackSnackbarKeyContext()

  const [childrenToUpdate, setChildrenToUpdate] = useState<
    EditStudentRequestBody[]
  >([])

  const [enrollmentStatusesToUpdate, setEnrollmentStatusesToUpdate] = useState<
    EnrollmentStatusUpdate[]
  >([])
  const [isInitialChildNamesLoad, setIsInitialChildNamesLoad] = useState(true)
  const { fieldStates: childNameStates, setFieldStates: setChildNameStates } =
    useDynamicFieldStates({
      fields: kids.map((kid) => {
        return {
          label: `${kid.studentKey}-name`,
          state: {
            state: kid.firstName,
            isValid: {
              input: true,
              length: true,
              maxLength: true,
              minLength: true,
              email: true,
            },
            required: true,
          },
        }
      }),
      isInitialLoad: isInitialChildNamesLoad,
      setIsInitialLoad: setIsInitialChildNamesLoad,
    })

  const [isInitialChildHideLoad, setIsInitialChildHideLoad] = useState(true)
  const { fieldStates: childHideStates, setFieldStates: setChildHideStates } =
    useDynamicFieldStates({
      fields: kids.map((kid) => {
        return {
          label: `${kid.studentKey}-hide`,
          state: {
            state: kid.hidden.toString(),
            isValid: {
              input: true,
              length: true,
              maxLength: true,
              minLength: true,
              email: true,
            },
            required: true,
          },
        }
      }),
      isInitialLoad: isInitialChildHideLoad,
      setIsInitialLoad: setIsInitialChildHideLoad,
    })

  const [isInitialChildBirthMonthLoad, setIsInitialChildBirthMonthLoad] =
    useState(true)
  const {
    fieldStates: childBirthMonthStates,
    setFieldStates: setChildBirthMonthStates,
  } = useDynamicFieldStates({
    fields: kids.map((kid) => {
      const birthMonth = kid.birthMonth?.toString() ?? ''
      return {
        label: `${kid.studentKey}-birthMonth`,
        state: {
          state: birthMonth,
          isValid: {
            input: true,
            length: true,
            maxLength: true,
            minLength: true,
            email: true,
          },
          required: true,
        },
      }
    }),
    isInitialLoad: isInitialChildBirthMonthLoad,
    setIsInitialLoad: setIsInitialChildBirthMonthLoad,
  })

  const [isInitialChildBirthYearLoad, setIsInitialChildBirthYearLoad] =
    useState(true)
  const {
    fieldStates: childBirthYearStates,
    setFieldStates: setChildBirthYearStates,
  } = useDynamicFieldStates({
    fields: kids.map((kid) => {
      const birthYear = kid.birthYear?.toString() ?? ''
      return {
        label: `${kid.studentKey}-birthYear`,
        state: {
          state: birthYear,
          isValid: {
            input: true,
            length: true,
            maxLength: true,
            minLength: true,
            email: true,
          },
          required: true,
        },
      }
    }),
    isInitialLoad: isInitialChildBirthYearLoad,
    setIsInitialLoad: setIsInitialChildBirthYearLoad,
  })

  const studentProgramsForEnrollments: kidPrograms[] = kids.flatMap((kid) =>
    kid.programs.map((program) => ({
      studentKey: kid.studentKey,
      programKey: program.programKey,
      status: program.enrollmentStatus,
    }))
  )

  const [isInitialEnrollmentLoad, setIsInitialEnrollmentLoad] = useState(true)
  const { fieldStates: enrollmentStates, setFieldStates: setEnrollmentStates } =
    useDynamicFieldStates({
      fields: studentProgramsForEnrollments.map((it) => {
        return {
          label: `${it.programKey}-${it.studentKey}`,
          state: {
            state: it.status,
            isValid: {
              input: true,
              length: true,
              maxLength: true,
              minLength: true,
              email: true,
            },
            required: true,
          },
        }
      }),
      isInitialLoad: isInitialEnrollmentLoad,
      setIsInitialLoad: setIsInitialEnrollmentLoad,
    })

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false)
  const [studentKey, setStudentKey] = useState(-1)
  const [studentPrograms, setStudentPrograms] = useState<Program2[]>([])
  const [studentName, setStudentName] = useState('')

  const prepareEnrollmentStatusForUpdate = (
    studentKey: number,
    programKey: number,
    status: EnrollmentStatus
  ) => {
    //Prepare body to update if there isnt one create one if there is one, add to it
    const enrollmentStatus: EnrollmentStatusUpdate = {
      studentKey: studentKey,
      programKey: programKey,
      status: status,
    }

    const updatedEnrollmentStatuses: EnrollmentStatusUpdate[] = []

    enrollmentStatusesToUpdate.forEach((enrollmentStatus) => {
      updatedEnrollmentStatuses.push(enrollmentStatus)
    })

    const findIndexToUpdate = enrollmentStatusesToUpdate.find(
      (enrollmentStatusToUpdate, index) => {
        if (
          enrollmentStatusToUpdate.studentKey === enrollmentStatus.studentKey &&
          enrollmentStatusToUpdate.programKey === enrollmentStatus.programKey
        ) {
          return (updatedEnrollmentStatuses[index] = enrollmentStatus)
        }
        return false
      }
    )
    if (!findIndexToUpdate) {
      updatedEnrollmentStatuses.push(enrollmentStatus)
      setEnrollmentStatusesToUpdate(updatedEnrollmentStatuses)
    }
  }

  const getEnrollmentStatusFromString = (
    status: string
  ): { status: EnrollmentStatus } | { error: string } => {
    switch (status) {
      case 'DROP':
        return { status: EnrollmentStatus.Drop }
      case 'ENROLLED':
        return { status: EnrollmentStatus.Enrolled }
      case 'MEMBER FULL':
        return { status: EnrollmentStatus.MemberFull }
      case 'MEMBER HALF':
        return { status: EnrollmentStatus.MemberHalf }
      case 'PENDING':
        return { status: EnrollmentStatus.Pending }
      default:
        return {
          error: `${t(
            'ChildrenSummaryCard.EnrollmentStatusUpdate.Error',
            'Failed to update enrollment status, invalid status'
          )}`,
        }
    }
  }

  const handleEnrollmentStatusChange = async (opts: {
    fieldId: string
    enrollmentStatus: string
    studentKey: number
    programKey: number
    studentName?: string
  }) => {
    const { fieldId, enrollmentStatus, studentKey, programKey } = opts

    const enrollmentStatusForStudent =
      getEnrollmentStatusFromString(enrollmentStatus)

    if ('error' in enrollmentStatusForStudent) {
      setSnackbarState(true)
      setSnackbarMessage(enrollmentStatusForStudent.error)
      setSnackbarSeverity(SnackbarSeverity.Error)
      return
    }
    setEnrollmentStates({
      ...enrollmentStates,
      [fieldId]: {
        ...enrollmentStates[fieldId],
        state: enrollmentStatus,
      },
    })

    if ('status' in enrollmentStatusForStudent) {
      prepareEnrollmentStatusForUpdate?.(
        studentKey,
        programKey,
        enrollmentStatusForStudent.status
      )
    }
  }

  const handleFieldValueChange = (fieldId: string, value: string) => {
    const [id, fieldType] = fieldId.split('-')
    const birthMonthFieldId = `${id}-birthMonth`
    const birthYearFieldId = `${id}-birthYear`

    prepareChildrenToUpdate(fieldId, value, kids, childrenToUpdate)
    switch (fieldType) {
      case 'name':
        setChildNameStates({
          ...childNameStates,
          [fieldId]: {
            ...childNameStates[fieldId],
            state: value,
          },
        })
        setBirthYearAsRequired(
          birthYearFieldId,
          childBirthYearStates,
          setChildBirthYearStates
        )
        setBirthMonthAsRequired(
          birthMonthFieldId,
          childBirthMonthStates,
          setChildBirthMonthStates
        )
        break
      case 'hide':
        setChildHideStates({
          ...childHideStates,
          [fieldId]: {
            ...childHideStates[fieldId],
            state: value,
          },
        })
        setBirthYearAsRequired(
          birthYearFieldId,
          childBirthYearStates,
          setChildBirthYearStates
        )
        setBirthMonthAsRequired(
          birthMonthFieldId,
          childBirthMonthStates,
          setChildBirthMonthStates
        )
        break
      case 'birthMonth':
        setChildBirthMonthStates({
          ...childBirthMonthStates,
          [fieldId]: {
            ...childBirthMonthStates[fieldId],
            state: value,
            required: true,
            isValid: {
              ...childBirthMonthStates[fieldId].isValid,
              input: !isNaN(Number(value)) && value !== '',
            },
          },
        })
        setBirthYearAsRequired(
          birthYearFieldId,
          childBirthYearStates,
          setChildBirthYearStates
        )
        break
      case 'birthYear':
        setChildBirthYearStates({
          ...childBirthYearStates,
          [fieldId]: {
            ...childBirthYearStates[fieldId],
            state: value,
            required: true,
            isValid: {
              ...childBirthYearStates[fieldId].isValid,
              input: !isNaN(Number(value)) && value !== '',
            },
          },
        })
        setBirthMonthAsRequired(
          birthMonthFieldId,
          childBirthMonthStates,
          setChildBirthMonthStates
        )
        break
    }
  }

  const resetFieldStates = () => {
    setChildNameStates({
      ...initialChildNameStates.current,
    })
    setChildHideStates({
      ...initialChildHideStates.current,
    })
    setChildBirthMonthStates({
      ...initialChildBirthMonthStates.current,
    })
    setChildBirthYearStates({
      ...initialChildBirthYearStates.current,
    })
    setEnrollmentStates({
      ...initialEnrollmentStates.current,
    })
  }

  const handleEdit = () => {
    setIsEditMode(true)
    initialChildNameStates.current = childNameStates
    initialChildBirthMonthStates.current = childBirthMonthStates
    initialChildBirthYearStates.current = childBirthYearStates
    initialEnrollmentStates.current = enrollmentStates
    initialChildHideStates.current = childNameStates
  }

  const handleCancel = () => {
    setIsEditMode(false)
    resetFieldStates()
  }
  const fieldsDoValidate = () => {
    const areValidChildren = childrenToUpdate.every(
      ({ birthMonth, birthYear }) =>
        !isNaN(Number(birthMonth)) && !isNaN(Number(birthYear))
    )
    return areValidChildren
  }
  const errorMessage = t(
    'Account.Children.ValidationMessage.Default',
    'Something went wrong. Please make sure you have filled out the required fields.'
  )
  const handleSave = async () => {
    if (!fieldsDoValidate()) {
      const e = {
        code: 'UnknownError',
        message: errorMessage,
      }
      setSnackbarState?.(true)
      setSnackbarMessage?.(e.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      return
    }
    const updateStudentResults = await Promise.allSettled(
      childrenToUpdate.map(async (body) => {
        try {
          return await studentApi.editStudent({ body })
        } catch (error) {
          const errorObj = (await extractedErrorObject(error)) ?? {
            code: 'Unknown',
            message: (error as unknown as Error).message,
          }
          // Used to set the reject reason
          throw new Error(errorObj.message).message
        }
      })
    )

    //Since user can modify other fields like student name, but no necessarily enrollmentStatus

    if (enrollmentStatusesToUpdate.length > 0) {
      const updateEnrollmentStatusResults = await Promise.allSettled(
        enrollmentStatusesToUpdate.map(async (body) => {
          try {
            return await updateEnrollmentStatus(body)
          } catch (error) {
            const errorObj = (await extractedErrorObject(error)) ?? {
              code: 'Unknown',
              message: (error as unknown as Error).message,
            }
            throw new Error(errorObj.message).message
          }
        })
      )

      const enrollmentRejections = updateEnrollmentStatusResults.filter(
        (updateEnrollmentStatusResult) =>
          updateEnrollmentStatusResult.status === 'rejected'
      )
      const enrollmentErrorMessage: string[] = []
      for (const rejection of enrollmentRejections) {
        enrollmentErrorMessage.push(
          `${(rejection as PromiseRejectedResult).reason} \n`
        )
      }
      for (const msg of enrollmentErrorMessage) {
        addSnackbarKey(enqueueSnackbar(msg, { variant: 'error' }))
      }

      const enrollmentSuccesses = updateEnrollmentStatusResults.filter(
        (updateEnrollmentStatusResult) =>
          updateEnrollmentStatusResult.status === 'fulfilled'
      )
      const enrollmentSuccessMessage =
        enrollmentSuccesses.length === updateEnrollmentStatusResults.length
          ? t(
              'ChildrenSummaryCardForFamilies.EnrollmentStatus.All.SuccessMessage',
              'Successfully updated enrollment status for all students that were modified.'
            )
          : t(
              'ChildrenSummaryCardForFamilies.EnrollmentStatus.Some.SuccessMessage',
              'Successfully updated enrollment status for some students that were modified.'
            )

      addSnackbarKey(
        enqueueSnackbar(enrollmentSuccessMessage, { variant: 'success' })
      )
    }

    // Separate rejections from success messages
    const studentRejections = updateStudentResults.filter(
      (updateStudentResult) => updateStudentResult.status === 'rejected'
    )

    const studentErrorMessage = studentRejections.map(
      (rejection) => `${(rejection as PromiseRejectedResult).reason} \n`
    )

    for (const msg of studentErrorMessage) {
      addSnackbarKey(enqueueSnackbar(msg, { variant: 'error' }))
    }

    const studentSuccessMessage: string[] = []
    const studentSuccesses = updateStudentResults.filter(
      (updateStudentResult) => updateStudentResult.status === 'fulfilled'
    )

    for (const success of studentSuccesses) {
      studentSuccessMessage.push(
        t(
          'ChildrenSummaryCardForFamilies.Student.SuccessMessage',
          `{{firstname}} was updated successfully. \n`,
          {
            firstname: (
              success as PromiseFulfilledResult<EditStudentRequestBody>
            ).value.firstName,
          }
        )
      )
    }

    for (const msg of studentSuccessMessage) {
      addSnackbarKey(enqueueSnackbar(msg, { variant: 'success' }))
    }

    setChildrenToUpdate([])
    setEnrollmentStatusesToUpdate([])
    setIsEditMode(false)
  }

  const handleDrop = async (dropData: {
    studentKey: number
    programs: Pick<Program2, 'programKey' | 'programType'>[]
    studentName: string
  }) => {
    try {
      const { studentKey, programs, studentName } = dropData
      const programKeys = programs.map((it) => it.programKey)

      await Promise.all(
        programKeys.map((programKey) =>
          updateEnrollmentStatus({
            studentKey,
            programKey,
            status: EnrollmentStatus.Drop,
          })
        )
      )

      const programNames = programs.map((it) => `${it.programType}`)
      const programNamesForSuccessMessage = getCommaSeparatedList(programNames)
      const fromProgramMessage =
        programNames.length > 1
          ? t(
              'ChildrenSummaryCardForFamilies.Message.FromPrograms',
              'from programs:'
            )
          : t(
              'ChildrenSummaryCardForFamilies.Message.FromProgram',
              'from program:'
            )

      refetchStudents?.()
      setSnackbarMessage?.(
        `${t(
          'ChildrenSummaryCardForFamilies.Message.DropStudent',
          `Successfully dropped student`
        )} ${studentName} ${fromProgramMessage} ${programNamesForSuccessMessage}.`
      )
      setSnackbarSeverity?.(SnackbarSeverity.Success)
      setSnackbarState?.(true)
    } catch (e) {
      const errorObj = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error).message ??
          t('Error occurred while attempting to drop student'),
      }
      setSnackbarMessage?.(errorObj.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarState?.(true)
    }
  }

  const handleFormSubmit = async (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault()
    await handleDrop({
      studentKey,
      programs: studentPrograms,
      studentName,
    })
    setIsConfirmationDialogOpen(false)
  }

  const handleConfirmationCancel = () => {
    setIsConfirmationDialogOpen(false)
  }

  const handleOpenConfirmationDialog = (
    // We only need a few items from Student, so pick them
    kid: Pick<Student, 'firstName' | 'studentKey' | 'programs'>
  ) => {
    setIsConfirmationDialogOpen(true)
    setStudentName(kid.firstName)
    setStudentKey(kid.studentKey)
    setStudentPrograms(kid.programs)
  }
  // FIXME: may have been removed by something else and needs adding back in.
  // For some reason ...snackbarProps caused linting no to notify of unused vars and ChildrenSummaryCard no longer used the confirmation dialog method
  void handleOpenConfirmationDialog

  const ConfirmDropStudentDialog = () => (
    <DropStudentModal
      isOpen={isConfirmationDialogOpen}
      handleFormSubmit={handleFormSubmit}
      handleConfirmationCancel={handleConfirmationCancel}
      studentName={studentName}
    />
  )

  return (
    <>
      {ConfirmDropStudentDialog()}
      <ChildrenSummaryCard
        variant={ChildrenSummaryCardVariant.Family}
        title={t('Families.ChildrenTable.Header', 'Children')}
        kids={kids}
        isEditMode={isEditMode}
        childNameStates={childNameStates}
        childHideStates={childHideStates}
        childBirthMonthStates={childBirthMonthStates}
        childBirthYearStates={childBirthYearStates}
        handleFieldValueChange={handleFieldValueChange}
        handleEditForParent={handleEdit}
        handleCancelForParent={handleCancel}
        handleSave={handleSave}
        //TODO: Add back if necessary
        // handleOpenConfirmationDialog={handleOpenConfirmationDialog}
        enrollmentStates={enrollmentStates}
        handleEnrollmentStatusChange={handleEnrollmentStatusChange}
      />
    </>
  )
}

function prepareChildrenToUpdate(
  fieldId: string,
  value: string,
  children: Student[],
  childrenToUpdate: EditStudentRequestBody[]
): void {
  const [studentKey, fieldType] = fieldId.split('-')
  const child: Student | undefined = children.find(
    (student) => student.studentKey === parseInt(studentKey)
  )

  if (!!child) {
    let updatedChild = {
      studentKey: child.studentKey,
      firstName: fieldType === 'name' ? value : child.firstName,
      hidden: fieldType === 'hide' ? JSON.parse(value) : child.hidden,
      birthMonth:
        fieldType === 'birthMonth'
          ? (value as unknown as number)
          : Number(child.birthMonth),
      birthYear:
        fieldType === 'birthYear'
          ? (value as unknown as number)
          : Number(child.birthYear),
      //it verify if the date was change, if the date that already in use is valid date since we are showing invalid date in the children array
      dateOfBirth:
        fieldType === 'dob' && value
          ? new Date(value)
          : //added since child.dateOfBirth can be a date object with invalid Date which is not a valid date
          !isNaN(child.dateOfBirth.getMonth())
          ? child.dateOfBirth
          : undefined,
    }
    //Check if the child is already added and update the child with  the new information.
    const findIndexToUpdate = childrenToUpdate.find((childToUpdate, index) => {
      if (childToUpdate.studentKey === child.studentKey) {
        updatedChild = {
          studentKey: child.studentKey,
          firstName: fieldType === 'name' ? value : childToUpdate.firstName,
          hidden:
            fieldType === 'hide' ? JSON.parse(value) : childToUpdate.hidden,
          birthMonth:
            fieldType === 'birthMonth'
              ? Number(value)
              : Number(childToUpdate.birthMonth),
          birthYear:
            fieldType === 'birthYear'
              ? Number(value)
              : Number(childToUpdate.birthYear),
          //it verify if the date was change, if the date that already in use is valid date since we are showing invalid date in the children array
          dateOfBirth:
            fieldType === 'dob' && value
              ? new Date(value)
              : childToUpdate.dateOfBirth,
        }

        return (childrenToUpdate[index] = updatedChild)
      }

      return false
    })

    if (!findIndexToUpdate) {
      childrenToUpdate.push(updatedChild)
    }
  }
}
export default ChildrenSummaryCardForFamilies
