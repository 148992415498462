import { createTheme } from '@mui/material/styles'

type Color = React.CSSProperties['color']
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    borderColor: {
      main: Color
      mediumEmphasis: Color
      green: Color
      grey: Color
    }
    customBackground: {
      avatar: Color
      error: Color
      onPrimary: Color
      searchbar: {
        main: Color
        light: Color
      }
      sidebar: Color
      logo: Color
      mapButton: Color
      dropDownMenu: Color
      selectedMenuItem: Color
      hoverSelectedMenuItem: Color
      closeButton: Color
      divider: Color
      progressBar: {
        main: Color
        bottom: Color
        disabled: Color
      }
      warning: Color
      event: Color
      stepperIcon: Color
      deactivateButton: Color
      greenBackground: Color
      transparent: Color
    }
    hover: {
      main: Color
      sidebar: Color
    }
    program: {
      foundations: Color
      essentials: Color
      challenge: Color
      scribblers: Color
    }
    textOrIcon: {
      onPrimary: Color
      onSecondary: Color
      onBackground: Color
      tableHeader: Color
      dropDownOptions: Color
      mapButton: Color
      noResultsFound: Color
      noResultsFoundSearchIcon: Color
      borderThirtyEight: Color
      searchIcon: Color
      viewInvoiceButton: Color
      redButton: Color
      helperText: Color
      warning: Color
      adventureButton: Color
      removeButton: Color
      subheader: Color
      checkbox: Color
      caption: Color
      greenButton: Color
      personIcon: Color
      deactivateButton: Color
      greenText: Color
      darkWhite: Color
      buttonLabel: Color
    }
    cookieBanner: {
      main: Color
      backgroundButton: Color
    }
  }
  interface PaletteOptions {
    borderColor: {
      main: Color
      mediumEmphasis: Color
      green: Color
      grey: Color
    }
    customBackground: {
      avatar: Color
      error: Color
      onPrimary: Color
      searchbar: {
        main: Color
        light: Color
      }
      sidebar: Color
      logo: Color
      mapButton: Color
      dropDownMenu: Color
      selectedMenuItem: Color
      hoverSelectedMenuItem: Color
      closeButton: Color
      divider: Color
      progressBar: {
        main: Color
        bottom: Color
        disabled: Color
      }
      warning: Color
      event: Color
      stepperIcon: Color
      deactivateButton: Color
      greenBackground: Color
      transparent: Color
    }
    hover: {
      main: Color
      sidebar: Color
    }
    program: {
      foundations: Color
      essentials: Color
      challenge: Color
      scribblers: Color
    }
    textOrIcon: {
      onPrimary: Color
      onSecondary: Color
      onBackground: Color
      tableHeader: Color
      dropDownOptions: Color
      mapButton: Color
      noResultsFound: Color
      noResultsFoundSearchIcon: Color
      borderThirtyEight: Color
      searchIcon: Color
      viewInvoiceButton: Color
      redButton: Color
      helperText: Color
      warning: Color
      adventureButton: Color
      removeButton: Color
      subheader: Color
      checkbox: Color
      caption: Color
      greenButton: Color
      personIcon: Color
      deactivateButton: Color
      greenText: Color
      darkWhite: Color
      buttonLabel: Color
    }
    cookieBanner: {
      main: Color
      backgroundButton: Color
    }
  }
}

/**
 * Colors from the CC style system.
 *
 * See: https://www.figma.com/proto/RmSIGQQhGwOiy1kTCe83KP/ProjectONE?node-id=1222:4916&scaling=min-zoom
 *
 * and also: https://brandpad.io/classical-conversations/
 */

const colors = {
  royalBlue: {
    main: '#051f42',
    twelveOpacity: 'rgba(5, 31, 66, 0.12)',
    mediumEmphasis: 'rgba(5, 31, 66, 0.65)',
    halfOpacity: 'rgba(5, 31, 66, 0.5)',
    thirtyEightOpacity: 'rgba(5, 31, 66, 0.38)',
    twentyFourOpacity: 'rgba(5, 31, 66, 0.24)',
    twentyOpacity: 'rgba(5, 31, 66, 0.2)',
    sixtyFiveOpacity: 'rgba(89, 99, 126)',
    divider: 'rgba(5, 31, 66, 0.12)',
    nearTransparent: 'rgba(5, 31, 66, 0.04)', // very transparent royalBlue
  },
  green: {
    primary: '#57ab82',
    alternate: '#5CAA7F',
    twelveOpacity: 'rgba(92, 170, 127, 0.12)',
    light: '#EBF5F0',
  },
  grey: {
    primary: '#D6D6E3',
    secondary: '#A0AAB7',
    light: '#E8E8E8',
  },
  darkWhite: {
    main: '#AFBDD1',
  },
  warmWhite: {
    quarter: '#FBF6F2',
    half: '#FDF5F1',
    threeFourths: '#FCF0E9',
    full: '#FCEDE0',
  },
  white: {
    main: 'rgba(255, 255, 255, 1)',
  },
  error: {
    main: '#C14F3C',
    lightest: '#F9EEEC',
  },
  brightBlue: '#83C1C0',
  orange: {
    main: '#DA7E32',
    dark: '#DB7B51',
    shadowLight: '#F3ECEA',
    shadowDark: '#E9E2DE',
  },
  softMaroon: '#C26E60',
  turquoise: '#75BABF',
  opal: {
    main: '#B5D4D9',
    twelveOpacity: 'rgba(5, 31, 66, 0.12)',
  },
  transparent: {
    main: 'rgba(0, 255, 0, 0)',
  },
}

const hidingMicrosoftEdgePasswordEyeballIcon = {
  '& input::-ms-reveal': {
    display: 'none !important',
  },
  '& input::-ms-clear': {
    display: 'none !important',
  },
}

const muiInputBase = {
  MuiInputBase: {
    styleOverrides: {
      root: {
        background: colors.warmWhite.quarter,
        fontFamily: 'sweet-sans-pro, sans-serif',
        fontWeight: 600,
        color: colors.royalBlue.main,
        '& input:-webkit-autofill': {
          WebkitBoxShadow: `0 0 0 1000px ${colors.warmWhite.quarter} inset !important`,
        },
        ...hidingMicrosoftEdgePasswordEyeballIcon,
      },
    },
  },
}

const filledInputBgColor = colors.warmWhite.quarter

const muiFilledInput = {
  MuiFilledInput: {
    styleOverrides: {
      root: {
        transition: 'none',
        backgroundColor: filledInputBgColor,
        '&:hover': {
          backgroundColor: filledInputBgColor,
        },
        '&.Mui-focused': {
          backgroundColor: filledInputBgColor,
        },
      },
      underline: {
        '&:before': {
          borderBottom: `1px solid ${colors.royalBlue.halfOpacity}`,
        },
        '&.Mui-disabled:before': {
          borderBottomStyle: 'solid',
          borderColor: colors.royalBlue.twentyOpacity,
        },
      },
      input: {
        '&.Mui-disabled': {
          background: colors.white.main,
          color: colors.royalBlue.main,
        },
      },
      multiline: {
        '&.Mui-disabled': {
          background: colors.white.main,
        },
      },
    },
  },
}

const muiFormHelperText = {
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        color: colors.royalBlue.mediumEmphasis,
        '&.Mui-disabled': {
          color: colors.royalBlue.mediumEmphasis,
        },
      },
      filled: {
        color: colors.royalBlue.mediumEmphasis,
        '&.Mui-disabled': {
          color: colors.royalBlue.mediumEmphasis,
        },
      },
    },
  },
}

const muiInputLabel = {
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: colors.royalBlue.main,
        fontFamily: 'sweet-sans-pro, sans-serif',
        fontWeight: 600,
        opacity: 0.65,
        '&.Mui-focused': {
          opacity: 1,
        },
        '&.Mui-error': {
          opacity: 1,
        },
      },
    },
  },
}

const muiButton = {
  MuiButton: {
    styleOverrides: {
      text: {
        color: colors.royalBlue.halfOpacity,
      },
      containedSecondary: {
        color: colors.white.main,
      },
      root: {
        fontSize: '14px',
      },
    },
  },
}

const muiCheckbox = {
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&.Mui-checked': {
          color: colors.green.primary,
        },
      },
    },
  },
}

const tabTextColors = {
  MuiTab: {
    styleOverrides: {
      textColorPrimary: {
        color: colors.royalBlue.halfOpacity,
        '&$selected': { color: colors.green.primary },
      },
      root: {
        '&:hover': { backgroundColor: 'rgba(5, 31, 66, 0.04)' },
        '&.Mui-selected': { color: colors.green.primary },
        minWidth: `160px`,
      },
    },
  },
}

const muiAvatar = {
  MuiAvatar: {
    styleOverrides: {
      colorDefault: {
        backgroundColor: colors.turquoise,
      },
    },
  },
}

const muiAccordion = {
  MuiAccordion: {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '&:before': {
          display: 'none',
        },
        '&$expanded': {
          margin: 'auto',
        },
      },
      expanded: {},
    },
  },
}

const muiAccordionSummary = {
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
        borderTopWidth: 1,
        borderStyle: 'solid',
        padding: '0 8px',
        borderColor: colors.royalBlue.twelveOpacity,
        color: colors.royalBlue.main,
        minHeight: 54,
        '&$expanded': {
          minHeight: 54,
        },
      },
      content: {
        '&$expanded': {
          margin: '12px 0',
        },
      },
      expanded: {},
      expandIcon: {
        color: colors.royalBlue.main,
      },
    },
  },
}

const muiAccordionDetails = {
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: '8px 0 24px',
      },
    },
  },
}

const muiTabs = {
  MuiTabs: {
    styleOverrides: {
      root: {
        backgroundColor: colors.white.main,
        border: '1px solid',
        borderColor: colors.royalBlue.divider,
        '& .MuiTabs-indicator': { backgroundColor: colors.green.primary },
      },
    },
  },
}

const muiTabPanel = {
  MuiTabPanel: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
}

const muiRadio = {
  MuiRadio: {
    styleOverrides: {
      root: {
        color: colors.royalBlue.thirtyEightOpacity,
      },
    },
  },
}

const muiAutoComplete = {
  MuiAutocomplete: {
    styleOverrides: {
      popper: {
        '& .MuiPaper-root': {
          '& .MuiAutocomplete-noOptions': {
            color: colors.grey.secondary,
          },
        },
      },
    },
  },
}

const muiDataGrid = {
  MuiDataGrid: {
    styleOverrides: {
      root: {
        '--DataGrid-overlayHeight': '300px',
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-columnHeader:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          color: colors.royalBlue.mediumEmphasis,
          fontWeight: 700,
        },
        '& .MuiDataGrid-cellContent': {
          fontFamily: 'plantin, sans-serif',
          fontWeight: 400,
          fontSize: '1rem',
          color: colors.royalBlue.main,
        },
        '& .MuiDataGrid-cell': {
          whiteSpace: 'normal !important',
          wordWrap: 'break-word !important',
        },
      },
    },
  },
}

export const muiTheme = createTheme({
  components: {
    ...muiInputBase,
    ...muiInputLabel,
    ...tabTextColors,
    ...muiButton,
    ...muiAvatar,
    ...muiAccordion,
    ...muiAccordionSummary,
    ...muiAccordionDetails,
    ...muiTabs,
    ...muiFilledInput,
    ...muiFormHelperText,
    ...muiTabPanel,
    ...muiRadio,
    ...muiAutoComplete,
    ...muiDataGrid,
    ...muiCheckbox,
  },
  palette: {
    background: {
      default: '#fefaf7',
    },
    borderColor: {
      main: colors.royalBlue.main,
      mediumEmphasis: colors.royalBlue.mediumEmphasis,
      green: colors.green.alternate,
      grey: colors.grey.secondary,
    },
    customBackground: {
      avatar: colors.turquoise,
      error: '#f9eeec',
      onPrimary: '#051f42',
      searchbar: {
        main: colors.warmWhite.full,
        light: '#fdf6ef',
      },
      sidebar: '#fdf5f1',
      logo: colors.grey.primary,
      mapButton: colors.royalBlue.mediumEmphasis,
      dropDownMenu: colors.warmWhite.half,
      selectedMenuItem: colors.orange.shadowLight,
      hoverSelectedMenuItem: colors.orange.shadowDark,
      closeButton: colors.royalBlue.main,
      divider: colors.royalBlue.divider,
      progressBar: {
        main: colors.opal.main,
        bottom: colors.royalBlue.nearTransparent,
        disabled: colors.opal.twelveOpacity,
      },
      warning: 'rgb(240, 207, 97, 0.24)',
      event: colors.green.alternate,
      stepperIcon: colors.green.alternate,
      deactivateButton: colors.error.lightest,
      greenBackground: colors.green.twelveOpacity,
      transparent: colors.transparent.main,
    },
    textOrIcon: {
      onPrimary: colors.warmWhite.quarter,
      onSecondary: colors.warmWhite.quarter,
      onBackground: colors.royalBlue.main,
      tableHeader: colors.royalBlue.mediumEmphasis,
      dropDownOptions: colors.royalBlue.mediumEmphasis,
      mapButton: colors.white.main,
      noResultsFound: colors.royalBlue.mediumEmphasis,
      noResultsFoundSearchIcon: colors.royalBlue.twelveOpacity,
      borderThirtyEight: colors.royalBlue.thirtyEightOpacity,
      searchIcon: colors.royalBlue.mediumEmphasis,
      viewInvoiceButton: colors.green.primary,
      redButton: colors.error.main,
      helperText: colors.royalBlue.halfOpacity,
      warning: colors.orange.main,
      adventureButton: colors.green.primary,
      removeButton: colors.royalBlue.thirtyEightOpacity,
      subheader: colors.royalBlue.mediumEmphasis,
      checkbox: colors.royalBlue.thirtyEightOpacity,
      caption: colors.royalBlue.halfOpacity,
      greenButton: colors.green.alternate,
      personIcon: colors.royalBlue.twentyFourOpacity,
      deactivateButton: colors.softMaroon,
      greenText: colors.green.alternate,
      darkWhite: colors.darkWhite.main,
      buttonLabel: colors.royalBlue.halfOpacity,
    },
    error: {
      main: colors.error.main,
    },
    hover: {
      main: '#dae9ec',
      sidebar: colors.royalBlue.nearTransparent,
    },
    grey: {
      300: colors.grey.light,
    },
    primary: {
      main: colors.royalBlue.main,
      light: colors.royalBlue.halfOpacity,
      dark: colors.royalBlue.mediumEmphasis,
    },
    program: {
      foundations: colors.brightBlue,
      essentials: colors.green.alternate,
      challenge: colors.softMaroon,
      scribblers: colors.orange.dark,
    },
    secondary: {
      main: colors.green.primary,
      light: colors.green.light,
    },
    text: {
      secondary: 'rgba(0, 0, 0, 0.04)',
      disabled: 'inherit',
    },
    cookieBanner: {
      main: colors.royalBlue.sixtyFiveOpacity,
      backgroundButton: colors.warmWhite.quarter,
    },
  },
  typography: {
    h1: {
      fontFamily: 'plantin, sans-serif',
      fontWeight: 400,
    },
    h2: {
      fontFamily: 'plantin, sans-serif',
      fontWeight: 600,
    },
    h3: {
      fontFamily: 'sweet-sans-pro, sans-serif',
      fontWeight: 600,
    },
    h4: {
      fontFamily: 'sweet-sans-pro, sans-serif',
      fontWeight: 800,
    },
    h5: {
      fontFamily: 'sweet-sans-pro, sans-serif',
      fontWeight: 800,
    },
    h6: {
      fontFamily: 'sweet-sans-pro, sans-serif',
      fontWeight: 800,
    },
    subtitle1: {
      fontFamily: 'sweet-sans-pro, sans-serif',
      fontWeight: 600,
    },
    subtitle2: {
      fontFamily: 'sweet-sans-pro, sans-serif',
      fontWeight: 700,
    },
    body1: {
      fontFamily: 'plantin, sans-serif',
      fontWeight: 400,
    },
    body2: {
      fontFamily: 'sweet-sans-pro, sans-serif',
      fontWeight: 400,
    },
    button: {
      fontFamily: 'sweet-sans-pro, sans-serif',
      fontWeight: 700,
    },
    caption: {
      fontFamily: 'sweet-sans-pro, sans-serif',
      fontWeight: 600,
    },
    overline: {
      fontFamily: 'sweet-sans-pro, sans-serif',
      fontWeight: 600,
    },
  },
})
