import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

/**
 * See UserAccountsSummaryTable.tsx for an example use case.
 * It resides within the TableContainer component, and when active, will overlay over that entire component.
 * Note, the parent container will most likely need a position: relative style applied to show the spinner centered in the correct spot.
 */

interface CircularProgressOverlayProps {
  /**
   * Determines whether to show the loading spinner or not
   */
  isLoading: boolean
}

/**
 * A utility component that overlays a loading spinner over the content it resides within, maintaining the size of its parent container.
 */
const CircularProgressOverlay: React.FC<CircularProgressOverlayProps> = ({
  isLoading,
}) => {
  if (isLoading) {
    return (
      <Box
        sx={{
          circularProgressOverlay: {
            display: 'flex',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,0.1)',
          },
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return null
}

export default CircularProgressOverlay
