import Card from '@mui/material/Card'
import React, {
  useMemo,
  useState,
  ReactElement,
  useCallback,
  useRef,
} from 'react'
import CardFormHeader from './CardFormHeader'
import Header, { HeaderVariant } from '../Elements/Header'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import { useLocation, useNavigate } from 'react-router'
import LedgerTable, { LedgerRow } from '../Table/LedgerTable'
import {
  Box,
  MenuItem,
  SelectChangeEvent,
  TableContainer,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import TableHeaders from '../Interfaces/TableHeaders'
import AdjacentLabels from '../Labels/AdjacentLabels'
import {
  InviteTabFlowStages,
  defaultAccountContextValue,
  useAccountContext,
} from '../Context/AccountContext'
import { MenuOption } from '../Menus/DropDown'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { useMountEffect } from '../../hooks/useMountEffect'
import DeclineInvitationModal from '../Modals/DeclineInvitationModal'
import {
  AcceptedEnrollmentInviteProgram,
  PotentialEnrollment,
  PotentialEnrollment1,
} from '../../swagger'
import ConfirmNavigationAway from '../Modals/ConfirmNavigationAwayModal'
import { enrollments, extractedErrorObject } from '../../api/swagger'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../Context/SnackbarContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'

const AssignChildrenCard: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const theme = useTheme()

  const location: {
    state: { openNavigationModal: boolean | undefined }
    pathname: string
  } = useLocation()

  const { openNavigationModal } = location.state ?? false

  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [
    confirmNavigationModalShouldAppear,
    setConfirmNavigationModalShouldAppear,
  ] = useState(openNavigationModal)
  const [isConfirmNavAwayModalOpen, setIsConfirmNavAwayModalOpen] =
    useState(false)
  const [rejectedEnrollments, setRejectedEnrollments] = useState<
    PotentialEnrollment1[]
  >([])
  const {
    updateAcceptedPrograms,
    studentAssignments: studentInviteMap,
    updateStudentAssignments: setStudentInviteMap,
    studentOptionsForProgram,
    updateStudentOptionsForProgram,
    selectedEnrollmentInvite,
    students,
    updateBreadcrumbs,
  } = useAccountContext()

  const { AssignChildrenCard } = useLoadingIds()

  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()

  const communityLabel = t(
    'AssignChildrenCard.Table.Header.Community',
    `Community`
  )
  const enrollmentSummaryPath = '/account/invites/enrollment-summary'
  const updateChildrenInfoPath =
    '/account/invites/assign-children/update-children-info'
  const headers: TableHeaders[] = [
    {
      label: communityLabel,
      align: 'left',
      id: 'communityName',
      render: (
        <AdjacentLabels
          leftLabel={communityLabel}
          rightLabel={selectedEnrollmentInvite?.communityName ?? ''}
        />
      ),
    },
    {
      label: '',
      align: 'left',
      id: 'studentName',
    },
  ]

  const childOptions = useRef<MenuOption[]>([
    {
      id: 'none-selected',
      name: t(
        'AssignChildrenCard.Dropdown.Option.NoneSelected',
        'None Selected'
      ),
    },
    ...students?.map((student) => ({
      id: student.studentKey,
      name: student.firstName,
    })),
    {
      id: 'skip-offered-spot-0',
      name: t(
        'AssignChildrenCard.Dropdown.Option.SkipOfferedSpot',
        'Skip Offered Spot'
      ),
    },
  ])

  useMountEffect(() => {
    /** If we've saved our selections, use them, otherwise use a default */
    if (studentInviteMap.size === 0) {
      const inviteMapArray =
        selectedEnrollmentInvite?.programs.map(
          (program): [number, Map<number, number>] => {
            const studentAssignmentsMap = new Map<number, number>()
            /** For each offered spot, set a spot and give it None Selected */
            for (let i = 1; i <= program.offeredSpots; i++) {
              studentAssignmentsMap.set(i, -1)
            }

            return [program.programKey, studentAssignmentsMap]
          }
        ) ?? []
      setStudentInviteMap(
        new Map<
          number,
          Map<number, number> /** programKey, a spot with a studentKey*/
        >(inviteMapArray)
      )
    }

    /**
     * update studentOptionsForProgram with possibly new
     * students, but keep already disabled options, disabled.
     */
    if (!!selectedEnrollmentInvite) {
      const map = new Map<number, MenuOption[]>()
      selectedEnrollmentInvite?.programs.forEach((program) =>
        map.set(program.programKey, [...childOptions.current])
      )

      // Find existing menuItems that are disabled, and set them in our new map
      for (const [programKey, program] of map.entries()) {
        const upToDateProgram = studentOptionsForProgram.get(programKey) ?? []
        for (const menuItem of program) {
          const foundMenuItem = upToDateProgram?.find(
            (updatedMenuItem) => updatedMenuItem.id === menuItem.id
          )
          menuItem.disabled = foundMenuItem?.disabled ?? false
        }
      }

      updateStudentOptionsForProgram(map)
    }
  })

  const [updatedSelection, setUpdatedSelection] = useState(false)

  const handleSelection = useCallback(
    (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
      const { name: id } = event.target

      /** We want the key without the prefix. There are special characters in 0 and 1 indexes which are undesirable. */
      const selectOptionId = `${(child as ReactElement).key}`.slice(2)
      const selectionParts = `${selectOptionId}`.split('-')

      const studentKey = +(selectionParts.length > 1
        ? +selectionParts[selectionParts.length - 1]
        : selectOptionId)

      /**
       * The programKey is after the dash of the field name,
       * The offeredSpot for a program is noted after the programKey
       *
       * The selectionOptionId is the id of the option. Useful when trying to index
       * the students array and display the value.
       */
      const [, programKey, offeredSpot] = id.split('-')

      const studentOptionsToUpdate = studentOptionsForProgram.get(
        +programKey
      ) as MenuOption[]

      const studentKeysMap = studentInviteMap.get(+programKey)

      const updatedStudentOptions = studentOptionsToUpdate?.map((option) => {
        const updatedOption = { ...option }
        const selectionAtThisIndex = option.id
        const previousSelectionForOfferedSpot = studentKeysMap?.get(
          +offeredSpot
        )

        if (selectionAtThisIndex === studentKey) {
          updatedOption.disabled = true
        } else if (previousSelectionForOfferedSpot === selectionAtThisIndex) {
          updatedOption.disabled = false
        }
        return { ...updatedOption }
      })

      studentOptionsForProgram.set(
        +programKey,
        updatedStudentOptions as MenuOption[]
      )

      updateStudentOptionsForProgram(studentOptionsForProgram)

      if (!!studentKeysMap) {
        studentKeysMap.set(+offeredSpot, studentKey)
        setStudentInviteMap(studentInviteMap.set(+programKey, studentKeysMap))
      }

      setUpdatedSelection(true)
    },
    [
      setStudentInviteMap,
      studentInviteMap,
      studentOptionsForProgram,
      updateStudentOptionsForProgram,
    ]
  )

  const rows = useMemo<LedgerRow[]>(() => {
    if (studentInviteMap.size === 0) return []

    if (updatedSelection) {
      setConfirmNavigationModalShouldAppear(true)
    }
    setUpdatedSelection(false)

    return (
      selectedEnrollmentInvite?.programs
        .map((program) => {
          let offeredSpot = 1
          const rows: LedgerRow[] = []
          const error = rejectedEnrollments.some(
            (rejectedEnrollment) =>
              rejectedEnrollment.studentKey ===
                studentInviteMap.get(program.programKey)?.get(offeredSpot) &&
              rejectedEnrollment.programKey === program.programKey
          )
          const menuOptions = studentOptionsForProgram.get(
            program.programKey
          ) as MenuOption[]

          while (offeredSpot <= program.offeredSpots) {
            rows.push({
              cells: [
                {
                  content: (
                    <Typography
                      variant="subtitle2"
                      color={theme.palette.textOrIcon.tableHeader}
                      fontWeight={'bold'}
                    >
                      {`${program.programType} (${
                        (program.semesterOneStartDate as Date).getFullYear()
                        // We can guarantee defined
                      })`}
                    </Typography>
                  ),
                  align: 'left',
                  colSpan: 1,
                },
                {
                  content: (
                    <TextField
                      id={`Children-${program.programKey}-${offeredSpot}`}
                      name={`Children-${program.programKey}-${offeredSpot}`}
                      label={t('AssignChildrenCard.Field.Label.Child', 'Child')}
                      variant="filled"
                      select
                      value={
                        (studentInviteMap
                          .get(program.programKey)
                          ?.get(offeredSpot) ?? 0) >= 0
                          ? students.find(({ studentKey }) => {
                              return (
                                studentKey ===
                                studentInviteMap
                                  .get(program.programKey)
                                  ?.get(offeredSpot)
                              )
                            })?.firstName ??
                            childOptions.current[
                              childOptions.current.length - 1
                            ].name
                          : childOptions.current[0].name
                      }
                      SelectProps={{
                        onChange: handleSelection,
                        MenuProps: {
                          PaperProps: {
                            sx: {
                              '& .MuiList-root': {
                                paddingTop: '0',
                                paddingBottom: '0',
                              },
                            },
                          },
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                        },
                      }}
                      fullWidth
                      error={error}
                      helperText={
                        error
                          ? t(
                              'AssignChildrenCard.ChildrenDropDown.Helper',
                              'The same student cannot be enrolled in the same program twice. Please select another student'
                            )
                          : undefined
                      }
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      {menuOptions.map((menuOption) => (
                        <MenuItem
                          disabled={menuOption.disabled}
                          key={menuOption.id}
                          value={menuOption.name}
                        >
                          {menuOption.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  ),
                  align: 'right',
                  colSpan: 3,
                },
              ],
            })
            offeredSpot++
          }
          return rows
        })
        .flat() ?? []
    )
  }, [
    studentInviteMap,
    updatedSelection,
    selectedEnrollmentInvite?.programs,
    rejectedEnrollments,
    studentOptionsForProgram,
    theme.palette.textOrIcon.tableHeader,
    t,
    childOptions,
    handleSelection,
    students,
  ])

  /**
   * Every number provided for a student is either the student's studentKey or
   * 0 meaning Decline Invitation. If the parsing of .split('-')[1] evaluates
   * to a string in the case of none-selected, then it will be -1 and the
   * button will be disabled.
   */
  const viewEnrollmentSummaryButtonEnabled = useMemo(() => {
    /**
     * Eslint does not like our use of updatedSelection if it's used in the dependency
     * but not in the useMemo hook. We don't want to enable the enrollmentSummary based
     * on the value of updatedSelection, but we do want to recalculate when that value
     * changes.
     */
    void updatedSelection
    return (
      selectedEnrollmentInvite &&
      selectedEnrollmentInvite.programs.every(
        /** We can guarantee the number is set, but .get can return undefined. */
        (program) => {
          const offeredSpotsMap = studentInviteMap.get(program.programKey)
          if (!offeredSpotsMap) {
            return false
          } else {
            let offeredSpot = 1
            while (offeredSpot <= program.offeredSpots) {
              const studentKey = offeredSpotsMap?.get(offeredSpot)
              if (!!studentKey && studentKey < 0) {
                return false
              }
              offeredSpot++
            }
            /** At this point, all offered spots are either selected or disabled */
            return true
          }
        }
      )
    )
  }, [selectedEnrollmentInvite, studentInviteMap, updatedSelection])

  const primaryButtonLoadingId = AssignChildrenCard.validatePotentialEnrollments

  const mapAcceptedPrograms = () => {
    const programsToAccept: AcceptedEnrollmentInviteProgram[] = []
    for (const [key, value] of studentInviteMap) {
      for (const [, studentKey] of value) {
        if (studentKey > 0) {
          programsToAccept.push({
            programKey: key,
            // We can guarantee the value is a number if we're submitting
            studentKey: studentKey as number,
            // We can guarantee the firstName is a string if the value is positive
            studentFirstName: students.find(
              (student) => student.studentKey === studentKey
            )?.firstName as string,
          })
        }
      }
    }
    return programsToAccept
  }

  const everyAssignedChildHasValidBirthData = (
    assignedChildrenKeys: number[]
  ): boolean => {
    return students
      .filter((student) => {
        return assignedChildrenKeys.includes(Number(student.studentKey))
      })
      .every((student) => {
        return Number(student.birthMonth) >= 0 && Number(student.birthYear) > 0
      })
  }

  const handleFormSubmit = async (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault()

    setOpenConfirmation(false)
    const acceptedPrograms = mapAcceptedPrograms()
    const assignedChildrenKeys = acceptedPrograms.map((children) =>
      Number(children.studentKey)
    )
    updateAcceptedPrograms(acceptedPrograms)

    let pathToNavigate = enrollmentSummaryPath

    if (!everyAssignedChildHasValidBirthData(assignedChildrenKeys)) {
      pathToNavigate = updateChildrenInfoPath
    }

    navigate({ pathname: pathToNavigate })
  }

  const handleSubmit = async () => {
    const enrollmentsToCheck: PotentialEnrollment[] = []

    for (const [key, value] of studentInviteMap) {
      let offeredSpot = 1
      let spotDefined = true
      while (spotDefined) {
        const studentKeyForSpot = value.get(offeredSpot)
        if (!studentKeyForSpot) {
          spotDefined = false
        }
        if (!!studentKeyForSpot && studentKeyForSpot > 0) {
          enrollmentsToCheck.push({
            programKey: key,
            studentKey: studentKeyForSpot as number,
          })
        }
        offeredSpot++
      }
    }

    try {
      const response = await enrollments.validatePotentialEnrollments({
        body: {
          potentialEnrollments: enrollmentsToCheck,
        },
      })

      if (
        Array.from(studentInviteMap).some(([programKey, assignedSpots]) => {
          const offeredSpots =
            selectedEnrollmentInvite?.programs.find(
              (program) => program.programKey === programKey
            )?.offeredSpots ?? 0

          let offeredSpot = 1
          let hasDeclined = false
          while (offeredSpot <= offeredSpots) {
            if (assignedSpots.get(offeredSpot) === 0) {
              hasDeclined = true
            }
            offeredSpot++
          }
          return hasDeclined
        }) &&
        response.rejectedEnrollments.length === 0
      ) {
        setOpenConfirmation(true)
      } else if (response.rejectedEnrollments.length === 0) {
        const acceptedPrograms = mapAcceptedPrograms()
        const assignedChildrenKeys = acceptedPrograms.map((children) =>
          Number(children.studentKey)
        )
        updateAcceptedPrograms(acceptedPrograms)
        setRejectedEnrollments([])

        let pathToNavigate = enrollmentSummaryPath

        if (!everyAssignedChildHasValidBirthData(assignedChildrenKeys)) {
          pathToNavigate = updateChildrenInfoPath
        }

        navigate({ pathname: pathToNavigate })
      } else {
        setRejectedEnrollments(response.rejectedEnrollments)
        setSnackbarMessage?.(
          t(
            'AssignChildrenCard.DuplicateEnrollment.Error',
            'Some students are assigned to the same program'
          )
        )
        // baloney
        setSnackbarSeverity?.(SnackbarSeverity.Error)
        setSnackbarState?.(true)
      }
    } catch (e) {
      const errorObj = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error).message ??
          t(
            'AssignChildrenCard.RejectEnrollments.Error',
            'An unknown error occurred.'
          ),
      }
      setSnackbarMessage?.(errorObj.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarState?.(true)
    }
  }

  const handleCancel = () => {
    setOpenConfirmation(false)
  }

  const handleSubmitConfirmNavAwayModal = async (
    event: React.FormEvent<HTMLDivElement>
  ) => {
    event.preventDefault()
    setIsConfirmNavAwayModalOpen(false)
    navigate({ pathname: '/account/invites/invitation-summary' })
    /** set back to default since we are navigating away and chose to disregard the selections */
    updateStudentOptionsForProgram(
      defaultAccountContextValue.studentOptionsForProgram
    )
    setStudentInviteMap(defaultAccountContextValue.studentAssignments)
  }

  const handleBackClick = () => {
    if (confirmNavigationModalShouldAppear) {
      setIsConfirmNavAwayModalOpen(true)
    } else {
      setStudentInviteMap(
        new Map<
          number,
          Map<number, number> /** programKey, a spot with a studentKey*/
        >()
      )
      navigate({ pathname: '/account/invites/invitation-summary' })
    }
  }

  useLoadingContext({
    asyncFunction: handleSubmit,
    loadingId: primaryButtonLoadingId,
  })

  useMountEffect(() => {
    updateBreadcrumbs(InviteTabFlowStages.AssignStudents)
  })

  return (
    <>
      <DeclineInvitationModal
        handleFormSubmit={handleFormSubmit}
        isOpen={openConfirmation}
        handleCancel={handleCancel}
      />
      <ConfirmNavigationAway
        isOpen={isConfirmNavAwayModalOpen}
        handleFormSubmit={handleSubmitConfirmNavAwayModal}
        handleConfirmationCancel={() => setIsConfirmNavAwayModalOpen(false)}
      />
      <Card>
        <CardFormHeader
          header={
            <Header
              id="assign-children-to-programs"
              component={'h2'}
              headerName="Assign Children to Programs"
              variant={HeaderVariant.Card}
            />
          }
        />
        <TableContainer
          aria-labelledby="assign-children-to-programs"
          sx={{
            mx: theme.spacing(3),
            width: 'auto',
          }}
        >
          <LedgerTable
            ariaLabel="Assign Children to Programs"
            tableHeaders={headers}
            rows={rows}
          />
        </TableContainer>
        <Box justifyContent={'center'} display="flex">
          <Box width={useShowOnDesktop() ? '50%' : '100%'}>
            <ActionButtons
              primaryButtonLabel={ContainedButtonVariant.ViewEnrollmentSummary}
              primaryButtonLoadingId={primaryButtonLoadingId}
              secondaryButtonLabel={TextButtonVariant.Back}
              secondaryClick={handleBackClick}
              disablePrimaryButton={!viewEnrollmentSummaryButtonEnabled}
              useBaseButton
              alwaysStack
            />
          </Box>
        </Box>
      </Card>
    </>
  )
}

export default AssignChildrenCard
