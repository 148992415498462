/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  AgreementRecord,
  AgreementRecordFromJSON,
  AgreementRecordToJSON,
  PaginationResponse,
  PaginationResponseFromJSON,
  PaginationResponseToJSON,
} from './'

/**
 *
 * @export
 * @interface UserTeamAgreementsResponse
 */
export interface UserTeamAgreementsResponse {
  /**
   * This returns the user agreements sent out for the current and previous academic year from the requesting logged in user in case the end point send query string with the actorKey it will also fetch the actor downline team agreements for the provided actorKey. It will return the Name, Email, Role, Date Sent, Type of Invite, Status of Invite, and roleKey
   * @type {Array<AgreementRecord>}
   * @memberof UserTeamAgreementsResponse
   */
  agreements: Array<AgreementRecord>
  /**
   *
   * @type {PaginationResponse}
   * @memberof UserTeamAgreementsResponse
   */
  pagination?: PaginationResponse
}

export function UserTeamAgreementsResponseFromJSON(
  json: any
): UserTeamAgreementsResponse {
  return UserTeamAgreementsResponseFromJSONTyped(json, false)
}

export function UserTeamAgreementsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserTeamAgreementsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    agreements: (json['agreements'] as Array<any>).map(AgreementRecordFromJSON),
    pagination: !exists(json, 'pagination')
      ? undefined
      : PaginationResponseFromJSON(json['pagination']),
  }
}

export function UserTeamAgreementsResponseToJSON(
  value?: UserTeamAgreementsResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    agreements: (value.agreements as Array<any>).map(AgreementRecordToJSON),
    pagination: PaginationResponseToJSON(value.pagination),
  }
}
