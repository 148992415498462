/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  CashAmount,
  CashAmountFromJSON,
  CashAmountToJSON,
  TuitionPayments,
  TuitionPaymentsFromJSON,
  TuitionPaymentsToJSON,
} from './'

/**
 *
 * @export
 * @interface AddTuitionPaymentRequestBody
 */
export interface AddTuitionPaymentRequestBody {
  /**
   * The unique identifier for the invoice the tuition payment is for
   * @type {number}
   * @memberof AddTuitionPaymentRequestBody
   */
  invoiceKey?: number
  /**
   * The total amount of the tuition payments being added
   * @type {number}
   * @memberof AddTuitionPaymentRequestBody
   */
  totalAmount?: number
  /**
   * The unique identifier for the student the tuition payment is for
   * @type {number}
   * @memberof AddTuitionPaymentRequestBody
   */
  studentKey?: number
  /**
   * The unique identifier for the program the tuition payment is for
   * @type {number}
   * @memberof AddTuitionPaymentRequestBody
   */
  programKey?: number
  /**
   *
   * @type {CashAmount}
   * @memberof AddTuitionPaymentRequestBody
   */
  amount?: CashAmount
  /**
   * Date the user received the payment from the parent for the program
   * @type {Date}
   * @memberof AddTuitionPaymentRequestBody
   */
  dateReceived?: Date
  /**
   * Description that goes along with the tuition payment
   * @type {string}
   * @memberof AddTuitionPaymentRequestBody
   */
  description: string
  /**
   * Designates who the payment is paid towards, the tutor or director.
   * @type {string}
   * @memberof AddTuitionPaymentRequestBody
   */
  invoiceOriginatorKey?: AddTuitionPaymentRequestBodyInvoiceOriginatorKeyEnum
  /**
   * If true, this is adding a director discount for a family's tuition billing. If left empty, defaults to false
   * @type {boolean}
   * @memberof AddTuitionPaymentRequestBody
   */
  isDiscount?: boolean
  /**
   * The tuition payments to add
   * @type {Array<TuitionPayments>}
   * @memberof AddTuitionPaymentRequestBody
   */
  tuitionPayments?: Array<TuitionPayments>
}

/**
 * @export
 * @enum {string}
 */
export enum AddTuitionPaymentRequestBodyInvoiceOriginatorKeyEnum {
  Director = 'director',
  Tutor = 'tutor',
}

export function AddTuitionPaymentRequestBodyFromJSON(
  json: any
): AddTuitionPaymentRequestBody {
  return AddTuitionPaymentRequestBodyFromJSONTyped(json, false)
}

export function AddTuitionPaymentRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddTuitionPaymentRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    invoiceKey: !exists(json, 'invoiceKey') ? undefined : json['invoiceKey'],
    totalAmount: !exists(json, 'totalAmount') ? undefined : json['totalAmount'],
    studentKey: !exists(json, 'studentKey') ? undefined : json['studentKey'],
    programKey: !exists(json, 'programKey') ? undefined : json['programKey'],
    amount: !exists(json, 'amount')
      ? undefined
      : CashAmountFromJSON(json['amount']),
    dateReceived: !exists(json, 'dateReceived')
      ? undefined
      : new Date(json['dateReceived']),
    description: json['description'],
    invoiceOriginatorKey: !exists(json, 'invoiceOriginatorKey')
      ? undefined
      : json['invoiceOriginatorKey'],
    isDiscount: !exists(json, 'isDiscount') ? undefined : json['isDiscount'],
    tuitionPayments: !exists(json, 'tuitionPayments')
      ? undefined
      : (json['tuitionPayments'] as Array<any>).map(TuitionPaymentsFromJSON),
  }
}

export function AddTuitionPaymentRequestBodyToJSON(
  value?: AddTuitionPaymentRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    invoiceKey: value.invoiceKey,
    totalAmount: value.totalAmount,
    studentKey: value.studentKey,
    programKey: value.programKey,
    amount: CashAmountToJSON(value.amount),
    dateReceived:
      value.dateReceived === undefined
        ? undefined
        : value.dateReceived.toISOString(),
    description: value.description,
    invoiceOriginatorKey: value.invoiceOriginatorKey,
    isDiscount: value.isDiscount,
    tuitionPayments:
      value.tuitionPayments === undefined
        ? undefined
        : (value.tuitionPayments as Array<any>).map(TuitionPaymentsToJSON),
  }
}
