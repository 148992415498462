import { validateEmail } from '../../../helpers/validateEmail'
import {
  InvitationType,
  LoginForInviteRequestBodySignUpTypeEnum,
  SignUpSendTokenRequestBodySignUpTypeEnum,
  auth,
  extractedErrorObject,
} from '../../../api/swagger'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../Routes/AuthProvider'
import { removeStringExtraSpaces } from '../../../utils/stringUtility'
import useLoadingContext from '../../../hooks/useLoadingContext'
import { useLoadingIds } from '../../../hooks/useLoadingIds'
import { InviteStep, useInviteContext } from '../../Context/InviteContext'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { LoadingContext } from '../../Context/LoadingContext'
import React from 'react'

export const useInvitationMethods = (opts: {
  inviteKey?: string
}): {
  /**
   * Error from any endpoint call whose presence is provided to AuthFormCard
   */
  error: string
  /**
   * Calls sendTokenForSignup and handles any errors
   *
   * @param email
   * @param password
   * @returns
   */
  handleSignUp: (email: string, password: string) => Promise<void>

  emailVerificationToken: string
  /** LoadingId to call resendCode to resend the verification code. */
  resendLoadingId: string
  /** LoadingId for handleSignUpConfirmVerificationCode */
  signUpVerificationLoadingId: string
  handleLogin: (email: string, password: string) => Promise<void>
  /** Validates correct email is used when logging in for this invitation. */
  isWrongAccount: boolean
  /**
   * Handler when using the wrong account for the invitation
   * which takes the user back to the login page NOT in an invitation
   */
  backToLogin: () => void
  /**
   * Handler when using the wrong account for the invitation
   * which takes the user back to the login page in an invitation
   */
  backToLoginForInvite: () => void
} => {
  const { inviteKey } = opts

  const { addLoadingIds } = React.useContext(LoadingContext)

  const {
    goToNextStep,
    updateVerificationCodeHasBeenResent,
    updateError,
    error,
    userInfo,
    emailVerificationToken,
    updateUserInfo,
    authenticationInfo,
    updateAuthInfo,
    emailForInvitation,
    resetContextToDefaults,
    invitationType,
    completeAgreementLoadingId,
  } = useInviteContext()

  const { t } = useTranslation()
  const { signup, signinForInvite } = useAuth()
  const availableLoadingIds = useLoadingIds()
  const navigate = useNavigate()

  const [isWrongAccount, setIsWrongAccount] = useState(false)

  const resetError = () => {
    if (error) {
      updateError('')
    }
  }

  const handleSignUp = async (email: string, password: string) => {
    try {
      /** If inviteKey is not provided, we shouldn't even have the signUp option */
      if (validateEmail(email) && !!inviteKey) {
        await auth.sendTokenForSignup({
          body: {
            username: email,
            signUpType:
              invitationType === InvitationType.Family
                ? SignUpSendTokenRequestBodySignUpTypeEnum.Family
                : SignUpSendTokenRequestBodySignUpTypeEnum.Agreement,
            typeIdentifier: inviteKey,
          },
        })

        /** Store the current email and password */
        updateUserInfo({
          ...userInfo,
        })
        updateAuthInfo({
          email,
          password,
          passwordConfirm: password,
        })
        /** Reset the error on success */
        resetError()

        goToNextStep()
      }
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: t(
          'InviteForm.Error.SendTokenForSignup',
          'Unknown error occurred during sign up.'
        ),
      }
      switch (errorObject?.code) {
        case 'IncorrectUsername':
          updateError(
            t(
              'InviteForm.Error.EmailDoesNotMatch',
              'The provided email does not match the email provided for the invitation.'
            )
          )
          break
        default:
          updateError(
            errorObject?.message ??
              t(
                'InviteForm.Error.CreatingAccount',
                'Could not create a new account at this time.'
              )
          )
          break
      }
    }
  }

  const handleSignUpConfirmVerificationCode = async () => {
    try {
      await signup({
        credentials: {
          username: removeStringExtraSpaces(authenticationInfo.email).trim(),
          password: authenticationInfo.password,
        },
        firstName: removeStringExtraSpaces(userInfo.firstName).trim(),
        lastName: removeStringExtraSpaces(userInfo.lastName).trim(),
        phone: removeStringExtraSpaces(userInfo.phone),
        token: emailVerificationToken.trim(),
      })
      resetError()
      goToNextStep()
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: t(
          'InviteForm.InviteForm.SignUp',
          'Unknown error occurred during sign up.'
        ),
      }
      switch (errorObject?.code) {
        case 'SignupTokenExpired':
          updateError(
            t('InviteForm.Error.Expired', 'The code you entered has expired')
          )
          break
        case 'IncorrectSignupToken':
          updateError(
            t(
              'InviteForm.Error.IncorrectToken',
              'The code you entered is incorrect'
            )
          )
          break
        default:
          updateError(errorObject?.message ?? '')
          break
      }
    }
  }

  const signUpVerificationLoadingId =
    availableLoadingIds.InviteForm.confirmTokenForSignup
  useLoadingContext({
    asyncFunction: handleSignUpConfirmVerificationCode,
    loadingId: signUpVerificationLoadingId,
  })

  const resendCode = async () => {
    try {
      if (!!inviteKey) {
        await auth.sendTokenForSignup({
          body: {
            username: authenticationInfo.email,
            signUpType:
              invitationType === InvitationType.Family
                ? SignUpSendTokenRequestBodySignUpTypeEnum.Family
                : SignUpSendTokenRequestBodySignUpTypeEnum.Agreement,
            typeIdentifier: inviteKey,
          },
        })
        // If a user navigates away before we're done awaiting the sendTokenForSignup call, don't try and perform these
        resetError()
        updateVerificationCodeHasBeenResent(true)
      }
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error)?.message ??
          t(
            'InviteForm.Error.ResendCode',
            'Could not resend verification code at this time'
          ),
      }
      updateError(errorObject.message)
    }
  }

  const resendLoadingId = availableLoadingIds.InviteForm.sendTokenForSignup
  useLoadingContext({
    asyncFunction: resendCode,
    loadingId: resendLoadingId,
  })

  const handleLogin = async (email: string, password: string) => {
    const isEmailValid = validateEmail(email)
    /** We ought not to call login if we don't have an inviteKey.  */
    if (!inviteKey) return
    const result = isEmailValid
      ? await signinForInvite(
          {
            username: email,
            password,
          },
          invitationType === InvitationType.Family
            ? LoginForInviteRequestBodySignUpTypeEnum.Family
            : LoginForInviteRequestBodySignUpTypeEnum.Agreement,
          inviteKey
        )
      : { isAuthorized: false }

    if (!result.isAuthorized) {
      isEmailValid &&
        updateError(
          t(
            'InviteForm.Error.Login',
            'We do not recognize the email or password.'
          )
        )
      return
    }

    const isCountryInformationStored =
      result.countryOfResidence !== undefined &&
      result.countryOfCitizenship !== undefined
    localStorage.setItem('username', email)
    resetError()

    /**
     * Handle emailForInvitation not being that with which was used
     * to login. If so, present the 'Wrong Account' message.
     *
     * We are logged in, but this is not our account so we cannot complete it.
     *
     */
    if (!new RegExp(emailForInvitation, 'i').test(email)) {
      setIsWrongAccount(true)
      return
    }

    // We only do this in login because users who are signing up will never have it
    if (isCountryInformationStored) {
      if (invitationType === InvitationType.Team) {
        addLoadingIds([completeAgreementLoadingId])
      } else {
        navigate(
          `/account/${
            invitationType === InvitationType.Family ? 'invites' : 'billing'
          }`,
          { replace: true }
        )
      }
    } else {
      /**
       * If we're logging in and don't have country information,
       * go to the Account Info step
       */
      goToNextStep(InviteStep.AccountInfo)
    }
  }

  const backToLoginForInvite = () => {
    /** This will signout the user  */
    resetContextToDefaults()
    setIsWrongAccount(false)
  }

  const backToLogin = () => {
    backToLoginForInvite()
    navigate({ pathname: '/login' })
  }

  return {
    handleSignUp,
    error,
    emailVerificationToken,
    resendLoadingId,
    signUpVerificationLoadingId,
    handleLogin,
    isWrongAccount,
    backToLoginForInvite,
    backToLogin,
  }
}
