import React, { CSSProperties } from 'react'
import { useTheme } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import TableHeader from '../Table/TableHeader'
import TableHeaders from '../Interfaces/TableHeaders'

// Reference `InvoiceCard.tsx` for an example on how to use this component

/**
 * This style function provides common styling that may be used along with LedgerTable.
 * It can be imported and used in other files where LedgerTable is used.
 */

/**
 * A table cell used specifically within a LedgerRow. HTML node: `<td>`.
 */
export interface LedgerCell {
  /**
   * The content to render in the table cell.
   * Usually a string, but can also be any React component (commonly a button).
   */
  content: string | JSX.Element | null
  /**
   * The alignment of the content within the cell.
   */
  align: 'left' | 'right' | 'center'
  /**
   * If content is a string, sets the color of the text to 1 of the 3 common text colors.
   * @default 'medium'
   */
  color?: 'main' | 'medium' | 'disabled' | 'green'
  /**
   * If provided, specifies the number of columns to span.
   */
  colSpan?: number
  /**
   * If provided, specifies CSS style to be applied to text in the cell
   */
  cssTextProps?: CSSProperties

  /**
   * If provided, specifies CSS style to be applied to that cell
   */
  cellCssProps?: CSSProperties
}

/**
 * Options to visually modify a LedgerRow.
 */
export interface LedgerRowOptions {
  /**
   * If `true`, sets the color of all text items in a row to green.
   */
  showAsGreenText?: boolean
  /**
   * If `true`, sets the color of all text items in a row to red.
   */
  showAsRedText?: boolean
}

/**
 * A table row used specifically within LedgerTable. HTML node: `<tr>`.
 */
export interface LedgerRow {
  /**
   * The cells to render within a row.
   * Must be an array of {@link LedgerCell} items.
   * Is an array to allow for a dynamic number of cells.
   * The length of this array should usually match the length of the tableHeaders array.
   */
  cells: LedgerCell[]
  /**
   * Enables or disables certain visual styles for elements within a row.
   */
  options?: LedgerRowOptions
}

/**
 * A ledger-style table that is used commonly in invoicing/payment screens.
 */
const LedgerTable: React.FC<{
  /**
   * The headers to render for this table.
   * Must be an array of {@link TableHeaders} items.
   */
  tableHeaders: TableHeaders[]
  /**
   * The rows to render for this table.
   * - If omitted, does not render the table.
   * - If `[]`, renders a single row with a line item that reads: "None".
   */
  rows?: LedgerRow[]
  /** Label for the table, if not providing a header to do so.
   *
   * FIXME: This should be required, if not aria-labelledby anything.
   */
  ariaLabel?: string

  /**
   * If provided, specifies CSS style to be applied to that table component
   */
  cssProps?: CSSProperties

  /**
   * If provided, add zebra-striping(alternating colors for an easy read) to any table row within the <tbody>
   */
  striped?: boolean
}> = ({ tableHeaders, rows, ariaLabel, cssProps, striped }) => {
  const theme = useTheme()

  if (!rows) return null

  const emptyCells: LedgerCell[] = Array.from(
    { length: tableHeaders.length },
    (_, i) => ({
      content: i === 0 ? 'None' : '',
      align: 'left',
    })
  )

  const emptyRow: LedgerRow[] = [
    {
      cells: emptyCells,
    },
  ]

  const rowsToRender = rows.length > 0 ? rows : emptyRow

  return (
    <Table
      sx={{
        marginBottom: theme.spacing(3.5),
        ...cssProps,
      }}
      aria-label={ariaLabel ?? 'ledger table'}
    >
      <TableHead>
        <TableHeader tableHeaders={tableHeaders} />
      </TableHead>
      <TableBody>
        {rowsToRender.map(({ cells, options = {} }, index) => {
          const { showAsGreenText = false, showAsRedText = false } = options

          return (
            <TableRow
              key={index}
              sx={{
                ...(striped && {
                  '@media print': {
                    '&:nth-of-type(even)': {
                      background: `${theme.palette.customBackground.progressBar.bottom}!important`,
                    },
                  },
                  '&:nth-of-type(even)': {
                    background: `${theme.palette.customBackground.progressBar.bottom}`,
                  },
                }),
              }}
            >
              {cells.map(
                (
                  {
                    content,
                    align,
                    color = 'medium',
                    colSpan,
                    cssTextProps,
                    cellCssProps,
                  },
                  index,
                  arr
                ) => {
                  const isFirstCell = index === 0
                  const isLastCell = index === arr.length - 1

                  return (
                    <TableCell
                      key={index}
                      colSpan={colSpan}
                      align={align}
                      sx={{
                        ...(isFirstCell && {
                          paddingLeft: 0,
                        }),
                        ...(isLastCell && {
                          paddingRight: 0,
                        }),
                        ...cellCssProps,
                      }}
                    >
                      {typeof content === 'string' ? (
                        <Typography
                          variant="subtitle2"
                          component="p"
                          sx={{
                            ...cssTextProps,
                            color:
                              color === 'main'
                                ? theme.palette.primary.main
                                : color === 'medium'
                                ? theme.palette.primary.dark
                                : theme.palette.primary.light,
                            ...(showAsRedText && {
                              color: theme.palette.error.main,
                            }),
                            ...(showAsGreenText && {
                              color: theme.palette.textOrIcon.greenText,
                            }),
                          }}
                        >
                          {content}
                        </Typography>
                      ) : (
                        content
                      )}
                    </TableCell>
                  )
                }
              )}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default LedgerTable
