import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import React from 'react'
import { Address } from '../../swagger'
import { TextFieldVariant } from '../Interfaces/FieldProps'
import ErrorAlert from '../Alerts/ErrorAlert'
import { useTranslation } from 'react-i18next'
import CardFormHeader from '../Card/CardFormHeader'
import Header, { HeaderVariant } from '../Elements/Header'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../Buttons/OutlinedButton'
import { StyledTextField } from './ProgramInformationFields'

interface ProgramAddressFieldsProps {
  address: Address
  isAddressValid: boolean
  isFieldDisabled: boolean
  editLocation: () => void
}

const ProgramAddressFields: React.FC<ProgramAddressFieldsProps> = (props) => {
  const { t } = useTranslation()
  const sharedProps = {
    disabled: true,
    variant: TextFieldVariant.Filled,
  }

  const { address, isAddressValid, isFieldDisabled, editLocation } = props

  const locationEmptyMessage = t(
    'Programs.ProgramForm.ValidationMessage.Location',
    'Location cannot be empty.'
  )

  return (
    <section aria-labelledby="programLocationHeader">
      <CardFormHeader
        header={
          <Header
            id="programLocationHeader"
            headerName={t(
              'Programs.ProgramFormCard.ProgramLocation.Header',
              'Program Location'
            )}
            component="h1"
            variant={HeaderVariant.Divider}
          />
        }
        buttons={
          !isFieldDisabled && (
            <OutlinedButton
              id="editLocation"
              variant={OutlinedButtonVariant.EditLocation}
              onClick={editLocation}
            />
          )
        }
      />
      {!isAddressValid && <ErrorAlert error={locationEmptyMessage} />}
      <form
        noValidate
        autoComplete="off"
        aria-labelledby="programLocationHeader"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="column" pb={5}>
              <StyledTextField
                {...sharedProps}
                id="streetAddress1Field"
                label={t(
                  'Programs.ProgramFormCard.FormField.StreetAddress1',
                  'Street Address'
                )}
                value={address.streetAddress1}
              />
              <StyledTextField
                id="streetAddress2Field"
                label={t(
                  'Programs.ProgramFormCard.FormField.StreetAddress2',
                  'Building, Suite, etc.'
                )}
                variant="filled"
                value={address.streetAddress2}
                disabled
              />
              <StyledTextField
                id="cityField"
                label={t('Programs.ProgramFormCard.FormField.City', 'City')}
                variant="filled"
                value={address.city}
                disabled
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="column" pb={5}>
              <StyledTextField
                id="countrySelect"
                label={t(
                  'Programs.ProgramFormCard.FormField.Country',
                  'Country'
                )}
                variant="filled"
                value={address.countryCode}
                disabled
              />
              <StyledTextField
                id="stateSelect"
                label={t(
                  'Programs.ProgramFormCard.FormField.State',
                  'State/Province'
                )}
                variant="filled"
                value={address.state}
                disabled
              />
              <StyledTextField
                id="postalCodeField"
                label={t(
                  'Programs.ProgramFormCard.FormField.PostalCode',
                  'Postal Code'
                )}
                inputProps={{ 'aria-label': 'PostalCode', maxLength: 12 }}
                variant="filled"
                value={address.zip}
                disabled
              />
            </Box>
          </Grid>
        </Grid>
      </form>
    </section>
  )
}

export default ProgramAddressFields
