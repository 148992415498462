/* eslint-disable @typescript-eslint/no-unused-vars */
import i18n from 'i18next'
import type { i18n as i18nT } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

const DoubleLengthPseudoLanguagePostProcessor = {
  type: 'postProcessor' as const,
  name: 'DoubleLengthPseudoLanguagePostProcessor',
  process(
    value: string,
    _key: string,
    _options: unknown,
    _translator: unknown
  ) {
    return value + value
  },
}

/** Returns a collator for sorting that is as aligned with i18next's selected language as practicable. If all else fails, uses whatever the browser considers its default locale. */
export const sortCollatorMatchingLanguagesOf = (i18n: i18nT): Intl.Collator => {
  const languages = i18n.languages
  // Intl will barf with a RangeError if you pass an invalid locale in the list, so instead, we manually search for a working language, skipping any mismatch between the i18n vs the Intl locales.
  for (const language of languages) {
    try {
      const collator = Intl.Collator(language)
      return collator
    } catch (e) {
      continue
    }
  }
  // None worked; let the browser use its default, then.
  const collator = Intl.Collator()
  return collator
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(DoubleLengthPseudoLanguagePostProcessor)
  .init({
    resources: {
      en: {},
    },
    fallbackLng: 'en',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    // Uncomment the "postProcess" line to test rendering with doubled strings during dev.
    // This duplicates the effects on string length from localization to a language such as German.
    // postProcess: [DoubleLengthPseudoLanguagePostProcessor.name],
  })

export default i18n
