import React, { useState, useEffect, useContext } from 'react'
import { EmptyPageCenter, Page } from '../Elements/PageMargins'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate, useLocation } from 'react-router'
import ProgramFormCard, {
  ProgramFormCardVariants,
} from '../Card/ProgramFormCard'
import { Program, ProgramType } from '../../swagger'
import TitleContext from '../../TitleContext'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import { Ability } from '@casl/ability'
import { extractedErrorObject, programs } from '../../api/swagger'
import EmptyProgramDetails from './EmptyProgramDetails'
import { useAuth } from '../Routes/AuthProvider'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Tab from '@mui/material/Tab'
import { CanAccess } from '../Elements/Access'
import EnrollmentsTable from '../Families/EnrollmentsTable'
import { useEnrollmentOptions } from '../../hooks/useEnrollmentOptions'
import EnrollmentsInviteTable from './EnrollmentsInviteTable'
import DropdownSelectOptions from '../Interfaces/DropdownSelectOptions'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { LoadingContext } from '../Context/LoadingContext'
import CircularProgress from '@mui/material/CircularProgress'
import useLoadingContext from '../../hooks/useLoadingContext'
import { Box } from '@mui/material'
import { useMountEffect } from '../../hooks/useMountEffect'
import { useProgramDetailsContext } from '../Context/ProgramDetailsContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { ACTING_AS_PARENT } from '../../utils/constants'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { useUnmountEffect } from '../../hooks/useUnmountEffect'

export enum ProgramDetailsTab {
  programDetails = '1',
  enrollments = '2',
  invites = '4',
}

const ProgramDetails: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { permissionAbility, userDetails } = useAuth()
  const title = t('Programs.ProgramDetails.Header', 'Programs')
  const { setSnackbarMessage, setSnackbarState, setSnackbarSeverity } =
    useSnackbarContext()
  const location: {
    state: {
      academicYear: string
      initiallySelectedTab: string
      tabToChange: string
    }
  } = useLocation()
  const showOnDesktop = useShowOnDesktop()
  let { academicYear } = location.state ?? {}
  const initiallySelectedTab = location?.state?.initiallySelectedTab || '1'
  const locationKey = location.state?.tabToChange ?? '1'

  const { ProgramDetails } = useLoadingIds()

  const filename = 'ProgramDetails'
  const fetchEnrollmentsLoadingId = `${filename}-${ProgramDetails.fetchEnrollments}`
  const fetchedProgramDetailsLoadingId = `${filename}-${ProgramDetails.fetchProgram}`

  const { removeLoadingIds, addLoadingIds, loadingIds } =
    React.useContext(LoadingContext)

  const { useTitleEffect } = useContext(TitleContext)
  useTitleEffect(title)

  const {
    enrollmentFilters,
    enrollments,
    enrollmentsPagination,
    hasEnrollmentInvitesWithInProgressStatus,
    searchEnrollments,

    fetchEnrollments,
    handleEnrollmentsPageChange,
    handleEnrollmentsPageSizeChange,
    updateEnrollmentFilters,
    updateSearchEnrollments,
    resetContextToDefaults,
  } = useProgramDetailsContext()

  /** State Objects */
  const [programType, setProgramType] = useState<ProgramType>()

  const [programDetails, setProgramDetails] = useState<Program | undefined>(
    undefined
  )
  const [initialProgramDetails, setInitialProgramDetails] = useState<
    Program | undefined
  >(undefined)

  const { programKey } = useParams<{ programKey: string }>()
  const programId = parseInt(`${programKey}`)
  const [fetchInitialDetails, setFetchInitialDetails] = useState(true)
  const [programAbility, setProgramAbility] = useState<Ability>()

  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [loadEnrollments, setLoadEnrollments] = useState(true)

  const [isProgramDetailsEdit, setIsProgramDetailsEdit] = useState(false)
  const [selectedTab, setSelectedTab] = useState(initiallySelectedTab ?? '1')
  const isTabInvitesTourCompleted = localStorage.getItem(
    'joyride-programDetails-enrollmentInvitesTable-complete'
  )

  useMountEffect(() => {
    if (!!initiallySelectedTab) {
      setSelectedTab(initiallySelectedTab)
    }
  })

  useUnmountEffect(() => {
    resetContextToDefaults()
  })

  /** Other Objects */
  const defaultErrorMessage = t(
    'ProgramDetails.Error.FetchProgramDetails',
    'Error occurred while retrieving program details'
  )

  const programTitle = t(
    'ProgramDetails.TabTitle.ProgramTitle',
    'Program Details'
  )

  const enrollmentsTitle = t(
    'ProgramDetails.TabTitle.EnrollmentsTitle',
    'Enrollments'
  )

  const invitesTitle = t('ProgramDetails.TabTitle.InvitesTitle', 'Invites')

  /** Hooks */
  useEffect(() => {
    if (fetchInitialDetails) {
      addLoadingIds([fetchedProgramDetailsLoadingId])
      setFetchInitialDetails(false)
    }
  }, [
    programId,
    fetchInitialDetails,
    defaultErrorMessage,
    addLoadingIds,
    fetchedProgramDetailsLoadingId,
    removeLoadingIds,
    loadingIds,
  ])

  const fetchProgramDetails = async () => {
    try {
      setIsLoading(true)
      // Fetched community name and address are random. Always in Cali and with Sally Ride
      const fetchedProgramDetails = await programs.fetchProgram({ programId })

      setProgramDetails(fetchedProgramDetails)
      setInitialProgramDetails(fetchedProgramDetails)
      if (!!fetchedProgramDetails) {
        setProgramType(fetchedProgramDetails.type)
      }
      setProgramAbility(
        new Ability(
          fetchedProgramDetails?.meta?.permissions?.map(
            ({ resourceCode: subject, actionCode: action }) => ({
              subject,
              action,
            })
          )
        )
      )
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message ?? defaultErrorMessage,
      }
      setErrorMessage(errorObject.message)
    } finally {
      removeLoadingIds([fetchedProgramDetailsLoadingId])
      setIsLoading(false)
    }
  }

  useLoadingContext({
    loadingId: fetchedProgramDetailsLoadingId,
    asyncFunction: fetchProgramDetails,
  })

  /**
   * Fetch Enrollments
   */

  const refetchEnrollmentsData = () => {
    // Programmatically trigger a refetch of enrollments
    setLoadEnrollments(true)
  }

  useEffect(() => {
    if (loadEnrollments) {
      // Only fetch enrollments if user has permission
      if (permissionAbility.can('view', 'Enrollment')) {
        addLoadingIds([fetchEnrollmentsLoadingId])
      }
      setLoadEnrollments(false)
    }
  }, [
    permissionAbility,
    defaultErrorMessage,
    programId,
    loadEnrollments,
    removeLoadingIds,
    fetchEnrollmentsLoadingId,
    addLoadingIds,
    loadingIds,
  ])

  const fetchProgramEnrollments = async () => {
    try {
      await fetchEnrollments(programId)
      removeLoadingIds([fetchEnrollmentsLoadingId])
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown Code',
        message: (e as unknown as Error).message ?? defaultErrorMessage,
      }
      setErrorMessage(errorObject.message)
    }
  }

  // FIXME: refactor fetchEnrollments use to not have a func in this component
  useLoadingContext({
    loadingId: fetchEnrollmentsLoadingId,
    asyncFunction: fetchProgramEnrollments,
  })

  useEffect(() => {
    if (userDetails.actingAs !== ACTING_AS_PARENT) {
      addLoadingIds([fetchEnrollmentsLoadingId])
    }
  }, [
    searchEnrollments,
    enrollmentFilters,
    enrollmentsPagination.page,
    enrollmentsPagination.pageSize,
    userDetails.actingAs,
    addLoadingIds,
    fetchEnrollmentsLoadingId,
  ])

  const enrollmentOptions = useEnrollmentOptions({
    permissionAbility: permissionAbility,
    programKey: programId,
  })

  /**
   * If error fetching enrollments, display snack bar alert
   */
  useEffect(() => {
    if (!!errorMessage) {
      setSnackbarState(true)
      setSnackbarMessage(errorMessage)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  })

  const updateSelectedTab = (newTab: string) => {
    setSelectedTab(newTab)
  }
  useEffect(() => {
    if (isTabInvitesTourCompleted && !showOnDesktop) {
      updateSelectedTab(locationKey)
    }
  }, [isTabInvitesTourCompleted, locationKey, showOnDesktop])

  useEffect(() => {
    if (
      location.state?.tabToChange === ProgramDetailsTab.programDetails &&
      !showOnDesktop
    ) {
      location.state.tabToChange = ProgramDetailsTab.invites
    }
  }, [location.state, showOnDesktop])

  const handleSave = () => {
    setFetchInitialDetails(true)
  }

  const onCommunitiesBreadCrumbClick = () => {
    navigate({ pathname: '/communities' })
  }

  //If no academic year from location, set academic year value from the program semesterOneStartDate
  academicYear =
    academicYear ??
    new Date(programDetails?.semesterOneStartDate as Date)
      .getUTCFullYear()
      .toString()

  const communityBreadcrumbLabel = `${programDetails?.communityName} (${academicYear})`

  const onCommunityBreadCrumbClick = () => {
    const selectedYearQueryString = !!academicYear
      ? new URLSearchParams({ year: academicYear }).toString()
      : ''

    navigate(
      {
        pathname: `/communities/community-details/${programDetails?.communityId}/overview`,
        search: selectedYearQueryString,
      },
      {
        state: {
          communityKey: programDetails?.communityId,
          communityName: programDetails?.communityName,
        },
      }
    )
  }

  const labelForBreadcrumb = () => {
    let label
    switch (programType) {
      case ProgramType.Scribblers:
        label = t(
          'Programs.ProgramDetails.BreadCrumb.ScribblersDetails',
          'Scribblers'
        )
        break
      case ProgramType.Foundations:
        label = t(
          'Programs.ProgramDetails.BreadCrumb.FoundationsDetails',
          'Foundations'
        )
        break
      case ProgramType.Essentials:
        label = t(
          'Programs.ProgramDetails.BreadCrumb.EssentialsDetails',
          'Essentials'
        )
        break
      case ProgramType.ChallengeA:
        label = t(
          'Programs.ProgramDetails.BreadCrumb.ChallengeADetails',
          'Challenge A'
        )
        break
      case ProgramType.ChallengeB:
        label = t(
          'Programs.ProgramDetails.BreadCrumb.ChallengeBDetails',
          'Challenge B'
        )
        break
      case ProgramType.ChallengeI:
        label = t(
          'Programs.ProgramDetails.BreadCrumb.ChallengeIDetails',
          'Challenge I '
        )
        break
      case ProgramType.ChallengeIi:
        label = t(
          'Programs.ProgramDetails.BreadCrumb.ChallengeIIDetails',
          'Challenge II'
        )
        break
      case ProgramType.ChallengeIii:
        label = t(
          'Programs.ProgramDetails.BreadCrumb.ChallengeIIIDetails',
          'Challenge III'
        )
        break
      case ProgramType.ChallengeIv:
        label = t(
          'Programs.ProgramDetails.BreadCrumb.ChallengeIVDetails',
          'Challenge IV'
        )
        break
      default:
        label = ''
    }
    return label
  }

  const setProgramDetailsForParent = (program?: Program) => {
    setProgramDetails(program)
  }

  const updateProgramDetailsEditMode = (isEdit: boolean) => {
    setIsProgramDetailsEdit(isEdit)
  }

  const switchTabs = (newValue: string) => {
    setSelectedTab(newValue)
  }

  if (!programDetails) {
    return <EmptyProgramDetails isLoading={isLoading} />
  }

  const Tabs = [
    <Tab
      key="1"
      label={programTitle}
      value={ProgramDetailsTab.programDetails}
    />,
    ...(permissionAbility.can('view', 'Enrollment')
      ? [
          <Tab
            key="2"
            label={enrollmentsTitle}
            value={ProgramDetailsTab.enrollments}
          />,
        ]
      : []),
    ...(permissionAbility.can('invite', 'Family')
      ? [<Tab key="4" label={invitesTitle} value={ProgramDetailsTab.invites} />]
      : []),
  ]

  const programBreadCrumb = [
    {
      label: t('Programs.ProgramDetails.BreadCrumb', 'Communities'),
      onClick: onCommunitiesBreadCrumbClick,
    },
    {
      label: communityBreadcrumbLabel,
      onClick: onCommunityBreadCrumbClick,
    },
    {
      label: labelForBreadcrumb(),
    },
  ]

  return (
    <TabContext value={selectedTab}>
      <TabList
        value={selectedTab}
        onChange={(_, newValue) => switchTabs(newValue)}
        textColor="primary"
        variant="scrollable"
        scrollButtons={true}
      >
        {Tabs}
      </TabList>
      <TabPanel value={ProgramDetailsTab.programDetails}>
        <Page withinTab>
          <DynamicBreadcrumbs breadcrumbs={programBreadCrumb} />
          <Box
            sx={{
              color: theme.palette.primary.main,
              maxWidth: 1200,
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                display: 'flex',
              },
            }}
          >
            <section aria-label="programFormCard">
              {loadingIds.has(fetchedProgramDetailsLoadingId) ? (
                <EmptyPageCenter adjustForMargins>
                  <CircularProgress />
                </EmptyPageCenter>
              ) : (
                <ProgramFormCard
                  variant={ProgramFormCardVariants.EditProgram}
                  totalEnrolledStudents={
                    enrollmentsPagination
                      ? enrollmentsPagination.totalCount
                      : enrollments.length
                  }
                  programDetails={programDetails}
                  handleSave={handleSave}
                  programAbility={programAbility}
                  setEditForParent={updateProgramDetailsEditMode}
                  hasEnrollment={enrollments.length > 0 ? true : false}
                  updateSelectedTab={updateSelectedTab}
                  initialProgramDetails={initialProgramDetails}
                  setProgramDetailsForParent={setProgramDetailsForParent}
                  isProgramDetailsEdit={isProgramDetailsEdit}
                  hasEnrollmentInvitesWithInProgressStatus={
                    hasEnrollmentInvitesWithInProgressStatus
                  }
                />
              )}
            </section>
          </Box>
        </Page>
      </TabPanel>
      <TabPanel value={ProgramDetailsTab.enrollments}>
        <Page withinTab>
          <DynamicBreadcrumbs breadcrumbs={programBreadCrumb} />
          <CanAccess I="view" on="Enrollment">
            {loadingIds.has(fetchEnrollmentsLoadingId) ? (
              <EmptyPageCenter adjustForMargins>
                <CircularProgress />
              </EmptyPageCenter>
            ) : (
              <section aria-label="enrollments">
                <EnrollmentsTable
                  pagination={enrollmentsPagination}
                  handleChangePage={handleEnrollmentsPageChange}
                  handleChangePageSize={handleEnrollmentsPageSizeChange}
                  searchText={searchEnrollments}
                  setSearchState={updateSearchEnrollments}
                  filters={enrollmentFilters}
                  setFilters={updateEnrollmentFilters}
                  enrollments={enrollments}
                  enrollmentOptions={enrollmentOptions?.statuses ?? []}
                  refetchEnrollmentsData={refetchEnrollmentsData}
                  tutors={
                    programDetails.tutors?.map((it) => {
                      return {
                        id: it.actorKey,
                        label: it.tutorName,
                      } as DropdownSelectOptions
                    }) ?? []
                  }
                  programInfo={{
                    programKey: programDetails.programsKey,
                    programType: programDetails.type,
                    status: programDetails.status,
                    firstSemesterStartDate: programDetails.semesterOneStartDate,
                  }}
                  academicYear={academicYear}
                  programAbility={
                    new Ability(
                      programDetails.meta?.permissions?.map(
                        ({ resourceCode: subject, actionCode: action }) => ({
                          subject,
                          action,
                        })
                      )
                    )
                  }
                  setEditForParent={updateProgramDetailsEditMode}
                  programEmailDirector={programDetails.directorEmail}
                />
              </section>
            )}
          </CanAccess>
        </Page>
      </TabPanel>
      <TabPanel value={ProgramDetailsTab.invites}>
        <Page withinTab>
          <DynamicBreadcrumbs breadcrumbs={programBreadCrumb} />
          <EnrollmentsInviteTable
            isTabInvitesTourCompleted={
              isTabInvitesTourCompleted as unknown as boolean
            }
          />
        </Page>
      </TabPanel>
    </TabContext>
  )
}

export default ProgramDetails
