import React, { useLayoutEffect, useState } from 'react'
import { SearchAndFilter } from './SearchAndFilter'
import {
  LearningCenterCategories,
  useLearningCenterContext,
} from '../Context/LearningCenterContext'
import { groupByCycleOrSemesterAndWeek } from './utils/learningCenterUtils'
import Accordion from '../Accordion/Accordion'
import {
  FilterFileType,
  LearningCenterTabs,
} from '../../utils/searchAndFilterEnums'
import { useTranslation } from 'react-i18next'
import LearningCenterContentCard from '../Card/LearningCenterContentCard'
import { FileType } from '../Elements/FileTypeResource'
import LoadingProgress from '../Elements/LoadingProgress'
import ViewMoreButton from '../Buttons/ViewMoreButton'
import WelcomeCenterTab from './WelcomeCenterTab'
import { Box, Typography, useTheme } from '@mui/material'
import { useScrollContext } from '../Context/ScrollContext'
import { useMountEffect } from '../../hooks/useMountEffect'
import { useLocation } from 'react-router'

interface LearningCenterTabContentProps {
  /** Available academicYears from useAcademicYears */
  academicYears: Array<{ year: number; name: string }>
  /** Selected year from useAcademicYears */
  selectedYear: number
  /** Notes whether fetchLearningCenterOptions is awaiting a response */
  loadingOptions?: boolean
  /** Notes whether fetchLearningCenterContent is awaiting a response */
  loadingContent?: boolean
}

const LearningCenterTabContent: React.FC<LearningCenterTabContentProps> = ({
  academicYears,
  selectedYear,
  loadingOptions = false,
  loadingContent = false,
}) => {
  const theme = useTheme()
  const { pathname } = useLocation()

  const {
    assets,
    selectedTabKey,
    searchQuery,
    contentType,
    category,
    includeOnlyFavorites,
    welcomeCenterContent,
  } = useLearningCenterContext()
  const { t } = useTranslation()

  const { updateScrollY, prevScrollY, updateScrollPathnameForSection } =
    useScrollContext()

  const isChallengeProgram = selectedTabKey === LearningCenterTabs.Challenge

  useMountEffect(() => updateScrollPathnameForSection(pathname))

  useLayoutEffect(() => {
    updateScrollY(prevScrollY)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Filtering by content type, program, and category (strand)
   * are all determined by fetchLearningCenterContent.
   *
   */
  const groupedAssets = groupByCycleOrSemesterAndWeek(
    assets,
    isChallengeProgram
  )

  const cyclesOrSemesters = Object.keys(groupedAssets)

  /**
   * If we are searching or filtering, display the content differently than
   * via accordions. Now it's via content cards.
   *
   * Filtering by challenge level has the added effect that, on load,
   * the state of the Challenge tab is always in search instead of semester.
   * Because of this, 'filtering' by level just switches the programType in
   * fetchLearningCenterContent instead of putting the page in a search state.
   */
  const isSearchingOrFiltering =
    !!searchQuery ||
    (!!category && category !== LearningCenterCategories.AllCategories) ||
    (!!contentType && contentType !== FilterFileType.AllTypes) ||
    includeOnlyFavorites

  /**
   * Leftover, arbitrary number to breakup the loading of content in search
   * state. This prevents a lag spike when rendering potentially hundreds of
   * cards for content. The View More button will maxLearningCenterContent to
   * currentLearningCenterMaxContentCount until it is greater than the total
   * assets.
   */
  const maxLearningCenterContent = 20
  const [
    currentLearningCenterMaxContentCount,
    setCurrentLearningCenterMaxContentCount,
  ] = useState(maxLearningCenterContent)

  const handleViewMoreLearningCenterContent = () => {
    setCurrentLearningCenterMaxContentCount(
      currentLearningCenterMaxContentCount + maxLearningCenterContent
    )
  }

  return (
    <Box
      sx={{
        margin: { xs: theme.spacing(3, 2), sm: theme.spacing(3) },
      }}
    >
      {/* 
        Only present the Search and Filter options when we have loaded
        fetchLearningCenterOptions. If the values are empty, then that is fine.
       */}
      {!loadingOptions && !welcomeCenterContent && (
        <SearchAndFilter
          academicYears={academicYears}
          selectedYear={selectedYear}
        />
      )}
      {/* While we are loading content, provide a loading spinner to indicate so */}
      {loadingContent ? (
        <LoadingProgress />
      ) : (
        <>
          {isSearchingOrFiltering ? (
            <>
              <Typography
                style={{
                  color: theme.palette.primary.main,
                  marginBottom: theme.spacing(4),
                  marginTop: theme.spacing(1.75),
                }}
                variant="h6"
                component="h2"
              >
                {assets.length === 1
                  ? `${assets.length} ${t(
                      'LearningCenter.SearchResult.ItemFoundIn',
                      'item found in'
                    )} ${selectedTabKey}`
                  : `${assets.length} ${t(
                      'LearningCenter.SearchResult.ItemsFoundIn',
                      'items found in'
                    )} ${selectedTabKey}`}
              </Typography>
              <Box
                display="flex"
                flexWrap="wrap"
                pt={1}
                pb={3}
                justifyContent={{ xs: 'center', sm: 'flex-start' }}
              >
                {assets
                  .slice(0, currentLearningCenterMaxContentCount)
                  .map((asset) => {
                    const cycleOrSemesterLabel = isChallengeProgram
                      ? `Semester ${asset.semester}`
                      : `Cycle ${asset.cycle}`

                    return (
                      <LearningCenterContentCard
                        key={asset.assetKey}
                        id={asset.assetKey}
                        fileType={asset.filetype as FileType}
                        title={
                          asset.title ||
                          t('LearningCenter.EmptyTitle', 'No title available')
                        }
                        description={
                          asset.description ||
                          t(
                            'LearningCenter.EmptyDescription',
                            'No description available'
                          )
                        }
                        subTitle={`${cycleOrSemesterLabel}, Week ${asset.week}`}
                        strand={asset.strand ?? ''}
                        isFavorite={asset.isFavorited ?? false}
                        viewOnly={asset.viewOnly ?? false}
                      />
                    )
                  })}
              </Box>
              {/* 
              Only present a View More button if currentLearningCenterMaxContentCount
              is less than the total number of assets. No sense in going beyond 
              that number when slicing.
               */}
              {assets.slice(0, currentLearningCenterMaxContentCount).length <
                assets.length && (
                <ViewMoreButton
                  handleViewMoreClick={handleViewMoreLearningCenterContent}
                />
              )}
            </>
          ) : !welcomeCenterContent ? (
            <>
              {cyclesOrSemesters.map((cycleOrSemester, index) => {
                const weekData = groupedAssets[cycleOrSemester]
                return (
                  <Accordion
                    key={cycleOrSemester}
                    sectionHeading={`${
                      isChallengeProgram ? 'Semester' : 'Cycle'
                    } ${cycleOrSemester}`}
                    cycleOrSemesterNumber={+cycleOrSemester}
                    accordionData={weekData}
                    positionCycle={index}
                  />
                )
              })}
            </>
          ) : (
            <WelcomeCenterTab content={assets} />
          )}
        </>
      )}
    </Box>
  )
}

export default LearningCenterTabContent
