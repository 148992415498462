/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  CashAmount,
  CashAmountFromJSON,
  CashAmountToJSON,
  LicensingFeePerRateBreakdown,
  LicensingFeePerRateBreakdownFromJSON,
  LicensingFeePerRateBreakdownToJSON,
} from './'

/**
 *
 * @export
 * @interface LicensingFee
 */
export interface LicensingFee {
  /**
   *
   * @type {string}
   * @memberof LicensingFee
   */
  code: LicensingFeeCodeEnum
  /**
   *
   * @type {Array<LicensingFeePerRateBreakdown>}
   * @memberof LicensingFee
   */
  breakdown: Array<LicensingFeePerRateBreakdown>
  /**
   *
   * @type {CashAmount}
   * @memberof LicensingFee
   */
  paid: CashAmount
  /**
   *
   * @type {CashAmount}
   * @memberof LicensingFee
   */
  owed: CashAmount
}

/**
 * @export
 * @enum {string}
 */
export enum LicensingFeeCodeEnum {
  License = 'license',
  NsfFee = 'nsfFee',
}

export function LicensingFeeFromJSON(json: any): LicensingFee {
  return LicensingFeeFromJSONTyped(json, false)
}

export function LicensingFeeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LicensingFee {
  if (json === undefined || json === null) {
    return json
  }
  return {
    code: json['code'],
    breakdown: (json['breakdown'] as Array<any>).map(
      LicensingFeePerRateBreakdownFromJSON
    ),
    paid: CashAmountFromJSON(json['paid']),
    owed: CashAmountFromJSON(json['owed']),
  }
}

export function LicensingFeeToJSON(value?: LicensingFee | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    code: value.code,
    breakdown: (value.breakdown as Array<any>).map(
      LicensingFeePerRateBreakdownToJSON
    ),
    paid: CashAmountToJSON(value.paid),
    owed: CashAmountToJSON(value.owed),
  }
}
