import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { Address } from '../../swagger'
import { addressApi, extractedErrorObject } from '../../api/swagger'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import ErrorAlert from '../Alerts/ErrorAlert'
import AddressForm from './AddressForm'
import AddressSelect from './AddressSelect'
import ActionButtons from '../Buttons/ActionButtons'
import InvalidAddress from './InvalidAddress'
import BasicModal from '../Modals/BasicModal'
import { TextButtonVariant } from '../Buttons/TextButton'
import { InvalidAddressVariant } from './InvalidAddress'
import { useCountryContext } from '../Context/CountryContext'

/** Value must be JSON parse-able which is why it is a string */
const initialSelectedValidAddress = 'null'

export const emptyAddressInformation = {
  locationName: '',
  streetAddress1: '',
  streetAddress2: '',
  city: '',
  state: '',
  zip: '',
  countryCode: '',
}

enum ModalStep {
  EnterAddress,
  Validating,
  SelectValidAddress,
  UnverifiedAddress,
  DeniedAddress,
}

const primaryActionButtonLabelMap: { [key: string]: ContainedButtonVariant } = {
  [ModalStep.EnterAddress]: ContainedButtonVariant.Save,
  [ModalStep.SelectValidAddress]: ContainedButtonVariant.Confirm,
  [ModalStep.UnverifiedAddress]: ContainedButtonVariant.UseAnyway,
  [ModalStep.DeniedAddress]: ContainedButtonVariant.EditAddress,
}

export interface AddressModalProps {
  disableGeocoding?: boolean
  isOpen: boolean
  onClose: () => void
  initialAddress?: Address
  onAddressConfirm: (address: Address) => void
}

const AddressModal: React.FC<AddressModalProps> = ({
  disableGeocoding = false,
  isOpen,
  onClose: onCloseFromProps,
  initialAddress,
  onAddressConfirm,
}) => {
  const { t } = useTranslation()

  const { countryOptions } = useCountryContext()

  const [step, setStep] = useState(ModalStep.EnterAddress)

  const navigatorLanguage = navigator.language
  const usLocale = 'en-US'

  /**
   * Default option for country ought to be US when adding a location
   * where no fields are currently filled.
   */
  const countryOptionsDefault =
    countryOptions.length > 0 &&
    navigatorLanguage.toLowerCase() === usLocale.toLowerCase() &&
    !!initialAddress?.countryCode
      ? initialAddress.countryCode
      : 'US'

  /** Store reference to initial values so when user clicks 'Cancel' we can reset the text inputs. */
  const initialAddressInformation = useRef<Address>({
    /** Use empty address information */
    ...emptyAddressInformation,
    /** Override with provided address information */
    ...initialAddress,
    /**
     * Use the default country option determined by navigator
     * language and the existence of a country code prior
     */
    countryCode: countryOptionsDefault,
  })

  const [addressInformation, setAddressInformation] = useState<Address>(
    initialAddressInformation.current
  )

  const [validAddresses, setValidAddresses] = useState<Address[]>([])
  const [error, setError] = useState('')

  /** Store a stringified version of the address in state to work with radio selection. */
  const [selectedValidAddress, setSelectedValidAddress] = React.useState(
    initialSelectedValidAddress
  )

  /** Use this variable for the actual address obj. */
  const selectedValidAddressObj = JSON.parse(selectedValidAddress)

  /**
   * Reset input fields, selections, and modal step to initial value
   */
  const resetModalState = () => {
    setAddressInformation(initialAddressInformation.current)
    setSelectedValidAddress(initialSelectedValidAddress)
    setError('')
    setStep(ModalStep.EnterAddress)
  }

  const backToFirstStep = () => {
    setStep(ModalStep.EnterAddress)
    setSelectedValidAddress(initialSelectedValidAddress)
  }

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    /** We trim the input value to account for user mistake. */
    setAddressInformation({
      ...addressInformation,
      [event.target.name]: event.target.value.trim(),
    })
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    /** Clear state field when country changes to avoid MUI warning. */
    if (event.target.name === 'countryCode') {
      setAddressInformation({
        ...addressInformation,
        countryCode: event.target.value,
        state: '',
      })
      return
    }
    setAddressInformation({
      ...addressInformation,
      [event.target.name]: event.target.value,
    })
  }

  const handleSelectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValidAddress(event.target.value)
  }

  const handleFormSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault()
    switch (step) {
      case ModalStep.EnterAddress:
        setError('')
        validateAddress()
        break
      case ModalStep.SelectValidAddress:
        confirmAddress(selectedValidAddressObj)
        break
      case ModalStep.UnverifiedAddress:
        setError('')
        geocodeAddress()
        break
      case ModalStep.DeniedAddress:
        backToFirstStep()
        break
    }
  }

  const confirmAddress = (value: Address) => {
    /** Ensures that we always return all 7 address fields, even if there is no address line 2 */
    onAddressConfirm({ ...value, streetAddress2: value.streetAddress2 ?? '' })
    onCloseFromProps()
  }

  const validateAddress = async () => {
    setStep(ModalStep.Validating)
    try {
      const result = await addressApi.validateAddress({
        body: addressInformation,
        disableGeocoding,
      })
      if (result.length === 0) {
        setStep(ModalStep.UnverifiedAddress)
        return
      }
      /**
       * If we only have 1 valid result and it matches what the
       * user typed then skip the SelectValidAddress step
       */
      if (result.length === 1) {
        const validatedAddress = result[0]
        const isExactMatch =
          validatedAddress.streetAddress1 ===
            addressInformation.streetAddress1 &&
          (validatedAddress.streetAddress2 ?? '') ===
            (addressInformation.streetAddress2 ?? '') &&
          validatedAddress.city === addressInformation.city &&
          validatedAddress.state === addressInformation.state &&
          validatedAddress.countryCode === addressInformation.countryCode &&
          validatedAddress.zip === addressInformation.zip

        if (isExactMatch) {
          confirmAddress(validatedAddress)
          return
        }
      }
      setValidAddresses(result)
      setStep(ModalStep.SelectValidAddress)
    } catch (err) {
      const errorObject = (await extractedErrorObject(err)) ?? {
        code: 'UnknownError',
        message:
          (err as unknown as Error).message ??
          'Failed to validate address at this time.',
      }
      setError(errorObject.message)
      setStep(ModalStep.EnterAddress)
    }
  }

  const geocodeAddress = async () => {
    setStep(ModalStep.Validating)
    try {
      const result = await addressApi.validateAddress({
        body: addressInformation,
        disableAddressVerification: true,
      })
      if (result.length === 0) {
        setStep(ModalStep.DeniedAddress)
        return
      }
      confirmAddress(result[0])
    } catch (err) {
      const errorObject = (await extractedErrorObject(err)) ?? {
        code: 'UnknownError',
        message:
          (err as unknown as Error).message ??
          'Failed to validate address at this time.',
      }
      setError(errorObject.message)
      setStep(ModalStep.UnverifiedAddress)
    }
  }

  const { streetAddress1, city, state, countryCode } = addressInformation
  const requiredValuesEmpty = !streetAddress1 || !city || !state || !countryCode
  const disablePrimaryButton =
    (step === ModalStep.EnterAddress && requiredValuesEmpty) ||
    (step === ModalStep.SelectValidAddress && !selectedValidAddressObj)

  return (
    <BasicModal
      isOpen={isOpen}
      dialogTitle={t('Address.Title', 'Location')}
      afterClose={resetModalState}
      handleFormSubmit={handleFormSubmit}
      labelledBy="address-form-title"
      maxWidth="md"
      dialogContent={
        <>
          {error && (
            <Box mt={-2}>
              <ErrorAlert error={error} />
            </Box>
          )}

          {step === ModalStep.EnterAddress && countryOptions.length > 0 && (
            <AddressForm
              addressInformation={addressInformation}
              onInputChange={handleInputChange}
              onInputBlur={handleInputBlur}
              countryOptions={countryOptions}
            />
          )}
          {step === ModalStep.Validating && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={200}
            >
              <CircularProgress />
            </Box>
          )}
          {step === ModalStep.SelectValidAddress && (
            <AddressSelect
              enteredAddress={addressInformation}
              validAddresses={validAddresses}
              selection={selectedValidAddress}
              onSelectionChange={handleSelectionChange}
              onEditAddressClick={backToFirstStep}
            />
          )}
          {step === ModalStep.UnverifiedAddress && (
            <InvalidAddress
              enteredAddress={addressInformation}
              variant={InvalidAddressVariant.Unverified}
              onEditAddressClick={backToFirstStep}
            />
          )}
          {step === ModalStep.DeniedAddress && (
            <InvalidAddress
              enteredAddress={addressInformation}
              variant={InvalidAddressVariant.Denied}
            />
          )}
        </>
      }
      dialogActions={
        <ActionButtons
          hide={step === ModalStep.Validating}
          primaryButtonLabel={primaryActionButtonLabelMap[step]}
          disablePrimaryButton={disablePrimaryButton}
          secondaryButtonLabel={TextButtonVariant.Cancel}
          secondaryClick={onCloseFromProps}
          fullWidthButtons={false}
        />
      }
    />
  )
}

export default AddressModal
