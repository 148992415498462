/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface Documents
 */
export interface Documents {
  /**
   *
   * @type {string}
   * @memberof Documents
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof Documents
   */
  label?: string
  /**
   *
   * @type {string}
   * @memberof Documents
   */
  numPages?: string
  /**
   *
   * @type {string}
   * @memberof Documents
   */
  mimeType?: string
  /**
   *
   * @type {string}
   * @memberof Documents
   */
  name?: string
}

export function DocumentsFromJSON(json: any): Documents {
  return DocumentsFromJSONTyped(json, false)
}

export function DocumentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Documents {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    label: !exists(json, 'label') ? undefined : json['label'],
    numPages: !exists(json, 'numPages') ? undefined : json['numPages'],
    mimeType: !exists(json, 'mimeType') ? undefined : json['mimeType'],
    name: !exists(json, 'name') ? undefined : json['name'],
  }
}

export function DocumentsToJSON(value?: Documents | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    label: value.label,
    numPages: value.numPages,
    mimeType: value.mimeType,
    name: value.name,
  }
}
