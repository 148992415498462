import React from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import OutlinedButton, { OutlinedButtonVariant } from './OutlinedButton'

interface ActionButtonsProps {
  /**
   * The label for the primary button.
   * Must be an enum value of {@link ContainedButtonVariant}.
   */
  primaryButtonLabel?: ContainedButtonVariant
  /**
   * The label for the secondary button.
   * Must be an enum value of {@link TextButtonVariant, @link ContainedButtonVariant or @link OutlinedButtonVariant}.
   */
  secondaryButtonLabel?:
    | TextButtonVariant
    | ContainedButtonVariant
    | OutlinedButtonVariant
  /**
   * The label for the tertiary button.
   * Must be an enum value of {@link TextButtonVariant or @link ContainedButtonVariant}.
   */
  tertiaryButtonLabel?: TextButtonVariant | ContainedButtonVariant
  /**
   * The label for the quaternary button.
   * Must be an enum value of {@link TextButtonVariant}.
   */
  quaternaryButtonLabel?: TextButtonVariant
  /**
   * If `true`, all buttons are hidden.
   * @default false
   */
  hide?: boolean
  /**
   * If `true`, all buttons are stacked vertically regardless of screen size.
   * @default false
   */
  alwaysStack?: boolean
  /**
   * If `true`, disables the primary button.
   * @default false
   */
  disablePrimaryButton?: boolean
  /**
   * If `true`, disables the secondary button.
   * @default false
   */
  disableSecondaryButton?: boolean
  /**
   * If `true`, all buttons evenly take up the width of the modal.
   * Only affects medium screen sizes and up.
   * @default true
   */
  fullWidthButtons?: boolean
  /**
   * The click handler function for the secondary button.
   */
  secondaryClick?: () => void
  /**
   * The click handler function for the tertiary button.
   */
  tertiaryClick?: () => void
  /**
   * The click handler function for the tertiary button.
   */
  quaternaryClick?: () => void
  /**
   *  A boolean to determine if we use the BaseButton component for async behavior or the button within ContainedButton
   *  THIS IS A TEMPORARY FIX SO WE DON"T HAVE TO BREAK ALL BUTTONS AT ONCE. The idea behind this boolean is to slowly
   *  transition buttons to the async behavior in BaseButton without a massive overhaul. This will eventually be removed
   *  so DO NOT based anything on this other than the ternary, for now.
   */
  useBaseButton?: boolean
  /**
   * LoadingId of the primary button. This will be the operationId of the api call being made
   * concatenated with the Component's name. This is optional only now because we are using useBaseButton.
   * FIXME: When we no longer need base button, require this property.
   *
   * e.g. If this is from AuthFormCard calling the signin endpoint the loadingId will be
   *
   *    signingAuthFormCard
   */
  primaryButtonLoadingId?: string
}

/**
 * A utility component used to display up to three buttons in a modal.
 * Buttons are displayed stacked vertically on mobile and side-by-side on medium screen sizes and up.
 * The primary button's click handler function must be defined as the modal's handleFormSubmit prop.
 */

const ActionButtons: React.FC<ActionButtonsProps> = ({
  primaryButtonLabel,
  secondaryButtonLabel,
  tertiaryButtonLabel,
  quaternaryButtonLabel,
  hide = false,
  alwaysStack = false,
  disablePrimaryButton = false,
  disableSecondaryButton = false,
  fullWidthButtons = true,
  secondaryClick,
  tertiaryClick,
  quaternaryClick,
  useBaseButton = false,
  primaryButtonLoadingId,
}) => {
  const showOnDesktop = useShowOnDesktop()
  const theme = useTheme()

  const baseProps = {
    css: {
      padding: theme.spacing(1, 3),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: theme.spacing(1),
      },
      ...(alwaysStack && {
        marginTop: theme.spacing(1),
      }),
    },
    fullWidth: fullWidthButtons,
  }

  const QuaternaryButton = !!quaternaryButtonLabel && (
    <TextButton
      id="quaternary"
      {...baseProps}
      variant={quaternaryButtonLabel}
      onClick={quaternaryClick}
    />
  )

  const tertiaryButtonProps = {
    ...baseProps,
    id: 'tertiary',
    onClick: tertiaryClick,
  }

  const TertiaryButton = () => {
    if (!tertiaryButtonLabel) {
      return undefined
    }

    if (
      Object.values(TextButtonVariant).includes(
        tertiaryButtonLabel as TextButtonVariant
      )
    ) {
      return (
        <TextButton
          {...tertiaryButtonProps}
          variant={tertiaryButtonLabel as TextButtonVariant}
        />
      )
    }

    return (
      <ContainedButton
        {...tertiaryButtonProps}
        variant={tertiaryButtonLabel as ContainedButtonVariant}
        useBaseButton={useBaseButton}
        loadingId={primaryButtonLoadingId}
      />
    )
  }

  const secondaryButtonProps = {
    ...baseProps,
    id: 'secondary',
    onClick: secondaryClick,
    disabled: disableSecondaryButton,
  }

  const SecondaryButton = () => {
    if (!secondaryButtonLabel) {
      return undefined
    }

    if (
      Object.values(TextButtonVariant).includes(
        secondaryButtonLabel as TextButtonVariant
      )
    ) {
      return (
        <TextButton
          {...secondaryButtonProps}
          variant={secondaryButtonLabel as TextButtonVariant}
        />
      )
    } else if (
      Object.values(ContainedButtonVariant).includes(
        secondaryButtonLabel as ContainedButtonVariant
      )
    ) {
      return (
        <ContainedButton
          {...secondaryButtonProps}
          variant={secondaryButtonLabel as ContainedButtonVariant}
          useBaseButton={useBaseButton}
          loadingId={primaryButtonLoadingId}
        />
      )
    } else {
      return (
        <OutlinedButton
          {...secondaryButtonProps}
          variant={secondaryButtonLabel as OutlinedButtonVariant}
        />
      )
    }
  }

  const PrimaryButton = () => {
    if (!primaryButtonLabel) {
      return undefined
    }

    return (
      <ContainedButton
        id="primary"
        type="submit"
        disabled={disablePrimaryButton}
        css={{
          margin: 0,
          padding: theme.spacing(1, 3),
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
        }}
        variant={primaryButtonLabel}
        fullWidth={fullWidthButtons}
        useBaseButton={useBaseButton}
        loadingId={primaryButtonLoadingId}
      />
    )
  }

  const ButtonsInOrder = (
    <React.Fragment>
      {PrimaryButton()}
      {SecondaryButton()}
      {TertiaryButton()}
      {QuaternaryButton}
    </React.Fragment>
  )

  const ButtonsInReverseOrder = (
    <React.Fragment>
      {QuaternaryButton}
      {TertiaryButton()}
      {SecondaryButton()}
      {PrimaryButton()}
    </React.Fragment>
  )

  return (
    <Box
      px={2}
      py={1}
      width="100%"
      display="flex"
      flexDirection={{
        xs: 'column',
        md: alwaysStack ? 'column' : 'row',
      }}
      justifyContent="flex-end"
      alignItems="center"
      sx={{
        [theme.breakpoints.down('md')]: {
          width: '100%',
          gap: theme.spacing(1.5),
        },
      }}
    >
      {!hide &&
        (showOnDesktop && !alwaysStack
          ? ButtonsInReverseOrder
          : ButtonsInOrder)}
    </Box>
  )
}

export default ActionButtons
