import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import TableFooterPagination from '../Pagination/TableFooterPagination'
import { useTranslation } from 'react-i18next'
import TableHeaders from '../Interfaces/TableHeaders'
import TableHeader from '../Table/TableHeader'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import CardFormHeader from '../Card/CardFormHeader'
import AmountDue from '../Account/Billing/AmountDue'
import getLocaleCurrencyForAmount from '../../utils/getLocaleCurrencyForAmount'
import { CashAmount } from '../../swagger'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../Buttons/OutlinedButton'
import { dateToSlashString } from '../../utils/dateUtility'
import { useAuth } from '../Routes/AuthProvider'

export interface TuitionBillingRow {
  date: Date
  transaction: string
  program: string
  description: string
  amount: CashAmount
  handleClick: () => void
}

interface TuitionBillingTableProps {
  tuitionBillingData: TuitionBillingRow[]
  amountDue: CashAmount
  primaryButton: React.ReactNode
  secondaryButtonProps: {
    id: string
    variant: OutlinedButtonVariant
    onClick: () => void
  }[]
  ariaLabelledBy?: string
}

/**
 * Shows a Program Director/Tutor their history of invoices sent to families and any payments received.
 * Table located in UI under Family > Family Profile.
 */
const TuitionBillingTable: React.FC<TuitionBillingTableProps> = ({
  tuitionBillingData,
  amountDue,
  primaryButton,
  secondaryButtonProps,
  ariaLabelledBy,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const pageSize = 10
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSize)
  const { permissionAbility } = useAuth()

  const canSendTuitionInvoice = permissionAbility.can(
    'sendTuitionInvoice',
    'Payment'
  )

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const tableHeaders: TableHeaders[] = [
    {
      label: t('TuitionBillingTable.TableHeader.Date', 'Date'),
      align: 'left',
    },
    {
      label: t('TuitionBillingTable.TableHeader.Transaction', 'Transaction'),
      align: 'left',
    },
    {
      label: t('TuitionBillingTable.TableHeader.Program', 'Program(s)'),
      align: 'left',
    },
    {
      label: t('TuitionBillingTable.TableHeader.Description', 'Description'),
      align: 'left',
    },
    {
      label: t('TuitionBillingTable.TableHeader.Amount', 'Amount'),
      align: 'right',
    },
    {
      label: '',
      htmlProps: {
        'aria-label': t(
          'TuitionBillingTable.TableHeader.ActionButtons',
          'Action Buttons'
        ),
      },
      align: 'right',
    },
  ]

  const EmptyTable = (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell colSpan={5}>
        <Typography
          component="p"
          variant="subtitle2"
          color={theme.palette.textOrIcon.subheader}
        >
          {t(
            'TuitionBillingTable.Empty',
            'No activity. Log any payments that have been made.'
          )}
        </Typography>
      </TableCell>
    </TableRow>
  )

  const isPaginationEnabled = tuitionBillingData.length > pageSize

  const tableData =
    rowsPerPage > 0
      ? tuitionBillingData.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : tuitionBillingData

  return (
    <Card>
      <Box mt={2.5} mx={2.5}>
        <CardFormHeader
          header={
            <Box>
              <AmountDue
                amountDue={amountDue.amount}
                currencyCode={amountDue.currencyCode}
              />
            </Box>
          }
          buttons={
            <>
              {secondaryButtonProps.map((it) => (
                <OutlinedButton key={it.id} {...it} />
              ))}
              {primaryButton}
            </>
          }
        />
      </Box>
      <TableContainer>
        <Table aria-labelledby={ariaLabelledBy}>
          <TableHead>
            <TableHeader tableHeaders={tableHeaders} />
          </TableHead>
          <TableBody>
            {tuitionBillingData.length > 0
              ? tableData.map(
                  (
                    {
                      date,
                      transaction,
                      program,
                      description,
                      amount,
                      handleClick,
                    },
                    index
                  ) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          ...(!isPaginationEnabled && {
                            '&:last-child td, &:last-child th': { border: 0 },
                          }),
                        }}
                      >
                        <TableCell
                          color={theme.palette.primary.main}
                          scope="row"
                          variant="head"
                        >
                          <Typography variant="body1">
                            {`${dateToSlashString(date)}, ${date.toLocaleString(
                              undefined,
                              {
                                timeStyle: 'short',
                              } as Intl.DateTimeFormatOptions
                            )}`}
                          </Typography>
                        </TableCell>
                        <TableCell color={theme.palette.primary.main}>
                          <Typography variant="body1">{transaction}</Typography>
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: 250,
                            color: theme.palette.primary.main,
                          }}
                        >
                          <Typography variant="body1">{program}</Typography>
                        </TableCell>
                        <TableCell color={theme.palette.primary.main}>
                          <Typography variant="body1">{description}</Typography>
                        </TableCell>
                        <TableCell
                          color={theme.palette.primary.main}
                          align="right"
                        >
                          <Typography variant="body1">
                            {getLocaleCurrencyForAmount(
                              amount.amount,
                              amount.currencyCode
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <TextButton
                            id="view"
                            variant={TextButtonVariant.View}
                            onClick={handleClick}
                            disabled={!canSendTuitionInvoice}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  }
                )
              : EmptyTable}
          </TableBody>
          {tuitionBillingData.length > pageSize && (
            <TableFooterPagination
              label={t('TuitionBillingTable.TablePagination.ViewAll', 'All')}
              count={tuitionBillingData.length}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              colSpan={tableHeaders.length}
              useDefaultPageSize={false}
            />
          )}
        </Table>
      </TableContainer>
    </Card>
  )
}

export default TuitionBillingTable
