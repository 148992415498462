/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { BillingInfo, BillingInfoFromJSON, BillingInfoToJSON } from './'

/**
 *
 * @export
 * @interface FetchFamilyTuitionBillingResponse
 */
export interface FetchFamilyTuitionBillingResponse {
  /**
   * List for encapsulating tuition payments and invoices for directors and tutors.
   * @type {Array<BillingInfo>}
   * @memberof FetchFamilyTuitionBillingResponse
   */
  billingList: Array<BillingInfo>
}

export function FetchFamilyTuitionBillingResponseFromJSON(
  json: any
): FetchFamilyTuitionBillingResponse {
  return FetchFamilyTuitionBillingResponseFromJSONTyped(json, false)
}

export function FetchFamilyTuitionBillingResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FetchFamilyTuitionBillingResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    billingList: (json['billingList'] as Array<any>).map(BillingInfoFromJSON),
  }
}

export function FetchFamilyTuitionBillingResponseToJSON(
  value?: FetchFamilyTuitionBillingResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    billingList: (value.billingList as Array<any>).map(BillingInfoToJSON),
  }
}
