import {
  Box,
  Card,
  CardContent,
  Divider,
  Paper,
  Switch,
  Typography,
  useTheme,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Header, { HeaderVariant } from '../Elements/Header'
import CardFormHeader from './CardFormHeader'
import { UserProfile } from '../../swagger'

// This is used to clean up the typing later on. The fields this is used to
// index are always going to be booleans.
type UserProfileWithPrivacySettings = { [k: string]: boolean }

interface ProfileVisibilityCardProps {
  user: UserProfile
  disableSwitches: boolean
  setUser: (user: UserProfile) => void
}

const ProfileVisibilityCard: React.FC<ProfileVisibilityCardProps> = ({
  user,
  disableSwitches,
  setUser,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const visibilityOptions = [
    {
      label: t(
        'ProfileVisibilityCard.Option.Profile',
        'Share My Profile With My Community'
      ),
      name: 'shareProfile',
      ariaLabel: 'share-profile',
      isChecked: !user.isProfilePrivate, // We flip these because the backend marks if they are private, while here we're marking if they should be shown.
      propName: 'isProfilePrivate',
    },
    {
      label: t(
        'ProfileVisibilityCard.Option.PhoneNumber',
        'Share my Phone Number'
      ),
      name: 'sharePhoneNumber',
      ariaLabel: 'share-phone-number',
      isChecked: !user.isPhoneNumberPrivate,
      propName: 'isPhoneNumberPrivate',
    },
    {
      label: t(
        'ProfileVisibilityCard.Option.LoginEmail',
        'Share my Login Email'
      ),
      name: 'shareLoginEmail',
      ariaLabel: 'share-login-email',
      isChecked: !user.isLoginEmailPrivate,
      propName: 'isLoginEmailPrivate',
    },
  ]

  let eventNameToPropName: { [k: string]: string } = {}
  visibilityOptions.forEach((option) => {
    eventNameToPropName = {
      ...eventNameToPropName,
      [option.name]: option.propName,
    }
  })

  const handlePrivacySwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const propName = eventNameToPropName[event.target.name]
    setUser({
      ...user,
      [propName]: !(user as unknown as UserProfileWithPrivacySettings)[
        propName
      ], // using the type here helps clean it up.
    })
  }

  return (
    <Card
      component={Paper}
      sx={{
        margin: theme.spacing(3, 0),
        [theme.breakpoints.down('md')]: {
          marginBottom: theme.spacing(10),
        },
      }}
    >
      <CardFormHeader
        header={
          <Box display="flex" flexDirection="column" pl={4} pt={3}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', lg: 'row' }}
              alignItems={{ xs: 'left', lg: 'center' }}
              justifyItems="middle"
              pb={2}
            >
              <Header
                id="profileVisibilityHeader"
                headerName={t(
                  'ProfileVisibilityCard.Title',
                  'Profile Visibility'
                )}
                component="h2"
                variant={HeaderVariant.Card}
                css={{
                  padding: 0,
                }}
              />
              <Box display="flex" alignItems="center" pl={{ xs: 0, lg: 4 }}>
                <Switch
                  name={visibilityOptions[0].name}
                  checked={
                    !(user as unknown as UserProfileWithPrivacySettings)[
                      visibilityOptions[0].propName
                    ] as boolean
                  }
                  onChange={handlePrivacySwitchChange}
                  inputProps={{ 'aria-label': visibilityOptions[0].ariaLabel }}
                  disabled={disableSwitches}
                  color="secondary"
                />
                <Typography
                  component="p"
                  variant="subtitle2"
                  style={{
                    textTransform: 'uppercase',
                    color: theme.palette.textOrIcon.subheader,
                  }}
                >
                  {visibilityOptions[0].label}
                </Typography>
              </Box>
            </Box>
            <Typography
              component="p"
              variant="body2"
              color={theme.palette.textOrIcon.subheader}
            >
              {t(
                'ProfileVisibilityCard.Subheader',
                'Your hidden profile information is never shared and visible only to you and your CC leadership.'
              )}
            </Typography>
          </Box>
        }
      />
      <CardContent
        sx={{
          paddingLeft: 0,
          marginLeft: 0,
          paddingRight: 0,
          marginRight: 0,
        }}
      >
        {visibilityOptions.map(
          (option, index) =>
            index !== 0 && (
              <Box key={option.name}>
                <Divider />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={{ xs: 'space-between', lg: 'left' }}
                  pl={4}
                  py={2}
                  pr={{ xs: 2, lg: 0 }}
                >
                  <Typography component="p" variant="subtitle2">
                    {option.label}
                  </Typography>
                  <Switch
                    name={option.name}
                    checked={
                      !(user as unknown as UserProfileWithPrivacySettings)[
                        option.propName
                      ] as boolean
                    }
                    onChange={handlePrivacySwitchChange}
                    inputProps={{ 'aria-label': option.ariaLabel }}
                    disabled={disableSwitches}
                    color="secondary"
                  />
                </Box>
              </Box>
            )
        )}
      </CardContent>
    </Card>
  )
}

export default ProfileVisibilityCard
