import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react'
import {
  extractedErrorObject,
  UserCommunity,
  UserProfile as UserInfo,
} from './api/swagger'
import { MenuOption } from './components/Menus/DropDown'
import useLoadingContext from './hooks/useLoadingContext'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from './components/Context/SnackbarContext'
import { fetchUserRolesForCommunities } from './api/user'
import { useTranslation } from 'react-i18next'
import { useLoadingIds } from './hooks/useLoadingIds'
import { useMountEffect } from './hooks/useMountEffect'
import { LoadingContext } from './components/Context/LoadingContext'
import { useAuth } from './components/Routes/AuthProvider'

const defaultValue = {
  user: undefined as UserInfo | undefined,
  setUser: (user?: UserInfo) => {
    console.warn(
      `The default value of UserContext.setUser was called with user=${user}. Did you forget to install a UserProvider?`
    )
  },
  roles: [] as MenuOption[],
  uniqueUserRoles: [] as UserCommunity[],
  selectedActorRole: {} as MenuOption,
}

export const useUser = (): typeof defaultValue => useContext(UserContext)

export const UserContext = createContext(defaultValue)
UserContext.displayName = 'UserContext'

interface UserProviderProps extends PropsWithChildren {
  initialUser?: UserInfo
}

export const UserProvider: React.FunctionComponent<UserProviderProps> = ({
  initialUser,
  children,
}) => {
  const { t } = useTranslation()
  const availableLoadingIds = useLoadingIds()

  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()

  const [actorMenuOptions, setActorMenuOptions] = useState<MenuOption[]>([])
  const [selectedActorMenuOption, setSelectedActorMenuOption] =
    useState<MenuOption>()
  const [uniqueUserRoles, setUniqueUserRoles] = useState(Array<UserCommunity>())

  const errorMessage = t(
    'UserContext.Error',
    'An error occurred while attempting load actors'
  )

  const fetchCurrentRoles = async () => {
    try {
      /** FIXME: Make the calls to roles within the UserContext */
      const fetchedRolesForCommunities = await fetchUserRolesForCommunities()

      let uniqueUserRoles: UserCommunity[] = fetchedRolesForCommunities

      const userRoles: UserCommunity[] = []
      const parentCommunities: UserCommunity[] = []

      uniqueUserRoles.forEach((userCommunity) => {
        if (userCommunity.actorKey === undefined) {
          parentCommunities.push(userCommunity)
        } else {
          userRoles.push(userCommunity)
        }
      })

      uniqueUserRoles = [
        ...parentCommunities,
        ...new Map<number | undefined, UserCommunity>(
          userRoles.map((role) => [role.actorKey, role])
        ).values(),
      ]

      const buildActorMenuOption = (
        userRole: UserCommunity & { actorKey: number }
      ): MenuOption => {
        const parts = [userRole.role]
        let yearsRoleIsValid = ''

        const duplicateRoles = uniqueUserRoles.filter(
          (it) => it.role === userRole.role
        )

        if (duplicateRoles.length > 1) {
          const supervisorFullName = userRole.supervisorFirstName
            ? `${userRole.supervisorFirstName} ${
                userRole.supervisorLastName?.substring(0, 1) ?? ''
              }.`
            : ''

          if (supervisorFullName) {
            parts.push(supervisorFullName)
          }

          const duplicateRoleAndSupervisor = duplicateRoles.filter(
            (it) =>
              it.supervisorFirstName === userRole.supervisorFirstName &&
              it.supervisorLastName === userRole.supervisorLastName
          )

          if (duplicateRoleAndSupervisor.length > 1) {
            if (userRole.region) {
              parts.push(userRole.region)
            }

            const duplicateRoleAndSupervisorAndRegion =
              duplicateRoleAndSupervisor.filter(
                (it) => it.region === userRole.region
              )
            if (duplicateRoleAndSupervisorAndRegion.length > 1) {
              parts.push(`${userRole.actorKey}`)
            }

            if (userRole.validFrom && userRole.validTo) {
              yearsRoleIsValid = `${userRole.validFrom.getFullYear()}-${userRole.validTo.getFullYear()}`
            }
          }
        }

        return {
          name: parts.join(' / '),
          id: userRole.actorKey,
          subtitle: yearsRoleIsValid,
        }
      }

      const roles = uniqueUserRoles.flatMap((it) => {
        if (it.actorKey) {
          return [
            // SAFETY: we know that we have a non null actorKey with the if statement we just did.
            buildActorMenuOption(it as UserCommunity & { actorKey: number }),
          ]
        }

        return []
      })

      roles.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))

      /**
       * Always give the parent role since we will login the user with their
       * lastSelectedActorKey and default to 'parent' if there was none.
       *
       * The userRoles endpoint will only return parent roles if there are
       * students/enrollments for the userKey.
       *
       * Roger is OK with this change.
       */
      roles.push({
        name: t('ActorSelector.Role.Parent', 'Parent'),
        id: 'parent',
      } as MenuOption)

      setActorMenuOptions(roles)
      setUniqueUserRoles(uniqueUserRoles)
    } catch (error) {
      const errorObj = (await extractedErrorObject(error)) ?? {
        code: 'Unknown',
        message: (error as unknown as Error).message ?? errorMessage,
      }
      setSnackbarMessage?.(errorObj.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarState?.(true)
    }
  }

  useLoadingContext({
    asyncFunction: fetchCurrentRoles,
    loadingId: availableLoadingIds.UserContext.fetchUserCommunities,
  })

  const { addLoadingIds } = React.useContext(LoadingContext)

  useMountEffect(() => {
    addLoadingIds([availableLoadingIds.UserContext.fetchUserCommunities])
  })

  const getActorById = useCallback(
    (value: number | 'parent') =>
      actorMenuOptions.find((item) => item.id === value),
    [actorMenuOptions]
  )

  const auth = useAuth()

  useEffect(() => {
    setSelectedActorMenuOption(
      getActorById(auth.userDetails.actingAs ?? 'parent')
    )
  }, [actorMenuOptions, auth.userDetails.actingAs, getActorById])

  const [userContextValue, setUserContextValue] = useState(() => {
    const setUser = (user?: UserInfo) => {
      // If torn down (which pretty much only happens in testing), actually calling setState will make React barf about "Warning: Can't perform a React state update on an unmounted component." plus another 10 lines or so of text. So, skip that.

      setUserContextValue((userContextValue) => {
        const isUnchanged = Object.is(user, userContextValue.user)
        return isUnchanged ? userContextValue : { ...userContextValue, user }
      })
    }

    return {
      user: initialUser,
      setUser,
    }
  })

  return (
    <UserContext.Provider
      value={{
        ...userContextValue,
        roles: actorMenuOptions,
        uniqueUserRoles,
        selectedActorRole: selectedActorMenuOption as MenuOption,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
