/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  SendTuitionInvoiceRequestBody,
  SendTuitionInvoiceRequestBodyToJSON,
  TuitionInvoice,
  TuitionInvoiceFromJSON,
} from '../models'
import * as runtime from '../runtime'

export interface FetchTuitionInvoiceRequest
  extends runtime.BaseRequestParameters {
  parentUserKey: number
  isTutorInvoice?: boolean
  billingUserKey?: number
  invoiceKey?: number
}

export interface SendTuitionInvoiceRequest
  extends runtime.BaseRequestParameters {
  parentUserKey: number
  billingUserKey?: number
  body?: SendTuitionInvoiceRequestBody
}

/**
 *
 */
export class InvoicesApi extends runtime.BaseAPI {
  /**
   * Fetches relevant data for a tuition invoice with fees for each enrollment, related discounts, and payments applied.    ## PERMISSIONS: - The requester, or someone in their downline, must have the following:  User/view[their team\'s], Program/view[their team\'s], Payment/sendTuitionInvoice[their team\'s] permission, else a 403 status code will be returned. - If the requester has an active  User/view[any], Program/view[any], and Payment/sendTuitionInvoice[any] permissions, they can fetch an invoice for any family.
   * Fetches relevant data for all tuition invoice.
   */
  async fetchTuitionInvoiceRaw(
    requestParameters: FetchTuitionInvoiceRequest
  ): Promise<runtime.ApiResponse<TuitionInvoice>> {
    if (
      requestParameters.parentUserKey === null ||
      requestParameters.parentUserKey === undefined
    ) {
      throw new runtime.RequiredError(
        'parentUserKey',
        'Required parameter requestParameters.parentUserKey was null or undefined when calling fetchTuitionInvoice.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.isTutorInvoice !== undefined) {
      queryParameters['isTutorInvoice'] = requestParameters.isTutorInvoice
    }

    if (requestParameters.billingUserKey !== undefined) {
      queryParameters['billingUserKey'] = requestParameters.billingUserKey
    }

    if (requestParameters.invoiceKey !== undefined) {
      queryParameters['invoiceKey'] = requestParameters.invoiceKey
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/invoice/tuition/{parentUserKey}`.replace(
        `{${'parentUserKey'}}`,
        encodeURIComponent(String(requestParameters.parentUserKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TuitionInvoiceFromJSON(jsonValue)
    )
  }

  /**
   * Fetches relevant data for a tuition invoice with fees for each enrollment, related discounts, and payments applied.    ## PERMISSIONS: - The requester, or someone in their downline, must have the following:  User/view[their team\'s], Program/view[their team\'s], Payment/sendTuitionInvoice[their team\'s] permission, else a 403 status code will be returned. - If the requester has an active  User/view[any], Program/view[any], and Payment/sendTuitionInvoice[any] permissions, they can fetch an invoice for any family.
   * Fetches relevant data for all tuition invoice.
   */
  async fetchTuitionInvoice(
    requestParameters: FetchTuitionInvoiceRequest
  ): Promise<TuitionInvoice> {
    const response = await this.fetchTuitionInvoiceRaw(requestParameters)
    return await response.value()
  }

  /**
   * Sends a copy of the generated tuition invoice by email with fees for each enrollment, related discounts, and payments applied. **NOTE**: directorDiscounts provided in the request body are deprecated and will now be ignored. Discounts are now added prior to invoice generation.      ## PERMISSIONS: - The requester or someone in their downline must have the following permissions to send an invoice:  User/view[their team\'s], Program/view[their team\'s], Payment/sendTuitionInvoice[their team\'s], else a 403 status code will be returned. - If the requester has active permissions:  User/view[any], Program/view[any], and Payment/sendTuitionInvoice[any], they can send an invoice to any family.
   * Sends a copy of the generated tuition invoice by email to the family.
   */
  async sendTuitionInvoiceRaw(
    requestParameters: SendTuitionInvoiceRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.parentUserKey === null ||
      requestParameters.parentUserKey === undefined
    ) {
      throw new runtime.RequiredError(
        'parentUserKey',
        'Required parameter requestParameters.parentUserKey was null or undefined when calling sendTuitionInvoice.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.billingUserKey !== undefined) {
      queryParameters['billingUserKey'] = requestParameters.billingUserKey
    }

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/invoice/tuition/{parentUserKey}`.replace(
        `{${'parentUserKey'}}`,
        encodeURIComponent(String(requestParameters.parentUserKey))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: SendTuitionInvoiceRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Sends a copy of the generated tuition invoice by email with fees for each enrollment, related discounts, and payments applied. **NOTE**: directorDiscounts provided in the request body are deprecated and will now be ignored. Discounts are now added prior to invoice generation.      ## PERMISSIONS: - The requester or someone in their downline must have the following permissions to send an invoice:  User/view[their team\'s], Program/view[their team\'s], Payment/sendTuitionInvoice[their team\'s], else a 403 status code will be returned. - If the requester has active permissions:  User/view[any], Program/view[any], and Payment/sendTuitionInvoice[any], they can send an invoice to any family.
   * Sends a copy of the generated tuition invoice by email to the family.
   */
  async sendTuitionInvoice(
    requestParameters: SendTuitionInvoiceRequest
  ): Promise<void> {
    await this.sendTuitionInvoiceRaw(requestParameters)
  }
}
