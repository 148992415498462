/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { Permission, PermissionFromJSON, PermissionToJSON } from './'

/**
 *
 * @export
 * @interface DetailMeta
 */
export interface DetailMeta {
  /**
   * Permissions relevant to the model that express the actions the requesting user can take on those items. Just because someone can sometimes edit some Things, doesn’t mean they can edit this specific Thing.
   * @type {Array<Permission>}
   * @memberof DetailMeta
   */
  permissions?: Array<Permission>
}

export function DetailMetaFromJSON(json: any): DetailMeta {
  return DetailMetaFromJSONTyped(json, false)
}

export function DetailMetaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DetailMeta {
  if (json === undefined || json === null) {
    return json
  }
  return {
    permissions: !exists(json, 'permissions')
      ? undefined
      : (json['permissions'] as Array<any>).map(PermissionFromJSON),
  }
}

export function DetailMetaToJSON(value?: DetailMeta | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    permissions:
      value.permissions === undefined
        ? undefined
        : (value.permissions as Array<any>).map(PermissionToJSON),
  }
}
