import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import { useNavigate } from 'react-router'
import Logo from '../../Images/Branding/cc-logo-white.svg'
import LogoBlue from '../../Images/Branding/cc-logo-blue.svg'
import TableFooterPagination from '../Pagination/TableFooterPagination'
import { TFunction } from 'i18next'
import DropDown, { DropDownVariant } from '../Menus/DropDown'
import { FetchEventsResponse, OrderByDirection } from '../../swagger'
import { displayFormattedSlashDate } from '../../utils/displayFormattedDate'
import { createSpacetime, EventFilter } from './Events'
import TableHeader from '../Table/TableHeader'
import TableHeaders from '../Interfaces/TableHeaders'
import NoResultsFound from '../Table/NoResultsFound'
import { EventSortBy, EventSortOptions } from './EventEnums'
import { timezoneTitleCase } from '../../utils/timeFormatUtilities'
import { Box, styled, useTheme } from '@mui/material'
import LoadingProgress from '../Elements/LoadingProgress'

const EventSection = styled('section')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const EventTableBody = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

export const labelForSortOption = (
  sortOption: EventSortOptions,
  t: TFunction
): string => {
  switch (sortOption) {
    case EventSortOptions.EventName:
      return t('Team.SortOptions.EventName', 'Sort By: Event Name')
    case EventSortOptions.PostalCode:
      return t('Team.SortOptions.PostalCode', 'Sort By: Postal Code')
    case EventSortOptions.StartTime:
      return t('Team.SortOptions.StartTime', 'Sort By: Start Time')
    case EventSortOptions.CreatedBy:
      return t('Team.SortOptions.CreatedBy', 'Sort By: Created By')
    default:
      return t('Team.SortOptions.StartDate', 'Sort By: Start Date')
  }
}

const baseTen = 10

const EventSummaryTable: React.FC<{
  events: FetchEventsResponse[]
  isLoading: boolean
  pagination: boolean
  pageSize: number
  totalCount: number
  resetTablePagination: (newPage: number) => void
  tableFooterPage: number
  handleEventFilterChange: <K extends keyof EventFilter>(
    filter: K,
    value: EventFilter[K],
    isMobile?: boolean,
    sortValue?: string
  ) => void
}> = ({
  isLoading,
  events,
  pagination,
  pageSize,
  totalCount,
  resetTablePagination,
  tableFooterPage,
  handleEventFilterChange,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    handleEventFilterChange('page', newPage + 1)
    resetTablePagination(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleEventFilterChange('pageSize', parseInt(event.target.value, baseTen))
  }

  const toEventDetailsPage = (id: number, eventName: string) => {
    navigate(
      {
        pathname: `/events/event-details/${id}`,
      },
      {
        state: { eventName },
      }
    )
  }

  const sortByOrder = OrderByDirection.Asc
  const [sortByProperty, setSortByProperty] = useState([
    `${EventSortBy.None} ${sortByOrder}`,
  ])
  const [sortValue, setSortValue] = useState(EventSortBy.None)

  const handleDropDownSelection = (selection: string) => {
    if (selection.includes('Date')) {
      setSortByProperty([`${EventSortBy.StartDate} ${sortByOrder}`])
      setSortValue(EventSortBy.StartDate)
    } else if (selection.includes('Postal')) {
      setSortByProperty([`${EventSortBy.PostalCode} ${sortByOrder}`])
      setSortValue(EventSortBy.PostalCode)
    } else if (selection.includes('Name')) {
      setSortByProperty([`${EventSortBy.EventName} ${sortByOrder}`])
      setSortValue(EventSortBy.EventName)
    } else if (selection.includes('Time')) {
      setSortByProperty([`${EventSortBy.StartTime} ${sortByOrder}`])
      setSortValue(EventSortBy.StartTime)
    } else if (selection.includes('Created')) {
      setSortByProperty([`${EventSortBy.CreatedBy} ${sortByOrder}`])
      setSortValue(EventSortBy.CreatedBy)
    }
  }

  useEffect(() => {
    handleEventFilterChange('orderBy', sortByProperty, undefined, sortValue)
  }, [sortByProperty, handleEventFilterChange, sortValue])

  const tableHeaders: TableHeaders[] = [
    {
      label: '',
      align: 'left',
      id: 'eventIcon',
    },
    {
      label: t('Events.EventName.Header', 'Event Name'),
      align: 'left',
    },
    {
      label: t('Events.EventType.Header', 'Event Type'),
      align: 'left',
    },
    {
      label: t('Events.Date.Header', 'Date'),
      align: 'left',
    },
    {
      label: t('Events.Time.Header', 'Time'),
      align: 'left',
    },
    {
      label: t('Events.Attendees.Header', 'Attendees'),
      align: 'left',
    },
    {
      label: t('Events.CreatedBy.Header', 'Created By'),
      align: 'left',
    },
  ]

  if (isLoading) {
    return <LoadingProgress />
  }

  return (
    <EventSection aria-labelledby={t('Events.Section.AriaLabel', 'Events')}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          marginBottom: theme.spacing(2),
        }}
      >
        <Typography variant="h6" component={'h2'}>
          {t('Events.Header', 'Events')}
        </Typography>

        <DropDown
          id="sort"
          menuOptions={Object.values(EventSortOptions).map((option) => {
            return {
              name: labelForSortOption(option, t),
              id: option,
            }
          })}
          defaultValue={t('Events.SortOption.Sort', 'sort')}
          handleSelection={handleDropDownSelection}
          variant={DropDownVariant.SortAndFilter}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table aria-labelledby={t('Events.Table.AriaLabel', 'Events')}>
          <TableHead>
            <TableHeader tableHeaders={tableHeaders} />
          </TableHead>
          <TableBody>
            {events.length > 0 ? (
              events.map((row) => (
                <TableRow
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  key={row.eventKey}
                  onClick={() =>
                    toEventDetailsPage(row.eventKey as number, row.name)
                  }
                >
                  <EventTableBody style={{ width: 20 }}>
                    <img
                      src={row.isOnline ? LogoBlue : Logo}
                      alt={t('Events.Table.EventImage.AltText', 'Event Image')}
                      style={{
                        height: 40,
                        width: 40,
                        padding: theme.spacing(1),
                        ...(row.isOnline
                          ? {
                              background: theme.palette.customBackground.logo,
                            }
                          : {
                              background: theme.palette.primary.main,
                            }),
                      }}
                    />
                  </EventTableBody>
                  <EventTableBody>
                    <Typography variant="body1" component="p">
                      {row.name}
                    </Typography>
                  </EventTableBody>
                  <EventTableBody>
                    <Typography variant="body1" component="p">
                      {row.eventType}
                    </Typography>
                  </EventTableBody>
                  <EventTableBody>
                    <Typography variant="body1" component="p">
                      {displayFormattedSlashDate(
                        row.startDate.toISOString(),
                        row.endDate?.toISOString() ?? ''
                      )}
                    </Typography>
                  </EventTableBody>
                  <EventTableBody>
                    <Typography variant="body1" component="p">
                      {`${createSpacetime(row).time()} - ${createSpacetime(
                        row,
                        false
                      ).time()} ${timezoneTitleCase(row.timezone)}`}
                    </Typography>
                  </EventTableBody>
                  <EventTableBody>
                    <Typography variant="body1" component="p">
                      {row.attendees}
                    </Typography>
                  </EventTableBody>
                  <EventTableBody>
                    <Typography variant="body1" component="p">
                      {row.createdBy}
                    </Typography>
                  </EventTableBody>
                </TableRow>
              ))
            ) : (
              <NoResultsFound />
            )}
          </TableBody>
          {pagination && totalCount > pageSize && (
            <TableFooterPagination
              label={t('Events.TablePagination.ViewAll', 'All')}
              count={totalCount}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={tableFooterPage}
              rowsPerPage={pageSize}
              colSpan={tableHeaders.length}
            />
          )}
        </Table>
      </TableContainer>
    </EventSection>
  )
}

export default EventSummaryTable
