/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface StudentInfo
 */
export interface StudentInfo {
  /**
   * The student's unique identifier.
   * @type {number}
   * @memberof StudentInfo
   */
  studentKey: number
  /**
   * The student's first name
   * @type {string}
   * @memberof StudentInfo
   */
  studentName: string
  /**
   * The student's date of birth as yyyy-mm-dd in UTC
   * @type {string}
   * @memberof StudentInfo
   */
  studentDateOfBirth: string
  /**
   * The parent's last name.
   * @type {string}
   * @memberof StudentInfo
   */
  familyName: string
  /**
   * The parent's email address.
   * @type {string}
   * @memberof StudentInfo
   */
  familyEmail: string
  /**
   * The actor key of the tutor assigned to the student.
   * @type {number}
   * @memberof StudentInfo
   */
  tutorActorKey?: number
  /**
   * The tutor's first and last name.
   * @type {string}
   * @memberof StudentInfo
   */
  tutorName?: string
}

export function StudentInfoFromJSON(json: any): StudentInfo {
  return StudentInfoFromJSONTyped(json, false)
}

export function StudentInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StudentInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentKey: json['studentKey'],
    studentName: json['studentName'],
    studentDateOfBirth: json['studentDateOfBirth'],
    familyName: json['familyName'],
    familyEmail: json['familyEmail'],
    tutorActorKey: !exists(json, 'tutorActorKey')
      ? undefined
      : json['tutorActorKey'],
    tutorName: !exists(json, 'tutorName') ? undefined : json['tutorName'],
  }
}

export function StudentInfoToJSON(value?: StudentInfo | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    studentKey: value.studentKey,
    studentName: value.studentName,
    studentDateOfBirth: value.studentDateOfBirth,
    familyName: value.familyName,
    familyEmail: value.familyEmail,
    tutorActorKey: value.tutorActorKey,
    tutorName: value.tutorName,
  }
}
