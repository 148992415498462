import Box from '@mui/material/Box'
import React, { useState } from 'react'
import { useShowOnDesktop } from '../../../hooks/useShowOnDesktop'
import ContainedButton, {
  ContainedButtonVariant,
} from '../../Buttons/ContainedButton'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../../Buttons/OutlinedButton'
import Header from '../../Elements/Header'
import SpaceBetweenSection from '../../Elements/SpaceBetweenSection'
import { LicensingBill } from '../../../swagger'
import PaymentModal, { PaymentModalVariant } from '../../Modals/PaymentModal'
import { BillingHistorySummaryTableVariant } from './BillingHistorySummaryTable'
import { LicensingPaymentBreakdown } from './LicensingPaymentBreakdown'
import { styled, useTheme } from '@mui/system'
import { Divider, Typography } from '@mui/material'
import BasicModal from '../../Modals/BasicModal'
import ActionButtons from '../../Buttons/ActionButtons'
import { useTranslation } from 'react-i18next'

const ProgramLicensingHeader = styled(Header)({
  paddingTop: 0,
})

interface ProgramLicensingInformationProps {
  header: string
  program: LicensingBill
  achDiscount: number
  refetch?: () => void
  disableMakeAPayment?: boolean
  disableShowDetails?: boolean
  isLicensingVariant?: boolean
  variant?: BillingHistorySummaryTableVariant
  achAllowed: boolean
}

export const ProgramLicensingInformation: React.FC<
  ProgramLicensingInformationProps
> = ({
  header,
  program,
  achDiscount,
  refetch,
  disableMakeAPayment = false,
  disableShowDetails = false,
  variant,
  achAllowed,
}) => {
  const { t } = useTranslation()
  const isLicensingPayments =
    !!variant && variant === BillingHistorySummaryTableVariant.LicensingPayments

  const showOnDesktop = useShowOnDesktop()
  const theme = useTheme()
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
  const [showDetails, setShowDetails] = useState(false)

  const onShowDetails = () => {
    setShowDetails((prevState) => !prevState)
  }

  const handlePaymentInformationModalConfirm = (
    event: React.FormEvent<HTMLDivElement>
  ) => {
    event.preventDefault()
    setIsPaymentModalOpen(false)
  }

  //When ACH payment is allowed, it shows the payment modal; otherwise, it shows an information modal
  return (
    <>
      {achAllowed ? (
        <PaymentModal
          isOpen={isPaymentModalOpen}
          onClose={() => {
            setIsPaymentModalOpen(false)
            refetch?.()
          }}
          variant={PaymentModalVariant.Licensing}
          programKey={program._for.programKey}
          amountDue={program.total}
          achDiscount={{
            amount: achDiscount,
            applyDiscountTimes: 0, // Don't apply for Licensing
          }}
        />
      ) : (
        <BasicModal
          isOpen={isPaymentModalOpen}
          maxWidth={'xs'}
          handleFormSubmit={handlePaymentInformationModalConfirm}
          dialogActions={
            <ActionButtons primaryButtonLabel={ContainedButtonVariant.Ok} />
          }
          dialogContent={
            <Box maxWidth={300} mx="auto" textAlign="center">
              <Typography component="p">
                {t(
                  'ProgramLicensingInformation.ConfirmationModal.Information.AchAllowed',
                  'Please contact your country coordinator directly to make your payment or email accounting directly at accounting@classicalconversations.com. '
                )}
              </Typography>
            </Box>
          }
        />
      )}
      <SpaceBetweenSection
        left={
          <>
            <ProgramLicensingHeader
              id={header}
              headerName={header}
              component="h6"
            />
          </>
        }
        right={
          <>
            <Box mt={showOnDesktop ? 0 : 1}>
              <OutlinedButton
                id="showDetails"
                variant={
                  !showDetails
                    ? OutlinedButtonVariant.ShowDetails
                    : OutlinedButtonVariant.HideDetails
                }
                onClick={onShowDetails}
                fullWidth={!showOnDesktop}
                disabled={disableShowDetails}
              />
            </Box>
            {isLicensingPayments ? (
              <Box mt={showOnDesktop ? 0 : 1}>
                <ContainedButton
                  id="makeAPayment"
                  variant={ContainedButtonVariant.MakeAPayment}
                  onClick={() => setIsPaymentModalOpen(true)}
                  fullWidth={!showOnDesktop}
                  disabled={disableMakeAPayment}
                />
              </Box>
            ) : null}
          </>
        }
        containerStyles={{ flexDirection: 'column' }}
      />
      <Divider
        style={{
          marginTop: theme.spacing(7),
          marginBottom: theme.spacing(2),
        }}
      />

      <LicensingPaymentBreakdown
        showDetails={showDetails}
        licensingBill={program}
        programName={program._for.programType ?? ''}
        currencyCode={program.total.currencyCode}
      />
    </>
  )
}

export default ProgramLicensingInformation
