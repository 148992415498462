/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface PayByAchRequestBody
 */
export interface PayByAchRequestBody {
  /**
   * The 9-digit routing number for the user's financial institution. May have leading zeroes, which is why it is a string, not a number.
   * @type {string}
   * @memberof PayByAchRequestBody
   */
  routing: string
  /**
   * The account number for the user's account at their financial institution.
   * @type {string}
   * @memberof PayByAchRequestBody
   */
  account: string
}

export function PayByAchRequestBodyFromJSON(json: any): PayByAchRequestBody {
  return PayByAchRequestBodyFromJSONTyped(json, false)
}

export function PayByAchRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PayByAchRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    routing: json['routing'],
    account: json['account'],
  }
}

export function PayByAchRequestBodyToJSON(
  value?: PayByAchRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    routing: value.routing,
    account: value.account,
  }
}
