import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import TitleContext from '../../TitleContext'
import { Page } from '../Elements/PageMargins'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import BusinessContentCard from '../Card/BusinessContentCard'
import Header from '../Elements/Header'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import ContentOverviewCard from '../Card/ContentOverviewCard'
import { FileType, FileTypeIcon } from '../Elements/FileTypeResource'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import { contentApi } from '../../api/swagger'
import { LearningPath as LearningPathModel } from '../../swagger'
import useMediaQuery from '@mui/material/useMediaQuery'
import LearningPathContentMobile from '../Card/LearningPathContentMobile'
import NoPermission from '../Elements/NoPermission'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import { styled } from '@mui/system'
import { Roles } from '../../utils/searchAndFilterEnums'

const LearningPathHeader = styled(Header)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  padding: 0,
  color: theme.palette.primary.dark,
}))

export const LearningPath: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const title = t('Business.LearningPath.Title', 'Business')
  const { useTitleEffect } = React.useContext(TitleContext)
  useTitleEffect(title)
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobileOrSmaller = useMediaQuery(theme.breakpoints.down('sm'))

  const { assetKey } = useParams<{
    assetKey: string
  }>()

  const parsedAssetKey = parseInt(`${assetKey}`)

  const [errorFetchingLearningPath, setErrorFetchingLearningPath] =
    useState(false)

  const [learningPath, setLearningPath] = useState<LearningPathModel>()
  const [isLoading, setIsLoading] = useState(false)
  // Fetch Learning Path
  useEffect(() => {
    const fetchWidenAsset = async () => {
      try {
        setIsLoading(true)
        const fetchedWidenAsset = await contentApi.fetchBusinessLearningPath({
          assetKey: parsedAssetKey,
        })

        setLearningPath(fetchedWidenAsset)
      } catch (error) {
        setErrorFetchingLearningPath(true)
      } finally {
        setIsLoading(false)
      }
    }
    fetchWidenAsset()
  }, [assetKey, parsedAssetKey])

  const handleStartLearning = () => {
    navigate(
      {
        pathname: `/business/business-content/${assetKey}`,
      },
      {
        state: {
          assetKeysInLearningPath,
        },
      }
    )
  }

  const navigateToBusiness = () => {
    navigate(
      {
        pathname: `/business`,
      },
      {
        /** Navigation Options */
      }
    )
  }

  const sortedLearningPathAssets =
    learningPath?.assets.sort(
      (a, b) => (a.listingPriority ?? 0) - (b.listingPriority ?? 0)
    ) ?? []

  const assetKeysInLearningPath = sortedLearningPathAssets.map(({ assetKey }) =>
    assetKey.toString()
  )

  const isFavorited = (): boolean => {
    const isFavorite = learningPath?.assets
      .filter((it) => it.assetKey === parsedAssetKey)
      .map((it) => it.isFavorited)
    return isFavorite?.[0] ?? false
  }

  return (
    <Page>
      {!!learningPath ? (
        <>
          {!errorFetchingLearningPath ? (
            <>
              <DynamicBreadcrumbs
                breadcrumbs={[
                  {
                    label: t('Business.LearningPath.BreadCrumb', 'Business'),
                    onClick: navigateToBusiness,
                  },
                  {
                    label: learningPath?.title,
                  },
                ]}
              />
              <Card>
                <Box p={{ xs: 2, sm: 4 }}>
                  <ContentOverviewCard
                    assetKey={parsedAssetKey}
                    icon={
                      <FileTypeIcon fileType={FileType.LearningPath} large />
                    }
                    iconBackground="light"
                    title={learningPath?.title || 'No Title Available'}
                    description={
                      learningPath?.description ||
                      'No description information available.'
                    }
                    actionArea={
                      <ContainedButton
                        id="startLearning"
                        variant={ContainedButtonVariant.StartLearning}
                        onClick={handleStartLearning}
                      />
                    }
                    isFavorite={isFavorited()}
                    isLearningPath={true}
                  />
                </Box>
              </Card>

              <LearningPathHeader
                id="learningPathHeader"
                headerName={learningPath?.title}
              />
              <Box display="flex" flexWrap="wrap" ml={{ xs: 0, md: -1 }}>
                {sortedLearningPathAssets.map((card) =>
                  isMobileOrSmaller ? (
                    <LearningPathContentMobile
                      key={card.assetKey}
                      widenAssetKey={card.assetKey}
                      fileType={card.filetype as FileType}
                      title={card.title}
                      routerState={{ assetKeysInLearningPath }}
                    />
                  ) : (
                    <BusinessContentCard
                      key={card.assetKey}
                      widenAssetKey={card.assetKey}
                      fileType={card.filetype as FileType}
                      subtitle={`Role: ${card.role as Roles}`}
                      title={card.title}
                      description={card.description ?? ''}
                      isLearningPath={false}
                      isFavorite={card.isFavorited ?? false}
                      routerState={{ assetKeysInLearningPath }}
                      viewOnly={card.viewOnly ?? false}
                      isNdaRequired={card.isNdaRequired ?? false}
                      ndaAcknowledged={card.ndaAcknowledged ?? false}
                    />
                  )
                )}
              </Box>
            </>
          ) : (
            <NoPermission
              isLoading={isLoading}
              content={t(
                'Business.LearningPath.NoPermission',
                `Sorry, you do not have permission to view this content.`
              )}
            />
          )}
        </>
      ) : (
        <NoPermission
          isLoading={isLoading}
          content={t(
            'Business.LearningPath.NoPermission',
            `Sorry, you do not have permission to view this content.`
          )}
        />
      )}
    </Page>
  )
}

export default LearningPath
