/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface FetchDiscourseConnectRedirectUrl
 */
export interface FetchDiscourseConnectRedirectUrl {
  /**
   * The link for which to redirect to
   * @type {string}
   * @memberof FetchDiscourseConnectRedirectUrl
   */
  redirectTo: string
}

export function FetchDiscourseConnectRedirectUrlFromJSON(
  json: any
): FetchDiscourseConnectRedirectUrl {
  return FetchDiscourseConnectRedirectUrlFromJSONTyped(json, false)
}

export function FetchDiscourseConnectRedirectUrlFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FetchDiscourseConnectRedirectUrl {
  if (json === undefined || json === null) {
    return json
  }
  return {
    redirectTo: json['redirect_to'],
  }
}

export function FetchDiscourseConnectRedirectUrlToJSON(
  value?: FetchDiscourseConnectRedirectUrl | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    redirect_to: value.redirectTo,
  }
}
