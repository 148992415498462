/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  CommunityListing,
  CommunityListingFromJSON,
  CommunityListingToJSON,
  PaginationResponse,
  PaginationResponseFromJSON,
  PaginationResponseToJSON,
} from './'

/**
 *
 * @export
 * @interface Communities
 */
export interface Communities {
  /**
   *
   * @type {Array<CommunityListing>}
   * @memberof Communities
   */
  managedCommunities: Array<CommunityListing>
  /**
   *
   * @type {Array<CommunityListing>}
   * @memberof Communities
   */
  memberCommunities: Array<CommunityListing>
  /**
   *
   * @type {PaginationResponse}
   * @memberof Communities
   */
  pagination?: PaginationResponse
}

export function CommunitiesFromJSON(json: any): Communities {
  return CommunitiesFromJSONTyped(json, false)
}

export function CommunitiesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Communities {
  if (json === undefined || json === null) {
    return json
  }
  return {
    managedCommunities: (json['managedCommunities'] as Array<any>).map(
      CommunityListingFromJSON
    ),
    memberCommunities: (json['memberCommunities'] as Array<any>).map(
      CommunityListingFromJSON
    ),
    pagination: !exists(json, 'pagination')
      ? undefined
      : PaginationResponseFromJSON(json['pagination']),
  }
}

export function CommunitiesToJSON(value?: Communities | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    managedCommunities: (value.managedCommunities as Array<any>).map(
      CommunityListingToJSON
    ),
    memberCommunities: (value.memberCommunities as Array<any>).map(
      CommunityListingToJSON
    ),
    pagination: PaginationResponseToJSON(value.pagination),
  }
}
