import React, { useEffect, useState } from 'react'
import { Page } from '../Elements/PageMargins'
import SearchBar from '../Search/SearchBar'
import TitleContext from '../../TitleContext'
import { useTranslation } from 'react-i18next'
import DropDown, { DropDownVariant } from '../Menus/DropDown'
import BusinessContentCard from '../Card/BusinessContentCard'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../Buttons/OutlinedButton'
import Box from '@mui/material/Box'
import { FileType } from '../Elements/FileTypeResource'
import { contentApi, ContentAsset } from '../../api/swagger'
import EmptyBusiness from './EmptyBusiness'
import { SnackbarSeverity } from '../../components/Alerts/SnackbarAlert'
import { BusinessContentOptions } from '../../swagger/models/BusinessContentOptions'
import { buildFilterOptions } from '../../utils/buildFilterOptions'
import { extractedErrorObject } from '../../api/swagger'
import NoPermission from '../Elements/NoPermission'
import { useLocation, Outlet } from 'react-router'
import { useSnackbarContext } from '../Context/SnackbarContext'
import {
  Categories,
  Roles,
  FilterFileType,
} from '../../utils/searchAndFilterEnums'
import { styled } from '@mui/system'
import Header from '../Elements/Header'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { LoadingContext } from '../Context/LoadingContext'
import { useMountEffect } from '../../hooks/useMountEffect'
import LoadingProgress from '../Elements/LoadingProgress'
import { useTheme } from '@mui/material/styles'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { useBusinessContext } from '../Context/BusinessContext'
import { escapeString } from '../../utils/stringUtility'

const SearchAndFilterWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(5, 3, 4, 0),
    flexDirection: 'column',
  },
}))

const SearchBarContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 2, 0, 0),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: 0,
    alignItems: 'center',
  },
}))

const BusinessCardWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginLeft: theme.spacing(-1),
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 'unset',
  },
}))

const FilterContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 2, 0, 0),
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(1, 0),
  },
}))

const FilterDropdown = styled(DropDown)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0),
  },
}))

export const verifyTitle = (value: string, object: ContentAsset): string => {
  return !value ? object.title ?? '' : value ?? ''
}

export const sortAlphaNum = (a: ContentAsset, b: ContentAsset): number =>
  verifyTitle(a.learningPathTitle ?? '', a).localeCompare(
    verifyTitle(b.learningPathTitle ?? '', b),
    navigator.language,
    {
      numeric: true,
      sensitivity: 'base',
    }
  )

/** We set the number to 12 because it plays nicely in all views with the flex layout. */
const additionalMaximumAmountOfCardsToShow = 12

export const Business: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const title = t('Business.Title', 'Business')
  const { useTitleEffect } = React.useContext(TitleContext)
  useTitleEffect(title)
  const location = useLocation()
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()
  const availableLoadingIds = useLoadingIds()
  const { addLoadingIds, loadingIds } = React.useContext(LoadingContext)

  const [filterOptions, setFilterOptions] = useState<BusinessContentOptions>()

  const [businessContent, setBusinessContent] = useState<ContentAsset[]>([])
  const [businessContentPerPage, setBusinessContentPerPage] = useState(
    additionalMaximumAmountOfCardsToShow
  )

  const [errorMessage, setErrorMessage] = useState('')
  const [isClearFilters, setIsClearFilters] = useState(false)

  const genericErrorMessage = t(
    'Business.Error.FetchContent',
    'Error occurred retrieving business content.'
  )

  const isShowOnDesktop = useShowOnDesktop()
  const {
    search: searchQuery,
    type: filterByType,
    role: filterByRole,
    category: filterByCategory,
    updateSearch,
    updateRole,
    updateType,
    updateCategory,
    resetContextToDefaults,
  } = useBusinessContext()

  // Fetch Business Content (also used when filtering)
  const fetchContentByParams = async () => {
    // Build request body based on filters. If filter is set to 'All...' then do not include query param
    const body = {
      ...(filterByRole !== Roles.AllRoles && { role: filterByRole }),
      ...(filterByCategory !== Categories.AllCategories &&
        filterByCategory !== Categories.MyFavorites && {
          category: filterByCategory,
        }),
      ...(filterByType !== FilterFileType.AllTypes && {
        filetype: filterByType,
      }),
      ...(filterByCategory === Categories.MyFavorites && {
        showOnlyFavorites: true,
      }),

      ...(!!searchQuery &&
        searchQuery.length >= 3 && {
          search: escapeString(searchQuery).trim(),
        }),
    }
    try {
      const fetchedWidenAsset = await contentApi.fetchBusinessContent(body)
      setBusinessContent(fetchedWidenAsset.sort(sortAlphaNum))
    } catch (error) {
      const errorObj = (await extractedErrorObject(error)) ?? {
        code: 'Unknown',
        message: (error as unknown as Error).message ?? genericErrorMessage,
      }
      setErrorMessage(errorObj.message)
    } finally {
    }
  }

  useLoadingContext({
    asyncFunction: fetchContentByParams,
    loadingId: availableLoadingIds.Business.fetchBusinessContent,
  })

  // Fetch all Filter Menu Options
  const fetchFilterMenuOptions = async () => {
    try {
      const fetchedFilterMenuOptions =
        await contentApi.fetchBusinessContentOptions({})

      setFilterOptions(fetchedFilterMenuOptions)
    } catch (error) {
      const errorObj = (await extractedErrorObject(error)) ?? {
        code: 'Unknown',
        message: (error as unknown as Error).message ?? genericErrorMessage,
      }
      setErrorMessage(errorObj.message)
    }
  }

  useLoadingContext({
    asyncFunction: fetchFilterMenuOptions,
    loadingId: availableLoadingIds.Business.fetchBusinessContentOptions,
  })

  useMountEffect(() => {
    addLoadingIds([
      availableLoadingIds.Business.fetchBusinessContentOptions,
      availableLoadingIds.Business.fetchBusinessContent,
    ])
  })

  useEffect(() => {
    addLoadingIds([
      availableLoadingIds.Business.fetchBusinessContentOptions,
      availableLoadingIds.Business.fetchBusinessContent,
    ])
  }, [
    searchQuery,
    filterByRole,
    filterByCategory,
    filterByType,
    addLoadingIds,
    availableLoadingIds.Business.fetchBusinessContentOptions,
    availableLoadingIds.Business.fetchBusinessContent,
  ])

  /**
   * If error display snack bar alert
   */
  useEffect(() => {
    if (!!errorMessage) {
      setSnackbarState(true)
      setSnackbarMessage(errorMessage)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  })

  const handleSearch = (searchText: string) => {
    updateSearch(searchText)
  }

  const handleDropDownSelection = (selection: string, id: string) => {
    switch (id) {
      case 'role':
        updateRole(selection as Roles)
        break
      case 'category':
        updateCategory(selection as Categories)
        break
      case 'type':
        updateType(selection as FilterFileType)
        break
    }
  }

  const handleClearAllFilters = () => {
    resetContextToDefaults()
    setIsClearFilters(true)
  }

  const handleViewMoreBusinessContent = () => {
    setBusinessContentPerPage(
      businessContentPerPage + additionalMaximumAmountOfCardsToShow
    )
  }

  const roleOptions = buildFilterOptions(
    filterOptions?.roles ?? [],
    'All Roles'
  )

  const categoryOptions = buildFilterOptions(
    filterOptions?.categories ?? [],
    'All Categories',
    'My Favorites'
  )

  const filetypeOptions = buildFilterOptions(
    filterOptions?.filetypes ?? [],
    'All Types'
  )

  const isLoading: boolean =
    loadingIds.has(availableLoadingIds.Business.fetchBusinessContent) ||
    loadingIds.has(availableLoadingIds.Business.fetchBusinessContentOptions)

  if (businessContent.length === 0 && !!errorMessage) {
    return <EmptyBusiness isLoading={isLoading} />
  }

  if (!businessContent.length && !filterOptions?.roles.length) {
    return (
      <Page>
        <NoPermission
          isLoading={isLoading}
          content={t(
            'Business.NoPermission',
            `Sorry, you do not have permission to view this content.`
          )}
        />
      </Page>
    )
  }

  const inSearchState =
    !!searchQuery ||
    filterByCategory !== Categories.AllCategories ||
    filterByType !== FilterFileType.AllTypes ||
    filterByRole !== Roles.AllRoles

  return (
    <Page>
      {location.pathname === '/business' && (
        <>
          <SearchAndFilterWrapper>
            <SearchBarContainer>
              <SearchBar
                handleSearch={handleSearch}
                searchValue={searchQuery}
              />
            </SearchBarContainer>
            <FilterContainer>
              <FilterDropdown
                formControlProps={{
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                }}
                id="role"
                menuOptions={roleOptions.map((role) => {
                  return { name: role.name, id: role.id }
                })}
                defaultValue={t('Business.FilterOption.Roles', 'All Roles')}
                handleSelection={handleDropDownSelection}
                variant={DropDownVariant.SortAndFilter}
                value={filterByRole}
                isClearFilters={isClearFilters}
              />
            </FilterContainer>
            <FilterContainer>
              <FilterDropdown
                formControlProps={{
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                }}
                id="category"
                menuOptions={categoryOptions.map((category) => {
                  return { name: category.name, id: category.id }
                })}
                defaultValue={t(
                  'Business.FilterOption.Categories',
                  'All Categories'
                )}
                handleSelection={handleDropDownSelection}
                variant={DropDownVariant.SortAndFilter}
                value={filterByCategory}
                isClearFilters={isClearFilters}
              />
            </FilterContainer>
            <FilterContainer>
              <FilterDropdown
                formControlProps={{
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                }}
                id="type"
                menuOptions={filetypeOptions.map((filetype) => {
                  return { name: filetype.name, id: filetype.id }
                })}
                defaultValue={t('Business.FilterOption.Types', 'All Types')}
                handleSelection={handleDropDownSelection}
                variant={DropDownVariant.SortAndFilter}
                value={filterByType}
                isClearFilters={isClearFilters}
              />
            </FilterContainer>
            <FilterContainer>
              <OutlinedButton
                css={{ paddingBottom: '3px', paddingTop: '3px' }}
                id="clearAllFilters"
                variant={OutlinedButtonVariant.ClearAllFilters}
                onClick={handleClearAllFilters}
                fullWidth={!isShowOnDesktop}
              />
            </FilterContainer>
          </SearchAndFilterWrapper>
          {isLoading ? (
            <LoadingProgress />
          ) : (
            <>
              <Header
                component="h2"
                headerName={
                  inSearchState && businessContent.length === 1
                    ? `${businessContent.length} ${t(
                        'BusinessContent.SearchResult.ItemFound',
                        'item found'
                      )}`
                    : inSearchState
                    ? `${businessContent.length} ${t(
                        'BusinessContent.SearchResult.ItemsFound',
                        'items found'
                      )}`
                    : t('BusinessContent.Cards.All', 'All Business Content')
                }
              />
              <BusinessCardWrapper>
                {businessContent
                  .slice(0, businessContentPerPage)
                  .map((business) => (
                    <BusinessContentCard
                      key={business.assetKey}
                      widenAssetKey={business.assetKey}
                      fileType={
                        business.learningPathTitle
                          ? FileType.LearningPath
                          : (business.filetype as FileType)
                      }
                      title={business.learningPathTitle || business.title}
                      description={
                        business.learningPathDescription ||
                        business.description ||
                        t(
                          'BusinessContentCard.EmptyDescription',
                          'No description available.'
                        )
                      }
                      isLearningPath={business?.learningPathTitle !== ''}
                      isFavorite={business?.isFavorited ?? false}
                      viewOnly={business?.viewOnly ?? false}
                      isNdaRequired={business?.isNdaRequired ?? false}
                      ndaAcknowledged={business?.ndaAcknowledged ?? false}
                    />
                  ))}
              </BusinessCardWrapper>
              {/** Hide button once we are viewing all available Business Content */}
              {businessContent.slice(0, businessContentPerPage).length <
                businessContent.length && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  my={6}
                >
                  <OutlinedButton
                    data-testid="viewMoreButton"
                    id="viewMoreDraftEvents"
                    onClick={handleViewMoreBusinessContent}
                    variant={OutlinedButtonVariant.ViewMore}
                  />
                </Box>
              )}
            </>
          )}
        </>
      )}
      {/* Render the children of business, anything with a route prefix of /business and also in the /business children. */}
      <Outlet />
    </Page>
  )
}

export default Business
