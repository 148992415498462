/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  CommunityManager,
  CommunityManagerFromJSON,
  CommunityManagerToJSON,
  CommunityOptionsDefaultProgramStartDates,
  CommunityOptionsDefaultProgramStartDatesFromJSON,
  CommunityOptionsDefaultProgramStartDatesToJSON,
  Supervisor,
  SupervisorFromJSON,
  SupervisorToJSON,
  User,
  UserFromJSON,
  UserToJSON,
} from './'

/**
 *
 * @export
 * @interface RegionCommunityOptions
 */
export interface RegionCommunityOptions {
  /**
   * Unique identifier for a region.
   * @type {number}
   * @memberof RegionCommunityOptions
   */
  id: number
  /**
   * Name of a region
   * @type {string}
   * @memberof RegionCommunityOptions
   */
  name: string
  /**
   *
   * @type {CommunityOptionsDefaultProgramStartDates}
   * @memberof RegionCommunityOptions
   */
  defaultProgramStartDates: CommunityOptionsDefaultProgramStartDates
  /**
   *
   * @type {Array<CommunityManager>}
   * @memberof RegionCommunityOptions
   */
  communityManagers: Array<CommunityManager>
  /**
   *
   * @type {Array<Supervisor>}
   * @memberof RegionCommunityOptions
   */
  supervisors: Array<Supervisor>
  /**
   *
   * @type {Array<User>}
   * @memberof RegionCommunityOptions
   */
  users: Array<User>
}

export function RegionCommunityOptionsFromJSON(
  json: any
): RegionCommunityOptions {
  return RegionCommunityOptionsFromJSONTyped(json, false)
}

export function RegionCommunityOptionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RegionCommunityOptions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: json['name'],
    defaultProgramStartDates: CommunityOptionsDefaultProgramStartDatesFromJSON(
      json['defaultProgramStartDates']
    ),
    communityManagers: (json['communityManagers'] as Array<any>).map(
      CommunityManagerFromJSON
    ),
    supervisors: (json['supervisors'] as Array<any>).map(SupervisorFromJSON),
    users: (json['users'] as Array<any>).map(UserFromJSON),
  }
}

export function RegionCommunityOptionsToJSON(
  value?: RegionCommunityOptions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    defaultProgramStartDates: CommunityOptionsDefaultProgramStartDatesToJSON(
      value.defaultProgramStartDates
    ),
    communityManagers: (value.communityManagers as Array<any>).map(
      CommunityManagerToJSON
    ),
    supervisors: (value.supervisors as Array<any>).map(SupervisorToJSON),
    users: (value.users as Array<any>).map(UserToJSON),
  }
}
