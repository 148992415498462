/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface StudentTutorAssignment
 */
export interface StudentTutorAssignment {
  /**
   * The unique identifier of the student being assigned a tutor. Provided by the fetchProgramStudents endpoint.
   * @type {number}
   * @memberof StudentTutorAssignment
   */
  studentKey: number
  /**
   * The actor key of the tutor being assigned to the student. Provided by the fetchProgram endpoint. If left empty, will unassign the current tutor from the student.
   * @type {number}
   * @memberof StudentTutorAssignment
   */
  tutorActorKey?: number
}

export function StudentTutorAssignmentFromJSON(
  json: any
): StudentTutorAssignment {
  return StudentTutorAssignmentFromJSONTyped(json, false)
}

export function StudentTutorAssignmentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StudentTutorAssignment {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentKey: json['studentKey'],
    tutorActorKey: !exists(json, 'tutorActorKey')
      ? undefined
      : json['tutorActorKey'],
  }
}

export function StudentTutorAssignmentToJSON(
  value?: StudentTutorAssignment | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    studentKey: value.studentKey,
    tutorActorKey: value.tutorActorKey,
  }
}
