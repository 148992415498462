import Box from '@mui/material/Box'
import Typography, { TypographyProps } from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import getLocaleCurrencyForAmount from '../../../utils/getLocaleCurrencyForAmount'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles'

/**
 * !!! Attempting to use component prop on Typography results in an error in the
 * form of TypeScript limitation regarding argument interface and overload
 * function signatures
 *
 * See known issues + workaround https://github.com/mui/material-ui/issues/15759#issuecomment-493994852
 */
const Label = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.textOrIcon.subheader,
})) as typeof Typography

interface AmountDueProps {
  amountDue: number
  currencyCode: string
  isEnrollmentAmount?: boolean
  isTextColorRed?: boolean
}

const AmountDue: React.FC<AmountDueProps> = ({
  amountDue,
  currencyCode,
  isEnrollmentAmount = false,
  isTextColorRed = false,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <>
      <Label component="p" variant="subtitle2">
        {t('AmountDue.Header.AmountDue', 'Amount Due')}
      </Label>
      <Box display="flex" alignItems="self-end">
        <Box pr={1}>
          <Typography
            component="p"
            variant="h3"
            color={
              isTextColorRed
                ? theme.palette.error.main
                : amountDue > 0
                ? theme.palette.textOrIcon.greenText
                : theme.palette.textOrIcon.darkWhite
            }
          >
            {getLocaleCurrencyForAmount(amountDue, currencyCode)}
          </Typography>
        </Box>
        {!!isEnrollmentAmount && (
          <Label component="span" variant="subtitle2">
            {t('AmountDue.Subtitle.DoesNotIncludeTax', 'Does not include tax')}
          </Label>
        )}
      </Box>
    </>
  )
}

export default AmountDue
