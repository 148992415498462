import React, { ReactNode } from 'react'
import { Box, Divider, SxProps, Theme, useTheme } from '@mui/material'
import { styled } from '@mui/system'

interface CardFormHeaderProps {
  header: ReactNode
  buttons?: ReactNode
  useDivider?: boolean
  headerContainerProps?: SxProps<Theme>
}

const HeaderAndButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    margin: theme.spacing(1, 0),
  },
}))

const ChildrenContainer = styled('div')(({ theme }) => ({
  float: 'right',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const CardFormHeaderDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(1),
}))

const CardFormHeader: React.FC<CardFormHeaderProps> = (props) => {
  const theme = useTheme()
  return (
    <>
      <HeaderAndButtons>
        <Box
          sx={{
            marginTop: theme.spacing(3),
            marginLeft: theme.spacing(3),
            display: 'flex',
            float: 'left',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
              margin: theme.spacing(2.5, 0, 1, 2),
            },
            ...props.headerContainerProps,
          }}
        >
          {props.header}
        </Box>
        <ChildrenContainer>{props.buttons}</ChildrenContainer>
      </HeaderAndButtons>
      {!!props.useDivider && <CardFormHeaderDivider />}
    </>
  )
}

export default CardFormHeader
