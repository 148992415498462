/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  Address,
  AddressFromJSON,
  AddressToJSON,
  CashAmount,
  CashAmountFromJSON,
  CashAmountToJSON,
  EnrollmentPaymentDetails,
  EnrollmentPaymentDetailsFromJSON,
  EnrollmentPaymentDetailsToJSON,
  PaymentMethodKey,
  PaymentMethodKeyFromJSON,
  PaymentMethodKeyToJSON,
  PaymentStatusKey,
  PaymentStatusKeyFromJSON,
  PaymentStatusKeyToJSON,
} from './'

/**
 *
 * @export
 * @interface EnrollmentPayment
 */
export interface EnrollmentPayment {
  /**
   *
   * @type {number}
   * @memberof EnrollmentPayment
   */
  paymentKey: number
  /**
   *
   * @type {Date}
   * @memberof EnrollmentPayment
   */
  begunAt: Date
  /**
   *
   * @type {number}
   * @memberof EnrollmentPayment
   */
  userKey: number
  /**
   *
   * @type {PaymentMethodKey}
   * @memberof EnrollmentPayment
   */
  paymentMethodKey: PaymentMethodKey
  /**
   *
   * @type {CashAmount}
   * @memberof EnrollmentPayment
   */
  amount: CashAmount
  /**
   *
   * @type {CashAmount}
   * @memberof EnrollmentPayment
   */
  tax: CashAmount
  /**
   *
   * @type {string}
   * @memberof EnrollmentPayment
   */
  taxTransactionId?: string
  /**
   *
   * @type {CashAmount}
   * @memberof EnrollmentPayment
   */
  total?: CashAmount
  /**
   *
   * @type {string}
   * @memberof EnrollmentPayment
   */
  orderId?: string
  /**
   *
   * @type {PaymentStatusKey}
   * @memberof EnrollmentPayment
   */
  paymentStatusKey: PaymentStatusKey
  /**
   *
   * @type {Address}
   * @memberof EnrollmentPayment
   */
  address?: Address
  /**
   *
   * @type {string}
   * @memberof EnrollmentPayment
   */
  desc?: string
  /**
   *
   * @type {string}
   * @memberof EnrollmentPayment
   */
  paymentProviderId?: string
  /**
   * The payment a.net or cornerstone transaction id.
   * @type {string}
   * @memberof EnrollmentPayment
   */
  transactionId?: string
  /**
   * Username of the actor who made the payment.
   * @type {string}
   * @memberof EnrollmentPayment
   */
  paidBy?: string
  /**
   *
   * @type {Array<EnrollmentPaymentDetails>}
   * @memberof EnrollmentPayment
   */
  enrollmentPayments: Array<EnrollmentPaymentDetails>
}

export function EnrollmentPaymentFromJSON(json: any): EnrollmentPayment {
  return EnrollmentPaymentFromJSONTyped(json, false)
}

export function EnrollmentPaymentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnrollmentPayment {
  if (json === undefined || json === null) {
    return json
  }
  return {
    paymentKey: json['paymentKey'],
    begunAt: new Date(json['begunAt']),
    userKey: json['userKey'],
    paymentMethodKey: PaymentMethodKeyFromJSON(json['paymentMethodKey']),
    amount: CashAmountFromJSON(json['amount']),
    tax: CashAmountFromJSON(json['tax']),
    taxTransactionId: !exists(json, 'taxTransactionId')
      ? undefined
      : json['taxTransactionId'],
    total: !exists(json, 'total')
      ? undefined
      : CashAmountFromJSON(json['total']),
    orderId: !exists(json, 'orderId') ? undefined : json['orderId'],
    paymentStatusKey: PaymentStatusKeyFromJSON(json['paymentStatusKey']),
    address: !exists(json, 'address')
      ? undefined
      : AddressFromJSON(json['address']),
    desc: !exists(json, 'desc') ? undefined : json['desc'],
    paymentProviderId: !exists(json, 'paymentProviderId')
      ? undefined
      : json['paymentProviderId'],
    transactionId: !exists(json, 'transactionId')
      ? undefined
      : json['transactionId'],
    paidBy: !exists(json, 'paidBy') ? undefined : json['paidBy'],
    enrollmentPayments: (json['enrollmentPayments'] as Array<any>).map(
      EnrollmentPaymentDetailsFromJSON
    ),
  }
}

export function EnrollmentPaymentToJSON(value?: EnrollmentPayment | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    paymentKey: value.paymentKey,
    begunAt: value.begunAt.toISOString().substr(0, 10),
    userKey: value.userKey,
    paymentMethodKey: PaymentMethodKeyToJSON(value.paymentMethodKey),
    amount: CashAmountToJSON(value.amount),
    tax: CashAmountToJSON(value.tax),
    taxTransactionId: value.taxTransactionId,
    total: CashAmountToJSON(value.total),
    orderId: value.orderId,
    paymentStatusKey: PaymentStatusKeyToJSON(value.paymentStatusKey),
    address: AddressToJSON(value.address),
    desc: value.desc,
    paymentProviderId: value.paymentProviderId,
    transactionId: value.transactionId,
    paidBy: value.paidBy,
    enrollmentPayments: (value.enrollmentPayments as Array<any>).map(
      EnrollmentPaymentDetailsToJSON
    ),
  }
}
