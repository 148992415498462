import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HelpIcon from '@mui/icons-material/Help'
import NotInterestedIcon from '@mui/icons-material/NotInterested'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import type { TFunction } from 'i18next'
import React, { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Permission } from '../../../api/swagger'
import { meta, extractedErrorObject } from '../../../api/swagger'
import type { Role } from '../../../swagger/models/Role'
import { GrantScopeCodeEnum } from '../../../swagger/models/Grant'
import { Page } from '../../Elements/PageMargins'
import SearchBar from '../../Search/SearchBar'
import { escapeString } from '../../../utils/stringUtility'
import Box from '@mui/material/Box'
import { useShowOnDesktop } from '../../../hooks/useShowOnDesktop'
import { useNavigate } from 'react-router'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material'
import { useSnackbarContext } from '../../Context/SnackbarContext'
import useLoadingContext from '../../../hooks/useLoadingContext'
import { useMountEffect } from '../../../hooks/useMountEffect'
import { useLoadingIds } from '../../../hooks/useLoadingIds'
import { LoadingContext } from '../../Context/LoadingContext'
import LoadingProgress from '../../Elements/LoadingProgress'
import { SnackbarSeverity } from '../../Alerts/SnackbarAlert'
import EmptyRoles from './EmptyRoles'

/**
 * !!! Attempting to use component prop on Typography results in an error in the
 * form of TypeScript limitation regarding argument interface and overload
 * function signatures
 *
 * See known issues + workaround https://github.com/mui/material-ui/issues/15759#issuecomment-493994852
 */
// For the naming see: Barney Stinson https://www.youtube.com/watch?v=Dqf1BmN4Dag
export const LegenWaitForItDary = styled(Paper)(({ theme }) => ({
  height: 36,
  margin: theme.spacing(0, 0, 0, 3),
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    margin: theme.spacing(0, 0, 3),
  },
})) as typeof Paper

export const BlueHelpIcon = styled(HelpIcon)(({ theme }) => ({
  color: theme.palette.customBackground.onPrimary,
  margin: theme.spacing(1),
  marginInlineStart: `${theme.spacing(1.5)}`,
}))

const LegendListItem = styled('li')(({ theme }) => ({
  marginInlineStart: `${theme.spacing(3)}`,
}))

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: 600,
    width: '75%',
  },
  [theme.breakpoints.down('sm')]: {
    height: 600,
    width: 'auto',
  },
}))

const StyledTable = styled(Table)(({ theme }) => ({
  '& th[scope="row"], th[scope="rowgroup"], #permissionsColumnHeader': {
    backgroundColor: theme.palette.hover.main,
  },
  backgroundColor: 'white',
}))

const UnorderedList = styled('ul')<{ showOnDesktop: boolean }>(
  ({ showOnDesktop }) => ({
    display: showOnDesktop ? 'inline' : 'block',
    '& li': {
      display: showOnDesktop ? 'inline' : 'block',
    },
  })
)

const presentationGroup = (permission: Permission) =>
  permission.category ?? permission.resourceCode
const presentationName = (permission: Permission) =>
  permission.name ?? permission.actionCode

const byNameThenActionCode = (first: Permission, second: Permission) => {
  const firstName = presentationName(first)
  const secondName = presentationName(second)
  if (firstName < secondName) {
    return -1
  } else if (firstName > secondName) {
    return +1
  } else {
    return 0
  }
}

export const groupPermissionsForDisplay = (
  permissions: Permission[]
): Map<string, Permission[]> => {
  const groupings = new Set(permissions.map((it) => presentationGroup(it)))

  const entries = Array.from(groupings).map((it): [string, Permission[]] => [
    it,
    [] as Array<Permission>,
  ])
  const grouped = new Map<string, Permission[]>(entries)

  for (const it of permissions) {
    grouped.get(presentationGroup(it))?.push(it)
  }

  for (const it of grouped.values()) {
    it.sort(byNameThenActionCode)
  }
  return grouped
}

enum GrantIconEnum {
  EnabledGlobally = 'Enabled Globally',
  RestrictedToHierarchy = 'Restricted to Hierarchy',
  Disabled = 'Disabled',
}

const grantForPermissionByRole = (
  permission: Permission,
  role: Role
): GrantIconEnum => {
  const grant = role.grants.find(
    (it) =>
      it.resourceCode === permission.resourceCode &&
      it.actionCode === permission.actionCode
  )
  switch (grant?.scopeCode) {
    case GrantScopeCodeEnum.Any:
      return GrantIconEnum.EnabledGlobally
    case GrantScopeCodeEnum.TheirTeams:
      return GrantIconEnum.RestrictedToHierarchy
    default:
      return GrantIconEnum.Disabled
  }
}

const GrantIcon: React.FunctionComponent<{
  grant: GrantIconEnum
  titleAccess?: string
}> = ({ grant, titleAccess }) => {
  const { t } = useTranslation()
  const label = titleAccess ?? labelForGrant(grant, t)
  switch (grant) {
    case GrantIconEnum.EnabledGlobally:
      return <CheckCircleIcon titleAccess={label} color="secondary" />
    case GrantIconEnum.RestrictedToHierarchy:
      return <RemoveCircleIcon titleAccess={label} color={'warning'} />
    case GrantIconEnum.Disabled: /* FALL THROUGH */
    default:
      return <NotInterestedIcon titleAccess={label} color="action" />
  }
}

const labelForGrant = (grant: GrantIconEnum, t: TFunction) => {
  switch (grant) {
    case GrantIconEnum.EnabledGlobally:
      return t('Roles.Grant.EnabledGlobally', 'Enabled Globally')
    case GrantIconEnum.RestrictedToHierarchy:
      return t('Roles.Grant.RestrictedToHierarchy', 'Restricted to Hierarchy')
    case GrantIconEnum.Disabled: /* FALL THROUGH */
    default:
      return t('Roles.Grant.Disabled', 'Disabled')
  }
}

export const sleep = (millis: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), millis)
  })
}

interface RolesProps {
  roles: Role[]
}

const searchRoles = (args: {
  rolesToSearch: Role[]
  searchInput: string
}): Role[] => {
  const filterRegex = new RegExp(escapeString(args.searchInput).trim(), 'i')
  const sortedRoles = args.rolesToSearch?.filter((role) => {
    return filterRegex.test(role.name ?? '')
  })
  return sortedRoles
}

export const Roles: React.FC<RolesProps> = (props) => {
  const { roles } = props
  const navigate = useNavigate()
  const showOnDesktop = useShowOnDesktop()

  const [permissionsByCategory, setPermissionsByCategory] = React.useState(
    new Map<string, Permission[]>()
  )
  const { addLoadingIds, loadingIds } = React.useContext(LoadingContext)
  const { Meta } = useLoadingIds()
  const [isLoading, setIsLoading] = useState(true)
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()
  const [errorMessage, setErrorMessage] = useState('')
  const { t } = useTranslation()
  const theme = useTheme()

  const [searchQuery, setSearchQuery] = useState('')

  const handleSearch = (searchText: string) => {
    setSearchQuery(searchText)
  }
  /**
   * Fetch permissions
   */
  const fetchPermissions = async () => {
    try {
      const fetchedPermissions = await meta.fetchPermissions({})

      const groupedPermissions = groupPermissionsForDisplay(fetchedPermissions)
      setPermissionsByCategory(groupedPermissions)
    } catch (err) {
      const errorObject = (await extractedErrorObject(err)) ?? {
        code: 'UnknownError',
        message:
          (err as unknown as Error).message ?? 'Failed to fetch permissions.',
      }
      setErrorMessage(errorObject.message)
    } finally {
      setIsLoading(false)
    }
  }
  /** Hooks */
  useLoadingContext({
    asyncFunction: fetchPermissions,
    loadingId: Meta.fetchPermissions,
  })

  useMountEffect(() => {
    addLoadingIds([Meta.fetchPermissions])
  })

  /*
   * If error fetching permissions, display snack bar alert
   */
  useEffect(() => {
    if (!!errorMessage) {
      setSnackbarState(true)
      setSnackbarMessage(errorMessage)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  })

  const filteredRoles = (searchQuery: string, roles: Role[]) => {
    const searchResults = (args: { searchQuery: string }): Role[] => {
      const searchedRoles = searchRoles({
        rolesToSearch: roles ?? [],
        searchInput: args.searchQuery,
      })
      return searchedRoles
    }

    const results = searchResults({
      searchQuery,
    })

    return results
  }

  const searchedRoles = useMemo(
    () => filteredRoles(searchQuery, roles ?? []),
    [searchQuery, roles]
  )

  const handleToEditRole = (roleKey: number) => {
    navigate(
      {
        pathname: `/admin/roles/role-details/${roleKey}`,
      },
      {
        /** Navigation Options */
      }
    )
  }
  if (loadingIds.has(Meta.fetchPermissions)) {
    return <LoadingProgress />
  }

  return (
    <Page withinTab>
      {!permissionsByCategory.size ? (
        <EmptyRoles isLoading={isLoading} />
      ) : (
        <>
          <Box display="flex" flexDirection={!showOnDesktop ? 'column' : 'row'}>
            <SearchBar handleSearch={handleSearch} />
            <LegenWaitForItDary
              component="section"
              aria-labelledby="legend-label"
            >
              <Typography
                variant="body2"
                id="legend-label"
                component="h2"
                display="inline"
              >
                <BlueHelpIcon />
                {t('Roles.Table.Legend.Title', 'KEY:')}
              </Typography>
              <UnorderedList
                showOnDesktop={showOnDesktop}
                aria-labelledby="legend-label"
              >
                {Object.values(GrantIconEnum).map((grant) => (
                  <LegendListItem key={grant}>
                    <GrantIcon grant={grant} titleAccess="" />
                    <Typography
                      variant="body2"
                      component="span"
                      margin={theme.spacing(1)}
                    >
                      {labelForGrant(grant, t)}
                    </Typography>
                  </LegendListItem>
                ))}
              </UnorderedList>
            </LegenWaitForItDary>
          </Box>
          <StyledTableContainer>
            <StyledTable stickyHeader>
              {searchedRoles.length > 0 && (
                <TableHead>
                  <TableRow>
                    <TableCell id="permissionsColumnHeader">
                      <Typography
                        variant="subtitle2"
                        component="h2"
                        display={'inline'}
                      >
                        {t('Roles.Table.Header.Permission', 'Permission')}
                      </Typography>
                    </TableCell>
                    {searchedRoles.map((it) => (
                      <TableCell
                        onClick={() => handleToEditRole(it.roleKey ?? -1)}
                        align="center"
                        key={it.name}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <Typography variant="subtitle2" component="p">
                          {it.name}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              )}
              {searchedRoles.length <= 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption">
                        {t('Roles.Table.NoResultsFound', 'No Roles found')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                Array.from(permissionsByCategory.entries()).map(
                  ([category, permissions]) => {
                    return (
                      <TableBody key={category}>
                        <TableRow key={category}>
                          <TableCell
                            component="th"
                            scope="rowgroup"
                            key={`${category} heading`}
                            /** We use a percentage here to make sure it still looks the same after a user performs a search.
                             * The number 17 is chosen because this allows the UI to look good on all device screens.
                             */
                            width="17%"
                          >
                            <Typography variant="subtitle2" component="h3">
                              {category}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {permissions.map((permission) => {
                          return (
                            <TableRow
                              key={`${permission.actionCode} ${permission.resourceCode}`}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                key={`${permission.actionCode} ${permission.resourceCode} label`}
                              >
                                <Tooltip title={permission.description ?? ''}>
                                  <Typography variant="caption">
                                    {presentationName(permission)}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                              {searchedRoles.map((role) => {
                                const grant = grantForPermissionByRole(
                                  permission,
                                  role
                                )
                                return (
                                  <TableCell
                                    align="center"
                                    key={`role ${role.name}`}
                                  >
                                    <GrantIcon grant={grant} />
                                  </TableCell>
                                )
                              })}
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    )
                  }
                )
              )}
            </StyledTable>
          </StyledTableContainer>
        </>
      )}
    </Page>
  )
}

export default Roles
