/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 1457424e (Thu Aug  8 13:36:41 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  UpdateRegistrantRequestBody,
  UpdateRegistrantRequestBodyToJSON,
} from '../models'
import * as runtime from '../runtime'

export interface UpdateRegistrantRequest extends runtime.BaseRequestParameters {
  body?: UpdateRegistrantRequestBody
}

/**
 *
 */
export class RegistrantsApi extends runtime.BaseAPI {
  /**
   *    Only a user with an active grant to \'edit\' is able to modify the existing registrants.    Calling this endpoint will trigger the update of a registrant and it will require the following fields:    registrantKey, firstName, lastName, email and attending.        This endpoint will return 204, when the update is performed successfully.
   * Update an existing registrant
   */
  async updateRegistrantRaw(
    requestParameters: UpdateRegistrantRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/registrant`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateRegistrantRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   *    Only a user with an active grant to \'edit\' is able to modify the existing registrants.    Calling this endpoint will trigger the update of a registrant and it will require the following fields:    registrantKey, firstName, lastName, email and attending.        This endpoint will return 204, when the update is performed successfully.
   * Update an existing registrant
   */
  async updateRegistrant(
    requestParameters: UpdateRegistrantRequest
  ): Promise<void> {
    await this.updateRegistrantRaw(requestParameters)
  }
}
