import React, { PropsWithChildren } from 'react'
import { Navigate, useLocation } from 'react-router'
import { useAuth } from './AuthProvider'

const PrivateRoute: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { isLoggedIn, countryInformation } = useAuth()
  const loggedIn = !!isLoggedIn
  const location = useLocation()

  const hasCountryInfo =
    !!countryInformation.countryOfCitizenship &&
    !!countryInformation.countryOfResidence

  return loggedIn && hasCountryInfo ? (
    <>{children}</>
  ) : isLoggedIn && !hasCountryInfo ? (
    <Navigate
      to={{
        pathname: '/country-information',
      }}
      state={{ from: location.pathname }}
      replace
    />
  ) : (
    <Navigate
      to={{
        pathname: '/login',
      }}
      state={{ from: location.pathname }}
      replace
    />
  )
}

export default PrivateRoute
