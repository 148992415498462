import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Typography from '@mui/material/Typography'
import { CheckboxesTypes } from './EnrollmentsTable'
import { useTranslation } from 'react-i18next'

type SemesterTuitionPaid = {
  [K in
    | CheckboxesTypes.semesterOneTuitionPaid
    | CheckboxesTypes.semesterTwoTuitionPaid]?: boolean
}
interface LicensingOwedCheckboxesProps {
  studentKey: number
  programKey: number
  semesterOneLicensingOwed?: boolean
  semesterTwoLicensingOwed?: boolean
  isChallenge: boolean
  canRemoveTuitionPayment?: boolean
  isEnrollmentsTableEdit: boolean
  handleOnChange: (
    studentKey: number,
    programKey: number,
    checkboxType: string
  ) => void
  semesterTuitionPaid: SemesterTuitionPaid
}

const LicensingOwedCheckboxes: React.FC<LicensingOwedCheckboxesProps> = ({
  semesterOneLicensingOwed,
  semesterTwoLicensingOwed,
  studentKey,
  programKey,
  isChallenge,
  isEnrollmentsTableEdit,
  handleOnChange,
  semesterTuitionPaid,
}) => {
  const { semesterOneTuitionPaid, semesterTwoTuitionPaid } = semesterTuitionPaid

  let isSemesterOneLicensingOwedDisabled = false
  let isSemesterTwoLicensingOwedDisabled = false

  /*
   Validates that 
   1. if the Tuition Paid checkbox is unchecked, the Licensing Owed checkboxes should not be allowed to be checked. 
   2. if both checkboxes semesterOneTuitionPaid and semesterOneLicensingOwed are checked and then uncheck 
   semesterOneTuitionPaid the checkbox corresponding to semesterOneLicensingOwed must be disabled immediately
   to avoid its modification.
   */

  if (!semesterOneTuitionPaid) {
    isSemesterOneLicensingOwedDisabled = true
  }
  if (!semesterTwoTuitionPaid) {
    isSemesterTwoLicensingOwedDisabled = true
  }

  const { t } = useTranslation()
  return (
    <FormGroup sx={{ flexDirection: 'row' }}>
      <FormControlLabel
        control={
          <Checkbox
            name="licensingOwedCheckBox1stSemester"
            color="secondary"
            sx={{ color: 'rgba(0, 0, 0, 0.26)' }}
          />
        }
        aria-label="1st Semester"
        label={
          isChallenge && (
            <Typography variant="caption" component="p">
              {t(
                'LicensingOwedCheckboxes.Checkbox.FirstSemester',
                '1st Semester'
              )}
            </Typography>
          )
        }
        disabled={!isEnrollmentsTableEdit || isSemesterOneLicensingOwedDisabled}
        checked={semesterOneLicensingOwed}
        onChange={() =>
          handleOnChange(
            studentKey,
            programKey,
            CheckboxesTypes.semesterOneLicensingOwed
          )
        }
      />
      {isChallenge && (
        <FormControlLabel
          control={
            <Checkbox
              name="licensingOwedCheckBox2ndSemester"
              color="secondary"
              sx={{ color: 'rgba(0, 0, 0, 0.26)' }}
            />
          }
          aria-label="2nd Semester"
          label={
            <Typography variant="caption" component="p">
              {t(
                'LicensingOwedCheckboxes.Checkbox.SecondSemester',
                '2nd Semester'
              )}
            </Typography>
          }
          disabled={
            !isEnrollmentsTableEdit || isSemesterTwoLicensingOwedDisabled
          }
          checked={semesterTwoLicensingOwed}
          onChange={() =>
            handleOnChange(
              studentKey,
              programKey,
              CheckboxesTypes.semesterTwoLicensingOwed
            )
          }
        />
      )}
    </FormGroup>
  )
}

export default LicensingOwedCheckboxes
