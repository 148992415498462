import React, { useEffect, useState } from 'react'
import { CommunityDetail } from '../../swagger/models'
import CommunityFormCard, {
  CommunityFormCardVariants,
} from '../Card/CommunityFormCard'
import { useTranslation } from 'react-i18next'
import { fetchCommunity } from '../../api/communities'
import { Ability } from '@casl/ability'
import Typography from '@mui/material/Typography'
import EmptyPage from '../Elements/EmptyPage'
import { useCommunitiesContext } from '../Context/CommunitiesContext'
import { useCommunityParams } from '../../hooks/useCommunityParams'

export const CommunityDetailsTab: React.FunctionComponent = () => {
  const { t } = useTranslation()

  const [fetchInitialDetails, setFetchInitialDetails] = useState(true)
  const [communityAbility, setCommunityAbility] = useState<Ability>()

  const [communityDetails, setCommunityDetails] = useState<
    CommunityDetail | undefined
  >(undefined)
  const [initialCommunityDetails, setInitialCommunityDetails] = useState<
    CommunityDetail | undefined
  >(undefined)

  const [isCancel, setIsCancel] = useState(false)

  const resetFieldsForCancel = () => {
    updateCancel(false)
    setCommunityDetails(initialCommunityDetails)
  }

  const updateCancel = (newValue: boolean) => {
    setIsCancel(newValue)
  }

  const { academicYear, updateCommunityName } = useCommunitiesContext()

  const { communityId } = useCommunityParams()

  useEffect(() => {
    if (fetchInitialDetails) {
      // Just in case we update the communityKey during community creation, let's not fetch the community details to populate fields.
      const fetchCommunityDetails = async () => {
        const fetchedCommunityDetails = await fetchCommunity(
          parseInt(`${communityId}`)
        )

        setCommunityDetails(fetchedCommunityDetails)
        updateCommunityName(fetchedCommunityDetails?.name ?? '')
        setInitialCommunityDetails(fetchedCommunityDetails)
        setCommunityAbility(
          new Ability(
            fetchedCommunityDetails?.meta?.permissions?.map(
              ({ resourceCode: subject, actionCode: action }) => ({
                subject,
                action,
              })
            )
          )
        )
      }
      fetchCommunityDetails()
      setFetchInitialDetails(false)
    }
  }, [communityId, fetchInitialDetails, updateCommunityName])

  const handleCancel = () => {
    updateCancel(true)
  }
  const handleSave = () => {
    setFetchInitialDetails(true)
  }

  const noCommunityDetailsMessage = (
    <Typography
      variant="subtitle1"
      component="p"
      style={{ whiteSpace: 'pre-line' }}
    >
      {t(
        'Communities.CommunityDetails.NoDetails',
        'Program enrollment not found.\nPlease complete enrollment to view Communities.'
      )}
    </Typography>
  )

  if (communityDetails) {
    return (
      <>
        <CommunityFormCard
          title={t(
            'Communities.CommunityDetails.FormCard.Header',
            'Community Details'
          )}
          variant={CommunityFormCardVariants.EditCommunity}
          communityDetails={communityDetails}
          handleSave={handleSave}
          communityAbility={communityAbility}
          academicYear={academicYear}
          resetFieldsForCancel={resetFieldsForCancel}
          isCancel={isCancel}
          handleCancel={handleCancel}
          updateCancel={updateCancel}
        />
      </>
    )
  }
  return <EmptyPage message={noCommunityDetailsMessage} />
}

export default CommunityDetailsTab
