import React from 'react'
import Header, { HeaderVariant } from '../Elements/Header'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import WarningIcon from '@mui/icons-material/Warning'
import CardFormHeader from './CardFormHeader'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import { useTranslation } from 'react-i18next'
import TableHeader from '../Table/TableHeader'
import TableHeaders from '../Interfaces/TableHeaders'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { DynamicFieldStates } from '../Interfaces/DynamicFieldStates'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import { Student, Program3, Enrollment } from '../../swagger'
import { useAuth } from '../Routes/AuthProvider'
import ChildrenEnrollmentCard from './ChildrenEnrollmentCard'
import StudentEnrollmentsRows from '../Table/StudentEnrollmentsRows'
import { directorOnlyEnrollmentStatuses } from '../../helpers/enrollmentHelper'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material'

const ChildrenSummaryCardHeader = styled(Header)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(1.5),
  },
}))

const TableTopBar = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2.5, 2, 2.5, 2), // 2.5,2,1,2
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

export enum ChildrenSummaryCardVariant {
  Family,
  Account,
}

export const programsForStudent = (programs: Program3[]): string => {
  const programNames = programs.map((program) => {
    return `${program.programType}`
  })
  return programNames.join(', ')
}

export type KidPrograms = Pick<
  Enrollment,
  'programKey' | 'studentKey' | 'status'
>

export interface ChildrenTableProps {
  variant: ChildrenSummaryCardVariant
  title: string
  kids: Student[]
  isEditMode: boolean
  childNameStates: DynamicFieldStates
  childHideStates: DynamicFieldStates
  childBirthMonthStates: DynamicFieldStates
  childBirthYearStates: DynamicFieldStates
  enrollmentStates: DynamicFieldStates
  handleFieldValueChange: (fieldId: string, value: string) => void
  handleEditForParent?: () => void
  handleSave?: () => void
  handleCancelForParent?: () => void

  handleEnrollmentStatusChange?: (opts: {
    fieldId: string
    enrollmentStatus: string
    studentKey: number
    programKey: number
    studentName: string
  }) => void
}

export const ChildrenSummaryCard: React.FC<ChildrenTableProps> = ({
  variant,
  title,
  kids,
  isEditMode,
  childNameStates,
  childHideStates,
  childBirthMonthStates,
  childBirthYearStates,
  handleFieldValueChange,
  handleEditForParent,
  handleSave,
  handleCancelForParent,
  enrollmentStates,
  handleEnrollmentStatusChange,
}) => {
  const { t } = useTranslation()
  const showOnMobile = !useShowOnDesktop()
  const theme = useTheme()

  const isFamilyVariant = variant === ChildrenSummaryCardVariant.Family
  const { permissionAbility } = useAuth()

  const tableHeaders: TableHeaders[] = [
    {
      label: t('Families.Children.Collapse.Header', ''),
      align: 'left',
      id: 'collapse',
      colSpan: 6,
    },
  ]

  const enrollmentTableHeaders: TableHeaders[] = [
    {
      label: t('Families.Children.Program.Header', 'Program'),
      align: 'left',
    },
    {
      label: t(
        'Families.Children.EnrollmentStatus.Header',
        'Enrollment Status'
      ),
      align: 'left',
    },
    {
      label: t(
        'Families.Children.EnrollmentStatusDate.Header',
        'Enrollment Status Date'
      ),
      align: 'left',
    },
  ]

  const canUserUpdateAnyEnrollmentStatus = permissionAbility.can(
    'updateToAnyStatus',
    'Enrollment'
  )

  const canHideChildren = permissionAbility.can('hide', 'Student')

  const handleEdit = () => {
    handleEditForParent?.()
  }

  const handleCancel = () => {
    handleCancelForParent?.()
  }

  const FamilySectionHead = (
    <TableTopBar>
      <ChildrenSummaryCardHeader
        id={title}
        headerName={title}
        component="h2"
        variant={HeaderVariant.SettingsHeader}
      />
      {!!kids.length && (
        <Box mt={3} width={{ xs: '100%', sm: '100%', md: 'unset' }}>
          {!isEditMode ? (
            <ContainedButton
              id="childrenTableEdit"
              variant={ContainedButtonVariant.Edit}
              onClick={handleEdit}
              fullWidth={showOnMobile}
            />
          ) : (
            <React.Fragment>
              <ContainedButton
                id="childrenTableSave"
                variant={ContainedButtonVariant.Save}
                onClick={handleSave}
                fullWidth={showOnMobile}
              />
              <TextButton
                id="childrenTableCancel"
                onClick={handleCancel}
                variant={TextButtonVariant.Cancel}
                fullWidth={showOnMobile}
              />
            </React.Fragment>
          )}
        </Box>
      )}
    </TableTopBar>
  )

  const AccountSectionHead = (
    <React.Fragment>
      <CardFormHeader
        header={
          <Header
            id={title}
            headerName={title}
            component="h2"
            variant={HeaderVariant.Card}
          />
        }
      />
      <Box
        mb={1.5}
        display="flex"
        alignItems="center"
        color="textOrIcon.tableHeader"
      >
        <Box mr={0.75}>
          <WarningIcon />
        </Box>
        <Typography variant="body2">
          {t(
            'Account.Profile.Children.Subtitle',
            "Your children's information is NEVER shared and visible only to you and your CC leadership."
          )}
        </Typography>
      </Box>
    </React.Fragment>
  )

  const SectionHead = isFamilyVariant ? FamilySectionHead : AccountSectionHead

  const isAccountVariant = variant === ChildrenSummaryCardVariant.Account
  // Filter out hidden children, since it is not taken from context
  const noKids = kids.filter((kid) => !kid.hidden).length === 0
  // Kids cards for mobile version
  if (showOnMobile) {
    return (
      <React.Fragment>
        {SectionHead}
        {noKids && !canHideChildren ? (
          <Paper
            sx={{
              ...(isAccountVariant && {
                marginTop: theme.spacing(3),
                padding: theme.spacing(4, 4, 5.5),
              }),
            }}
          >
            <Box pt={2} color="textOrIcon.tableHeader">
              <Typography variant="subtitle1" component="p">
                {t(
                  'ChildrenSummaryCard.Children.Empty',
                  'No children enrolled'
                )}
              </Typography>
            </Box>
          </Paper>
        ) : (
          kids.map((kid, index) => (
            <Box
              sx={{
                margin: theme.spacing(2.5),
              }}
              key={index}
            >
              <ChildrenEnrollmentCard
                key={`${kid.studentKey}`}
                kid={kid}
                isEditMode={isEditMode}
                handleFieldValueChange={handleFieldValueChange}
                childNameStates={childNameStates}
                childHideStates={childHideStates}
                childBirthMonthStates={childBirthMonthStates}
                childBirthYearStates={childBirthYearStates}
                canUserUpdateAnyEnrollmentStatus={
                  canUserUpdateAnyEnrollmentStatus
                }
                isAccountVariant={isAccountVariant}
                handleEnrollmentStatusChange={(opts) =>
                  handleEnrollmentStatusChange?.(opts)
                }
                directorOnlyEnrollmentStatuses={directorOnlyEnrollmentStatuses}
                enrollmentStates={enrollmentStates}
              />
            </Box>
          ))
        )}
      </React.Fragment>
    )
  }

  const spacing = isAccountVariant
    ? {
        pt: 2,
      }
    : {
        px: 2,
        pb: 2,
      }

  // Kids rows for desktop version
  const kidRows = kids.map((kid) => (
    <StudentEnrollmentsRows
      key={`${kid.studentKey}`}
      kid={kid}
      isEditMode={isEditMode}
      handleFieldValueChange={handleFieldValueChange}
      childNameStates={childNameStates}
      childHideStates={childHideStates}
      childBirthMonthStates={childBirthMonthStates}
      childBirthYearStates={childBirthYearStates}
      canUserUpdateAnyEnrollmentStatus={canUserUpdateAnyEnrollmentStatus}
      handleEnrollmentStatusChange={(opts) =>
        handleEnrollmentStatusChange?.(opts)
      }
      directorOnlyEnrollmentStatuses={directorOnlyEnrollmentStatuses}
      tableHeaders={enrollmentTableHeaders}
      enrollmentStates={enrollmentStates}
    />
  ))

  return (
    <TableContainer
      component={Paper}
      sx={{
        ...(isAccountVariant && {
          marginTop: theme.spacing(3),
          padding: theme.spacing(4, 4, 5.5),
        }),
      }}
    >
      {SectionHead}
      {noKids && !canHideChildren ? (
        <Box {...spacing} color="textOrIcon.tableHeader">
          <Typography variant="subtitle1" component="p">
            {t('Account.Profile.Children.Empty', 'No children enrolled')}
          </Typography>
        </Box>
      ) : (
        <Table aria-labelledby={title}>
          <TableHead>
            <TableHeader tableHeaders={tableHeaders} />
          </TableHead>
          <TableBody>{kidRows}</TableBody>
        </Table>
      )}
    </TableContainer>
  )
}

export default ChildrenSummaryCard
